import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import React from 'react'
import { makeStyles } from '@mui/styles'
import PropTypes from 'prop-types'
import Slide from '@mui/material/Slide'
import {FormattedMessage} from "react-intl";

const useStyles = makeStyles(theme => ({
    root: {
        position: 'fixed',
        bottom: '50px',
        width: '70%',
        margin: 'auto',
        left: '240px',
        right: '0px',
        height: '60px',
        zIndex: '100000000',
        borderRadius: '10px',
        backgroundColor: 'rgba(0, 0, 0, 0.9)',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: '0px 15px',
        transition: 'all 150ms linear',
        [theme.breakpoints.down('xs')]: {
            left: '0px',
            width: '90%'
        }
    },
    text: {
        flex: 'auto',
        margin: 'auto 20px'
    },
    button: {
        margin: 'auto 20px'
    }
}))

const SaveUnsavedChangesPopup = (props) => {
    const classes = useStyles()
    const { show, onSaveClicked } = props

    const saveClickedHandler = event => {
        onSaveClicked()
    }

    return (
        <Slide direction="up" in={show}>
            <div className={classes.root}>
                <Typography className={classes.text}>
                    <FormattedMessage
                        id={'SaveBar.UnsavedChanges'}
                        defaultMessage={'Du har ulagrede endringer, ønsker du å lagre?'}
                    />
                </Typography>
                <Button variant={'contained'} className={classes.button} onClick={saveClickedHandler}>
                    <FormattedMessage
                        id={'Global.SaveButton'}
                        defaultMessage={'Lagre'}
                    />
                </Button>
            </div>
        </Slide>
    )
}

SaveUnsavedChangesPopup.propTypes = {
    show: PropTypes.bool,
    onSaveClicked: PropTypes.func
}

export default SaveUnsavedChangesPopup
