import React, { useEffect, useState } from 'react'

import Grid from '@mui/material/Grid'
import { makeStyles } from '@mui/styles'
import Typography from '@mui/material/Typography'
import AddIcon from '@mui/icons-material/AddCircle'
import { Link, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { getFinanceOptions } from '../store/actions/financeOptionActions'
import FinanceOptionCard from '../components/FinanceOptionCard'
import Fade from '@mui/material/Fade'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'

const useStyles = makeStyles(theme => ({
  root: {
    padding: '20px'
  },
  newFinanceOption: {
    padding: '20px',
    height: '100px',
    overflow: 'hidden',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '20px',
    display: 'flex',
    background: 'none'
  },
  addIcon: {
    height: '50px',
    width: '50px',
    margin: '10px auto'
  },
  createProjectButton: {
    cursor: 'pointer',
    display: 'grid',
    userSelect: 'none',
    transition: 'all 150ms ease-out',
    color: '#FFFFFF66',
    '&:hover': {
      color: '#FFFFFFFF'
    }
  },
  filterHeader: {
    display: 'flex'
  },
  columnHeader: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    borderRadius: '25px',
    transition: 'all 150ms ease-in-out',
    height: '40px',
    userSelect: 'none',
    '&:hover': {
      background: 'rgba(0, 0, 0, 0.2)'
    }
  },
  columnHeaderOrderIcon: {
    display: 'flex'
  }
}))

const YourFundingPage = (props) => {
  const { financeOptions, userInfo } = props
  const classes = useStyles()

  useEffect(() => {
    props.getYourFinanceOptions()
  }, [])

  const [ordering, setOrdering] = useState({
    column: 'name',
    orderAsc: false
  })

  const setOrderingClicked = (columnName) => {
    setOrdering({
      column: columnName,
      orderAsc: ordering.column === columnName ? !ordering.orderAsc : false
    })
  }

  let orderedOptions = financeOptions.filter(x => x.organizationId === userInfo.currentlyActiveOrganization)

  if (ordering.column === 'name') {
    orderedOptions = orderedOptions.sort((a, b) => ordering.orderAsc
      ? (a.name > b.name ? -1 : 1)
      : (b.name > a.name ? -1 : 1))
  } else if (ordering.column === 'orgForm') { orderedOptions = orderedOptions.sort((a, b) => ordering.orderAsc ? a.orgForm - b.orgForm : b.orgForm - a.orgForm) } else if (ordering.column === 'sector') { orderedOptions = orderedOptions.sort((a, b) => ordering.orderAsc ? a.sector - b.sector : b.sector - a.sector) } else if (ordering.column === 'type') { orderedOptions = orderedOptions.sort((a, b) => ordering.orderAsc ? a.type - b.type : b.type - a.type) } else if (ordering.column === 'deadline') {
    orderedOptions = orderedOptions.sort(function (optionA, optionB) {
      const optionADeadlines = optionA.deadlines.sort((a, b) => new Date(b.deadline) - new Date(a.deadline))
      const optionBDeadlines = optionB.deadlines.sort((a, b) => new Date(b.deadline) - new Date(a.deadline))

      if (optionADeadlines.length === 0 && optionBDeadlines.length > 0) { return 1 } else if (optionBDeadlines.length === 0 && optionADeadlines.length > 0) { return -1 } else if (optionADeadlines.length === 0 && optionBDeadlines.length === 0) { return 0 }

      if (ordering.orderAsc) { return new Date(optionBDeadlines[0].deadline) - new Date(optionADeadlines[0].deadline) } else { return new Date(optionADeadlines[0].deadline) - new Date(optionBDeadlines[0].deadline) }
    })
  }

  return (
        <div className={classes.root}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant={'body2'}>
                        Her kan du administrere finansieringskilder din bedrift tilbyr
                    </Typography>
                    <Typography variant={'h4'}>
                        Mine Finansieringskilder
                    </Typography>
                </Grid>
                <Grid item sm={12} lg={12}>
                    <Grid container justify={'flex-start'} alignItems={'center'} spacing={2}>
                        <Grid item xs={12} lg={5} align={'left'}
                              style={{ paddingLeft: '20px' }}
                              onClick={(event) => setOrderingClicked('name')}
                              className={classes.columnHeader}
                        >
                            <Typography variant={'caption'}
                                        className={classes.filterHeader}
                            >
                                Navn
                            </Typography>
                            {
                                ordering.column === 'name' &&
                                <div>
                                    {
                                        ordering.orderAsc ? <KeyboardArrowUpIcon className={classes.columnHeaderOrderIcon} /> : <KeyboardArrowDownIcon className={classes.columnHeaderOrderIcon} />
                                    }
                                </div>
                            }
                        </Grid>
                        <Grid item xs={2} lg={1} align={'center'}
                              onClick={(event) => setOrderingClicked('orgForm')}
                              className={classes.columnHeader}>
                            <Typography variant={'caption'}
                                        className={classes.filterHeader}
                            >
                                Formål
                            </Typography>
                            {
                                ordering.column === 'orgForm' &&
                                <div>
                                    {
                                        ordering.orderAsc ? <KeyboardArrowUpIcon className={classes.columnHeaderOrderIcon} /> : <KeyboardArrowDownIcon className={classes.columnHeaderOrderIcon} />
                                    }
                                </div>
                            }
                        </Grid>
                        <Grid item xs={2} lg={1} align={'center'}
                              onClick={(event) => setOrderingClicked('sector')}
                              className={classes.columnHeader}>
                            <Typography variant={'caption'}
                                        className={classes.filterHeader}
                            >
                                Sektor
                            </Typography>
                            {
                                ordering.column === 'sector' &&
                                <div>
                                    {
                                        ordering.orderAsc ? <KeyboardArrowUpIcon className={classes.columnHeaderOrderIcon} /> : <KeyboardArrowDownIcon className={classes.columnHeaderOrderIcon} />
                                    }
                                </div>
                            }
                        </Grid>
                        <Grid item xs={2} lg={1} align={'center'}
                              onClick={(event) => setOrderingClicked('type')}
                              className={classes.columnHeader}>
                            <Typography variant={'caption'}
                                        className={classes.filterHeader}
                            >
                                Type
                            </Typography>
                            {
                                ordering.column === 'type' &&
                                <div>
                                    {
                                        ordering.orderAsc ? <KeyboardArrowUpIcon className={classes.columnHeaderOrderIcon} /> : <KeyboardArrowDownIcon className={classes.columnHeaderOrderIcon} />
                                    }
                                </div>
                            }
                        </Grid>
                        <Grid item xs={2} lg={2} align={'right'}
                              onClick={(event) => setOrderingClicked('deadline')}
                              className={classes.columnHeader}>
                            <Typography variant={'caption'}
                                        className={classes.filterHeader}
                            >
                                Neste søknadsfrist
                            </Typography>
                            {
                                ordering.column === 'deadline' &&
                                <div>
                                    {
                                        ordering.orderAsc ? <KeyboardArrowUpIcon className={classes.columnHeaderOrderIcon} /> : <KeyboardArrowDownIcon className={classes.columnHeaderOrderIcon} />
                                    }
                                </div>
                            }
                        </Grid>
                        <Grid item xs={2} lg={2} align={'right'}>
                        </Grid>
                    </Grid>
                </Grid>
                {
                    orderedOptions.map((financeOption, index) => (
                        <Fade key={index} in timeout={500} style={{ transitionDelay: index * 25 }}>
                            <Grid item xs={12} sm={12} key={financeOption.id}>
                                <FinanceOptionCard option={financeOption} {...props} />
                            </Grid>
                        </Fade>
                    ))
                }
                <Grid item xs={12} sm={12}>
                    <div className={classes.newFinanceOption} >
                        <Link to={'new-funding'}
                              className={classes.createProjectButton}
                        >
                            <AddIcon className={classes.addIcon} />
                            <Typography variant={'subtitle2'}>
                                Opprett finansieringskilde
                            </Typography>
                        </Link>

                    </div>
                </Grid>
            </Grid>
        </div>
  )
}

const mapStateToProps = (state) => {
  return {
    error: state.financeOption.error,
    financeOptions: state.financeOption.financeOptions,
    userInfo: state.user.userInfo
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getYourFinanceOptions: () => dispatch(getFinanceOptions())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(YourFundingPage))
