import axios from 'axios'
import { API_ROOT, configWithAuth } from '../api'
import { history } from '../history'

export const getYourProjects = (organizationId) => {
  return (dispatch) => {
    dispatch({ type: 'GET_PROJECTS.REQUEST' })

    axios.get(`${API_ROOT}project/${organizationId}`, configWithAuth())
      .then((result) => {
        dispatch({ type: 'GET_PROJECTS.SUCCESS', data: result.data })
      })
      .catch((error) => {
        dispatch({ type: 'GET_PROJECTS.ERROR', error })
        dispatch({ type: 'SET_SNACKBAR', data: { variant: 'error', message: 'Fikk ikke hentet prosjekter.' } })
      })
  }
}

export const createProject = (newProject) => {
  return (dispatch) => {
    dispatch({ type: 'CREATE_PROJECT.REQUEST' })

    axios.post(`${API_ROOT}project`, JSON.stringify(newProject), configWithAuth())
      .then((result) => {
        dispatch({ type: 'CREATE_PROJECT.SUCCESS', data: result.data })
        dispatch({ type: 'SET_SNACKBAR', data: { variant: 'success', message: 'Prosjekt opprettet.' } })
        history.push('/projects/' + result.data.id)
      })
      .catch((error) => {
        dispatch({ type: 'CREATE_PROJECT.ERROR', error })
      })
  }
}

export const deleteProject = (projectId) => {
  return (dispatch) => {
    dispatch({ type: 'DELETE_PROJECT.REQUEST' })

    axios.delete(`${API_ROOT}project/${projectId}`, configWithAuth())
      .then((result) => {
        dispatch({ type: 'DELETE_PROJECT.SUCCESS', data: projectId })
        dispatch({ type: 'SET_SNACKBAR', data: { variant: 'success', message: 'Prosjektet er slettet.' } })
        history.push('/projects')
      })
      .catch((error) => {
        dispatch({ type: 'DELETE_PROJECT.ERROR', error })
        dispatch({ type: 'SET_SNACKBAR', data: { variant: 'error', message: 'Feil ved sletting av prosjekt.' } })
      })
  }
}

export const patchProject = (patch, projectId) => {
  return (dispatch) => {
    dispatch({ type: 'PATCH_PROJECT.REQUEST' })

    axios.patch(`${API_ROOT}project/${projectId}`, JSON.stringify(patch), configWithAuth('application/json-patch+json'))
      .then((result) => {
        dispatch({ type: 'PATCH_PROJECT.SUCCESS', data: result.data })
        dispatch({ type: 'SET_SNACKBAR', data: { variant: 'success', message: 'Prosjektet er oppdatert.' } })
      })
      .catch((error) => {
        dispatch({ type: 'PATCH_PROJECT.ERROR', error })
        dispatch({ type: 'SET_SNACKBAR', data: { variant: 'error', message: 'Feil ved oppdatering av prosjekt.' } })
      })
  }
}

export const getGenres = () => {
  return (dispatch) => {
    dispatch({ type: 'GET_GENRES.REQUEST' })

    axios.get(`${API_ROOT}project/genres`, configWithAuth())
      .then((result) => {
        dispatch({ type: 'GET_GENRES.SUCCESS', data: result.data })
      })
      .catch((error) => {
        dispatch({ type: 'GET_GENRES.ERROR', error })
        dispatch({ type: 'SET_SNACKBAR', data: { variant: 'error', message: 'Fikk ikke hentet sjangre.' } })
      })
  }
}

export const uploadCoverImage = (formData) => {
  return (dispatch) => {
    dispatch({ type: 'UPDATE_PROJECT_COVER.REQUEST' })

    axios.post(`${API_ROOT}project/change-cover`, formData, configWithAuth('multipart/form-data'))
      .then((result) => {
        dispatch({ type: 'UPDATE_PROJECT_COVER.SUCCESS', data: result.data })
        dispatch({ type: 'SET_SNACKBAR', data: { variant: 'success', message: 'Prosjektbilde er oppdatert.' } })
      })
      .catch((error) => {
        dispatch({ type: 'UPDATE_PROJECT_COVER.ERROR', error })
        dispatch({ type: 'SET_SNACKBAR', data: { variant: 'error', message: 'Feil ved oppdatering av prosjektbilde.' } })
      })
  }
}
