const initState = {
  error: '',
  fetchingFinanceOptions: false,
  creatingFinanceOption: false,
  patchingFinanceOption: false,
  subscriptionChanging: false,
  subscriptionFetching: false,
  creatingDeadline: false,
  deletingDeadline: false,
  updatingDeadline: false,
  financeOptions: [],
  financeOptionsSubscriptions: [],
  grants: []
}

const financeOptionReducer = (state = initState, action) => {
  switch (action.type) {
    case 'GET_FINANCEOPTIONS_REQUEST':
      return {
        ...state,
        error: '',
        fetchingFinanceOptions: true
      }
    case 'GET_FINANCEOPTIONS_SUCCESS':
      return {
        ...state,
        error: '',
        fetchingFinanceOptions: false,
        financeOptions: action.data
      }
    case 'GET_FINANCEOPTIONS_ERROR':
      return {
        ...state,
        fetchingFinanceOptions: false,
        error: 'Failed to fetch user info'
      }

    case 'CREATE_FINANCEOPTION_REQUEST':
      return {
        ...state,
        creatingFinanceOption: true,
        error: ''
      }
    case 'CREATE_FINANCEOPTION_SUCCESS':
    {
      const financeOptionsCopy = state.financeOptions
      const existingIndex = financeOptionsCopy.findIndex(x => x.id === action.data.id)
      if (existingIndex >= 0) { financeOptionsCopy[existingIndex] = action.data }

      return {
        ...state,
        error: '',
        creatingFinanceOption: false,
        financeOptions: financeOptionsCopy
      }
    }
    case 'CREATE_FINANCEOPTION_ERROR':
      return {
        ...state,
        creatingFinanceOption: false,
        error: 'Failed to fetch user info'
      }

    case 'PATCH_PROJECT_ERROR':
      return {
        ...state,
        error: action.error
      }

    case 'DELETE_FINANCEOPTION_SUCCESS':
    {
      const financeOptionsCopy = state.financeOptions
      const existingIndex = financeOptionsCopy.findIndex(x => x.id === action.data)
      if (existingIndex >= 0) { financeOptionsCopy.splice(existingIndex, 1) }

      return {
        ...state,
        financeOptions: financeOptionsCopy
      }
    }
    case 'DELETE_FINANCEOPTION_ERROR':
      return {
        ...state,
        error: action.error
      }

    case 'CREATE_FINANCEOPTION_DEADLINE_REQUEST':
      return {
        ...state,
        error: '',
        creatingDeadline: true
      }
    case 'CREATE_FINANCEOPTION_DEADLINE_SUCCESS':
    {
      const financeOptionsCopy = state.financeOptions
      const existingIndex = financeOptionsCopy.findIndex(x => x.id === action.data.id)
      if (existingIndex >= 0) { financeOptionsCopy[existingIndex] = action.data }

      return {
        ...state,
        error: '',
        creatingDeadline: false,
        financeOptions: financeOptionsCopy
      }
    }
    case 'CREATE_FINANCEOPTION_DEADLINE_ERROR':
      return {
        ...state,
        creatingDeadline: false,
        error: 'Failed to fetch user info'
      }

    case 'UPDATE_FINANCEOPTION_DEADLINE_REQUEST':
      return {
        ...state,
        error: '',
        updatingDeadline: true
      }
    case 'UPDATE_FINANCEOPTION_DEADLINE_SUCCESS':
    {
      const financeOptionsCopy = state.financeOptions
      const existingIndex = financeOptionsCopy.findIndex(x => x.id === action.data.id)
      if (existingIndex >= 0) { financeOptionsCopy[existingIndex] = action.data }

      return {
        ...state,
        error: '',
        updatingDeadline: false,
        financeOptions: financeOptionsCopy
      }
    }
    case 'UPDATE_FINANCEOPTION_DEADLINE_ERROR':
      return {
        ...state,
        updatingDeadline: false,
        error: 'Failed to fetch user info'
      }

    case 'DELETE_FINANCEOPTION_DEADLINE_REQUEST':
      return {
        ...state,
        error: '',
        deletingDeadline: true
      }
    case 'DELETE_FINANCEOPTION_DEADLINE_SUCCESS':
    {
      const financeOptionsCopy = state.financeOptions
      const existingIndex = financeOptionsCopy.findIndex(x => x.id === action.data.id)
      if (existingIndex >= 0) { financeOptionsCopy[existingIndex] = action.data }

      return {
        ...state,
        error: '',
        deletingDeadline: false,
        financeOptions: financeOptionsCopy
      }
    }
    case 'DELETE_FINANCEOPTION_DEADLINE_ERROR':
      return {
        ...state,
        deletingDeadline: false,
        error: 'Failed to fetch user info'
      }

    case 'PATCH_FINANCEOPTION_REQUEST':
      return {
        ...state,
        error: '',
        patchingFinanceOption: true
      }
    case 'PATCH_FINANCEOPTION_SUCCESS':
    {
      const financeOptionsCopy = state.financeOptions
      const existingIndex = financeOptionsCopy.findIndex(x => x.id === action.data.id)
      if (existingIndex >= 0) { financeOptionsCopy[existingIndex] = action.data }

      return {
        ...state,
        error: '',
        patchingFinanceOption: false,
        financeOptions: financeOptionsCopy
      }
    }
    case 'PATCH_FINANCEOPTION_ERROR':
      return {
        ...state,
        patchingFinanceOption: false,
        error: 'Failed to fetch user info'
      }

    case 'SUBSCRIPTION_GET_REQUEST':
      return {
        ...state,
        error: '',
        subscriptionFetching: true
      }
    case 'SUBSCRIPTION_GET_SUCCESS':
    {
      return {
        ...state,
        error: '',
        subscriptionFetching: false,
        financeOptionsSubscriptions: action.data
      }
    }
    case 'SUBSCRIPTION_GET_ERROR':
      return {
        ...state,
        subscriptionFetching: false,
        error: 'Failed to fetch user info'
      }

    case 'SUBSCRIPTION_CHANGE_REQUEST':
      return {
        ...state,
        error: '',
        subscriptionChanging: true
      }
    case 'SUBSCRIPTION_CHANGE_SUCCESS':
    {
      return {
        ...state,
        error: '',
        subscriptionChanging: false,
        financeOptionsSubscriptions: action.data
      }
    }
    case 'SUBSCRIPTION_CHANGE_ERROR':
      return {
        ...state,
        subscriptionChanging: false,
        error: 'Failed to fetch user info'
      }

    case 'UPLOAD_FINANCEOPTIONRESOURCE.REQUEST':
    {
      return {
        ...state,
        uploadingResource: true
      }
    }

    case 'UPLOAD_FINANCEOPTIONRESOURCE.SUCCESS':
    {
      const financeOptionsCopy = state.financeOptions
      const existingIndex = financeOptionsCopy.findIndex(x => x.id === action.data.id)
      if (existingIndex >= 0) { financeOptionsCopy[existingIndex] = action.data }

      return {
        ...state,
        error: '',
        uploadingResource: false,
        financeOptions: financeOptionsCopy
      }
    }

    case 'UPLOAD_FINANCEOPTIONRESOURCE.ERROR':
      return {
        ...state,
        error: action.error,
        uploadingResource: false
      }

    case 'DELETE_FINANCEOPTIONRESOURCE.REQUEST':
    {
      return {
        ...state,
        deletingResource: true
      }
    }

    case 'UPLOAD_FINANCEOPTIONGRANTS.REQUEST':
    {
      return {
        ...state,
        uploadingGrants: true
      }
    }

    case 'UPLOAD_FINANCEOPTIONGRANTS.SUCCESS':
    {
      // const financeOptionsCopy = state.financeOptions;
      // const existingIndex = financeOptionsCopy.findIndex(x => x.id === action.data.id);
      // if(existingIndex >= 0)
      //     financeOptionsCopy[existingIndex] = action.data;

      return {
        ...state,
        error: '',
        uploadingGrants: false
        // grants: financeOptionsCopy,
      }
    }

    case 'UPLOAD_FINANCEOPTIONGRANTS.ERROR':
      return {
        ...state,
        error: action.error,
        uploadingGrants: false
      }

    case 'GET_FINANCEOPTIONGRANTS.REQUEST':
    {
      return {
        ...state,
        fetchingGrants: true
      }
    }

    case 'GET_FINANCEOPTIONGRANTS.SUCCESS':
    {
      // const financeOptionsCopy = state.financeOptions;
      // const existingIndex = financeOptionsCopy.findIndex(x => x.id === action.data.id);
      // if(existingIndex >= 0)
      //     financeOptionsCopy[existingIndex] = action.data;

      return {
        ...state,
        error: '',
        fetchingGrants: false,
        grants: action.data
      }
    }

    case 'GET_FINANCEOPTIONGRANTS.ERROR':
      return {
        ...state,
        error: action.error,
        fetchingGrants: false
      }

    case 'DELETE_FINANCEOPTIONRESOURCE.SUCCESS':
    {
      const financeOptionsCopy = state.financeOptions
      const existingIndex = financeOptionsCopy.findIndex(x => x.id === action.data.id)
      if (existingIndex >= 0) { financeOptionsCopy[existingIndex] = action.data }

      return {
        ...state,
        error: '',
        deletingResource: false,
        financeOptions: financeOptionsCopy
      }
    }

    case 'DELETE_FINANCEOPTIONRESOURCE.ERROR':
      return {
        ...state,
        error: action.error,
        deletingResource: false
      }

    case 'ADD_FINANCEOPTIONFAVOURITE.REQUEST':
      return {
        ...state,
        error: '',
        addingFavourite: true
      }

    case 'ADD_FINANCEOPTIONFAVOURITE.SUCCESS':
    {
      return {
        ...state,
        error: '',
        addingFavourite: false
      }
    }

    case 'ADD_FINANCEOPTIONFAVOURITE.ERROR':
      return {
        ...state,
        error: action.error,
        addingFavourite: false
      }


    case 'REMOVE_FINANCEOPTIONFAVOURITE.REQUEST':
      return {
        ...state,
        error: '',
        addingFavourite: true
      }

    case 'REMOVE_FINANCEOPTIONFAVOURITE.SUCCESS':
    {
      return {
        ...state,
        error: '',
        addingFavourite: false
      }
    }

    case 'REMOVE_FINANCEOPTIONFAVOURITE.ERROR':
      return {
        ...state,
        error: action.error,
        addingFavourite: false
      }

    case 'CHANGE_ORGANIZATION_REQUEST':
      return state

    case 'SIGNOUT_SUCCESS':
      return initState

    default:
      return state
  }
}

export default financeOptionReducer
