import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import ErrorIcon from '@mui/icons-material/Error'
import InfoIcon from '@mui/icons-material/Info'
import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'
import SnackbarContent from '@mui/material/SnackbarContent'
import WarningIcon from '@mui/icons-material/Warning'
import { makeStyles } from '@mui/styles'

const variantIcon = {
    success: CheckCircleIcon,
    warning: WarningIcon,
    error: ErrorIcon,
    info: InfoIcon
}

const useStyles = makeStyles(theme => ({
    success: {
        backgroundColor: 'rgba(99, 194, 118, 1)'
    },
    error: {
        backgroundColor: 'rgba(255, 81, 89, 1)'
    },
    info: {
        backgroundColor: 'rgb(225,236,222)'
    },
    warning: {
        backgroundColor: 'rgb(255,141,69)'
    },
    icon: {
        fontSize: 20
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing(1)
    },
    message: {
        display: 'flex',
        alignItems: 'center'
    }
}))

const SnackbarWrapper = React.forwardRef((props, ref) => {
    const classes = useStyles()
    const { message, onClose, variant, ...other } = props
    const Icon = variantIcon[variant]

    return (
        <SnackbarContent
            ref={ref}
            className={classes[variant]}
            aria-describedby="client-snackbar"
            message={
                <span id="client-snackbar" className={classes.message}>
                    <Icon className={clsx(classes.icon, classes.iconVariant)}/>
                    {
                        typeof (message) === 'string' &&
                        message
                    }
                </span>
            }
            action={[
                <IconButton key="close" aria-label="close" color="inherit" onClick={onClose}>
                    <CloseIcon className={classes.icon}/>
                </IconButton>
            ]}
            {...other}
        />
    )
})

SnackbarWrapper.propTypes = {
    variant: PropTypes.string,
    message: PropTypes.string,
    onClose: PropTypes.func
}

export default (SnackbarWrapper)
