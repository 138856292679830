import React, { useEffect, useState } from 'react'

import Grid from '@mui/material/Grid'
import {ListItemButton, ListSubheader, Stack, TableContainer, TableHead, TextField} from '@mui/material'
import { makeStyles } from '@mui/styles'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import Table from '@mui/material/Table'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableBody from '@mui/material/TableBody'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import {
    createFinanceOptionDeadline,
    deleteFinanceOption,
    deleteFinanceOptionDeadline,
    getSubscriptions,
    getFinanceOptions,
    patchFinanceOption,
    subscriptionChange,
    updateFinanceOptionDeadline,
    getFinanceOptionGrants,
    addFinanceOptionFavourite,
    removeFinanceOptionFavourite
} from '../store/actions/financeOptionActions'
import { getYourOrganizations } from '../store/actions/organizationActions'
import moment from 'moment'
import {
    financeOptionOrgforms,
    financeOptionTypes,
    financeOptionSectors
} from '../constants/financeOptionEnums'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import ListItemText from '@mui/material/ListItemText'
import Url from 'url-parse'
import Chip from '@mui/material/Chip'
import CardContent from '@mui/material/CardContent'
import CardHeader from '@mui/material/CardHeader'
import {FormattedMessage} from 'react-intl'
import { history } from '../store/history'
import CardActions from '@mui/material/CardActions'
import { useIntl } from 'react-intl'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import EventIcon from '@mui/icons-material/Event'
import NotificationsIcon from '@mui/icons-material/Notifications'
import {
    checkBool, checkCompanyAge,
    checkCultureTest, checkFlag,
    checkOrganizationLocation
} from '../constants/filteringLogic'
import {
    companyRoleEnum,
    gameBusinessModelEnum,
    gamePlayModeEnum,
    gamePurposeEnum, pegiRatingEnum,
    projectStageEnums
} from '../constants/projectEnums'
import { flagToArray } from '../constants/helpers'
import CheckIcon from '@mui/icons-material/Check'
import ClearIcon from '@mui/icons-material/Clear'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'
import LanguageIcon from '@mui/icons-material/Language'
import GetAppIcon from '@mui/icons-material/GetApp'
import LinkIcon from '@mui/icons-material/Link'
import { ReactComponent as LinkNewTab } from '../assets/link-new-tab.svg'
import CriteriaStatus from '../components/CriteriaStatus'
import Link from '@mui/material/Link'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogActions from '@mui/material/DialogActions'
import Dialog from '@mui/material/Dialog'
import FormControl from '@mui/material/FormControl'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction'
import Avatar from '@mui/material/Avatar'
import IconButton from '@mui/material/IconButton'
import {
    BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer
} from 'recharts'
import currency from 'currency.js'
import _ from 'lodash'
import { addDays, addYears } from 'date-fns';
import { primaryDarkColor, secondaryDarkColor } from '../App';
import { useTheme } from '@mui/material/styles';
import {DatePicker} from "@mui/lab";
import StarIcon from '@mui/icons-material/Star';
import StarOutlineIcon from '@mui/icons-material/StarOutline';

const useStyles = makeStyles(theme => ({
    root: {
        marginTop: '50px',
        padding: '20px'
    },
    adminBar: {
        position: 'fixed',
        top: '64px',
        height: '50px',
        left: '0px',
        right: '0px',
        background: theme.palette.background.default,
        zIndex: 1300,
        boxShadow: '0px 10px 10px rgba(0, 0, 0, 0.1)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '0px 15px 0px 25px',
        [theme.breakpoints.down('xs')]: {
            left: '0px'
        }
    },
    actionButtons: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        [theme.breakpoints.down('xs')]: {
            justifyContent: 'space-evenly'
        }
    },
    actionButton: {
        margin: '5px',
        textDecoration: 'none'
    },
    table: {
        width: '100%'
    },
    tableCell: {
        borderBottom: 'solid #FFF 1px',
        paddingLeft: '5px',
        paddingRight: '5px !important'
    },
    descriptionReadMoreUrlText: {
        display: 'flex',
        alignItems: 'center',
        margin: '15px 0px 0px 0px !important'
    },
    descriptionReadMoreUrlIcon: {
        display: 'flex',
        margin: '0px 5px',
        height: '15px',
        width: '15px'
    },
    descriptionTextField: {
        flex: 'auto'
    },
    descriptionText: {
        flex: 'auto'
    },
    subscriptionLabel: {
        flex: 'auto'
    }
}))

const FundingPage = (props) => {
    const {
        financeOptions, yourOrganizations, error, fetchingFinanceOptions, creatingFinanceOption, creatingDeadline,
        deletingDeadline, userInfo, financeOptionsSubscriptions, subscriptionChanging, subscriptionFetching, metadata, yourProjects
    } = props
    const classes = useStyles()

    const intl = useIntl()
    const theme = useTheme()
    const [grants, setGrants] = useState([])
    const [seeAllDeadlines, setSeeAllDeadlines] = useState(false)
    const [subscriptionsOpen, setSubscriptionsOpen] = useState(false)
    const [viewAsMember, setViewAsMember] = useState(false)
    const [anchorSettingsEl, setAnchorSettingsEl] = useState(null)
    const [localFinanceOptionsSubscriptions, setLocalFinanceOptionsSubscriptions] = useState([])
    const [chartDateRange, setChartDateRange] = useState({
        startDate: addYears(new Date(), -5),
        endDate: new Date()
    })
    const [localFinanceOptionCopy, setLocalFinanceOptionCopy] = useState({
        id: '',
        name: '',
        organization: {
            id: -1
        },
        description: '',
        descriptionReadMoreUrl: '',
        deadlines: [],
        resources: [],
        type: 0,
        sector: 0,
        orgForm: 0,
        website: '',
        counties: [],
        isNarrative: false
    })

    useEffect(() => {
        props.getSubscriptions()
        props.getFinanceOptionGrants(props.match.params.id)
    }, [])

    useEffect(() => {
        const financeOptionId = props.match.params.id
        if (financeOptionId != null) {
            const currentFinanceOption = financeOptions.find(x => x.id.toString() === financeOptionId)

            if (currentFinanceOption != null) {
                setLocalFinanceOptionCopy(currentFinanceOption)
            }
        }
    }, [financeOptions, fetchingFinanceOptions, creatingFinanceOption, creatingDeadline, deletingDeadline])

    useEffect(() => {
        setLocalFinanceOptionsSubscriptions(financeOptionsSubscriptions.find(x => parseInt(x.financeOptionId) === parseInt(props.match.params.id)))
    }, [financeOptionsSubscriptions])

    useEffect(() => {
        props.getYourFinanceOptions()
        props.getYourOrganizations()
    }, [])

    useEffect(() => {
        setGrants(props.grants.filter(x => moment(x.fundingDateTime).isBetween(chartDateRange.startDate, chartDateRange.endDate)))
    }, [props.grants, chartDateRange])

    function handleSelectChartDateRange (ranges) {
        // setChartDateRange(ranges.selection);
        setChartDateRange(ranges);
        setGrants(props.grants.filter(x => moment(x.fundingDateTime).isBetween(chartDateRange.startDate, chartDateRange.endDate)))
    }

    function handleEditProjectClick (event, focus) {
        event.stopPropagation()
        event.preventDefault()

        let extraFocus = ''
        if (focus) { extraFocus += `#${focus}` }

        history.push(`/funding/${localFinanceOptionCopy.id}/edit${extraFocus}`)
    }

    function handleSubscriptionChange (event, flag) {
        let updatedFinanceOptionSubscriptions = localFinanceOptionsSubscriptions
        if (updatedFinanceOptionSubscriptions) {
            updatedFinanceOptionSubscriptions.subscriptionFlags = updatedFinanceOptionSubscriptions.subscriptionFlags ^= flag
        } else {
            updatedFinanceOptionSubscriptions = {
                userId: userInfo.id,
                financeOptionId: props.match.params.id,
                subscriptionFlags: flag
            }
        }

        setLocalFinanceOptionsSubscriptions({
            ...updatedFinanceOptionSubscriptions
        })
    }

    function handleSubscriptionSaveChanges (event) {
        if (localFinanceOptionsSubscriptions) {
            const subscriptionChangeDto = {
                userId: localFinanceOptionsSubscriptions.userId,
                financeOptionId: localFinanceOptionsSubscriptions.financeOptionId,
                subscriptionFlags: localFinanceOptionsSubscriptions.subscriptionFlags
            }

            props.subscriptionChange(subscriptionChangeDto)
        }

        setSubscriptionsOpen(false)
    }

    const selectedOrganization = yourOrganizations.find(x => x.id === userInfo.currentlyActiveOrganization)
    const canEdit = localFinanceOptionCopy.organization && localFinanceOptionCopy.organization.id === userInfo.currentlyActiveOrganization
    const deadlinesSortedAndFiltered = localFinanceOptionCopy.deadlines
        .sort((a, b) => moment(a.deadline) - moment(b.deadline))
        .filter(x => moment(x.deadline) > moment())

    const officialResources = localFinanceOptionCopy.resources.filter(x => x.isOfficial)
    const nonOfficialResources = localFinanceOptionCopy.resources.filter(x => !x.isOfficial)

    const subscriptionFlags = localFinanceOptionsSubscriptions ? localFinanceOptionsSubscriptions.subscriptionFlags : 0

    const grantBars = []
    
    grants.forEach((grant, index) => {
        if (grantBars.some(x => x.name.toLowerCase() === grant.projectName.toLowerCase())) {
            return
        }
        
        // console.log(grant.projectName)
        grantBars.push({
            name: grant.projectName,
            color: '#' + Math.floor(Math.random() * 16777215).toString(16)
        })
    })
    
    const groupedGrants = _.groupBy(grants, function (element) {
        return moment(element.fundingDateTime).format('MMM YY')
    }, {})

    let groupedSummedGrants = Object.keys(groupedGrants).map(groupedGrantKey => {
        const summedGrant = {
            date: groupedGrantKey,
            amount: _.sumBy(groupedGrants[groupedGrantKey], 'amount'),
            grants: groupedGrants[groupedGrantKey]
        }
        
        groupedGrants[groupedGrantKey].forEach((grant, idx) => {
            summedGrant[grant.projectName] = grant.amount
        })
        
        return summedGrant
    })

    groupedSummedGrants = _.orderBy(groupedSummedGrants, (x) => {
        return moment(x.date, 'MMM YY')
    }, ['asc'])

    const definedRanges = [
        {
            label: 'Last year',
            startDate: addYears(new Date(), -1),
            endDate: new Date()
        },
        {
            label: 'Last 3 years',
            startDate: addYears(new Date(), -3),
            endDate: new Date()
        },
        {
            label: 'Last 5 years',
            startDate: addYears(new Date(), -5),
            endDate: new Date()
        }
    ]

    if (props.grants && props.grants.length > 0) {
        definedRanges.push(
            {
                label: 'All time',
                startDate: _.minBy(props.grants, (grant) => { return grant.fundingDateTime; }).fundingDateTime,
                endDate: new Date()
            })
    }
    
    const isFavourite = userInfo.financeOptionFavourites && userInfo.financeOptionFavourites.length > 0 && userInfo.financeOptionFavourites.find(x => x.financeOptionId === localFinanceOptionCopy.id);

    return (
        <div className={classes.root}>
            <Dialog open={subscriptionsOpen} onClose={(event) => {
                setSubscriptionsOpen(false)
                setLocalFinanceOptionsSubscriptions(financeOptionsSubscriptions.find(x => parseInt(x.financeOptionId) === parseInt(props.match.params.id)))
            }} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">
                    <FormattedMessage
                        id={'Subscriptions.DialogTitle'}
                        defaultMessage={'Abonnering'}
                    />
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <FormattedMessage
                            id={'Subscriptions.Intro'}
                            defaultMessage={'Her kan du styre hva du ønsker å bli varslet om på denne finansieringskilden. For å unngå å fylle opp innboksen din sender vi varslinger som gjelder innhold i en samlet e-post en gang i blant.'}
                        />
                    </DialogContentText>
                    <FormControl component="fieldset" fullWidth>
                        <FormGroup aria-label="subscriptions" row>
                            <FormControlLabel
                                style={{ width: '100%' }}
                                classes={{
                                    label: classes.subscriptionLabel
                                }}
                                value="top"
                                checked={subscriptionFlags & 1}
                                onChange={e => handleSubscriptionChange(e, 1)}
                                control={<Switch color="primary"/>}
                                label={
                                    <FormattedMessage
                                        id={'FinanceOption.DeadlineSubscription'}
                                        defaultMessage={'Bli varslet 14 dager før søknadsfrist'}
                                    />
                                }
                                labelPlacement="start"
                            />
                            <FormControlLabel
                                style={{ width: '100%' }}
                                classes={{
                                    label: classes.subscriptionLabel
                                }}
                                checked={subscriptionFlags & 2}
                                onChange={e => handleSubscriptionChange(e, 2)}
                                control={<Switch color="primary"/>}
                                label={
                                    <FormattedMessage
                                        id={'FinanceOption.ContentSubscription'}
                                        defaultMessage={'Bli varslet om viktige oppdatering på innhold'}
                                    />
                                }
                                labelPlacement="start"
                            />
                        </FormGroup>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={(event) => {
                        setSubscriptionsOpen(false)
                        setLocalFinanceOptionsSubscriptions(financeOptionsSubscriptions.find(x => parseInt(x.financeOptionId) === parseInt(props.match.params.id)))
                    }} color="primary">
                        <FormattedMessage
                            id={'Global.Regret'}
                            defaultMessage={'Angre'}
                        />
                    </Button>
                    <Button
                        onClick={handleSubscriptionSaveChanges}
                        color="primary"
                    >
                        <FormattedMessage
                            id={'Global.Update'}
                            defaultMessage={'Oppdater'}
                        />
                    </Button>
                </DialogActions>
            </Dialog>
            {
                canEdit &&
                <div className={classes.adminBar}>
                    <Typography variant={'body2'} style={{
                        background: '#E43030',
                        color: '#FFF',
                        padding: '5px 10px',
                        userSelect: 'none',
                        borderRadius: '5px'
                    }}>
                        <FormattedMessage
                            id={'Global.YouAreAdmin'}
                            defaultMessage={'Du kan redigere denne siden'}
                        />
                    </Typography>
                    {
                        canEdit &&
                        <Button className={classes.actionButton}
                                onClick={(event) => handleEditProjectClick(event, null)}
                                variant={'contained'}
                        >
                            <FormattedMessage
                                id={'Global.MakeEdits'}
                                defaultMessage={'Rediger siden'}
                            />
                        </Button>
                    }
                </div>
            }
            <Grid container spacing={2} alignItems={'center'}>
                <Grid item xs={12} sm={12}>
                    <Typography variant={'body2'}>
                        {localFinanceOptionCopy.organization && localFinanceOptionCopy.organization.name}
                    </Typography>
                    <Typography variant={'h4'}>
                        {localFinanceOptionCopy.name}
                        {
                            isFavourite ? 
                            <IconButton onClick={event => {
                                event.preventDefault();
                                event.stopPropagation();
                                props.removeFavourite(localFinanceOptionCopy.id)
                            }}>
                                <StarIcon color={"primary"} />
                            </IconButton> 
                            :
                            <IconButton onClick={event => props.addFavourite(localFinanceOptionCopy.id)}>
                                <StarOutlineIcon />
                            </IconButton>
                        }
                    </Typography>
                    <Typography variant={'caption'}>
                        <FormattedMessage
                            id="Global.LastModified"
                            defaultMessage="Last modified by {name} on {date}"
                            values={{
                                date: moment(localFinanceOptionCopy.modifiedAt).format('LL'),
                                name: 'Dataspillsenteret'
                            }}
                        />
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={12} className={classes.actionButtons}>
                    <Button variant={'contained'}
                            className={classes.actionButton}
                            onClick={e => setSubscriptionsOpen(true)}
                            disabled={subscriptionFetching || subscriptionChanging}
                    >
                        <NotificationsIcon/>
                        <FormattedMessage
                            id={'FinanceOption.ChangeSubscriptions'}
                            defaultMessage={'Endre varslinger'}
                        />
                    </Button>
                    {
                        localFinanceOptionCopy.website &&
                        <a href={(localFinanceOptionCopy.website.indexOf('://') === -1 ? new Url('http://' + localFinanceOptionCopy.website) : new Url(localFinanceOptionCopy.website))}
                           target={'_blank'}
                           className={classes.actionButton} rel="noreferrer">
                            <Button variant={'contained'}>
                                SØK HER
                                <LinkNewTab className={classes.descriptionReadMoreUrlIcon}/>
                            </Button>
                        </a>
                    }
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={7}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Card>
                                <CardHeader
                                    titleTypographyProps={{ variant: 'h6' }}
                                    title={<FormattedMessage
                                        id="FinanceOption.AboutHeader"
                                        defaultMessage={'Om finansieringskilde'}
                                    />}
                                    subheader={<FormattedMessage
                                        id="FinanceOption.AboutSubHeader"
                                        defaultMessage={'Her kan du lese mer om finansieringskilden.'}
                                    />}
                                />
                                <CardContent>
                                    <div style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        flex: 'auto'
                                    }}>
                                        <Typography variant={'body2'}
                                                    className={classes.descriptionText}
                                                    style={{ whiteSpace: 'pre-line' }}>
                                            {localFinanceOptionCopy.description}
                                        </Typography>
                                        {
                                            localFinanceOptionCopy.descriptionReadMoreUrl && localFinanceOptionCopy.descriptionReadMoreUrl.length > 0 &&
                                            <Link
                                                href={(localFinanceOptionCopy.descriptionReadMoreUrl.indexOf('://') === -1 ? new Url('http://' + localFinanceOptionCopy.descriptionReadMoreUrl) : new Url(localFinanceOptionCopy.descriptionReadMoreUrl))}
                                                target="_blank"
                                                className={classes.descriptionReadMoreUrlText}>
                                                {'Les mer på ' + (localFinanceOptionCopy.descriptionReadMoreUrl.indexOf('://') === -1 ? new Url('http://' + localFinanceOptionCopy.descriptionReadMoreUrl).hostname + '/...' : new Url(localFinanceOptionCopy.descriptionReadMoreUrl).hostname + '/...')}
                                            </Link>
                                        }
                                    </div>
                                </CardContent>
                            </Card>
                        </Grid>
                        {
                            yourProjects.length > 0 &&
                            <Grid item xs={12}>
                                <Card>
                                    <CardHeader titleTypographyProps={{ variant: 'h6' }}
                                                title={<FormattedMessage
                                                    id="FinanceOption.YourProjectsHeader"
                                                    defaultMessage={'Dine Prosjekter'}
                                                />}
                                                subheader={<FormattedMessage
                                                    id="FinanceOption.YourProjectsSubheader"
                                                    defaultMessage={'Her kan du se hvordan dine prosjekter treffer på søknadskriteriene til denne finansieringskilden'}
                                                />}
                                    />
                                    <CardContent>
                                        {
                                            localFinanceOptionCopy.orgForm === 1 &&
                                            <Accordion>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon/>}
                                                    aria-controls="orgFundingCriterias"
                                                    id="orgFundingCriterias"
                                                >
                                                    <Typography variant={'body1'}
                                                                style={{ display: 'flex', alignItems: 'center' }}>
                                                        {
                                                            checkOrganizationLocation(metadata.countiesMap, localFinanceOptionCopy, selectedOrganization) &&
                                                            checkCompanyAge(localFinanceOptionCopy, selectedOrganization)
                                                                ? <CheckIcon style={{ marginRight: '10px' }}/>
                                                                : <ClearIcon
                                                                    style={{ marginRight: '10px' }}/>
                                                        }
                                                        {'Selskap: ' + selectedOrganization.name}
                                                    </Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={6}>
                                                            <Typography variant={'subtitle1'} style={{
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                margin: '10px 0px'
                                                            }}>
                                                                Kriterier for selskaper
                                                            </Typography>
                                                            <CriteriaStatus name={'Geografi'}
                                                                            didPass={checkOrganizationLocation(metadata.countiesMap, localFinanceOptionCopy, selectedOrganization)}
                                                                            messageIfPass={'Selskap med din hovedadresse kan søke'}
                                                                            messageIfFail={`Kun selskap fra ${localFinanceOptionCopy.counties.join(', ')} kan søke`}
                                                            />
                                                            {
                                                                localFinanceOptionCopy.companyAgeMin > 0 && localFinanceOptionCopy.companyAgeMax < 999 &&
                                                                <CriteriaStatus name={'Selskapetsalder'}
                                                                                didPass={checkCompanyAge(localFinanceOptionCopy, selectedOrganization)}
                                                                                messageIfPass={'Selskapet ditt har riktig alder for å søke'}
                                                                                messageIfFail={`Kun selskaper eldre enn ${localFinanceOptionCopy.companyAgeMax} år og yngre enn ${localFinanceOptionCopy.companyAgeMax} år kan søke`}
                                                                />
                                                            }
                                                        </Grid>
                                                    </Grid>
                                                </AccordionDetails>
                                            </Accordion>
                                        }
                                        {
                                            localFinanceOptionCopy.orgForm === 0 && yourProjects.map((project, index) => (
                                                <Accordion key={index}>
                                                    <AccordionSummary
                                                        expandIcon={<ExpandMoreIcon/>}
                                                        aria-controls={project.id}
                                                        id={project.id}
                                                    >
                                                        <Typography variant={'body1'}
                                                                    style={{ display: 'flex', alignItems: 'center' }}>
                                                            {
                                                                checkOrganizationLocation(metadata.countiesMap, localFinanceOptionCopy, selectedOrganization) &&
                                                                checkCompanyAge(localFinanceOptionCopy, selectedOrganization) &&
                                                                checkFlag(localFinanceOptionCopy, project, 'stage', 'stage') &&
                                                                checkFlag(localFinanceOptionCopy, project, 'gamePlayMode', 'gamePlayMode') &&
                                                                checkFlag(localFinanceOptionCopy, project, 'gamePurpose', 'gamePurpose') &&
                                                                checkFlag(localFinanceOptionCopy, project, 'businessModel', 'businessModel') &&
                                                                checkFlag(localFinanceOptionCopy, project, 'companyRole', 'companyRole') &&
                                                                checkFlag(localFinanceOptionCopy, project, 'pegiRating', 'pegiRating') &&
                                                                checkBool(localFinanceOptionCopy, project, 'isNarrative', 'containsCentralNarrativeThroughEntireGame') &&
                                                                checkBool(localFinanceOptionCopy, project, 'hasRightsToIp', 'rightsToIp') &&
                                                                checkCultureTest(localFinanceOptionCopy, project)
                                                                    ? <CheckIcon style={{ marginRight: '10px' }}/>
                                                                    : <ClearIcon
                                                                        style={{ marginRight: '10px' }}/>
                                                            }
                                                            {'Prosjekt: ' + project.name}
                                                        </Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={6}>
                                                                <Typography variant={'subtitle1'} style={{
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    margin: '10px 0px'
                                                                }}>
                                                                    Kriterier for selskaper
                                                                </Typography>
                                                                <CriteriaStatus name={'Geografi'}
                                                                                didPass={checkOrganizationLocation(metadata.countiesMap, localFinanceOptionCopy, selectedOrganization)}
                                                                                messageIfPass={'Selskap med din hovedadresse kan søke'}
                                                                                messageIfFail={`Kun selskap fra ${localFinanceOptionCopy.counties.join(', ')} kan søke`}
                                                                />
                                                                {
                                                                    localFinanceOptionCopy.companyRole > 0 &&
                                                                    <CriteriaStatus name={'Rolle'}
                                                                                    didPass={checkFlag(localFinanceOptionCopy, project, 'companyRole', 'companyRole')}
                                                                                    messageIfPass={'Selskapet ditt har en rolle man kan søke for'}
                                                                                    messageIfFail={`Kun selskaper med rolle ${flagToArray(localFinanceOptionCopy.companyRole, companyRoleEnum).map(x => intl.formatMessage({ id: x })).join(', ')} kan søke`}
                                                                    />
                                                                }
                                                                {
                                                                    localFinanceOptionCopy.companyAgeMin > 0 && localFinanceOptionCopy.companyAgeMax < 999 &&
                                                                    <CriteriaStatus name={'Selskapetsalder'}
                                                                                    didPass={checkCompanyAge(localFinanceOptionCopy, selectedOrganization)}
                                                                                    messageIfPass={'Selskapet ditt har riktig alder for å søke'}
                                                                                    messageIfFail={`Kun selskaper eldre enn ${localFinanceOptionCopy.companyAgeMax} år og yngre enn ${localFinanceOptionCopy.companyAgeMax} år kan søke`}
                                                                    />
                                                                }
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <Typography variant={'subtitle1'} style={{
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    margin: '10px 0px'
                                                                }}>
                                                                    Kriterier for prosjekter
                                                                </Typography>
                                                                <CriteriaStatus name={'Stadie'}
                                                                                didPass={checkFlag(localFinanceOptionCopy, project, 'stage', 'stage')}
                                                                                messageIfPass={'Prosjektet ditt er i riktig stadiet for å søke'}
                                                                                messageIfFail={`Kun prosjekter i et av følgende stadier kan søke: ${flagToArray(localFinanceOptionCopy.stage, projectStageEnums).map(x => intl.formatMessage({ id: x })).join(', ')}`}
                                                                />
                                                                {
                                                                    localFinanceOptionCopy.hasToPassCultureTest &&
                                                                    <CriteriaStatus name={'Kulturtesten'}
                                                                                    didPass={checkCultureTest(localFinanceOptionCopy, project)}
                                                                                    messageIfPass={'Prosjektet ditt har bestått kulturtesten'}
                                                                                    messageIfFail={`Prosjektet ditt består ikke kulturtesten, som ${localFinanceOptionCopy.name} krever`}
                                                                    />
                                                                }
                                                                {
                                                                    localFinanceOptionCopy.isNarrative &&
                                                                    <CriteriaStatus name={'Narrativt fokus'}
                                                                                    didPass={checkBool(localFinanceOptionCopy, project, 'isNarrative', 'containsCentralNarrativeThroughEntireGame')}
                                                                                    messageIfPass={'Prosjektet ditt har et narrativt fokus'}
                                                                                    messageIfFail={`Kun prosjekter med narrativt fokus kan søke på ${localFinanceOptionCopy.name}`}
                                                                    />
                                                                }
                                                                {
                                                                    localFinanceOptionCopy.hasRightsToIp &&
                                                                    <CriteriaStatus name={'Rettigheter til IP'}
                                                                                    didPass={checkBool(localFinanceOptionCopy, project, 'hasRightsToIp', 'rightsToIp')}
                                                                                    messageIfPass={'Prosjektet ditt har rettighetene til denne IP\'en'}
                                                                                    messageIfFail={`Kun prosjekter med rettigheter til prosjektets IP kan søke på ${localFinanceOptionCopy.name}`}
                                                                    />
                                                                }
                                                                {
                                                                    localFinanceOptionCopy.gamePlayMode > 0 &&
                                                                    <CriteriaStatus name={'Spillermodus'}
                                                                                    didPass={checkFlag(localFinanceOptionCopy, project, 'gamePlayMode', 'gamePlayMode')}
                                                                                    messageIfPass={'Prosjektet ditt har en spillermodus man kan søke for'}
                                                                                    messageIfFail={`Kun prosjekter med spillermodus ${flagToArray(localFinanceOptionCopy.gamePlayMode, gamePlayModeEnum).map(x => intl.formatMessage({ id: x })).join(', ')} kan søke`}
                                                                    />
                                                                }
                                                                {
                                                                    localFinanceOptionCopy.gamePurpose > 0 &&
                                                                    <CriteriaStatus name={'Formål'}
                                                                                    didPass={checkFlag(localFinanceOptionCopy, project, 'gamePurpose', 'gamePurpose')}
                                                                                    messageIfPass={'Prosjektet ditt har et formål man kan søke for'}
                                                                                    messageIfFail={`Kun prosjekter med formål ${flagToArray(localFinanceOptionCopy.gamePurpose, gamePurposeEnum).map(x => intl.formatMessage({ id: x })).join(', ')} kan søke`}
                                                                    />
                                                                }
                                                                {
                                                                    localFinanceOptionCopy.businessModel > 0 &&
                                                                    <CriteriaStatus name={'Forretningsmodell'}
                                                                                    didPass={checkFlag(localFinanceOptionCopy, project, 'businessModel', 'businessModel')}
                                                                                    messageIfPass={'Prosjektet ditt har en forretningsmodell man kan søke for'}
                                                                                    messageIfFail={`Kun prosjekter med forretningsmodell ${flagToArray(localFinanceOptionCopy.businessModel, gameBusinessModelEnum).map(x => intl.formatMessage({ id: x })).join(', ')} kan søke`}
                                                                    />
                                                                }
                                                                {
                                                                    localFinanceOptionCopy.pegiRating > 0 &&
                                                                    <CriteriaStatus name={'PEGI'}
                                                                                    didPass={checkFlag(localFinanceOptionCopy, project, 'pegiRating', 'pegiRating')}
                                                                                    messageIfPass={'Prosjektet ditt har en PEGI Rating man kan søke for'}
                                                                                    messageIfFail={`Kun prosjekter med rating ${flagToArray(localFinanceOptionCopy.pegiRating, pegiRatingEnum).map(x => intl.formatMessage({ id: x })).join(', ')} kan søke`}
                                                                    />
                                                                }
                                                            </Grid>
                                                        </Grid>
                                                    </AccordionDetails>
                                                </Accordion>
                                            ))
                                        }
                                    </CardContent>
                                </Card>
                            </Grid>
                        }
                        {
                            props.grants && props.grants.length > 0 &&
                            <Grid item xs={12}>
                                <Card style={{ overflow: 'visible' }} >
                                    <CardHeader titleTypographyProps={{ variant: 'h6' }}
                                                title={<FormattedMessage
                                                    id="FinanceOption.Grants"
                                                    defaultMessage={'Tilskudd'}
                                                />}
                                                subheader={<FormattedMessage
                                                    id="FinanceOption.GrantsSubheader"
                                                    defaultMessage={'En oversikt over tidligere gitte tilskudd til denne ordningen.'}
                                                />}
                                    />
                                    <CardContent>
                                        <Stack direction={'row'} spacing={2}>
                                            <DatePicker
                                                label='Start date'
                                                value={chartDateRange.startDate}
                                                onChange={date => setChartDateRange({
                                                    startDate: date,
                                                    endDate: chartDateRange.endDate
                                                })}
                                                renderInput={(params) => <TextField size={"small"} {...params} />}
                                            />
                                            <DatePicker
                                                label='End date'
                                                value={chartDateRange.startDate}
                                                onChange={date => setChartDateRange({
                                                    startDate: date,
                                                    endDate: chartDateRange.endDate
                                                })}
                                                renderInput={(params) => <TextField size={"small"} {...params} />}
                                            />
                                        </Stack>
                                        <div style={{ width: '100%', height: 300 }}>
                                            <ResponsiveContainer>
                                                <BarChart
                                                    data={groupedSummedGrants}
                                                    margin={{
                                                        top: 20, right: 30, left: 20, bottom: 5
                                                    }}
                                                >
                                                    <CartesianGrid horizontal={false} vertical={false} />
                                                    <XAxis dataKey="date" />
                                                    <YAxis tickFormatter={tickItem => currency(Math.round(tickItem * 0.001).toFixed(0), { separator: ' ', symbol: '\'', pattern: '#!', precision: 0 }).format()} />
                                                    {
                                                        groupedSummedGrants && groupedSummedGrants.length > 0 &&
                                                        <Tooltip content={({ active, payload, label }) => {
                                                            if (active) {
                                                                const amount = currency(Math.round(payload[0].value).toFixed(0), { separator: '.', symbol: '', pattern: '#!', precision: 0, prefix: 'NOK' }).format()
                                                    
                                                                return (
                                                                    <Card>
                                                                        <CardContent>
                                                                            <CardHeader
                                                                                title={label}
                                                                            />
                                                                            <Typography>
                                                                                {amount} fordelt på {groupedSummedGrants.find(x => x.date === label).grants.length} tilskudd
                                                                            </Typography>
                                                                            {/*<TableContainer>*/}
                                                                            {/*    <Table size="small" aria-label="a dense table">*/}
                                                                            {/*        <TableHead>*/}
                                                                            {/*            <TableRow>*/}
                                                                            {/*                <TableCell>Prosjekt</TableCell>*/}
                                                                            {/*                <TableCell>Selskap</TableCell>*/}
                                                                            {/*                <TableCell align="right">Sum</TableCell>*/}
                                                                            {/*            </TableRow>*/}
                                                                            {/*        </TableHead>*/}
                                                                            {/*        <TableBody>*/}
                                                                            {/*            {*/}
                                                                            {/*                groupedSummedGrants.find(x => x.date === label).grants.sort((a, b) => b.amount - a.amount).map((grant, index) => {*/}
                                                                            {/*                    return (*/}
                                                                            {/*                        <TableRow key={grant.id}>*/}
                                                                            {/*                            <TableCell component="th" scope="row">*/}
                                                                            {/*                                {grant.projectName}*/}
                                                                            {/*                            </TableCell>*/}
                                                                            {/*                            <TableCell component="th" scope="row">*/}
                                                                            {/*                                {grant.companyName}*/}
                                                                            {/*                            </TableCell>*/}
                                                                            {/*                            <TableCell align="right">*/}
                                                                            {/*                                {currency(Math.round(grant.amount).toFixed(0), { separator: '.', symbol: '', pattern: '#!', precision: 0 }).format()}*/}
                                                                            {/*                            </TableCell>*/}
                                                                            {/*                        </TableRow>*/}
                                                                            {/*                    )*/}
                                                                            {/*                })*/}
                                                                            {/*            }*/}
                                                                            {/*            <TableRow>*/}
                                                                            {/*                <TableCell component="th" scope="row" colSpan={2}>*/}
                                                                            {/*                    {'Totalt'}*/}
                                                                            {/*                </TableCell>*/}
                                                                            {/*                <TableCell align="right">*/}
                                                                            {/*                    {amount}*/}
                                                                            {/*                </TableCell>*/}
                                                                            {/*            </TableRow>*/}
                                                                            {/*        </TableBody>*/}
                                                                            {/*    </Table>*/}
                                                                            {/*</TableContainer>*/}
                                                                            {/*<List dense>*/}
                                                                            {/*    {*/}
                                                                            {/*        groupedSummedGrants.find(x => x.date === label).grants.sort((a, b) => b.amount - a.amount).map((grant, index) => {*/}
                                                                            {/*            return <ListItem key={index}>*/}
                                                                            {/*                <ListItemText*/}
                                                                            {/*                    primary={`${grant.companyName} - ${grant.projectName}`}*/}
                                                                            {/*                    secondary={currency(Math.round(grant.amount).toFixed(0), { separator: '.', symbol: '', pattern: '#!', precision: 0 }).format()}*/}
                                                                            {/*                />*/}
                                                                            {/*            </ListItem>*/}
                                                                            {/*        })*/}
                                                                            {/*    }*/}
                                                                            {/*</List>*/}
                                                                        </CardContent>
                                                                    </Card>
                                                                )
                                                            }
                                                            return null
                                                        }} />
                                                    }
                                                    {/*{*/}
                                                    {/*    grantBars.map((grantBar, index) => {*/}
                                                    {/*        return <Bar key={index} dataKey={grantBar.name} stackId={'a'} fill={grantBar.color} />*/}
                                                    {/*    })*/}
                                                    {/*}*/}
                                                    <Bar dataKey="amount" stackId="a" fill={ primaryDarkColor } radius={[3, 3, 0, 0]} />
                                                </BarChart>
                                            </ResponsiveContainer>
                                        </div>
                                    </CardContent>
                                </Card>
                            </Grid>
                        }
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={5}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Card>
                                <CardHeader
                                    titleTypographyProps={{ variant: 'h6' }}
                                    title={<FormattedMessage
                                        id="FinanceOption.FactsHeader"
                                        defaultMessage={'Faktaboks'}
                                    />}
                                    subheader={<FormattedMessage
                                        id="FinanceOption.FactsSubHeader"
                                        defaultMessage={'Generell fakta om finansieringskilden'}
                                    />}
                                />
                                <CardContent>
                                    <Table className={classes.table} size="small">
                                        <TableBody>
                                            <TableRow>
                                                <TableCell component="th" scope="row" className={classes.tableCell}>
                                                    Type
                                                </TableCell>
                                                <TableCell align="right" className={classes.tableCell}>
                                                    <Typography variant={'body2'}>
                                                        {intl.formatMessage({ id: financeOptionTypes.find(x => x.enum === localFinanceOptionCopy.type).name })}
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell component="th" scope="row" className={classes.tableCell}>
                                                    Sektor
                                                </TableCell>
                                                <TableCell align="right" className={classes.tableCell}>
                                                    <Typography variant={'body2'}>
                                                        {intl.formatMessage({ id: financeOptionSectors.find(x => x.enum === localFinanceOptionCopy.sector).name })}
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell component="th" scope="row" className={classes.tableCell}>
                                                    Enhet
                                                </TableCell>
                                                <TableCell align="right" className={classes.tableCell}>
                                                    <Typography variant={'body2'}>
                                                        {intl.formatMessage({ id: financeOptionOrgforms.find(x => x.enum === localFinanceOptionCopy.orgForm).name })}
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                            {
                                                localFinanceOptionCopy.contactName && localFinanceOptionCopy.contactName.length > 0 &&
                                                <TableRow>
                                                    <TableCell component="th" scope="row" className={classes.tableCell}>
                                                        Kontaktperson
                                                    </TableCell>
                                                    <TableCell align="right" className={classes.tableCell}>
                                                        <Typography variant={'body2'}>
                                                            {localFinanceOptionCopy.contactName}
                                                        </Typography>
                                                    </TableCell>
                                                </TableRow>
                                            }
                                            {
                                                localFinanceOptionCopy.counties && localFinanceOptionCopy.counties.length > 0 &&
                                                <TableRow>
                                                    <TableCell component="th" scope="row" className={classes.tableCell}>
                                                        Områder
                                                    </TableCell>
                                                    <TableCell align="right" className={classes.tableCell}>
                                                        {
                                                            localFinanceOptionCopy.counties.map((item, index) => (
                                                                <Chip key={index} size="small"
                                                                      label={item}/>
                                                            ))
                                                        }
                                                    </TableCell>
                                                </TableRow>
                                            }
                                        </TableBody>
                                    </Table>
                                </CardContent>
                            </Card>
                        </Grid>
                        {
                            deadlinesSortedAndFiltered.length > 0 &&
                            <Grid item xs={12}>
                                <Card style={{ background: primaryDarkColor, color: '#FFF' }}>
                                    <CardHeader
                                        titleTypographyProps={{ variant: 'h6' }}
                                        title={<FormattedMessage
                                            id="FinanceOption.NextDeadlineHeader"
                                            defaultMessage={'Neste søknadsfrist'}
                                        />}
                                    />
                                    <CardContent>
                                        <Typography variant={'h5'} style={{ fontWeight: '600' }}>
                                            {
                                                moment(deadlinesSortedAndFiltered[0].deadline).format('LL')
                                            }
                                        </Typography>
                                        <Typography variant={'caption'}>
                                            {
                                                `Søknadsfristen utløper: ${moment().to(moment(deadlinesSortedAndFiltered[0].deadline).endOf('day'))}`
                                            }
                                        </Typography>
                                        {
                                            seeAllDeadlines &&
                                            <List>
                                                {
                                                    deadlinesSortedAndFiltered.slice(1).map((deadline, index) => (
                                                        <ListItem key={deadline.id}>
                                                            <ListItemAvatar>
                                                                <EventIcon/>
                                                            </ListItemAvatar>
                                                            <ListItemText
                                                                primary={moment(deadline.deadline).format('LL')}
                                                                secondary={moment().to(deadline.deadline)}
                                                            />
                                                        </ListItem>
                                                    ))
                                                }
                                            </List>
                                        }
                                    </CardContent>
                                    <CardActions>
                                        {
                                            seeAllDeadlines && deadlinesSortedAndFiltered.length > 1 &&
                                            <Button variant={'outlined'}
                                                    onClick={(event) => setSeeAllDeadlines(false)}>
                                                <FormattedMessage
                                                    id="Global.HideMore"
                                                    defaultMessage={"Ikke vis mer"}
                                                />
                                            </Button>
                                        }
                                        {
                                            !seeAllDeadlines && deadlinesSortedAndFiltered.length > 1 &&
                                            <Button variant={'outlined'}
                                                    onClick={(event) => setSeeAllDeadlines(true)}>
                                                <FormattedMessage
                                                    id="Global.ShowMore"
                                                    defaultMessage={"Vis mer"}
                                                />
                                            </Button>
                                        }
                                    </CardActions>
                                </Card>
                            </Grid>
                        }
                        {
                            localFinanceOptionCopy.resources.length > 0 &&
                            <Grid item xs={12}>
                                <Card>
                                    <CardHeader
                                        titleTypographyProps={{ variant: 'h6' }}
                                        title={<FormattedMessage
                                            id="FinanceOption.Resources"
                                            defaultMessage={'Ressurser og maler'}
                                        />}
                                        subheader={<FormattedMessage
                                        id="FinanceOption.ResourcesSubHeader"
                                        defaultMessage={'Her finner du maler og ressurser som kan hjelpe deg i prosessen.'}
                                    />}
                                    />
                                    <CardContent>
                                        <List>
                                            {
                                                officialResources.length > 0 &&
                                                <ListSubheader>
                                                    <FormattedMessage
                                                        id="Funding.OfficialResources"
                                                        defaultMessage={"Offisielle Ressurser"}
                                                    />
                                                </ListSubheader>
                                            }
                                            {
                                                officialResources.map((resource, index) => (
                                                    <ListItem key={index} disablePadding>
                                                        <ListItemButton component={Link} to={resource.resourceUrl} target="_blank">
                                                            <ListItemAvatar>
                                                                <Avatar style={{ backgroundColor: primaryDarkColor }} >
                                                                    {resource.resourceType === 0 && <PictureAsPdfIcon/>}
                                                                    {resource.resourceType === 1 && <LanguageIcon/>}
                                                                </Avatar>
                                                            </ListItemAvatar>
                                                            <ListItemText primary={resource.name}
                                                                          secondary={moment(resource.createdAt).format('LL')}/>
                                                            <ListItemSecondaryAction >
                                                                <a href={resource.resourceUrl} target={'_blank'} rel="noreferrer">
                                                                    <IconButton edge="end" aria-label="download">
                                                                        {resource.resourceType === 0 && <GetAppIcon/>}
                                                                        {resource.resourceType === 1 && <LinkIcon/>}
                                                                    </IconButton>
                                                                </a>
                                                            </ListItemSecondaryAction>
                                                        </ListItemButton>
                                                    </ListItem>
                                                ))
                                            }
                                            {
                                                nonOfficialResources.length > 0 &&
                                                <ListSubheader>
                                                    <FormattedMessage
                                                        id="Funding.OtherResources"
                                                        defaultMessage={"Ressurser fra andre"}
                                                    />
                                                </ListSubheader>
                                            }
                                            {
                                                nonOfficialResources.map((resource, index) => (
                                                    <ListItem key={index} disablePadding>
                                                        <ListItemButton component={Link} to={resource.resourceUrl} target="_blank">
                                                            <ListItemAvatar>
                                                                <Avatar style={{ backgroundColor: secondaryDarkColor }} >
                                                                    {resource.resourceType === 0 && <PictureAsPdfIcon/>}
                                                                    {resource.resourceType === 1 && <LanguageIcon/>}
                                                                </Avatar>
                                                            </ListItemAvatar>
                                                            <ListItemText primary={resource.name}
                                                                          secondary={moment(resource.createdAt).format('LL')}/>
                                                            <ListItemSecondaryAction>
                                                                <a href={resource.resourceUrl} target={'_blank'} rel="noreferrer">
                                                                    <IconButton edge="end" aria-label="download">
                                                                        {resource.resourceType === 0 && <GetAppIcon/>}
                                                                        {resource.resourceType === 1 && <LinkIcon/>}
                                                                    </IconButton>
                                                                </a>
                                                            </ListItemSecondaryAction>
                                                        </ListItemButton>
                                                    </ListItem>
                                                ))
                                            }
                                        </List>
                                    </CardContent>
                                </Card>
                            </Grid>
                        }
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        fetchingFinanceOptions: state.financeOption.fetchingFinanceOptions,
        creatingFinanceOption: state.financeOption.creatingFinanceOption,
        creatingDeadline: state.financeOption.creatingDeadline,
        deletingDeadline: state.financeOption.deletingDeadline,
        error: state.financeOption.error,
        financeOptions: state.financeOption.financeOptions,
        financeOptionsSubscriptions: state.financeOption.financeOptionsSubscriptions,
        subscriptionChanging: state.financeOption.subscriptionChanging,
        subscriptionFetching: state.financeOption.subscriptionFetching,
        yourOrganizations: state.organization.yourOrganizations,
        userInfo: state.user.userInfo,
        metadata: state.metadata.metadata,
        yourProjects: state.project.yourProjects,
        grants: state.financeOption.grants,
        isLoadingGrants: state.financeOption.grants
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getYourFinanceOptions: () => dispatch(getFinanceOptions()),
        getYourOrganizations: () => dispatch(getYourOrganizations()),
        patchFinanceOption: (patch, id) => dispatch(patchFinanceOption(patch, id)),
        createFinanceOptionDeadline: (newDeadline) => dispatch(createFinanceOptionDeadline(newDeadline)),
        deleteFinanceOptionDeadline: (deadlineId) => dispatch(deleteFinanceOptionDeadline(deadlineId)),
        updateFinanceOptionDeadline: (deadline) => dispatch(updateFinanceOptionDeadline(deadline)),
        subscriptionChange: (change) => dispatch(subscriptionChange(change)),
        getSubscriptions: () => dispatch(getSubscriptions()),
        deleteFinanceOption: (id) => dispatch(deleteFinanceOption(id)),
        getFinanceOptionGrants: (id) => dispatch(getFinanceOptionGrants(id)),
        addFavourite: (id) => dispatch(addFinanceOptionFavourite(id)),
        removeFavourite: (id) => dispatch(removeFinanceOptionFavourite(id))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(FundingPage))
