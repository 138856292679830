import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import AppBar from '@mui/material/AppBar'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import BusinessIcon from '@mui/icons-material/Business'
import VideoGameIcon from '@mui/icons-material/VideogameAsset'
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import { makeStyles, useTheme } from '@mui/styles'
import * as ROUTES from '../constants/routes'
import { useHistory, useLocation, withRouter } from 'react-router'
import { Link as RouterLink } from 'react-router-dom'
import Link from '@mui/material/Link'
import Avatar from '@mui/material/Avatar'
import SearchIcon from '@mui/icons-material/Search'
import PersonIcon from '@mui/icons-material/Person'
import InputBase from '@mui/material/InputBase'
import { signOut } from '../store/actions/authActions'
import { connect } from 'react-redux'
import { getUser } from '../store/actions/userActions'
import { changeOrganization, getOrganizationUsers, getYourOrganizations } from '../store/actions/organizationActions'
import MenuItem from '@mui/material/MenuItem'
import { getMetadata } from '../store/actions/metadataActions'
import Badge from '@mui/material/Badge'
import { ButtonGroup, ListItemAvatar, Menu, useScrollTrigger } from '@mui/material'
import { getYourProjects } from '../store/actions/projectActions'
import ReactGA from 'react-ga'
import { version } from '../../package.json'
import { getFinanceOptions } from '../store/actions/financeOptionActions'
import Popover from '@mui/material/Popover'
import ListSubheader from '@mui/material/ListSubheader'
import Button from '@mui/material/Button'
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import { AccountCircle, Person } from '@material-ui/icons'
import { ReactComponent as NonedaIcon } from '../assets/Noneda_Icon.svg';
import ExitToAppIcon from '@mui/icons-material/ExitToApp'
import {FormattedMessage, injectIntl} from 'react-intl'
import { getNews } from '../store/actions/newsActions'
import {defineMessages} from "@formatjs/intl";

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex'
    },
    drawer: {
        flexShrink: 0,
        [theme.breakpoints.down('sm')]: {
            width: '0px'
        }
    },
    appBar: {
        height: '64px'
    },
    menuButton: {
        display: 'none',
        marginRight: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
            display: 'block'
        }
    },
    toolbar: {
    },
    content: {
        flexGrow: 1,
        padding: '64px 0px 0px 5px'
    },
    avatar: {
        margin: '30px auto 10px auto',
        width: 70,
        height: 70,
        display: 'flex',
        justifyContent: 'center'
    },
    logoContainer: {
        marginRight: '5px !important',
    },
    logoText: {
        color: theme.palette.mode === 'dark' ? '#FFF' : '#253054',
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        }
    },
    logoIcon: {
        height: '30px',
        width: '30px',
        marginRight: '5px'
    },
    profileName: {
        display: 'flex',
        justifyContent: 'center',
        fontWeight: '500',
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        }
    },
    profileCompany: {
        display: 'flex',
        justifyContent: 'center'
    },
    navMenu: {
        margin: '20px auto'
    },
    search: {
        position: 'relative',
        height: '40px',
        borderRadius: '5px',
        paddingLeft: '10px',
        transition: 'background-color 150ms ease-out',
        background: theme.palette.background.paper,
        width: '500px',
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        }
    },
    searchIcon: {
        width: '50px',
        height: '40px',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    inputRoot: {
        color: 'inherit',
        height: '100%',
        width: '100%'
    },
    inputInput: {
        padding: '0px 0px 0px 20px',
        display: 'flex',
        width: '100%',
        height: '100%',
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        }
    },
    selectedOrganization: {
        width: '100%'
    },
    searchPopover: {
        borderBottomLeftRadius: '5px',
        borderBottomRightRadius: '5px',
        boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.15)',
        background: theme.palette.mode === 'dark' ? '##253054' : '#FFFFFF'
    },
    searchList: {
        backgroundColor: 'inherit',
        position: 'relative',
        overflow: 'auto',
        maxHeight: 300,
        width: '500px',
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        }
    },
    listSection: {
        backgroundColor: 'inherit'
    },
    ul: {
        backgroundColor: 'inherit',
        padding: 0
    },
    menu: {
        marginTop: '20px',
        marginBottom: '20px'
    },
    menuTab: {
        height: '50px',
        minHeight: '50px',
        padding: '3px 5px',
        margin: '5px'
    },
    menuTabWrapper: {
        flexDirection: 'row',
        justifyContent: 'left',
        alignItems: 'center',
        textAlign: 'left'
    },
    menuTabIcon: {
        marginRight: '20px'
    },
    menuTabIndicator: {
        left: '0px',
        marginTop: '7px',
        height: '36px !important',
        width: '9px',
        boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.1)',
        borderRadius: '5px',
        transition: 'all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms'
    },
    menuTabIndicatorInvisible: {
        left: '0px',
        marginTop: '7px',
        height: '36px !important',
        width: '9px',
        boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.1)',
        borderRadius: '5px',
        opacity: '0',
        transition: 'all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms'
    },
    grow: {
        flexGrow: 1
    }
}))

function ElevationScroll (props) {
    const { children, window } = props
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0,
        target: window ? window() : undefined
    })

    return React.cloneElement(children, {
        elevation: trigger ? 1 : 0
    })
}

ElevationScroll.propTypes = {
    children: PropTypes.element.isRequired,
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window: PropTypes.func
}

export const searchMessages = defineMessages({
    SearchFunding: {
        id: "Search.Funding",
        defaultMessage: "Finansiering"
    },
    SearchProject: {
        id: "Search.Project",
        defaultMessage: "Prosjekt"
    },
    SearchActionLinks: {
        id: "Search.ActionLinks",
        defaultMessage: "Actions & links"
    }
})

function LoggedInNav (props) {
    const { container, content, userInfo, yourOrganizations, selectedOrganizationId, changingActiveOrganization, yourProjects, organizationUsers, financeOptions } = props
    const classes = useStyles()
    
    const location = useLocation()
    const theme = useTheme()
    const history = useHistory()
    const [mobileOpen, setMobileOpen] = useState(false)
    const [totalSearchableList, setTotalSearchableList] = useState([])
    const [searchResults, setSearchResults] = useState([])
    const [searchValue, setSearchValue] = useState('')
    const [suggestions, setSuggestions] = useState([])
    const [searchAnchorEl, setSearchAnchorEl] = useState(null)
    const [selectOrgOpen, setSelectOrgOpen] = useState(false)

    const [anchorEl, setAnchorEl] = useState(null)
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null)

    const isMenuOpen = Boolean(anchorEl)
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl)

    const handleProfileMenuOpen = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null)
    }

    const handleMenuClose = () => {
        setSelectOrgOpen(false)
        setAnchorEl(null)
        handleMobileMenuClose()
    }

    const handleMobileMenuOpen = (event) => {
        setMobileMoreAnchorEl(event.currentTarget)
    }

    function groupBy (xs, key) {
        return xs.reduce(function (rv, x) {
            (rv[x[key]] = rv[x[key]] || []).push(x)
            return rv
        }, {})
    }

    const handleSearchChanged = (event) => {
        setSearchValue(event.target.value)

        const searchFiltered = totalSearchableList.filter(x => x.label.toLowerCase().includes(event.target.value.toLowerCase()))
        const searchFilteredAndGrouped = groupBy(searchFiltered, 'group')
        setSearchResults(searchFilteredAndGrouped)

        if (event.target.value.length > 1 && searchFiltered.length > 0) {
            setSearchAnchorEl(event.currentTarget)
        } else {
            setSearchAnchorEl(null)
        }
    }

    const searchItemClicked = (event, item) => {
        history.push(item.link)
        setSearchAnchorEl(false)
        setSearchResults([])
        setSearchValue('')
    }

    const searchOpen = Boolean(searchAnchorEl)
    const searchId = searchOpen ? 'simple-popover' : undefined

    const selectedOrganization = userInfo && yourOrganizations.find(x => x.id === userInfo.currentlyActiveOrganization)

    useEffect(() => {
        props.getUserInfo()
        props.getMetadata()
        props.getYourOrganizations()
        props.getFinanceOptions()
        if (userInfo && userInfo.currentlyActiveOrganization !== '00000000-0000-0000-0000-000000000000') {
            props.getOrganizationUsers(userInfo.currentlyActiveOrganization)
            props.getYourProjects(userInfo.currentlyActiveOrganization)
        }

        const totalSearchableList = []

        financeOptions && totalSearchableList.push(
            ...financeOptions.map(financeOption => {
                return {
                    group: "Search.Funding",
                    icon: <Avatar style={{ width: '24px', height: '24px' }} src={financeOption.organization.logoUrl} />,
                    label: financeOption.name,
                    link: '/funding/' + financeOption.id
                }
            })
        )

        yourProjects && totalSearchableList.push(
            ...yourProjects.map(project => {
                return {
                    group: "Search.Project",
                    label: project.name,
                    icon: <Avatar style={{ width: '24px', height: '24px' }} src={project.coverImageUrl} />,
                    link: '/projects/' + project.id
                }
            })
        )

        totalSearchableList.push(
            {
                group: "Search.ActionLinks",
                label: 'Your Organization',
                icon: <Avatar style={{ width: '24px', height: '24px' }} ><BusinessIcon /></Avatar>,
                link: '/company'
            },
            {
                group: "Search.ActionLinks",
                label: 'Your Profile',
                icon: <Avatar style={{ width: '24px', height: '24px' }} ><PersonIcon /></Avatar>,
                link: '/account'
            }
        )

        if (selectedOrganization) {
            if (selectedOrganization.hasFunding) {
                totalSearchableList.push(
                    {
                        group: "Search.ActionLinks",
                        label: 'New Funding',
                        icon: <Avatar style={{ width: '24px', height: '24px' }} ><MonetizationOnIcon /></Avatar>,
                        link: '/new-funding/'
                    }
                )
            }
            if (selectedOrganization.hasProjects) {
                totalSearchableList.push(
                    {
                        group: "Search.ActionLinks",
                        label: 'New Project',
                        icon: <Avatar style={{ width: '24px', height: '24px' }} ><VideoGameIcon /></Avatar>,
                        link: '/new-project'
                    }
                )
            }
        }

        setTotalSearchableList(totalSearchableList)
    }, [location])

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen)
    }

    const signOut = (event) => {
        props.signOut()
        props.history.push(ROUTES.HOME)
    }

    useEffect(() => {
        if (userInfo && userInfo.id && selectedOrganization && selectedOrganization.id) {
            ReactGA.set({
                userId: userInfo.id,
                organizationId: selectedOrganization.id
            })
        }
    }, [userInfo, yourOrganizations])

    let currentLocationTabValue = 'home'
    if (location.pathname.length === 1) {
        currentLocationTabValue = 'home'
    }
    if (location.pathname.endsWith('/company')) {
        currentLocationTabValue = 'company'
    } else if (location.pathname.endsWith('/company')) {
        currentLocationTabValue = 'company'
    } else if (location.pathname.endsWith('/projects')) {
        currentLocationTabValue = 'projects'
    } else if (location.pathname.endsWith('/new-project')) {
        currentLocationTabValue = 'new-project'
    } else if (location.pathname.endsWith('/funding')) {
        currentLocationTabValue = 'funding'
    } else if (location.pathname.endsWith('/admin')) {
        currentLocationTabValue = 'admin'
    }

    function handleSignOut (event) {
        handleMenuClose()
        signOut()
    }

    const handleChangeOrganization = (event, id) => {
        setSelectOrgOpen(false)
        props.changeOrganization(id)
    }

    const handleToggle = () => {
        setSelectOrgOpen((prevOpen) => !prevOpen)
    }

    const menuId = 'primary-search-account-menu'
    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            id={menuId}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={isMenuOpen}
            onClose={handleMenuClose}
        >
            {
                selectOrgOpen &&
                yourOrganizations.sort((a, b) => a.name < b.name ? -1 : 1).map((option, index) => (
                    <MenuItem
                        key={option.id}
                        disabled={index === 2}
                        selected={option.id === userInfo.currentlyActiveOrganization}
                        onClick={(event) => handleChangeOrganization(event, option.id)}
                    >
                        {option.name}
                    </MenuItem>
                ))
            }
            {
                !selectOrgOpen &&
                <div>
                    <MenuItem onClick={handleMenuClose} component={RouterLink} to={'/account'}>
                        <PersonIcon className={classes.menuTabIcon}/>
                        {userInfo && userInfo.name && userInfo.name.length > 0 ? userInfo.name : userInfo.email}
                    </MenuItem>
                    <ButtonGroup aria-label="split button" fullWidth>
                        <Button component={RouterLink}
                                to={'/company'}
                                style={{ border: 'none', justifyContent: 'left' }}
                        >
                            <Badge badgeContent={organizationUsers.filter(x => !x.approved).length} color="secondary"
                                   className={classes.menuTabIcon}>
                                <BusinessIcon/>
                            </Badge>
                            {selectedOrganization ? selectedOrganization.name : ''}
                        </Button>
                        {
                            yourOrganizations.length > 1 &&
                            <Button
                                size="small"
                                style={{ width: 'auto', border: 'none' }}
                                aria-controls={selectOrgOpen ? 'split-button-menu' : undefined}
                                aria-expanded={selectOrgOpen ? 'true' : undefined}
                                aria-label="select merge strategy"
                                aria-haspopup="menu"
                                onClick={handleToggle}
                            >
                                <ArrowDropDownIcon/>
                            </Button>
                        }
                    </ButtonGroup>
                    {
                        selectedOrganization && yourProjects.length > 0 && selectedOrganization.hasProjects &&
                        <MenuItem onClick={handleMenuClose} component={RouterLink} to={'/projects'}>
                            <VideoGameIcon className={classes.menuTabIcon}/>
                            <FormattedMessage
                                id="Global.Projects"
                                defaultMessage='Prosjekter'
                            />
                        </MenuItem>
                    }
                    {
                        selectedOrganization && selectedOrganization.hasProjects && yourProjects.length === 0 &&
                        <MenuItem onClick={handleMenuClose} component={RouterLink} to={'/new-project'}>
                            <VideoGameIcon className={classes.menuTabIcon}/>
                            <FormattedMessage
                                id="Global.CreateGameProject"
                                defaultMessage='Opprett spillprosjekt'
                            />
                        </MenuItem>
                    }
                    {
                        selectedOrganization && selectedOrganization.hasFunding &&
                        <MenuItem onClick={handleMenuClose} component={RouterLink} to={'/your-funding'}>
                            <MonetizationOnIcon className={classes.menuTabIcon}/>
                            <FormattedMessage
                                id="Global.Funding"
                                defaultMessage='Finansiering'
                            />
                        </MenuItem>
                    }
                    {
                        userInfo && userInfo.userRole === 1 &&
                        <MenuItem onClick={handleMenuClose} component={RouterLink} to={'/admin'}>
                            <SupervisorAccountIcon className={classes.menuTabIcon}/>
                            <FormattedMessage
                                id="Global.Administrator"
                                defaultMessage='Administrator'
                            />
                        </MenuItem>
                    }
                    <Divider style={{ margin: '10px 0px' }} />
                    <MenuItem onClick={handleSignOut}>
                        <ExitToAppIcon className={classes.menuTabIcon} />
                        <ListItemText primary={<FormattedMessage
                            id="Global.LogOut"
                            defaultMessage='Logg ut'
                        />}/>
                    </MenuItem>

                    <div style={{
                        textAlign: 'center',
                        margin: '10px 10px',
                        display: 'grid'
                    }}>
                        <Typography variant={'caption'} style={{ textAlign: 'center' }}>
                            {`v.${version}`}
                        </Typography>
                        <Link component={RouterLink} to={'/about'}
                              style={{ textAlign: 'center' }}>
                            <FormattedMessage
                                id="Toolbar.AboutThisSite"
                                defaultMessage="Om spillfinansiering.no"
                            />
                        </Link>
                    </div>
                </div>
            }
        </Menu>
    )

    const indicatorClass = currentLocationTabValue === '' ? classes.menuTabIndicatorInvisible : classes.menuTabIndicator

    return (
        <div className={classes.root}>
            <ElevationScroll {...props}>
                <AppBar position="fixed" className={classes.appBar}>
                    <Toolbar className={classes.toolbar}>
                        <Button className={classes.logoContainer} component={RouterLink} to={'/'} >
                            <NonedaIcon className={classes.logoIcon} />
                            <Typography style={{ textDecoration: 'none' }} className={classes.logoText}>
                                <FormattedMessage
                                    id="Global.LogoText"
                                    defaultMessage='Funding Portal'
                                    description='The logo text used on most pages'
                                />
                            </Typography>
                        </Button>
                        <div className={classes.search}>
                            <InputBase
                                placeholder={props.intl.formatMessage({
                                    id: 'messageId',
                                    defaultMessage: 'Søk så skal du finne...',
                                    description: 'Search placeholder text'
                                })}
                                value={searchValue}
                                onChange={handleSearchChanged}
                                classes={{
                                    root: classes.inputRoot,
                                    input: classes.inputInput
                                }}
                                inputProps={{ 'aria-label': 'search' }}
                                endAdornment={<div className={classes.searchIcon}>
                                    <SearchIcon/>
                                </div>}
                            />
                            <Popover
                                id={searchId}
                                open={searchOpen}
                                anchorEl={searchAnchorEl}
                                disableAutoFocus
                                disableEnforceFocus
                                onClose={() => setSearchAnchorEl(null)}
                                classes={{
                                    paper: classes.searchPopover
                                }}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left'
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left'
                                }}
                            >
                                <List className={classes.searchList} dense subheader={<li/>}>
                                    {
                                        Object.keys(searchResults).map((searchGroupKey) => {
                                            return (
                                                <li key={`section-${searchGroupKey}`} className={classes.listSection}>
                                                    <ul className={classes.ul}>
                                                        <ListSubheader>{props.intl.formatMessage({ id: searchGroupKey })}</ListSubheader>
                                                        {
                                                            searchResults[searchGroupKey].map((item, index) => (
                                                                <ListItem button key={`item-${index}`} onClick={e => searchItemClicked(e, item)}>
                                                                    <ListItemAvatar style={{ minWidth: '35px' }} >
                                                                        {item.icon}
                                                                    </ListItemAvatar>
                                                                    <ListItemText primary={`${item.label}`}/>
                                                                </ListItem>
                                                            ))
                                                        }
                                                    </ul>
                                                </li>
                                            )
                                        })
                                    }
                                </List>
                            </Popover>
                        </div>

                        <div className={classes.grow} />

                        <Typography variant={'body2'} className={classes.profileName}>
                            {userInfo && userInfo.name && userInfo.name.length > 0 ? userInfo.name : userInfo.email}
                        </Typography>
                        <IconButton
                            onClick={handleProfileMenuOpen}
                            aria-label="account of current user"
                            aria-controls="primary-search-account-menu"
                            aria-haspopup="true"
                            color="inherit"
                        >
                            <Avatar src={userInfo.profilePictureUrl}>
                                <AccountCircle />
                            </Avatar>
                        </IconButton>
                        {renderMenu}
                    </Toolbar>
                </AppBar>
            </ElevationScroll>
            <main className={classes.content}>
                {content}
            </main>
        </div>
    )
}

LoggedInNav.propTypes = {
    container: PropTypes.instanceOf(typeof Element === 'undefined' ? Object : Element)
}

const mapStateToProps = (state) => {
    return {
        authError: state.auth.authError,
        userInfo: state.user.userInfo,
        yourProjects: state.project.yourProjects,
        yourOrganizations: state.organization.yourOrganizations,
        financeOptions: state.financeOption.financeOptions,
        selectedOrganizationId: state.organization.selectedOrganizationId,
        changingActiveOrganization: state.organization.changingActiveOrganization,
        organizationUsers: state.organization.organizationUsers
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        signOut: () => dispatch(signOut()),
        getUserInfo: () => dispatch(getUser()),
        getMetadata: () => dispatch(getMetadata()),
        getYourProjects: (organizationId) => dispatch(getYourProjects(organizationId)),
        getYourOrganizations: () => dispatch(getYourOrganizations()),
        getOrganizationUsers: (id) => dispatch(getOrganizationUsers(id)),
        getFinanceOptions: () => dispatch(getFinanceOptions()),
        changeOrganization: (id) => dispatch(changeOrganization(id)),
        getNews: () => dispatch(getNews())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(injectIntl(LoggedInNav)))
