import React, { Component, useEffect, useState } from 'react'

import { makeStyles } from '@mui/styles'
import Typography from '@mui/material/Typography'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'
import { ReactComponent as Kulturradet } from '../assets/kulturradet.svg'
import BergenKommune from '../assets/bergenkommune.png'
import Zefyr from '../assets/zefyr.png'
import WhatsNew from '../components/WhatsNew'

const useStyles = makeStyles(theme => ({
    root: {
        padding: '20px'
    },
    supportLogo: {
        height: '50px', 
        padding: '20px 30px',
        boxSizing: 'content-box',
        backgroundColor: '#EEEDF3',
        borderRadius: '5px',
        margin: '10px auto',
    },
    supportLogos: {
        display: 'flex', 
        flexWrap: 'wrap',
        margin: '10px auto',
    }
}))

const AboutPage = (props) => {
    const classes = useStyles()

    useEffect(() => {
    }, [])

    return (
        <div className={classes.root}>
            <Typography variant={'overline'}>
                Om denne siden
            </Typography>
            <Typography variant={'h4'}>
                Dataspillsenteret - Finansieringsportal
            </Typography>
            <div style={{ padding: '20px 0px' }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <Card>
                            <CardHeader
                                title={'Om portalen'}
                            />
                            <CardContent>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Typography variant={'body'}>
                                            Denne portalen er en del av prosjektet Boost og alt innhold i portalen
                                            driftes og eies av <a href={"https://noneda.no"} target={"_blank"}>Noneda</a>.<br/>
                                            Kontakt for spørsmål, tilbakemeldinger og bruk av portalen sendes til Tallak
                                            Rundholt (daglig leder) per e-post: <a
                                            href={'mailto:tallak@noneda.no'}>tallak@noneda.no</a>.
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant={'h6'}>
                                            Finansiering
                                        </Typography>
                                        <Typography variant={'body'}>
                                            Portalen er finansiert gjennom tilskudd fra Bergen kommune, Kulturrådet og
                                            Mediefondet Zefyr.
                                        </Typography>
                                        <div className={classes.supportLogos}>
                                            <Kulturradet className={classes.supportLogo} />
                                            <img src={BergenKommune} className={classes.supportLogo} />
                                            <img src={Zefyr} className={classes.supportLogo} />
                                        </div>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant={'h6'}>
                                            Teknisk løsning
                                        </Typography>
                                        <Typography variant={'body'}>
                                            Teknisk løsning utvikles og eies av Marianne Lerdahl & Ole Andreas Haley.
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Card>
                            <CardHeader
                                title={'Versjonslogg'}
                            />
                            <CardContent>
                                <WhatsNew />
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AboutPage))
