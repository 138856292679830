import React from 'react'
import ReactDOM from 'react-dom'
import registerServiceWorker from './registerServiceWorker'
import App from './App'
import { Provider } from 'react-redux'
import { configureStore, persistor } from './store/store'
import { PersistGate } from 'redux-persist/integration/react'
import './styles/index.css'

ReactDOM.render(
    <Provider store={configureStore}>
        <PersistGate loading={null} persistor={persistor}>
            <App/>
        </PersistGate>
    </Provider>,
    document.getElementById('root'))

registerServiceWorker()
