import { createStore, applyMiddleware } from 'redux'
import { history } from './history'
import { routerMiddleware } from 'connected-react-router'
import { createLogger } from 'redux-logger'
import createRootReducer from './reducers/rootReducer'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunk from 'redux-thunk'

const loggerMiddleware = createLogger()

const middlewares = [
  thunk,
  routerMiddleware(history),
  loggerMiddleware
]
const enhancers = [applyMiddleware(...middlewares)]

const persistConfig = {
  key: 'root',
  storage
  // blacklist: ['router'],
}

const persistedReducer = persistReducer(persistConfig, createRootReducer(history))

export const configureStore = createStore(
  persistedReducer,
  composeWithDevTools(...enhancers)
)
export const persistor = persistStore(configureStore)
