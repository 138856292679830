import moment from 'moment'

export const filterBasedOnOrgLocation = (countiesMap, fundingOptions, organization) => {
  if (!fundingOptions) { return [] }
  if (!countiesMap || !organization) { return fundingOptions }

  const countyMap = countiesMap.find(x => x.municipality.toLowerCase() === organization.municipality.toLowerCase())
  if (!countyMap) { return fundingOptions }

  return fundingOptions.filter(financeOption => financeOption.counties.map(county => county.toLowerCase()).includes(countyMap.county.toLowerCase()))
}

export const checkOrganizationLocation = (countiesMap, fundingOption, organization) => {
  if (!fundingOption || !countiesMap || !organization) { return false }

  if (!fundingOption.counties || fundingOption.counties.length === 0) { return true }

  const countyMap = countiesMap.find(x => x.municipality.toLowerCase() === organization.municipality.toLowerCase())
  if (!countyMap) { return false }

  return fundingOption.counties.map(x => x.toLowerCase()).includes(countyMap.county.toLowerCase())
}

export const filterOnCompanyAge = (fundingOptions, organization) => {
  if (!fundingOptions) { return [] }
  if (!organization) { return fundingOptions }

  return fundingOptions.filter(financeOption =>
    moment(organization.registerDateUnitRegister).add(financeOption.companyAgeMin, 'year') < moment() &&
        moment(organization.registerDateUnitRegister) > moment().subtract(financeOption.companyAgeMax, 'year')
  )
}

export const checkCompanyAge = (fundingOption, organization) => {
  if (!fundingOption || !organization) { return false }

  return moment(organization.registerDateUnitRegister).add(fundingOption.companyAgeMin, 'year') < moment() &&
        moment(organization.registerDateUnitRegister) > moment().subtract(fundingOption.companyAgeMax, 'year')
}

export const filterBasedOnCultureTest = (fundingOptions, projects) => {
  if (!fundingOptions) { return [] }
  if (!projects) { return fundingOptions }

  return fundingOptions.filter(fundingOption => projects.find(project => {
    if (fundingOption.hasToPassCultureTest) {
      const passNeeded = 3
      let passCount = 0
      if (project.documentationInNorwegianOrSami) {
        passCount++
      }
      if (project.relevantForNorwegianAudience) {
        passCount++
      }
      if (project.mainPlotIsInNorwayOrEUOrFantasy) {
        passCount++
      }
      if (project.containsCentralNarrativeThroughEntireGame) {
        passCount++
      }

      return passCount >= passNeeded
    } else {
      return true
    }
  }))
}

export const checkCultureTest = (fundingOption, project) => {
  if (!fundingOption || !project) { return false }

  if (fundingOption.hasToPassCultureTest) {
    const passNeeded = 3
    let passCount = 0
    if (project.documentationInNorwegianOrSami) {
      passCount++
    }
    if (project.relevantForNorwegianAudience) {
      passCount++
    }
    if (project.mainPlotIsInNorwayOrEUOrFantasy) {
      passCount++
    }
    if (project.containsCentralNarrativeThroughEntireGame) {
      passCount++
    }

    return passCount >= passNeeded
  } else {
    return true
  }
}

export const filterOnBool = (fundingOptions, projects, fundingOptionsAttribute, projectAttribute) => {
  if (!fundingOptions) { return [] }
  if (!projects) { return fundingOptions }

  return fundingOptions.filter(fundingOption => !fundingOption[fundingOptionsAttribute] || projects.find(project => fundingOption[fundingOptionsAttribute] === project[projectAttribute]))
}

export const checkBool = (fundingOption, project, fundingOptionsAttribute, projectAttribute) => {
  if (!fundingOption || !project) { return false }

  if (!fundingOption[fundingOptionsAttribute]) { return true }

  return fundingOption[fundingOptionsAttribute] === project[projectAttribute]
}

export const filterOnFlag = (fundingOptions, projects, fundingOptionsAttribute, projectAttribute) => {
  if (!fundingOptions) { return [] }
  if (!projects) { return fundingOptions }

  return fundingOptions.filter(fundingOption => fundingOption[fundingOptionsAttribute] === 0 || projects.find(project => project[projectAttribute] & fundingOption[fundingOptionsAttribute]))
}

export const checkFlag = (fundingOption, project, fundingOptionsAttribute, projectAttribute) => {
  if (!fundingOption || !project) { return false }
  if (fundingOption[fundingOptionsAttribute] === 0) { return true }

  return project[projectAttribute] & fundingOption[fundingOptionsAttribute]
}
