import TimelineItem from '@mui/lab/TimelineItem'
import TimelineSeparator from '@mui/lab/TimelineSeparator'
import Avatar from '@mui/material/Avatar'
import TimelineConnector from '@mui/lab/TimelineConnector'
import TimelineContent from '@mui/lab/TimelineContent'
import Typography from '@mui/material/Typography'
import Timeline from '@mui/lab/Timeline'
import React from 'react'
import { makeStyles } from '@mui/styles'
import { connect } from 'react-redux'
import { ReactComponent as TwitterLogo } from '../assets/TwitterLogo.svg'
import { ReactComponent as FacebookLogo } from '../assets/FacebookLogo.svg'
import moment from 'moment'
import {FormattedMessage} from 'react-intl'
import {Link} from "react-router-dom";
import MoreTimeIcon from '@mui/icons-material/MoreTime';

const useStyles = makeStyles(theme => ({
    root: {
        margin: '0px',
    },
    missingOppositeContent: {
        '&:before': {
            content: 'none !important'
        }
    },
    normalConnector: {
        marginTop: '5px',
        marginBottom: '-10px'
    },
    lastConnectorTail: {
        display: 'none'
    }
}))

const NewsListItem = (props) => {
    const classes = useStyles()

    let icon = <Avatar style={{ width: '28px', height: '28px', marginTop: '8px', marginBottom: '8px' }} />
    let formatMessage = "";

    if(props.news && props.news.properties) {
        try {
            const newsProps = JSON.parse(props.news.properties);
            let formatMessageValues = {};
            const financeOption = props.financeOptions.find(x => x.id === props.news.financeOptionId);
            const organization =  props.yourOrganizations.find(x => x.id === props.news.organizationId);

            if (props.news.type === 0) {
                if (organization) {
                    formatMessageValues['organization'] = organization.name;
                }
                if (financeOption) {
                    formatMessageValues['fundingOption'] = <Link to={`funding/${financeOption.id}`} >
                        {financeOption.name}
                    </Link>;
                }
                if (newsProps.newDeadline) {
                    formatMessageValues['deadline'] = moment(newsProps.newDeadline).format("LL");
                }
                
                formatMessage = <FormattedMessage
                    id={'News.NewDeadline'}
                    defaultMessage={'Ny søknadsfrist for {fundingOption} lagt til: {deadline}'}
                    values={formatMessageValues}
                />
                
                icon = <MoreTimeIcon style={{ width: '28px', height: '28px', marginTop: '8px', marginBottom: '8px' }} />
            } else if (props.news.type === 1) {
                if (newsProps.url) {
                    formatMessageValues['url'] = <a href={newsProps.url} target={'_blank'}>
                        <FormattedMessage
                            id={'News.Tweet'}
                            defaultMessage={'tweet'}
                        />
                    </a>;
                }
                if (newsProps.source) {
                    formatMessageValues['source'] = newsProps.source
                }
                
                formatMessage = <FormattedMessage
                    id={'News.NewTweet'}
                    defaultMessage={'Ny {url} fra {source}'}
                    values={formatMessageValues}
                />

                icon = <TwitterLogo style={{ width: '28px', height: '28px', marginTop: '8px', marginBottom: '8px' }} />
            } else if (props.news.type === 2) {
                if (newsProps.url) {
                    formatMessageValues['url'] = <a href={newsProps.url} target={'_blank'}>
                        <FormattedMessage
                            id={'News.Post'}
                            defaultMessage={'post'}
                        />
                    </a>;
                }
                if (newsProps.source) {
                    formatMessageValues['source'] = newsProps.source;
                }
                
                formatMessage = <FormattedMessage
                    id={'News.NewFacebook'}
                    defaultMessage={'Ny {url} fra {source}'}
                    values={formatMessageValues}
                />

                icon = <FacebookLogo style={{ width: '28px', height: '28px', marginTop: '8px', marginBottom: '8px' }} />
            } else if (props.news.type === 3) {
                formatMessage = <FormattedMessage
                    id={'News.NewResource'}
                    defaultMessage={''}
                    values={formatMessageValues}
                />

                icon = <Avatar style={{ width: '28px', height: '28px', marginTop: '8px', marginBottom: '8px' }} src={props.news.organization.logoUrl} />
            }
        } catch(e) {
            console.log(e);
        }
    }

    return (
        <Timeline className={classes.root}>
            <TimelineItem classes={{ missingOppositeContent: classes.missingOppositeContent }}>
                <TimelineSeparator>
                    {icon}
                    <TimelineConnector className={props.index === props.total - 1 ? classes.lastConnectorTail : classes.normalConnector} />
                </TimelineSeparator>
                <TimelineContent>
                    <div style={{display: 'flex'}}>
                        <Typography>
                            {formatMessage} {<Typography variant={'caption'}>{moment(props.news.createdAt).fromNow()}</Typography>}
                        </Typography>
                    </div>
                </TimelineContent>
            </TimelineItem>
        </Timeline>
    )
}

const mapStateToProps = (state) => {
    return {
        yourOrganizations: state.organization.yourOrganizations,
        financeOptions: state.financeOption.financeOptions
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewsListItem)
