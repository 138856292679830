import axios from 'axios'
import { API_ROOT, configWithAuth } from '../api'

export const getNews = () => {
    return (dispatch) => {
        dispatch({ type: 'GET_NEWS_REQUEST' })

        axios.get(`${API_ROOT}news`, configWithAuth())
            .then((result) => {
                dispatch({ type: 'GET_NEWS_SUCCESS', data: result.data })
            })
            .catch((error) => {
                dispatch({ type: 'GET_NEWS_ERROR', error })
                dispatch({ type: 'SET_SNACKBAR', data: { variant: 'error', message: 'Fikk ikke hentet nyheter.' } })
            })
    }
}

export const addNews = (news) => {
    return (dispatch) => {
        axios.post(`${API_ROOT}news`, JSON.stringify(news), configWithAuth())
        dispatch({ type: 'POST_NEWS_REQUEST' })

            .then((result) => {
                dispatch({ type: 'POST_NEWS_SUCCESS', data: result.data })
            })
            .catch((error) => {
                dispatch({ type: 'POST_NEWS_ERROR', error })
                dispatch({ type: 'SET_SNACKBAR', data: { variant: 'error', message: 'Fikk ikke laget nyhet.' } })
            })
    }
}
