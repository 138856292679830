import React, { useEffect, useState } from 'react'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import { resetPasswordPin, resetPasswordRequestPin } from '../store/actions/authActions'
import { connect } from 'react-redux'
import { makeStyles } from '@mui/styles'
import Fade from '@mui/material/Fade'
import CircularProgress from '@mui/material/CircularProgress'
import CardContent from '@mui/material/CardContent'
import CardActions from '@mui/material/CardActions'
import {FormattedMessage} from 'react-intl'
import * as queryString from 'query-string'
import { Link } from 'react-router-dom'

const useStyles = makeStyles(theme => ({
  root: {
    padding: '20px'
  },
}))

const ResetPasswordPage = (props) => {
  const { authError, resettingPassword } = props
  const classes = useStyles()

  const [gotPinThroughSearch, setGotPinThroughSearch] = useState(false)
  const [requestedPin, setRequestedPin] = useState(false)
  const [resetPasswordForm, setResetPasswordForm] = useState({
    email: '',
    newPassword: '',
    pin: ''
  })

  useEffect(() => {
    const params = queryString.parse(props.location.search)
    if (params.email != null && params.pin != null) {
      setResetPasswordForm({
        ...resetPasswordForm,
        email: params.email,
        pin: params.pin
      })
      setRequestedPin(true)
      setGotPinThroughSearch(true)
    } else if (params.email != null) {
      setResetPasswordForm({
        ...resetPasswordForm,
        email: params.email
      })
    }
  }, [])

  const onRequestPinClicked = event => {
    event.preventDefault()

    props.resetPasswordRequestPin(resetPasswordForm.email)
    setRequestedPin(true)
  }

  const onResetPasswordClicked = event => {
    event.preventDefault()

    props.resetPasswordPin(resetPasswordForm)
  }

  const onChange = event => {
    setResetPasswordForm({
      ...resetPasswordForm,
      [event.target.name]: event.target.value
    })
  }

  const onChangePin = event => {
    const clampedPin = event.target.value
    if (clampedPin.length > 4) { return }

    setResetPasswordForm({
      ...resetPasswordForm,
      pin: event.target.value
    })
  }

  return (
        <div className={classes.root}>
            <Grid container spacing={5} justifyContent={'center'} alignContent={'center'}>
                <Grid item xs={12} sm={5}>
                    <Fade in timeout={500}>
                        <Card>
                            <form onSubmit={requestedPin ? onResetPasswordClicked : onRequestPinClicked} style={{ padding: 20 }}>
                                <CardContent>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <Typography variant={'h6'} align={'center'} marginBottom>
                                                {
                                                    gotPinThroughSearch
                                                      ? <FormattedMessage
                                                            id="SignIn.SetPasswordHeader"
                                                            defaultMessage='Sett et passord for din bruker og logg deretter inn for å komme i gang'
                                                        />
                                                      : <FormattedMessage
                                                            id="SignIn.ResetPasswordheader"
                                                            defaultMessage='For å sette passord på nytt, sjekk at e-posten din er riktig og trykk på Gjenopprett Passord'
                                                        />
                                                }
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                name="email"
                                                value={resetPasswordForm.email}
                                                onChange={onChange}
                                                type="text"
                                                label={
                                                    <FormattedMessage
                                                        id="Global.EmailTextField"
                                                        defaultMessage='Email address'
                                                        description='Label for the email address text field'
                                                    />
                                                }
                                                fullWidth
                                                variant={'outlined'}
                                            />
                                        </Grid>
                                        {
                                            requestedPin && !gotPinThroughSearch &&
                                            <Grid item xs={12}>
                                                <TextField
                                                    name="pin"
                                                    value={resetPasswordForm.pin}
                                                    onChange={onChangePin}
                                                    type="number"
                                                    label={
                                                        <FormattedMessage
                                                            id="Global.PinTextField"
                                                            defaultMessage='4-sifret PIN kode'
                                                        />
                                                    }
                                                    fullWidth
                                                    variant={'outlined'}
                                                />
                                            </Grid>
                                        }
                                        {
                                            requestedPin && resetPasswordForm.pin.length === 4 &&
                                            <Grid item xs={12}>
                                                <TextField
                                                    name="newPassword"
                                                    value={resetPasswordForm.newPassword}
                                                    onChange={onChange}
                                                    type="password"
                                                    label={
                                                        <FormattedMessage
                                                            id="Global.NewPasswordTextField"
                                                            defaultMessage='Nytt passord'
                                                            description='Label for the password text field'
                                                        />
                                                    }
                                                    fullWidth
                                                    variant={'outlined'}
                                                />
                                            </Grid>
                                        }
                                        <Grid item xs={12}>
                                            {authError && <p>{authError}</p>}
                                        </Grid>
                                        <Grid item xs={12}>
                                            {
                                                requestedPin &&
                                                <Button disabled={resetPasswordForm.email === '' || resetPasswordForm.pin.length !== 4 || resetPasswordForm.password === '' || resettingPassword}
                                                        variant={'contained'}
                                                        fullWidth
                                                        type="submit">
                                                    {
                                                        resettingPassword
                                                          ? <CircularProgress size={24}/>
                                                          : <FormattedMessage
                                                                id="SignIn.ResetPassword"
                                                                defaultMessage='Sett nytt passord'
                                                            />
                                                    }
                                                </Button>
                                            }
                                            {
                                                !requestedPin &&
                                                <Button disabled={resetPasswordForm.email === '' || resettingPassword}
                                                        variant={'contained'}
                                                        fullWidth
                                                        type="submit">
                                                    {
                                                        resettingPassword
                                                          ? <CircularProgress size={24}/>
                                                          : <FormattedMessage
                                                                id="SignIn.ResetPasswordRequest"
                                                                defaultMessage='Gjenopprett passord'
                                                            />
                                                    }
                                                </Button>
                                            }

                                        </Grid>
                                    </Grid>
                                </CardContent>
                                <CardActions>
                                    <Typography variant={'caption'} component={Link} to={'signin'} style={{ margin: 'auto auto' }}>
                                        Tilbake til siden for å logge inn
                                    </Typography>
                                </CardActions>
                            </form>
                        </Card>
                    </Fade>
                </Grid>
            </Grid>
        </div>
  )
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.authError,
    isAuthenticating: state.auth.isAuthenticating,
    resettingPassword: state.auth.resettingPassword
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    resetPasswordPin: (form) => dispatch(resetPasswordPin(form)),
    resetPasswordRequestPin: (email) => dispatch(resetPasswordRequestPin(email))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordPage)
