import axios from 'axios'
import { API_ROOT, configWithoutAuth } from '../api'

axios.defaults.timeout = 10000;

export const getMetadata = () => {
  return (dispatch) => {
    axios.get(`${API_ROOT}metadata`, configWithoutAuth())
      .then((result) => {
        dispatch({ type: 'GET_METADATA_SUCCESS', data: result.data })
      })
      .catch((error) => {
        dispatch({ type: 'GET_METADATA_ERROR', error })
        dispatch({ type: 'SET_SNACKBAR', data: { variant: 'error', message: 'Fikk ikke hentet metadata.' } })
      })
  }
}
