export const setSnackbar = (variant = 'info', message = '') => {
  return (dispatch) => {
    dispatch({
      type: 'SET_SNACKBAR',
      data: {
        variant: variant,
        message: message
      }
    })
  }
}

export const closeSnackbar = () => {
  return (dispatch) => {
    dispatch({ type: 'CLOSE_SNACKBAR' })
  }
}
