const initState = {
  metadata: [],
  error: ''
}

const metadataReducer = (state = initState, action) => {
  switch (action.type) {
    case 'GET_METADATA_SUCCESS':
      return {
        ...state,
        error: '',
        metadata: action.data
      }
    case 'GET_METADATA_ERROR':
      return {
        ...state,
        error: 'Failed to fetch metadata'
      }

    default:
      return state
  }
}

export default metadataReducer
