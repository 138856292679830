const initState = {
  variant: 'info',
  message: '',
  open: false
}

const snackbarReducer = (state = initState, action) => {
  switch (action.type) {
    case 'SET_SNACKBAR':
      return {
        ...state,
        message: action.data.message,
        variant: action.data.variant,
        open: true
      }
    case 'CLOSE_SNACKBAR':
      return {
        ...state,
        open: false
      }

    case 'SIGNOUT_SUCCESS':
      return initState

    default:
      return state
  }
}

export default snackbarReducer
