import React, { useEffect } from 'react'
import { Router as BrowserRouter, Switch, Route } from 'react-router-dom'
import LandingPage from './pages/LandingPage'
import * as ROUTES from './constants/routes'
import SignInPage from './pages/SignInPage'
import HomePage from './pages/HomePage'
import ProjectsPage from './pages/ProjectsPage'
import CompanyPage from './pages/CompanyPage'
import AccountPage from './pages/AccountPage'
import AdminPage from './pages/AdminPage'
import FundingPage from './pages/FundingPage'
import ProjectPage from './pages/ProjectPage'
import NewProjectPage from './pages/NewProjectPage'
import NotFoundPage from './pages/NotFoundPage'
import LoggedOutNav from './components/LoggedOutNav'
import LoggedInNav from './components/LoggedInNav'
import { connect } from 'react-redux'
import YourFundingPage from './pages/YourFundingPage'
import NewFundingPage from './pages/NewFundingPage'
import FundingOverviewPage from './pages/FundingOverviewPage'
import { history } from './store/history'
import Snackbar from '@mui/material/Snackbar'
import SnackbarWrapper from './components/SnackbarWrapper'
import { closeSnackbar } from './store/actions/snackbarActions'
import EditProjectPage from './pages/EditProjectPage'
import EditFundingPage from './pages/EditFundingPage'
import ReactGA from 'react-ga'
import ResetPasswordPage from './pages/ResetPasswordPage'
import { createTheme, ThemeProvider } from '@mui/material';
import { IntlProvider } from 'react-intl'
import { useClearCache } from 'react-clear-cache'
import AdapterMoment from '@mui/lab/AdapterMoment';

import localeNorData from './i18n/compiled/nb.json'
import localeEnData from './i18n/compiled/en.json'
import { getMetadata } from './store/actions/metadataActions'
import AboutPage from './pages/AboutPage'
import useMediaQuery from '@mui/material/useMediaQuery'
import CssBaseline from '@mui/material/CssBaseline'
import { version } from '../package.json'

import {LocalizationProvider} from "@mui/lab"; // theme css file

const trackingId = 'UA-160615803-1' // Replace with your Google Analytics tracking ID
ReactGA.initialize(trackingId)

// ReactGA.event({
//     category: "Sign Up",
//     action: "User pressed the big blue sign up button",
// })

// Initialize google analytics page view tracking
history.listen(location => {
  ReactGA.set({ page: location.pathname }) // Update the user's current page
  ReactGA.pageview(location.pathname) // Record a pageview for the given page
})

const supportedLanguages = ['en', 'nb']
const language = (navigator.languages && navigator.languages[0]) || navigator.language
let messages
let languageWithoutRegionCode = language.toLowerCase().split(/[_-]+/)[0]

const storedLanguage = localStorage.getItem('language')
if (storedLanguage) {
  switch (storedLanguage) {
    case 'nb':
      languageWithoutRegionCode = 'nb'
      break
    case 'en':
      languageWithoutRegionCode = 'en'
      break
  }
}

switch (languageWithoutRegionCode) {
  case 'nb':
    messages = localeNorData
    localStorage.setItem('language', 'nb')
    break
  case 'en':
    messages = localeEnData
    localStorage.setItem('language', 'en')
    break
  default:
    messages = localeEnData
    localStorage.setItem('language', 'en')
}

// 44874F
// 74BD7A
// 3A2D3D

export const primaryLightColor = '#EB5B1C'
export const secondaryLightColor = '#058EEB'
export const backgroundDefaultLightColor = '#EEEDF3'
export const backgroundPaperLightColor = '#FFFFFF'
export const errorLightColor = '#EB1210'

export const primaryDarkColor = '#EB5B1C'
export const secondaryDarkColor = '#058EEB'
export const backgroundDefaultDarkColor = '#182039'
export const backgroundPaperDarkColor = '#2a3350'
export const errorDarkColor = '#EB1210'

const App = (props) => {
  const { isAuthenticated, snackbar } = props
  const { isLatestVersion, emptyCacheStorage } = useClearCache()
  const overridePreferDarkMode = localStorage.getItem('preferDarkMode')
  const defaultPrefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)')
  const preferDarkMode = overridePreferDarkMode != null ? Boolean(overridePreferDarkMode === 'true') : defaultPrefersDarkMode

  const theme = React.useMemo(
      () => {
        return createTheme({
          palette: {
            mode: preferDarkMode ? 'dark' : 'light',
            primary: {
              main: preferDarkMode ? primaryDarkColor : primaryLightColor
            },
            secondary: {
              main: preferDarkMode ? secondaryDarkColor : secondaryLightColor
            },
            background: {
              default: preferDarkMode ? backgroundDefaultDarkColor : backgroundDefaultLightColor,
              paper: preferDarkMode ? backgroundPaperDarkColor : backgroundPaperLightColor
            },
            error: {
              main: preferDarkMode ? errorDarkColor : errorLightColor
            }
          },
          components: {
            MuiAppBar: {
              styleOverrides: {
                root: {
                  boxShadow: 'none'
                },
                colorPrimary: {
                  backgroundColor: preferDarkMode ? backgroundDefaultDarkColor : backgroundDefaultLightColor,
                  color: preferDarkMode ? '#FFFFFF' : '#333333'
                }
              }
            },
            MuiDrawer: {
              styleOverrides: {
                paper: {
                  // backgroundColor: preferDarkMode ? 'rgba(0,0,0,0)' : 'rgba(0,0,0,0)'
                }
              }
            },
            MuiCardHeader: {
              styleOverrides: {
                title: {
                  fontSize: '18px',
                  fontWeight: '700'
                }
              }
            },
            MuiCard: {
              styleOverrides: {
                root: {
                  boxShadow: preferDarkMode ? '0px 5px 10px #171c2b' : '0px 5px 10px #DFE1E6',
                  borderRadius: '10px'
                }
              }
            },
            MuiCardActions: {
              styleOverrides: {
                root: {
                  padding: '8px 16px 16px 16px'
                }
              }
            },
            MuiButton: {
              styleOverrides: {
                contained: {
                  padding: '5px 20px',
                  borderRadius: '5px',
                  // backgroundColor: primaryColor,
                  // color: secondaryColor,
                  boxShadow: 'none',
                  '&:hover': {
                    boxShadow: 'none'
                  }
                }
              }
            },
            MuiStepper: {
              styleOverrides: {
                root: {
                  backgroundColor: '#FFFFFF00'
                }
              }
            },
            MuiMobileStepper: {
              styleOverrides: {
                root: {
                  backgroundColor: '#FFFFFF00'
                }
              }
            },
            MuiChip: {
              styleOverrides: {
                root: {
                  borderRadius: '5px',
                  margin: '2px'
                },
                deleteIcon: {
                  fill: '#EB1210',
                  color: '#35323C',
                  '&:hover': {
                    fill: '#b80d0c'
                  }
                }
              }
            },
            MuiAccordion: {
              styleOverrides: {
                root: {
                  boxShadow: 'none',
                  border: 'none',
                  // border: '2px solid rgba(0,0,0,0.2)',
                  // borderTop: '1px solid #FFFFFF',
                  borderRadius: '0px !important',
                  '&$expanded': {
                    margin: 'auto'
                  },
                  '&::before': {}
                }
              }
            },
            MuiAccordionSummary: {
              styleOverrides: {
                root: {
                  borderRadius: '5px',
                  minHeight: '50px',
                  '&$expanded': {
                    minHeight: '50px'
                  }
                },
                content: {
                  '&$expanded': {
                    margin: '12px 0'
                  }
                }
              }
            },
            MuiPaper: {
              styleOverrides: {
                elevation2: {
                  boxShadow: 'none'
                },
                elevation4: {
                  boxShadow: 'none'
                }
              }
            },
            MuiAvatar: {
              styleOverrides: {
                root: {
                  boxSizing: 'border-box',
                  borderRadius: '5px'
                }
              }
            },
            MuiInputBase: {
              styleOverrides: {
                root: {
                  color: 'inherit',
                }
              }
            },
            MuiFormLabel: {
              styleOverrides: {
                root: {
                  color: 'inherit',
                }
              }
            }
          }
        })
      },
      [defaultPrefersDarkMode]
  )

  useEffect(() => {
    props.getMetadata()
    if (!isLatestVersion) { emptyCacheStorage() }
  }, [])

  function closeSnackbar (event, reason) {
    props.closeSnackbar();
  }

  return (
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <CssBaseline/>
          <IntlProvider locale={languageWithoutRegionCode} messages={messages} textComponent="span">
            <BrowserRouter history={history}>
              <Snackbar
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                  }}
                  open={snackbar.open}
                  autoHideDuration={3000}
                  onClose={closeSnackbar}
              >
                <SnackbarWrapper
                    onClose={closeSnackbar}
                    variant={snackbar.variant}
                    message={snackbar.message}
                />
              </Snackbar>
              {
                isAuthenticated
                    ? <div>
                      <LoggedInNav content={
                        <Switch>
                          <Route exact path={ROUTES.HOME} component={HomePage}/>
                          <Route exact path={ROUTES.FUNDING_OVERVIEW} component={FundingOverviewPage}/>
                          <Route exact path={ROUTES.PROJECTS} component={ProjectsPage}/>
                          <Route exact path={ROUTES.COMPANY} component={CompanyPage}/>
                          <Route exact path={ROUTES.ACCOUNT} component={AccountPage}/>
                          <Route exact path={ROUTES.ADMIN} component={AdminPage}/>
                          <Route exact path={ROUTES.YOUR_FUNDING} component={YourFundingPage}/>
                          <Route exact path={ROUTES.NEW_FUNDING} component={NewFundingPage}/>
                          <Route exact path={ROUTES.FUNDING} component={FundingPage}/>
                          <Route exact path={ROUTES.PROJECT} component={ProjectPage}/>
                          <Route exact path={ROUTES.NEW_PROJECT} component={NewProjectPage}/>
                          <Route exact path={ROUTES.EDIT_PROJECT} component={EditProjectPage}/>
                          <Route exact path={ROUTES.EDIT_FUNDING} component={EditFundingPage}/>
                          <Route exact path={ROUTES.ABOUT_PAGE} component={AboutPage}/>
                          <Route component={NotFoundPage}/>
                        </Switch>
                      }>
                      </LoggedInNav>
                    </div>
                    : <div>
                      <LoggedOutNav content={
                        <Switch>
                          <Route exact path={ROUTES.HOME} component={LandingPage}/>
                          {/* <Route exact path={ROUTES.SIGN_UP} component={SignUpPage}/> */}
                          <Route exact path={ROUTES.SIGN_IN} component={SignInPage}/>
                          <Route exact path={ROUTES.RESET_PASSWORD} component={ResetPasswordPage}/>
                          <Route component={NotFoundPage}/>
                        </Switch>
                      }>
                      </LoggedOutNav>
                    </div>
              }
            </BrowserRouter>
          </IntlProvider>
        </LocalizationProvider>
      </ThemeProvider>
  )
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.authError,
    isAuthenticated: state.auth.isAuthenticated,
    snackbar: state.snackbar
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    closeSnackbar: () => dispatch(closeSnackbar()),
    getMetadata: () => dispatch(getMetadata())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App)
