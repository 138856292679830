import React from 'react'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import Typography from '@mui/material/Typography'

const NotFoundPage = () => (
    <div style={{ padding: 20 }}>
        <Grid container spacing={5} justifyContent={'center'} alignContent={'center'}>
            <Grid item xs={6}>
                <Card>
                    <Typography variant={'overline'}>
                        Page not found
                    </Typography>
                </Card>
            </Grid>
        </Grid>
    </div>
)

export default NotFoundPage
