import React, { useEffect, useState } from 'react'

import Grid from '@mui/material/Grid'
import {
  CardActions,
  CardContent,
  ListItem,
  ListItemAvatar, ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import Typography from '@mui/material/Typography'
import { connect } from 'react-redux'
import {getFinanceOptions, removeFinanceOptionFavourite} from '../store/actions/financeOptionActions'
import {FormattedMessage} from 'react-intl'
import {
  filterBasedOnCultureTest,
  filterBasedOnOrgLocation, filterOnBool, filterOnCompanyAge,
  filterOnFlag
} from '../constants/filteringLogic'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import { patchOrganization } from '../store/actions/organizationActions'
import jsonpatch from 'fast-json-patch'
import Card from '@mui/material/Card'
import ErrorIcon from '@mui/icons-material/Error'
import CardHeader from '@mui/material/CardHeader'
import { Link, Link as RouterLink } from 'react-router-dom'
import moment from 'moment'
import { useIntl } from 'react-intl'
import { getNews } from '../store/actions/newsActions'
import NewsListItem from '../components/NewsListItem'
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import PropTypes from "prop-types";
import List from "@mui/material/List";
import Avatar from "@mui/material/Avatar";
import _ from "lodash";
import IconButton from "@mui/material/IconButton";
import StarIcon from "@mui/icons-material/Star";
import {Alert} from "@mui/lab";

const useStyles = makeStyles(theme => ({
  root: {
    padding: '20px'
  },
  filterHeader: {
    display: 'flex'
  },
  columnHeader: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    borderRadius: '25px',
    transition: 'all 150ms ease-in-out',
    height: '40px',
    userSelect: 'none',
    '&:hover': {
      background: 'rgba(0, 0, 0, 0.2)'
    }
  },
  columnHeaderOrderIcon: {
    display: 'flex'
  },
  list: {
    padding: '0px',
    margin: '0px'
  },
  listItem: {
    listStyle: 'none',
    padding: '0px',
    margin: '10px auto'
  }
}))

const spring = {
  type: 'spring',
  damping: 50,
  stiffness: 300
}

const listVariant = {
  initial: { scale: 1, opacity: 1 },
  enter: {
    scale: 1,
    opacity: 1,
    transition: {
      when: 'beforeChildren',
      staggerChildren: 0.3
    }
  }
}

const listItemVariant = {
  initial: { scale: 1, opacity: 0 },
  enter: { scale: 1, opacity: 1, transition: { duration: 0.5 } }
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
      <div
          role="tabpanel"
          hidden={value !== index}
          id={`simple-tabpanel-${index}`}
          aria-labelledby={`simple-tab-${index}`}
          {...other}
      >
        {value === index && (
            <div>
              {children}
            </div>
        )}
      </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const HomePage = (props) => {
  const { financeOptions, fetchingFinanceOptions, yourProjects, yourOrganizations, userInfo, metadata, fetchingProjects } = props
  const classes = useStyles()
  const intl = useIntl()

  const [ordering, setOrdering] = useState({
    column: 'name',
    orderAsc: false
  })
  const [showNotMatching, setShowNotMatching] = useState(false)
  const [date, changeDate] = useState(new Date())

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const selectedOrganization = userInfo && yourOrganizations.find(x => x.id === userInfo.currentlyActiveOrganization)

  const setOrderingClicked = (columnName) => {
    setOrdering({
      column: columnName,
      orderAsc: ordering.column === columnName ? !ordering.orderAsc : false
    })
  }

  useEffect(() => {
    props.getFinanceOptions()
    props.getNews()
  }, [])

  const handleAddFundingModule = (event) => {
    const patchedOrgCopy = {
      ...selectedOrganization,
      hasFunding: true
    }

    const patch = jsonpatch.compare(selectedOrganization, patchedOrgCopy)
    props.patchOrganization(patch, selectedOrganization.id)
  }

  const handleAddProjectModule = event => {
    const patchedOrgCopy = {
      ...selectedOrganization,
      hasProjects: true
    }

    const patch = jsonpatch.compare(selectedOrganization, patchedOrgCopy)
    props.patchOrganization(patch, selectedOrganization.id)
  }

  let orderedOptions = financeOptions
  const hasProjects = yourProjects.length > 0

  if (!hasProjects) {
    orderedOptions = orderedOptions.filter(x => x.orgForm !== 0)
  }
  if (!selectedOrganization) {
    orderedOptions = orderedOptions.filter(x => x.orgForm !== 1)
  }

  orderedOptions = filterBasedOnOrgLocation(metadata.countiesMap, orderedOptions, selectedOrganization)
  orderedOptions = filterOnCompanyAge(orderedOptions, selectedOrganization)
  orderedOptions = filterBasedOnCultureTest(orderedOptions, yourProjects)
  orderedOptions = filterOnFlag(orderedOptions, yourProjects, 'stage', 'stage')
  orderedOptions = filterOnFlag(orderedOptions, yourProjects, 'gamePlayMode', 'gamePlayMode')
  orderedOptions = filterOnFlag(orderedOptions, yourProjects, 'gamePurpose', 'gamePurpose')
  orderedOptions = filterOnFlag(orderedOptions, yourProjects, 'businessModel', 'businessModel')
  orderedOptions = filterOnFlag(orderedOptions, yourProjects, 'companyRole', 'companyRole')
  orderedOptions = filterOnFlag(orderedOptions, yourProjects, 'pegiRating', 'pegiRating')
  orderedOptions = filterOnBool(orderedOptions, yourProjects, 'hasRightsToIp', 'rightsToIp')
  orderedOptions = filterOnBool(orderedOptions, yourProjects, 'isNarrative', 'containsCentralNarrativeThroughEntireGame')

  let orderedNonMatchingOptions = financeOptions.filter(financeOption => !orderedOptions.find(matchingOption => matchingOption.id === financeOption.id))

  if (ordering.column === 'name') {
    orderedOptions = orderedOptions.sort((a, b) => ordering.orderAsc
        ? (a.name > b.name ? -1 : 1)
        : (b.name > a.name ? -1 : 1))
    orderedNonMatchingOptions = orderedNonMatchingOptions.sort((a, b) => ordering.orderAsc
        ? (a.name > b.name ? -1 : 1)
        : (b.name > a.name ? -1 : 1))
  } else if (ordering.column === 'orgForm') {
    orderedOptions = orderedOptions.sort((a, b) => ordering.orderAsc ? a.orgForm - b.orgForm : b.orgForm - a.orgForm)
    orderedNonMatchingOptions = orderedNonMatchingOptions.sort((a, b) => ordering.orderAsc ? a.orgForm - b.orgForm : b.orgForm - a.orgForm)
  } else if (ordering.column === 'sector') {
    orderedOptions = orderedOptions.sort((a, b) => ordering.orderAsc ? a.sector - b.sector : b.sector - a.sector)
    orderedNonMatchingOptions = orderedNonMatchingOptions.sort((a, b) => ordering.orderAsc ? a.sector - b.sector : b.sector - a.sector)
  } else if (ordering.column === 'type') {
    orderedOptions = orderedOptions.sort((a, b) => ordering.orderAsc ? a.type - b.type : b.type - a.type)
    orderedNonMatchingOptions = orderedNonMatchingOptions.sort((a, b) => ordering.orderAsc ? a.type - b.type : b.type - a.type)
  } else if (ordering.column === 'deadline') {
    orderedOptions = orderedOptions.sort(function (optionA, optionB) {
      const optionADeadlines = optionA.deadlines.sort((a, b) => new Date(a.deadline) - new Date(b.deadline))
      const optionBDeadlines = optionB.deadlines.sort((a, b) => new Date(a.deadline) - new Date(b.deadline))

      if (optionADeadlines.length === 0 && optionBDeadlines.length > 0) { return -1 } else if (optionBDeadlines.length === 0 && optionADeadlines.length > 0) { return 1 } else if (optionADeadlines.length === 0 && optionBDeadlines.length === 0) { return 0 }

      if (ordering.orderAsc) { return new Date(optionBDeadlines[0].deadline) - new Date(optionADeadlines[0].deadline) } else { return new Date(optionADeadlines[0].deadline) - new Date(optionBDeadlines[0].deadline) }
    })

    orderedNonMatchingOptions = orderedNonMatchingOptions.sort(function (optionA, optionB) {
      const optionADeadlines = optionA.deadlines.sort((a, b) => new Date(a.deadline) - new Date(b.deadline))
      const optionBDeadlines = optionB.deadlines.sort((a, b) => new Date(a.deadline) - new Date(b.deadline))

      if (optionADeadlines.length === 0 && optionBDeadlines.length > 0) { return -1 } else if (optionBDeadlines.length === 0 && optionADeadlines.length > 0) { return 1 } else if (optionADeadlines.length === 0 && optionBDeadlines.length === 0) { return 0 }

      if (ordering.orderAsc) { return new Date(optionBDeadlines[0].deadline) - new Date(optionADeadlines[0].deadline) } else { return new Date(optionADeadlines[0].deadline) - new Date(optionBDeadlines[0].deadline) }
    })
  }

  const renderDayContents = (day, date) => {
    const todaysFinanceOptions = financeOptions.filter(x => x.deadlines.find(y => moment(y.deadline).isSame(date, 'day')))

    const tooltipText = `Tooltip for date: ${todaysFinanceOptions.length}`
    return <span className={todaysFinanceOptions.length > 0 ? 'dayWithFinanceOptions' : 'dayWithoutFinanceOptions'} title={tooltipText}>{moment(date).date()}</span>
  }

  const lastCreatedFinanceOptions = financeOptions.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
  const lastUpdatedFinanceOptions = financeOptions.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
  const favouritedFinanceOptions = userInfo.financeOptionFavourites && userInfo.financeOptionFavourites.length > 0 ? financeOptions.filter(x => userInfo.financeOptionFavourites.find(y => y.financeOptionId === x.id)) : [];

  const fundingOrganizations = _.groupBy(financeOptions, x => x.organizationId);

  return (
      <div className={classes.root}>
        <Dialog
            open={!fetchingProjects && !fetchingFinanceOptions && selectedOrganization && !selectedOrganization.hasFunding && !selectedOrganization.hasProjects}>
          <DialogTitle id="new-user">
            <FormattedMessage
                id="Home.WelcomeNewUser"
                defaultMessage='Velkommen som ny bruker'
            />
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <Typography variant={'body2'}>
                <FormattedMessage
                    id="Home.WelcomeIntro"
                    defaultMessage='For å kunne bruke denne portalen, trenger vi å vite hvorfor du er her. Om du er her for å finne finansiering til dine spillprosjekter, trykk på "Jeg har spillprosjekt(er)". Om du har finansiering og ønsker søknader fra selskaper med spillprosjekter, trykk på "Jeg har finansiering".'
                />
              </Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleAddProjectModule}
                    variant={'contained'}
                    color="primary">
              <FormattedMessage
                  id="Funding.HaveProjects"
                  defaultMessage='Jeg har spillprosjekt(er)'
              />
            </Button>
            <Button
                onClick={handleAddFundingModule}
                variant={'contained'}
                color="primary"
            >
              <FormattedMessage
                  id="Funding.HaveFunding"
                  defaultMessage='Jeg har finansiering'
              />
            </Button>
          </DialogActions>
        </Dialog>
        {
            selectedOrganization && (selectedOrganization.hasFunding || selectedOrganization.hasProjects) &&
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant={'h4'}>
                  <FormattedMessage
                      id="Home.HiUser"
                      defaultMessage='Hei, {name}'
                      values={{name: userInfo.name}}
                  />
                </Typography>
                <Typography variant={'body2'}>
                  <FormattedMessage
                      id="Home.PageDescription"
                      defaultMessage='Her finner du finansieringskilder som passer din bedrift og dine prosjekter'
                  />
                </Typography>
              </Grid>
              <Grid item xs={12} sm={7}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Card style={{ minHeight: '400px' }}>
                      <CardHeader title={'Finansiering til dine prosjekter'} />
                      <CardContent>
                        {
                            !hasProjects &&
                            <Grid container spacing={4}>
                              <Grid item xs={12}>
                                <Alert severity="info">
                                  <FormattedMessage
                                      id="Home.CreateFirstProject"
                                      defaultMessage="Opprett ditt første prosjekt for å se finansieringskilder som passer prosjekt og fasen prosjektet er i."
                                  />
                                </Alert>
                              </Grid>
                              <Grid item xs={12}>
                                <Button color={'primary'} variant={'contained'} fullWidth size={'large'} component={RouterLink} to={'/new-project'} >
                                  <FormattedMessage
                                      id="Global.CreateGameProject"
                                      defaultMessage='Opprett spillprosjekt'
                                  />
                                </Button>
                              </Grid>
                            </Grid>
                        }
                        {
                            hasProjects &&
                            <List>
                              {
                                yourProjects.map((project, index) => {
                                  return <ListItem key={project.id}
                                                   disablePadding
                                  >
                                    <ListItemButton component={Link} to={`/projects/${project.id}`}>
                                      <ListItemAvatar>
                                        <Avatar
                                            alt={project.name}
                                            src={project.coverImageUrl}
                                        />
                                      </ListItemAvatar>
                                      <ListItemText id={project.id}
                                                    primary={project.name}
                                                    secondary={
                                                      <FormattedMessage
                                                          id="Home.ProjectViewDetails"
                                                          defaultMessage="Se hvilke finansieringsmuligheter som eksisterer for dette prosjektet"
                                                      />
                                                    }
                                      />
                                    </ListItemButton>
                                  </ListItem>
                                })
                              }
                            </List>
                        }
                      </CardContent>
                      {
                          hasProjects &&
                          <CardActions>
                            <Button variant={'outlined'} component={Link} to={'/new-project'}>
                              <FormattedMessage
                                  id="Home.NewProject"
                                  defaultMessage='Nytt prosjekt'
                              />
                            </Button>
                            <Button variant={'outlined'} component={Link} to={'/projects'}>
                              <FormattedMessage
                                  id="Home.ToProjects"
                                  defaultMessage='Mine Prosjekter'
                              />
                            </Button>
                          </CardActions>
                      }
                    </Card>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Card>
                      <CardContent>
                        <Tabs value={value}
                              onChange={handleChange}
                              aria-label="basic tabs example"
                              variant="scrollable"
                              scrollButtons="auto"
                        >
                          <Tab label={<FormattedMessage
                              id="Home.FundingNew"
                              defaultMessage='Nye'
                          />} {...a11yProps(0)} />
                          {/*<Tab label={*/}
                          {/*  <FormattedMessage*/}
                          {/*      id="Home.FundingLatest"*/}
                          {/*      defaultMessage='Aktuelle'*/}
                          {/*  />*/}
                          {/*} {...a11yProps(1)} />*/}
                          <Tab label={
                            <FormattedMessage
                                id="Home.FundingFavourites"
                                defaultMessage='Favoritter'
                            />
                          } {...a11yProps(2)} />
                        </Tabs>
                        <TabPanel value={value} index={0}>
                          <List dense>
                            {
                              lastCreatedFinanceOptions.slice(0, 5).map((item, index) => {
                                return (
                                    <ListItem key={index} disablePadding>
                                      <ListItemButton component={Link} to={`/funding/${item.id}`}>
                                        <ListItemAvatar>
                                          <Avatar alt={item.organization.name} src={item.organization.logoUrl} />
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={item.name}
                                            secondary={item.organization.name}
                                        />
                                        <Typography variant={"caption"}>
                                          <FormattedMessage
                                              id="Home.FundingNewCreatedAt"
                                              defaultMessage='Lagt til: {date}'
                                              values={{
                                                date: moment(item.createdAt).format("LL")
                                              }}
                                          />
                                        </Typography>
                                      </ListItemButton>
                                    </ListItem>
                                )
                              })
                            }
                          </List>
                        </TabPanel>
                        {/*<TabPanel value={value} index={1}>*/}
                        {/*  <List dense>*/}
                        {/*    {*/}
                        {/*      lastUpdatedFinanceOptions.slice(0, 5).map((item, index) => {*/}
                        {/*        return (*/}
                        {/*            <ListItem key={index} disablePadding>*/}
                        {/*              <ListItemButton component={Link} to={`/funding/${item.id}`}>*/}
                        {/*                <ListItemAvatar>*/}
                        {/*                  <Avatar alt={item.organization.name} src={item.organization.logoUrl} />*/}
                        {/*                </ListItemAvatar>*/}
                        {/*                <ListItemText*/}
                        {/*                    primary={item.name}*/}
                        {/*                    secondary={item.organization.name}*/}
                        {/*                />*/}
                        {/*                <Typography variant={"caption"}>*/}
                        {/*                  <FormattedMessage*/}
                        {/*                      id="Home.FundingUpdatedCreatedAt"*/}
                        {/*                      defaultMessage='Oppdatert: {date}'*/}
                        {/*                      values={{*/}
                        {/*                        date: moment(item.createdAt).format("LL")*/}
                        {/*                      }}*/}
                        {/*                  />*/}
                        {/*                </Typography>*/}
                        {/*              </ListItemButton>*/}
                        {/*            </ListItem>*/}
                        {/*        )*/}
                        {/*      })*/}
                        {/*    }*/}
                        {/*  </List>*/}
                        {/*</TabPanel>*/}
                        <TabPanel value={value} index={1}>
                          {
                            favouritedFinanceOptions.slice(0, 5).map((item, index) => {
                              return (
                                  <ListItem key={index} disablePadding>
                                    <ListItemButton component={Link} to={`/funding/${item.id}`}>
                                      <ListItemAvatar>
                                        <Avatar alt={item.organization.name} src={item.organization.logoUrl} />
                                      </ListItemAvatar>
                                      <ListItemText
                                          primary={item.name}
                                          secondary={item.organization.name}
                                      />
                                      <ListItemSecondaryAction>
                                        <IconButton onClick={event => props.removeFavourite(item.id)}>
                                          <StarIcon color={"primary"} />
                                        </IconButton>
                                      </ListItemSecondaryAction>
                                    </ListItemButton>
                                  </ListItem>
                              )
                            })
                          }
                          {
                              favouritedFinanceOptions.length === 0 &&
                              <Alert severity="info">
                                <FormattedMessage
                                    id="Global.NoFavouritesFeedback"
                                    defaultMessage="Trykk på stjernen inne på en finansieringskilde for å legge til som favoritt. Da vil den dukke opp her for kjappere tilgang."
                                />
                              </Alert>
                          }
                        </TabPanel>
                      </CardContent>
                      <CardActions>
                      </CardActions>
                    </Card>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={5}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <Card>
                      <CardContent>
                        <Typography variant={'h3'} color={'primary'}>
                          {financeOptions.length}
                        </Typography>
                        <Typography variant={'h6'}>
                          <FormattedMessage
                              id="HomePage.AmountFundingOptions"
                              defaultMessage='Finansieringskilder'
                          />
                        </Typography>
                      </CardContent>
                      <CardActions>
                        <Button component={Link} to={'/funding'}>
                          <FormattedMessage
                              id="Home.FundingViewAll"
                              defaultMessage='Se alle'
                          />
                        </Button>
                      </CardActions>
                    </Card>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Card>
                      <CardContent>
                        <Typography variant={'h3'} color={'primary'}>
                          {Object.keys(fundingOrganizations).length}
                        </Typography>
                        <Typography variant={'h6'}>
                          <FormattedMessage
                              id="HomePage.AmountFundingOrgs"
                              defaultMessage='Finansører'
                          />
                        </Typography>
                      </CardContent>
                      <CardActions>
                        {/*<Button component={Link} to={'/funding'}>*/}
                        {/*  <FormattedMessage*/}
                        {/*      id="Home.FundingViewAll"*/}
                        {/*      defaultMessage='Se alle'*/}
                        {/*  />*/}
                        {/*</Button>*/}
                      </CardActions>
                    </Card>
                  </Grid>
                  <Grid item xs={12}>
                    <Card>
                      <CardHeader
                          title={
                            <FormattedMessage
                                id={'Home.LatestUpdates'}
                                defaultMessage={'Siste oppdateringer'}
                            />
                          }
                      />
                      <CardContent style={{maxHeight: '585px', overflowY: 'auto'}}>
                        {
                            props.news && props.news.map((news, index) => {
                              return <NewsListItem key={index} news={news} index={index} total={props.news.length} />
                            })
                        }
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
        }
      </div>
  )
}

const mapStateToProps = (state) => {
  return {
    userInfo: state.user.userInfo,
    yourOrganizations: state.organization.yourOrganizations,
    yourProjects: state.project.yourProjects,
    fetchingProjects: state.project.fetchingProjects,
    metadata: state.metadata.metadata,
    financeOptions: state.financeOption.financeOptions,
    fetchingFinanceOptions: state.financeOption.fetchingFinanceOptions,
    news: state.news.news
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getFinanceOptions: () => dispatch(getFinanceOptions()),
    getNews: () => dispatch(getNews()),
    patchOrganization: (organization, id) => dispatch(patchOrganization(organization, id)),
    removeFavourite: (id) => dispatch(removeFinanceOptionFavourite(id))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(HomePage)
