import React, { useEffect, useState } from 'react'

import Grid from '@mui/material/Grid'
import { makeStyles } from '@mui/styles'
import Typography from '@mui/material/Typography'
import { financeOptionOrgforms, financeOptionTypes, financeOptionSectors } from '../constants/financeOptionEnums'
import Card from '@mui/material/Card'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import CardHeader from '@mui/material/CardHeader'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { createFinanceOption, getFinanceOptions } from '../store/actions/financeOptionActions'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import Slider from '@mui/material/Slider'
import CardContent from '@mui/material/CardContent'
import CardActions from '@mui/material/CardActions'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import SearchIcon from '@mui/icons-material/Search'
import BusinessIcon from '@mui/icons-material/Business'
import MoneyIcon from '@mui/icons-material/AttachMoney'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/styles'
import StepConnector from '@mui/material/StepConnector'
import OutlinedInput from '@mui/material/OutlinedInput'
import InputAdornment from '@mui/material/InputAdornment'
import LinkIcon from '@mui/icons-material/Link'
import FormHelperText from '@mui/material/FormHelperText'
import {FormattedMessage} from 'react-intl'
import PersonIcon from '@mui/icons-material/Person'
import { useIntl } from 'react-intl'
import Autocomplete from '@mui/material/Autocomplete'
import {
  companyRoleEnum,
  gameBusinessModelEnum,
  gamePlayModeEnum,
  gamePurposeEnum,
  pegiRatingEnum,
  projectStageEnums
} from '../constants/projectEnums'
import Checkbox from '@mui/material/Checkbox'
import Chip from '@mui/material/Chip'
import FormControlLabel from '@mui/material/FormControlLabel'
import { uniq } from 'lodash'
import NumberFormat from 'react-number-format'
import DatePicker from '@mui/lab/DatePicker';
import IconButton from '@mui/material/IconButton'
import DeleteIcon from '@mui/icons-material/Delete'
import moment from 'moment'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
const checkedIcon = <CheckBoxIcon fontSize="small" />

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 17
  },
  active: {
    '& $line': {
      backgroundColor: '#FFF'
    }
  },
  completed: {
    '& $line': {
      backgroundColor: '#FFF'
    }
  },
  line: {
    height: 2,
    border: 0,
    backgroundColor: 'rgba(255, 255, 255, 0.3)',
    borderRadius: 3,
    cursor: 'default'
  }
})(StepConnector)

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: 'rgba(255, 255, 255, 0.3)',
    zIndex: 1,
    color: '#fff',
    width: 35,
    height: 35,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center'
  },
  active: {
    backgroundColor: '#FFF',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)'
  },
  completed: {
    backgroundColor: '#FFF'
  },
  icon: {
    width: 18,
    height: 18,
    color: '#c72946'
  }
})

function ColorlibStepIcon (props) {
  const classes = useColorlibStepIconStyles()
  const { active, completed } = props

  const icons = {
    1: <SearchIcon className={classes.icon}/>,
    2: <BusinessIcon className={classes.icon}/>,
    3: <MoneyIcon className={classes.icon}/>,
    4: <MoneyIcon className={classes.icon}/>
  }

  return (
      <div
          className={clsx(classes.root, {
            [classes.active]: active,
            [classes.completed]: completed
          })}
      >
        {icons[String(props.icon)]}
      </div>
  )
}

ColorlibStepIcon.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool,
  icon: PropTypes.node
}

const steps = [
  'Informasjon',
  'Fakta',
  'Kriterier',
  'Frister'
]

const useStyles = makeStyles(theme => ({
  root: {
    padding: '20px'
  },
  formControl: {
    width: '100%'
  },
  sliderContainer: {
    margin: '40px 25px 25px 25px',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
}))

const NewFundingPage = (props) => {
  const { yourProjects, yourOrganizations, userInfo, metadata, creatingFinanceOption } = props
  const classes = useStyles()

  const intl = useIntl()
  const currentDate = new Date()
  const [activeStep, setActiveStep] = useState(0)
  const [completedStep, setCompletedStep] = useState(0)
  const [maxFundingOfTotalProjectBudget, setMaxFundingOfTotalProjectBudget] = React.useState([0])
  const [companyMinMax, setCompanyMinMax] = React.useState([0, 100])
  const [newDeadlineModalOpen, setNewDeadlineModalOpen] = useState(false)
  const [newDeadlineDate, setNewDeadlineDate] = useState(new Date())

  const [dataCopy, setDataCopy] = useState({
    name: '',
    description: '',
    type: 0,
    sector: 0,
    orgForm: 0,
    companyAgeMin: 0,
    companyAgeMax: 999,
    organizationId: null,
    deadlines: []
  })

  useEffect(() => {
    setDataCopy({
      ...dataCopy,
      organizationId: userInfo.currentlyActiveOrganization
    })

    props.getYourFinanceOptions()
  }, [])

  const createFinanceOptionClicked = (event) => {
    props.createFinanceOption(dataCopy, userInfo.currentlyActiveOrganization)
  }

  const changeNewFinanceOption = (event) => {
    setDataCopy({
      ...dataCopy,
      [event.target.id]: event.target.value
    })
  }

  const deleteDeadline = (date) => {
    let deadlinesCopy = [...dataCopy.deadlines]
    deadlinesCopy = deadlinesCopy.filter(x => moment(x.deadline).isSame(date))

    setDataCopy({
      ...dataCopy,
      deadlines: deadlinesCopy
    })
  }

  const updateDeadline = (previousDate, newDate) => {
    const deadlinesCopy = [...dataCopy.deadlines]
    const existingDateIndex = deadlinesCopy.findIndex(x => moment(x.deadline).isSame(previousDate))
    if (existingDateIndex >= 0) { deadlinesCopy[existingDateIndex].deadline = newDate }

    setDataCopy({
      ...dataCopy,
      deadlines: deadlinesCopy
    })
  }

  const createDeadline = (date) => {
    const deadlinesCopy = [...dataCopy.deadlines]
    deadlinesCopy.push({ deadline: date })

    setDataCopy({
      ...dataCopy,
      deadlines: deadlinesCopy
    })
  }

  const handleSelectChange = (event, attribute) => {
    setDataCopy({
      ...dataCopy,
      [attribute]: event.target.value
    })
  }

  const handleInputChange = (event) => {
    setDataCopy({
      ...dataCopy,
      [event.target.id]: event.target.value
    })
  }

  const handleCompanyAgeSliderChange = (event, newValue) => {
    setDataCopy({
      ...dataCopy,
      companyAgeMin: Math.min(...newValue),
      companyAgeMax: Math.max(...newValue)
    })
  }

  function newDeadlineClicked (event) {
    setNewDeadlineModalOpen(true);
  }

  function addNewDeadlineClicked (event) {
    createDeadline(newDeadlineDate)
  }

  const handleChange = (attribute, newValue) => {
    setDataCopy({
      ...dataCopy,
      [attribute]: newValue[0]
    })
  }

  const handleNext = (event) => {
    if (activeStep === steps.length) { return }

    setActiveStep(activeStep + 1)
    if (activeStep >= completedStep) { setCompletedStep(activeStep) }
  }

  const handleBack = (event) => {
    if (activeStep === 0) { return }

    setActiveStep(activeStep - 1)
  }

  const handleRangeSliderChange = (minAttribute, maxAttribute, minValue, maxValue) => {
    setDataCopy({
      ...dataCopy,
      [minAttribute]: minValue,
      [maxAttribute]: maxValue
    })
  }

  const handleStepChange = (event, step) => {
    if (completedStep < step) { return }

    setActiveStep(step)
  }

  const handleCheckboxChange = name => event => {
    setDataCopy({
      ...dataCopy,
      [name]: event.target.checked
    })
  }

  const changeProjectNumberInfo = (values, attribute) => {
    setDataCopy({
      ...dataCopy,
      [attribute]: values.floatValue
    })
  }

  const handleAutoCompleteChange = (event, value, attribute) => {
    setDataCopy({
      ...dataCopy,
      [attribute]: value
    })
  }

  const onFlagValueChanged = (event, attribute, property, enumFlags) => {
    let roles = 0

    attribute.forEach((attr) => {
      roles = roles | enumFlags[attr]
    })

    setDataCopy({
      ...dataCopy,
      [property]: roles
    })
  }

  const flagToArray = (flags, enumFlags) => {
    const result = []

    Object.keys(enumFlags).forEach((flagKey) => {
      if (flags & enumFlags[flagKey]) { result.push(flagKey) }
    })

    return result
  }

  const gamePlayModes = flagToArray(dataCopy.gamePlayMode, gamePlayModeEnum)
  const gameBusinessModels = flagToArray(dataCopy.businessModel, gameBusinessModelEnum)
  const companyRoles = flagToArray(dataCopy.companyRole, companyRoleEnum)
  const gamePurposes = flagToArray(dataCopy.gamePurpose, gamePurposeEnum)
  const stages = flagToArray(dataCopy.stage, projectStageEnums)
  const pegiRatings = flagToArray(dataCopy.pegiRating, pegiRatingEnum)

  const deadlinesSortedAndFiltered = dataCopy.deadlines
      .sort((a, b) => moment(a.deadline) - moment(b.deadline))

  return (
      <div className={classes.root}>
        <Dialog open={newDeadlineModalOpen} onClose={event => setNewDeadlineModalOpen(false)}>
          <DialogTitle id="form-dialog-title">
            <FormattedMessage
                id={'FinanceOption.NewDeadlineModalTitle'}
                defaultMessage={'Legg til ny søknadsfrist'}
            />
          </DialogTitle>
          <DialogContent>
            <DatePicker
                openTo="year"
                views={['year', 'month', 'day']}
                value={newDeadlineDate}
                onChange={(date) => setNewDeadlineDate(date)}
                renderInput={(params) => <TextField fullWidth variant={"outlined"} {...params} helperText={'Åpen informasjon'} />}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={addNewDeadlineClicked}>
              <FormattedMessage
                  id={'FinanceOption.NewDeadlineModalAddButton'}
                  defaultMessage={'Legg til'}
              />
            </Button>
          </DialogActions>
        </Dialog>
        <Backdrop className={classes.backdrop} open={creatingFinanceOption}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <Grid container spacing={3} justifyContent={'center'}>
          <Grid item xs={12}>
            <Typography variant={'body2'}>
              {'Oppretter finansieringskilde'}
            </Typography>
            <Typography variant={'h4'}>
              {dataCopy.name.length > 0 ? dataCopy.name : '_'}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Stepper alternativeLabel
                     activeStep={activeStep}
                     connector={<ColorlibConnector/>}
            >
              {steps.map((label, index) => (
                  <Step key={label}
                        onClick={(event) => handleStepChange(event, index)}
                        disabled={index > completedStep}
                        style={{ cursor: 'pointer' }}
                  >
                    <StepLabel StepIconComponent={ColorlibStepIcon}>
                      {label}
                    </StepLabel>
                  </Step>
              ))}
            </Stepper>
          </Grid>
          <Grid item xs={12}>
            {
              activeStep === 0 &&
              <Grid container spacing={2} justifyContent={'center'}>
                <Grid item xs={12} sm={7}>
                  <Card>
                    <CardHeader
                        title={'Opprett finansieringskilde'}
                        subheader={'Gi den et navn som er synlig for alle'}
                    />
                    <CardContent>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <TextField
                              id="name"
                              name="name"
                              value={dataCopy.name}
                              onChange={handleInputChange}
                              type="text"
                              label={
                                <FormattedMessage
                                    id={'Funding.WhatIsName'}
                                    defaultMessage={'Hva heter din finansieringskilde?'}
                                />
                              }
                              fullWidth
                              variant={'outlined'}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <FormControl fullWidth variant="outlined">
                            <InputLabel id={'type'}>
                              <FormattedMessage
                                  id={'Finance.WhatFundingType'}
                                  defaultMessage={'Hvilken type finansiering er det?'}
                              />
                            </InputLabel>
                            <Select
                                labelId="type"
                                id="type"
                                label={
                                  <FormattedMessage
                                      id={'Finance.WhatFundingType'}
                                      defaultMessage={'Hvilken type finansiering er det?'}
                                  />
                                }
                                value={dataCopy.type}
                                onChange={(event) => handleSelectChange(event, 'type')}
                            >
                              {
                                financeOptionTypes.map((item, index) => (
                                    <MenuItem value={item.enum}
                                              key={item.enum}>
                                      {intl.formatMessage({ id: financeOptionTypes.find(x => x.enum === item.enum).name })}
                                    </MenuItem>
                                ))
                              }
                            </Select>
                            <FormHelperText style={{ marginLeft: '14px' }}>
                              <FormattedMessage
                                  id={'Global.OpenInformation'}
                                  defaultMessage={'Offentlig informasjon'}
                              />
                            </FormHelperText>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                          <FormControl fullWidth variant="outlined">
                            <InputLabel id={'sector'}>
                              <FormattedMessage
                                  id={'Global.WhatSector'}
                                  defaultMessage={'Hvilken sektor kommer finansieringen fra?'}
                              />
                            </InputLabel>
                            <Select
                                labelId="sector"
                                id="sector"
                                label={
                                  <FormattedMessage
                                      id={'Global.WhatSector'}
                                      defaultMessage={'Hvilken sektor kommer finansieringen fra?'}
                                  />
                                }
                                value={dataCopy.sector}
                                onChange={(event) => handleSelectChange(event, 'sector')}
                            >
                              {
                                financeOptionSectors.map((item, index) => (
                                    <MenuItem value={item.enum}
                                              key={item.enum}>
                                      {intl.formatMessage({ id: financeOptionSectors.find(x => x.enum === item.enum).name })}
                                    </MenuItem>
                                ))
                              }
                            </Select>
                            <FormHelperText style={{ marginLeft: '14px' }}>
                              <FormattedMessage
                                  id={'Global.OpenInformation'}
                                  defaultMessage={'Offentlig informasjon'}
                              />
                            </FormHelperText>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                          <FormControl fullWidth variant="outlined">
                            <InputLabel id={'orgForm'}>
                              <FormattedMessage
                                  id={'Global.WhatPurpose'}
                                  defaultMessage={'Hva gjelder finansieringen for?'}
                              />
                            </InputLabel>
                            <Select
                                labelId="orgForm"
                                label={
                                  <FormattedMessage
                                      id={'Global.WhatPurpose'}
                                      defaultMessage={'Hva gjelder finansieringen for?'}
                                  />
                                }
                                id="orgForm"
                                value={dataCopy.orgForm}
                                onChange={(event) => handleSelectChange(event, 'orgForm')}
                            >
                              {
                                financeOptionOrgforms.map((item, index) => (
                                    <MenuItem value={item.enum}
                                              key={item.enum}>
                                      {intl.formatMessage({ id: financeOptionOrgforms.find(x => x.enum === item.enum).name })}
                                    </MenuItem>
                                ))
                              }
                            </Select>
                            <FormHelperText style={{ marginLeft: '14px' }}>
                              <FormattedMessage
                                  id={'Global.OpenInformation'}
                                  defaultMessage={'Offentlig informasjon'}
                              />
                            </FormHelperText>
                          </FormControl>
                        </Grid>
                      </Grid>
                    </CardContent>
                    <CardActions style={{ display: 'flex' }}>
                      <Button variant={'contained'}
                              fullWidth
                              disabled={dataCopy.name.length === 0}
                              onClick={handleNext}
                      >
                        <FormattedMessage
                            id={'Global.NextStep'}
                            defaultMessage={'Neste steg'}
                        />
                      </Button>
                    </CardActions>
                  </Card>
                </Grid>
              </Grid>
            }
            {
              activeStep === 1 &&
              <Grid container spacing={2} justifyContent={'center'}>
                <Grid item xs={12} sm={7}>
                  <Card>
                    <CardHeader
                        title={'Om finansieringskilde'}
                    />
                    <CardContent>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <TextField
                              id="description"
                              name="description"
                              value={dataCopy.description}
                              onChange={handleInputChange}
                              type="text"
                              label={
                                <FormattedMessage
                                    id={'Finance.ProvideDescription'}
                                    defaultMessage={'Vennligst kopier inn eller skriv en kjapp beskrivelse'}
                                />
                              }
                              fullWidth
                              variant={'outlined'}
                              multiline
                              rows={5}
                              helperText={
                                <FormattedMessage
                                    id={'Global.OpenInformation'}
                                    defaultMessage={'Offentlig informasjon'}
                                />
                              }
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <FormControl fullWidth variant="outlined">
                            <InputLabel htmlFor="descriptionReadMoreUrl">
                              <FormattedMessage
                                  id={'Global.ReadMoreUrlInput'}
                                  defaultMessage={'Har du en lenke hvor man kan lese mer?'}
                              />
                            </InputLabel>
                            <OutlinedInput
                                id="descriptionReadMoreUrl"
                                type="text"
                                label={
                                  <FormattedMessage
                                      id={'Global.ReadMoreUrlInput'}
                                      defaultMessage={'Har du en lenke hvor man kan lese mer?'}
                                  />
                                }
                                onChange={handleInputChange}
                                value={dataCopy.descriptionReadMoreUrl}
                                startAdornment={<InputAdornment
                                    position="start"><LinkIcon/></InputAdornment>}
                            />
                            <FormHelperText>
                              <FormattedMessage
                                  id={'Global.OpenInformation'}
                                  defaultMessage={'Offentlig informasjon'}
                              />
                            </FormHelperText>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                          <FormControl fullWidth variant="outlined">
                            <InputLabel htmlFor="website">
                              <FormattedMessage
                                  id={'Global.ContactPersonInput'}
                                  defaultMessage={'Hvem er kontaktperson?'}
                              />
                            </InputLabel>
                            <OutlinedInput
                                id="contactName"
                                type="text"
                                label={
                                  <FormattedMessage
                                      id={'Global.ContactPersonInput'}
                                      defaultMessage={'Hvem er kontaktperson?'}
                                  />
                                }
                                onChange={handleInputChange}
                                value={dataCopy.contactName}
                                startAdornment={<InputAdornment
                                    position="start"><PersonIcon/></InputAdornment>}
                            />
                            <FormHelperText style={{ marginLeft: '14px' }}>
                              <FormattedMessage
                                  id={'Global.OpenInformation'}
                                  defaultMessage={'Offentlig informasjon'}
                              />
                            </FormHelperText>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                          <FormControl fullWidth variant="outlined">
                            <InputLabel htmlFor="website">
                              <FormattedMessage
                                  id={'Global.ApplicationUrlInputt'}
                                  defaultMessage={'Hva er lenken til siden man søker gjennom?'}
                              />
                            </InputLabel>
                            <OutlinedInput
                                id="website"
                                type="text"
                                label={
                                  <FormattedMessage
                                      id={'Global.ApplicationUrlInputt'}
                                      defaultMessage={'Hva er lenken til siden man søker gjennom?'}
                                  />
                                }
                                onChange={handleInputChange}
                                value={dataCopy.website}
                                startAdornment={<InputAdornment
                                    position="start"><LinkIcon/></InputAdornment>}
                            />
                            <FormHelperText style={{ marginLeft: '14px' }}>
                              <FormattedMessage
                                  id={'Global.OpenInformation'}
                                  defaultMessage={'Offentlig informasjon'}
                              />
                            </FormHelperText>
                          </FormControl>
                        </Grid>
                      </Grid>
                    </CardContent>
                    <CardActions style={{ display: 'flex' }}>
                      <Button variant={'outlined'}
                              fullWidth
                              onClick={handleBack}
                      >
                        <FormattedMessage
                            id={'Global.PreviousStep'}
                            defaultMessage={'Tilbake'}
                        />
                      </Button>
                      <Button variant={'contained'}
                              fullWidth
                              onClick={handleNext}
                      >
                        <FormattedMessage
                            id={'Global.NextStep'}
                            defaultMessage={'Neste steg'}
                        />
                      </Button>
                    </CardActions>
                  </Card>
                </Grid>
              </Grid>
            }
            {
              activeStep === 2 &&
              <Grid container spacing={2} justifyContent={'center'}>
                <Grid item xs={7}>
                  <Card>
                    <CardHeader
                        title={'Prosjekt'}
                        subheader={'Dette brukes til å filtrere på prosjektet'}
                    />
                    <CardContent>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Autocomplete
                              multiple
                              autoComplete={false}
                              id="gamePurpose"
                              options={Object.keys(gamePurposeEnum).map((key, index) => key)}
                              disableCloseOnSelect
                              value={gamePurposes}
                              onChange={(event, attributes) => onFlagValueChanged(event, attributes, 'gamePurpose', gamePurposeEnum)}
                              getOptionLabel={option => {
                                return intl.formatMessage({ id: option })
                              }}
                              renderOption={(props, option, { selected }) => (
                                  <li {...props}>
                                    <Checkbox
                                        icon={icon}
                                        checkedIcon={checkedIcon}
                                        style={{ marginRight: 8 }}
                                        checked={selected}
                                    />
                                    {intl.formatMessage({ id: option })}
                                  </li>
                              )}
                              renderInput={params => (
                                  <TextField
                                      {...params}
                                      fullWidth
                                      label="Prosjektet må ha en av disse formålene"
                                      variant="outlined"
                                  />
                              )}
                              renderTags={(value, getTagProps) =>
                                  value.map((option, index) => (
                                      <Chip key={index} label={intl.formatMessage({ id: option })}/>
                                  ))
                              }
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Autocomplete
                              multiple
                              autoComplete={false}
                              id="pegiRating"
                              options={Object.keys(pegiRatingEnum).map((key, index) => key)}
                              disableCloseOnSelect
                              value={pegiRatings}
                              onChange={(event, attributes) => onFlagValueChanged(event, attributes, 'pegiRating', pegiRatingEnum)}
                              getOptionLabel={option => {
                                return intl.formatMessage({ id: option })
                              }}
                              renderOption={(props, option, { selected }) => (
                                  <li {...props}>
                                    <Checkbox
                                        icon={icon}
                                        checkedIcon={checkedIcon}
                                        style={{ marginRight: 8 }}
                                        checked={selected}
                                    />
                                    {intl.formatMessage({ id: option })}
                                  </li>
                              )}
                              renderInput={params => (
                                  <TextField
                                      {...params}
                                      fullWidth
                                      label="Aksepterte PEGI-merkinger"
                                      variant="outlined"
                                  />
                              )}
                              renderTags={(value, getTagProps) =>
                                  value.map((option, index) => (
                                      <Chip key={index} label={intl.formatMessage({ id: option })}/>
                                  ))
                              }
                          />
                        </Grid>
                        {/* <Grid item xs={12}> */}
                        {/*    <Autocomplete */}
                        {/*        multiple */}
                        {/*        id="platforms" */}
                        {/*        options={platforms} */}
                        {/*        disableCloseOnSelect */}
                        {/*        onChange={handlePlatformChange} */}
                        {/*        value={dataCopy.platforms} */}
                        {/*        renderOption={(option, {selected}) => ( */}
                        {/*            <React.Fragment> */}
                        {/*                <Checkbox */}
                        {/*                    icon={icon} */}
                        {/*                    checkedIcon={checkedIcon} */}
                        {/*                    style={{marginRight: 8}} */}
                        {/*                    checked={selected} */}
                        {/*                /> */}
                        {/*                {option.name} */}
                        {/*            </React.Fragment> */}
                        {/*        )} */}
                        {/*        renderInput={params => ( */}
                        {/*            <TextField */}
                        {/*                {...params} */}
                        {/*                fullWidth */}
                        {/*                variant="outlined" */}
                        {/*                label={"Spillet må komme ut på en av disse plattformene"} */}
                        {/*            /> */}
                        {/*        )} */}
                        {/*        renderTags={(value, getTagProps) => */}
                        {/*            value.map((option, index) => ( */}
                        {/*                <Chip key={index} label={option.name} /> */}
                        {/*            )) */}
                        {/*        } */}
                        {/*    /> */}
                        {/* </Grid> */}
                        <Grid item xs={12} sm={8}>
                          <Autocomplete
                              multiple
                              id="gamePlayMode"
                              options={Object.keys(gamePlayModeEnum).map((key, index) => key)}
                              disableCloseOnSelect
                              value={gamePlayModes}
                              onChange={(event, attributes) => onFlagValueChanged(event, attributes, 'gamePlayMode', gamePlayModeEnum)}
                              getOptionLabel={option => {
                                return intl.formatMessage({ id: option })
                              }}
                              renderOption={(props, option, { selected }) => (
                                  <li {...props}>
                                    <Checkbox
                                        icon={icon}
                                        checkedIcon={checkedIcon}
                                        style={{ marginRight: 8 }}
                                        checked={selected}
                                    />
                                    {intl.formatMessage({ id: option })}
                                  </li>
                              )}
                              renderInput={params => (
                                  <TextField
                                      {...params}
                                      fullWidth
                                      label="Aksepterte spillermoduser"
                                      variant="outlined"
                                  />
                              )}
                              renderTags={(value, getTagProps) =>
                                  value.map((option, index) => (
                                      <Chip key={index} label={intl.formatMessage({ id: option })}/>
                                  ))
                              }
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <FormControlLabel
                              control={
                                <Checkbox
                                    checked={dataCopy.hasToPassCultureTest ?? false}
                                    onChange={handleCheckboxChange('hasToPassCultureTest')}
                                    value={'hasToPassCultureTest'}
                                    color="primary"
                                />
                              }
                              label="Må bestå kulturtesten"
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <FormControlLabel
                              control={
                                <Checkbox
                                    checked={dataCopy.hasRightsToIp ?? false}
                                    onChange={handleCheckboxChange('hasRightsToIp')}
                                    value={'hasRightsToIp'}
                                    color="primary"
                                />
                              }
                              label="Selskapet innehar rettighetene til spillets IP"
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <FormControlLabel
                              control={
                                <Checkbox
                                    checked={dataCopy.isNarrative ?? false}
                                    onChange={handleCheckboxChange('isNarrative')}
                                    value={'isNarrative'}
                                    color="primary"
                                />
                              }
                              label="Prosjektet må ha et sentralt narrativ"
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Autocomplete
                              multiple
                              id="availableInLanguages"
                              options={metadata.languages.map(x => x.name)}
                              // filterSelectedOptions
                              disableCloseOnSelect
                              onChange={(event, value) => handleAutoCompleteChange(event, value, 'availableInLanguages')}
                              getOptionLabel={option => option}
                              value={dataCopy.availableInLanguages}
                              renderTags={(value, getTagProps) =>
                                  value.map((option, index) => (
                                      <Chip
                                          key={index} label={option} {...getTagProps({ index })} />
                                  ))
                              }
                              renderOption={(props, option, { selected }) => (
                                  <li {...props}>
                                    <Checkbox
                                        icon={icon}
                                        checkedIcon={checkedIcon}
                                        style={{ marginRight: 8 }}
                                        checked={selected}
                                    />
                                    {intl.formatMessage({ id: option })}
                                  </li>
                              )}
                              style={{ width: '100%' }}
                              renderInput={params => (
                                  <TextField
                                      {...params}
                                      variant="outlined"
                                      label={'Spillet må være tilgjengelig på disse språkene'}
                                      fullWidth
                                  />
                              )}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Autocomplete
                              multiple
                              id="originalMusicComposerNationality"
                              options={metadata.languages.map(x => x.name)}
                              // filterSelectedOptions
                              disableCloseOnSelect
                              onChange={(event, value) => handleAutoCompleteChange(event, value, 'originalMusicComposerNationality')}
                              getOptionLabel={option => option}
                              value={dataCopy.originalMusicComposerNationality}
                              renderTags={(value, getTagProps) =>
                                  value.map((option, index) => (
                                      <Chip
                                          key={index} label={option} {...getTagProps({ index })} />
                                  ))
                              }
                              renderOption={(props, option, { selected }) => (
                                  <li {...props}>
                                    <Checkbox
                                        icon={icon}
                                        checkedIcon={checkedIcon}
                                        style={{ marginRight: 8 }}
                                        checked={selected}
                                    />
                                    {intl.formatMessage({ id: option })}
                                  </li>
                              )}
                              style={{ width: '100%' }}
                              renderInput={params => (
                                  <TextField
                                      {...params}
                                      variant="outlined"
                                      label={'Spillets komponist og originalmusikk må ha nasjonalitet fra'}
                                      fullWidth
                                  />
                              )}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Autocomplete
                              multiple
                              id="mainProducerCounties"
                              options={uniq(metadata.countiesMap.map(x => x.county))}
                              // filterSelectedOptions
                              disableCloseOnSelect
                              onChange={(event, value) => handleAutoCompleteChange(event, value, 'mainProducerCounties')}
                              getOptionLabel={option => option}
                              value={dataCopy.mainProducerCounties}
                              renderTags={(value, getTagProps) =>
                                  value.map((option, index) => (
                                      <Chip
                                          key={index} label={option} {...getTagProps({ index })} />
                                  ))
                              }
                              renderOption={(props, option, { selected }) => (
                                  <li {...props}>
                                    <Checkbox
                                        icon={icon}
                                        checkedIcon={checkedIcon}
                                        style={{ marginRight: 8 }}
                                        checked={selected}
                                    />
                                    {option}
                                  </li>
                              )}
                              style={{ width: '100%' }}
                              renderInput={params => (
                                  <TextField
                                      {...params}
                                      variant="outlined"
                                      label={'Hovedprodusenten må ha bostedsadresse i en av disse fylkene'}
                                      fullWidth
                                  />
                              )}
                          />
                        </Grid>

                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={7}>
                  <Card>
                    <CardHeader
                        title={'Prosjekt: Økonomi & forretningsmodell'}
                        subheader={'Dette brukes til å filtrere på prosjektet'}
                    />
                    <CardContent>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Autocomplete
                              multiple
                              id="stage"
                              options={Object.keys(projectStageEnums).map((key, index) => key)}
                              disableCloseOnSelect
                              value={stages}
                              onChange={(event, attributes) => onFlagValueChanged(event, attributes, 'stage', projectStageEnums)}
                              getOptionLabel={option => {
                                return intl.formatMessage({ id: option })
                              }}
                              renderOption={(props, option, { selected }) => (
                                  <li {...props}>
                                    <Checkbox
                                        icon={icon}
                                        checkedIcon={checkedIcon}
                                        style={{ marginRight: 8 }}
                                        checked={selected}
                                    />
                                    {intl.formatMessage({ id: option })}
                                  </li>
                              )}
                              renderInput={params => (
                                  <TextField
                                      {...params}
                                      fullWidth
                                      label="Prosjektfaser det kan gis penger til"
                                      variant="outlined"
                                  />
                              )}
                              renderTags={(options, getTagProps) => (
                                  options.map((option, index) => (
                                      <Chip key={index} label={intl.formatMessage({ id: option })}/>
                                  ))
                              )}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Autocomplete
                              multiple
                              id="businessModel"
                              options={Object.keys(gameBusinessModelEnum).map((key, index) => key)}
                              disableCloseOnSelect
                              value={gameBusinessModels}
                              onChange={(event, attributes) => onFlagValueChanged(event, attributes, 'businessModel', gameBusinessModelEnum)}
                              getOptionLabel={option => {
                                return intl.formatMessage({ id: option })
                              }}
                              renderOption={(props, option, { selected }) => (
                                  <li {...props}>
                                    <Checkbox
                                        icon={icon}
                                        checkedIcon={checkedIcon}
                                        style={{ marginRight: 8 }}
                                        checked={selected}
                                    />
                                    {intl.formatMessage({ id: option })}
                                  </li>
                              )}
                              renderInput={params => (
                                  <TextField
                                      {...params}
                                      fullWidth
                                      label="Aksepterte finansieringsmodeller"
                                      variant="outlined"
                                  />
                              )}
                              renderTags={(options, getTagProps) => (
                                  options.map((option, index) => (
                                      <Chip key={index} label={intl.formatMessage({ id: option })}/>
                                  ))
                              )}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <NumberFormat value={dataCopy.projectBudgetMin}
                                        displayType={'input'}
                                        variant={'outlined'}
                                        fullWidth
                                        thousandSeparator={' '}
                                        prefix={'NOK '}
                                        id='projectBudgetMin'
                                        label={'Minimum posjektbudsjett'}
                                        onValueChange={(values) => changeProjectNumberInfo(values, 'projectBudgetMin')}
                                        customInput={TextField}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <NumberFormat value={dataCopy.projectBudgetMax}
                                        displayType={'input'}
                                        variant={'outlined'}
                                        fullWidth
                                        thousandSeparator={' '}
                                        prefix={'NOK '}
                                        id='projectBudgetMax'
                                        label={'Maksimum posjektbudsjett'}
                                        onValueChange={(values) => changeProjectNumberInfo(values, 'projectBudgetMax')}
                                        customInput={TextField}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Typography gutterBottom>
                            Hvor mye kan dette tilskuddet støtte maksimalt av totalbudsjett?
                          </Typography>
                          <div className={classes.sliderContainer}>
                            <Slider
                                min={0}
                                step={5}
                                marks={[
                                  {
                                    value: 0,
                                    label: '0%'
                                  },
                                  {
                                    value: 25,
                                    label: '25%'
                                  },
                                  {
                                    value: 50,
                                    label: '50%'
                                  },
                                  {
                                    value: 75,
                                    label: '75%'
                                  },
                                  {
                                    value: 100,
                                    label: '100%'
                                  }
                                ]}
                                max={100}
                                value={maxFundingOfTotalProjectBudget}
                                onChange={(event, value) => setMaxFundingOfTotalProjectBudget(value)}
                                onChangeCommitted={(event, value) => handleChange('maximumFundingOfTotalProjectBudget', value)}
                                valueLabelDisplay="on"
                                getAriaValueText={(value) => `${value}`}
                            />
                          </div>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={7}>
                  <Card>
                    <CardHeader
                        title={'Selskap'}
                        subheader={'Dette brukes til å filtrere på selskapsdata'}
                    />
                    <CardContent>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Autocomplete
                              multiple
                              autoComplete={false}
                              id="companyRole"
                              options={Object.keys(companyRoleEnum).map((key, index) => key)}
                              disableCloseOnSelect
                              value={companyRoles}
                              onChange={(event, attributes) => onFlagValueChanged(event, attributes, 'companyRole', companyRoleEnum)}
                              getOptionLabel={option => {
                                return intl.formatMessage({ id: option })
                              }}
                              renderOption={(props, option, { selected }) => (
                                  <li {...props}>
                                    <Checkbox
                                        icon={icon}
                                        checkedIcon={checkedIcon}
                                        style={{ marginRight: 8 }}
                                        checked={selected}
                                    />
                                    {intl.formatMessage({ id: option })}
                                  </li>
                              )}
                              renderInput={params => (
                                  <TextField
                                      {...params}
                                      fullWidth
                                      label="Rolle(r) søkers selskap må inneha i Prosjektet"
                                      variant="outlined"
                                  />
                              )}
                              renderTags={(options, getTagProps) => (
                                  options.map((option, index) => (
                                      <Chip key={index} label={intl.formatMessage({ id: option })}/>
                                  ))
                              )}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Autocomplete
                              multiple
                              id="counties"
                              options={uniq(metadata.countiesMap.map(x => x.county))}
                              // filterSelectedOptions
                              disableCloseOnSelect
                              onChange={(event, value) => handleAutoCompleteChange(event, value, 'counties')}
                              getOptionLabel={option => option}
                              value={dataCopy.counties}
                              renderTags={(value, getTagProps) =>
                                  value.map((option, index) => (
                                      <Chip
                                          key={index} label={option} {...getTagProps({ index })} />
                                  ))
                              }
                              renderOption={(props, option, { selected }) => (
                                  <li {...props}>
                                    <Checkbox
                                        icon={icon}
                                        checkedIcon={checkedIcon}
                                        style={{ marginRight: 8 }}
                                        checked={selected}
                                    />
                                    {intl.formatMessage({ id: option })}
                                  </li>
                              )}
                              style={{ width: '100%' }}
                              renderInput={params => (
                                  <TextField
                                      {...params}
                                      variant="outlined"
                                      label={'Selskapet må ha hovedkontor i en av disse fylkene'}
                                      fullWidth
                                      helperText={'Åpen informasjon'}
                                  />
                              )}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Typography id="range-slider" gutterBottom>
                            Optimal selskapsalder
                          </Typography>
                          <div className={classes.sliderContainer}>
                            <Slider
                                min={0}
                                max={100}
                                value={companyMinMax}
                                onChange={(event, values) => setCompanyMinMax(values)}
                                onChangeCommitted={(event, values) => handleRangeSliderChange('companyAgeMin', 'companyAgeMax', Math.min(...values), Math.max(...values))}
                                valueLabelDisplay="on"
                                aria-labelledby="range-slider"
                                getAriaValueText={(value) => `${value}`}
                            />
                          </div>
                        </Grid>
                      </Grid>
                    </CardContent>
                    <CardActions style={{ display: 'flex' }}>
                      <Button variant={'outlined'}
                              fullWidth
                              onClick={handleBack}
                      >
                        <FormattedMessage
                            id={'Global.PreviousStep'}
                            defaultMessage={'Tilbake'}
                        />
                      </Button>
                      <Button variant={'contained'}
                              fullWidth
                              onClick={handleNext}
                      >
                        <FormattedMessage
                            id={'Global.NextStep'}
                            defaultMessage={'Neste steg'}
                        />
                      </Button>
                    </CardActions>
                  </Card>
                </Grid>
              </Grid>
            }
            {
              activeStep === 3 &&
              <Grid container spacing={2} justifyContent={'center'}>
                <Grid item xs={7}>
                  <Card>
                    <CardHeader
                        title={'Søknadsfrister'}
                        subheader={'Fyll inn søknadsfrister for tilskuddet her'}
                    />
                    <CardContent>
                      <Grid container spacing={3}>
                        {
                          deadlinesSortedAndFiltered.map((deadline, index) => (
                              <Grid item xs={12} key={index}>
                                <Grid container alignItems="flex-start">
                                  <Grid item xs={10}>
                                    <DatePicker
                                        openTo="year"
                                        format="DD/MM/YYYY"
                                        label="Søknadsfrist"
                                        id="deadline"
                                        views={['year', 'month', 'day']}
                                        value={deadline.deadline}
                                        onChange={(date) => updateDeadline(deadline.deadline, date)}
                                        renderInput={(params) => <TextField fullWidth variant={"outlined"} {...params} helperText={'Åpen informasjon'} />}
                                    />
                                  </Grid>
                                  <Grid item xs={2}
                                        style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <IconButton
                                        onClick={(event) => deleteDeadline(deadline.deadline)}
                                    >
                                      <DeleteIcon/>
                                    </IconButton>
                                  </Grid>
                                </Grid>
                              </Grid>
                          ))
                        }
                        <Grid item xs={12}>
                          <Button onClick={newDeadlineClicked} fullWidth>
                            <FormattedMessage
                                id={'FinanceOption.AddNewDeadline'}
                                defaultMessage={'Ny søknadsfrist'}
                            />
                          </Button>
                        </Grid>
                      </Grid>
                    </CardContent>
                    <CardActions style={{ display: 'flex' }}>
                      <Button variant={'outlined'}
                              fullWidth
                              disabled={creatingFinanceOption}
                              onClick={handleBack}
                      >
                        <FormattedMessage
                            id={'Global.PreviousStep'}
                            defaultMessage={'Tilbake'}
                        />
                      </Button>
                      <Button variant={'contained'}
                              fullWidth
                              disabled={creatingFinanceOption}
                              onClick={createFinanceOptionClicked}
                      >
                        <FormattedMessage
                            id={'Finance.CreateFinanceOption'}
                            defaultMessage={'Opprett finansieringskilde'}
                        />
                      </Button>
                    </CardActions>
                  </Card>
                </Grid>
              </Grid>
            }
          </Grid>
        </Grid>
      </div>
  )
}

const mapStateToProps = (state) => {
  return {
    error: state.financeOption.error,
    financeOptions: state.financeOption.financeOptions,
    creatingFinanceOption: state.financeOption.creatingFinanceOption,
    yourOrganizations: state.organization.yourOrganizations,
    userInfo: state.user.userInfo,
    metadata: state.metadata.metadata
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getYourFinanceOptions: () => dispatch(getFinanceOptions()),
    createFinanceOption: (newFinanceOption, organizationId) => dispatch(createFinanceOption(newFinanceOption, organizationId))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(NewFundingPage))
