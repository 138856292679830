import axios from 'axios'
import { API_ROOT, configWithAuth } from '../api'
import { signOut } from './authActions'

export const getUser = () => {
  return (dispatch) => {
    axios.get(`${API_ROOT}user`, configWithAuth())
      .then((result) => {
        dispatch({ type: 'GET_USER_SUCCESS', data: result.data })
      })
      .catch((error) => {
        if (error.response?.status === 401) {
          signOut()
        }
        dispatch({ type: 'GET_USER_ERROR', error })
        dispatch({ type: 'SET_SNACKBAR', data: { variant: 'error', message: 'Fikk ikke hentet brukeren din.' } })
      })
  }
}

export const patchUser = (userPatch) => {
  return (dispatch) => {
    dispatch({ type: 'PATCH_USER.REQUEST' })

    axios.patch(`${API_ROOT}user`, JSON.stringify(userPatch), configWithAuth('application/json-patch+json'))
      .then((result) => {
        dispatch({ type: 'PATCH_USER.SUCCESS', data: result.data })
        dispatch({ type: 'SET_SNACKBAR', data: { variant: 'success', message: 'Bruker er oppdatert' } })
      })
      .catch((error) => {
        dispatch({ type: 'PATCH_USER.ERROR', error })
        dispatch({ type: 'SET_SNACKBAR', data: { variant: 'error', message: 'Noe gikk feil, prøv igjen eller kontakt support' } })
      })
  }
}

export const uploadProfilePicture = (formData) => {
  return (dispatch) => {
    dispatch({ type: 'UPLOAD_PROFILEPICTURE.REQUEST' })

    axios.post(`${API_ROOT}user/profile-picture`, formData, configWithAuth('multipart/form-data'))
      .then((result) => {
        dispatch({ type: 'UPLOAD_PROFILEPICTURE.SUCCESS', data: result.data })
        dispatch({ type: 'SET_SNACKBAR', data: { variant: 'success', message: 'Profilbildet er oppdatert.' } })
      })
      .catch((error) => {
        dispatch({ type: 'UPLOAD_PROFILEPICTURE.ERROR', error })
        dispatch({ type: 'SET_SNACKBAR', data: { variant: 'error', message: 'Feil ved oppdatering av profilbildet.' } })
      })
  }
}

// Admin
export const adminGetAllUsers = () => {
  return (dispatch) => {
    dispatch({ type: 'USER.ADMIN_GET_USERS.REQUEST' })

    axios.get(`${API_ROOT}admin/user`, configWithAuth())
      .then((result) => {
        dispatch({ type: 'USER.ADMIN_GET_USERS.SUCCESS', data: result.data })
      })
      .catch((error) => {
        dispatch({ type: 'USER.ADMIN_GET_USERS.ERROR', error })
        dispatch({ type: 'SET_SNACKBAR', data: { variant: 'error', message: 'Feil ved henting av brukere.' } })
      })
  }
}
