import authReducer from './authReducer'
import projectReducer from './projectReducer'
import organizationReducer from './organizationReducer'
import userReducer from './userReducer'
import financeOptionReducer from './financeOptionReducer'
import metadataReducer from './metadataReducer'
import newsReducer from './newsReducer'
import snackbarReducer from './snackbarReducer'
import { combineReducers } from 'redux'

const createRootReducer = (history) => combineReducers({
  auth: authReducer,
  project: projectReducer,
  organization: organizationReducer,
  user: userReducer,
  financeOption: financeOptionReducer,
  metadata: metadataReducer,
  snackbar: snackbarReducer,
  news: newsReducer
})

export default createRootReducer
