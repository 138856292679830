import CheckIcon from '@mui/icons-material/Check'
import ClearIcon from '@mui/icons-material/Clear'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import React from 'react'

const CriteriaStatus = ({ name, didPass, messageIfPass, messageIfFail }) => {
  return (
        <div style={{
          display: 'flex',
          alignItems: 'center',
          margin: '10px 0px',
          textDecoration: 'underline'
        }}>
            {
                didPass
                  ? <CheckIcon
                        style={{ marginRight: '10px' }}/>
                  : <ClearIcon
                        style={{ marginRight: '10px' }}/>
            }
            <Tooltip disableFocusListener disableTouchListener
                     placement="top"
                     title={didPass ? messageIfPass : messageIfFail}
            >
                <Typography variant={'body1'}>

                    {name}
                </Typography>
            </Tooltip>
        </div>
  )
}

export default CriteriaStatus
