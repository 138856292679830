export const API_ROOT = getUrl()

function getUrl () {
  return "https://api.techfinansiering.no/api/";

  if (window.location.hostname.toLowerCase().includes('localhost')) {
    return 'http://localhost:5005/api/'
  } else {
    return 'https://api.techfinansiering.no/api/'
  }
}

export function configWithAuth (contentType = 'application/json') {
  const authToken = window.localStorage.getItem('authBody')

  const config = {
    headers: {
      'Content-Type': contentType,
      Authorization: 'Bearer ' + authToken
    }
  }

  return config
}

export function configWithoutAuth () {
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  }

  return config
}
