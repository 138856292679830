import axios from 'axios'
import { API_ROOT, configWithAuth } from '../api'
import { history } from '../history'

export const getYourOrganizations = () => {
  return (dispatch) => {
    dispatch({ type: 'GET_YOUR_COMPANIES.REQUEST' })

    axios.get(`${API_ROOT}organization`, configWithAuth())
      .then((result) => {
        dispatch({ type: 'GET_YOUR_COMPANIES.SUCCESS', data: result.data })
      })
      .catch((error) => {
        dispatch({ type: 'GET_YOUR_COMPANIES.ERROR', error })
        dispatch({ type: 'SET_SNACKBAR', data: { variant: 'error', message: 'Noe gikk feil, prøv igjen eller kontakt support' } })
      })
  }
}

export const createOrganization = (organization, creatorId) => {
  return (dispatch) => {

  }
}

export const changeOrganization = (organizationId) => {
  return (dispatch) => {
    dispatch({ type: 'CHANGE_ORGANIZATION_REQUEST', data: organizationId })

    axios.post(`${API_ROOT}organization/set-active/${organizationId}`, null, configWithAuth())
      .then((result) => {
        dispatch({ type: 'CHANGE_ORGANIZATION_SUCCESS', data: result.data })
        dispatch({ type: 'SET_SNACKBAR', data: { variant: 'info', message: 'Skiftet organisasjon' } })
        history.push('/')
      })
      .catch((error) => {
        dispatch({ type: 'CHANGE_ORGANIZATION_ERROR', error })
        dispatch({ type: 'SET_SNACKBAR', data: { variant: 'error', message: 'Noe gikk feil, prøv igjen eller kontakt support' } })
      })
  }
}

export const getOrganizationUsers = (organizationId) => {
  return (dispatch) => {
    dispatch({ type: 'GET_ORGANIZATION_USERS_REQUEST', data: organizationId })

    axios.get(`${API_ROOT}organization/${organizationId}/users`, configWithAuth())
      .then((result) => {
        dispatch({ type: 'GET_ORGANIZATION_USERS_SUCCESS', data: result.data })
      })
      .catch((error) => {
        dispatch({ type: 'GET_ORGANIZATION_USERS_ERROR', error })
        dispatch({ type: 'SET_SNACKBAR', data: { variant: 'error', message: 'Noe gikk feil, prøv igjen eller kontakt support' } })
      })
  }
}

export const organizationUsersAccessResponse = (organizationId, userId, approve) => {
  return (dispatch) => {
    dispatch({ type: 'ORGANIZATION_USER_ACCESS_RESPONSE.REQUEST', data: { id: userId } })

    const json = JSON.stringify({
      userId: userId,
      approve: approve
    })

    axios.post(`${API_ROOT}organization/${organizationId}/access-response`, json, configWithAuth())
      .then((result) => {
        dispatch({ type: 'ORGANIZATION_USER_ACCESS_RESPONSE.SUCCESS', data: result.data })
        dispatch({ type: 'SET_SNACKBAR', data: { variant: 'success', message: approve ? 'Brukertilgang godkjent' : 'Brukertilgang fjernet' } })
      })
      .catch((error) => {
        dispatch({ type: 'ORGANIZATION_USER_ACCESS_RESPONSE.ERROR', error, data: { id: userId } })
        dispatch({ type: 'SET_SNACKBAR', data: { variant: 'error', message: 'Noe gikk feil, prøv igjen eller kontakt support' } })
      })
  }
}

export const patchOrganization = (organizationPatch, organizationId) => {
  return (dispatch) => {
    dispatch({ type: 'PATCH_ORGANIZATION.REQUEST' })

    axios.patch(`${API_ROOT}organization/${organizationId}`, JSON.stringify(organizationPatch), configWithAuth('application/json-patch+json'))
      .then((result) => {
        dispatch({ type: 'PATCH_ORGANIZATION.SUCCESS', data: result.data })
        dispatch({ type: 'SET_SNACKBAR', data: { variant: 'success', message: 'Selskapsinformasjon er lagret' } })
      })
      .catch((error) => {
        dispatch({ type: 'PPATCH_ORGANIZATION.ERROR', error })
        dispatch({ type: 'SET_SNACKBAR', data: { variant: 'error', message: 'Noe gikk feil, prøv igjen eller kontakt support' } })
      })
  }
}

export const uploadOrganizationLogo = (formData, organizationId) => {
  return (dispatch) => {
    dispatch({ type: 'UPLOAD_ORGLOGO.REQUEST' })

    axios.post(`${API_ROOT}organization/${organizationId}/logo`, formData, configWithAuth('multipart/form-data'))
      .then((result) => {
        dispatch({ type: 'UPLOAD_ORGLOGO.SUCCESS', data: result.data })
        dispatch({ type: 'SET_SNACKBAR', data: { variant: 'success', message: 'Logo er oppdatert.' } })
      })
      .catch((error) => {
        dispatch({ type: 'UPLOAD_ORGLOGO.ERROR', error })
        dispatch({ type: 'SET_SNACKBAR', data: { variant: 'error', message: 'Feil ved oppdatering av logo.' } })
      })
  }
}

export const inviteUser = (form, organizationId) => {
  return (dispatch) => {
    dispatch({ type: 'ORG_INVITE_USER.REQUEST' })

    axios.post(`${API_ROOT}organization/${organizationId}/invite`, JSON.stringify(form), configWithAuth())
      .then((result) => {
        dispatch({ type: 'ORG_INVITE_USER.SUCCESS', data: result.data })
        dispatch(getOrganizationUsers(organizationId))
        dispatch({ type: 'SET_SNACKBAR', data: { variant: 'success', message: 'Bruker er invitert.' } })
      })
      .catch((error) => {
        dispatch({ type: 'ORG_INVITE_USER.ERROR', error })
        dispatch({ type: 'SET_SNACKBAR', data: { variant: 'error', message: 'Feil ved invitering av bruker.' } })
      })
  }
}
