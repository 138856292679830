import React, { useCallback, useState } from 'react'

import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import { connect } from 'react-redux'
import { useDropzone } from 'react-dropzone'
import DialogActions from '@mui/material/DialogActions'
import IconButton from '@mui/material/IconButton'
import EditIcon from '@mui/icons-material/Edit'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import Dialog from '@mui/material/Dialog'
import * as PropTypes from 'prop-types'
import { uploadFinanceOptionResourceFile, uploadFinanceOptionResourceUrl } from '../store/actions/financeOptionActions'
import { makeStyles } from '@mui/styles'
import Select from '@mui/material/Select'
import InputLabel from '@mui/material/InputLabel'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'

const useStyles = makeStyles(theme => ({
  iconButton: {
    width: '35px',
    height: '35px',
    padding: '9px',
    backgroundColor: '#FFFFFF',
    color: '#333',
    position: 'absolute',
    right: '0px',
    top: '0px',
    bottom: '0px',
    margin: 'auto auto',
    '&:hover': {
      backgroundColor: '#CCCCCC'
    }
  }
}))

const NewFinanceOptionResourceDialog = (props) => {
  const { financeOption, dialogOpen, onClose, uploadingResource } = props

  const classes = useStyles()
  const [resourceName, setResourceName] = useState('')
  const [isOfficial, setIsOfficial] = useState(false)
  const [url, setUrl] = useState('')
  const [type, setType] = useState('file')
  const [file, setFile] = useState(null)
  const [anchorEl, setAnchorEl] = useState(null)

  const onDrop = useCallback(acceptedFiles => {
    if (acceptedFiles.length > 0) {
      setFile(acceptedFiles[0])
    }
  }, [])
  const { getRootProps, getInputProps, open } = useDropzone({ onDrop, accept: 'application/pdf', noClick: true, noKeyboard: true })

  const handleClose = (event) => {
    onClose()
  }

  const handleDropZoneContextOpen = (event) => {
    event.stopPropagation()

    setAnchorEl(null)
  }

  const handleDropZoneContextClose = (event) => {
    event.stopPropagation()

    setAnchorEl(null)
  }

  const handleClick = event => {
    event.stopPropagation()

    setAnchorEl(event.currentTarget)
  }

  const handleOpenUploadDialog = (event) => {
    event.stopPropagation()

    open()
    setAnchorEl(null)
  }

  const onSubmit = (event) => {
    event.stopPropagation()
    event.preventDefault()

    if ((type === 'file' && !file) || (type === 'url' && url.length === 0)) { return }

    const formData = new FormData()
    formData.append('financeOptionId', financeOption.id)
    formData.append('isOfficial', isOfficial)
    formData.append('resourceName', resourceName)

    if (type === 'file') {
      formData.append('file', file)
      props.uploadFinanceOptionResourceFile(formData)
    } else if (type === 'url') {
      formData.append('url', url)
      props.uploadFinanceOptionResourceUrl(formData)
    } else {
      return
    }

    onClose()
  }

  if (!open || !financeOption) { return null }

  return (
        <Dialog onClose={handleClose} open={dialogOpen}>
            <DialogTitle>{`Add resource to ${financeOption.name}`}</DialogTitle>
            <form onSubmit={onSubmit}>
                <DialogContent>
                    <Grid container spacing={2} justifyContent={'center'} alignContent={'center'}>
                        <Grid item xs={12}>
                            <TextField
                                autoFocus
                                name="resourceName"
                                value={resourceName}
                                onChange={e => setResourceName(e.target.value)}
                                type="text"
                                label={'Resource Name'}
                                fullWidth
                                variant={'filled'}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth className={classes.formControl}>
                                <InputLabel id="type">Resource Type</InputLabel>
                                <Select
                                    labelId="type"
                                    id="type"
                                    value={type}
                                    onChange={e => setType(e.target.value)}
                                >
                                    <MenuItem value={'file'}>File</MenuItem>
                                    <MenuItem value={'url'}>Url</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={isOfficial}
                                        onChange={e => setIsOfficial(e.target.checked)}
                                        name="isOfficial"
                                        color="primary"
                                    />
                                }
                                label="Is Official Resource"
                            />
                        </Grid>
                        {
                            type === 'url' &&
                            <Grid item xs={12}>
                                <TextField
                                    autoFocus
                                    name="url"
                                    value={url}
                                    onChange={e => setUrl(e.target.value)}
                                    type="text"
                                    label={'Resource URL'}
                                    fullWidth
                                    variant={'filled'}
                                />
                            </Grid>
                        }
                        {
                            type === 'file' &&
                            <Grid item xs={12}>
                                <div {...getRootProps()} style={{ width: '100%' }}
                                >
                                    <input {...getInputProps()} />
                                    {
                                        <div style={{ height: '40px', width: '100%', position: 'relative', background: 'rgba(0,0,0,0.2)', display: 'flex', alignItems: 'center', padding: '10px', borderRadius: '20px' }}>
                                            {file ? file.name : 'No file added'}
                                            <IconButton className={classes.iconButton} onClick={handleClick}>
                                                <EditIcon style={{ height: 'inherit', width: 'inherit' }}/>
                                            </IconButton>
                                            <Menu
                                                id="simple-menu"
                                                anchorEl={anchorEl}
                                                keepMounted
                                                open={Boolean(anchorEl)}
                                                onClose={handleDropZoneContextClose}
                                            >
                                                <MenuItem onClick={handleOpenUploadDialog}>Change</MenuItem>
                                            </Menu>
                                        </div>
                                    }
                                </div>
                            </Grid>
                        }
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant={'contained'} type={'submit'} onClick={onSubmit} disabled={(type === 'file' && !file) || (type === 'url' && url.length === 0) || uploadingResource}>
                        Add
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
  )
}

NewFinanceOptionResourceDialog.propTypes = {
  dialogOpen: PropTypes.bool.isRequired,
  financeOption: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired
}

const mapStateToProps = (state) => {
  return {
    uploadingResource: state.financeOption.uploadingResource
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    uploadFinanceOptionResourceFile: (formData) => dispatch(uploadFinanceOptionResourceFile(formData)),
    uploadFinanceOptionResourceUrl: (formData) => dispatch(uploadFinanceOptionResourceUrl(formData))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewFinanceOptionResourceDialog)
