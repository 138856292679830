const initState = {
  yourOrganizations: [],
  organizationUsers: [],
  selectedOrganizationId: '',
  changingActiveOrganization: false,
  fetchingOrganizations: false,
  patchingOrganization: false,
  updatingOrgLogo: false,
  fetchingOrganizationUsers: false,
  invitingUser: false,
  error: '',
  approvingUsers: []
}

const organizationReducer = (state = initState, action) => {
  switch (action.type) {
    case 'GET_YOUR_COMPANIES.REQUEST':
      return {
        ...state,
        fetchingOrganizations: true
      }
    case 'GET_YOUR_COMPANIES.SUCCESS':
      return {
        ...state,
        yourOrganizations: action.data,
        fetchingOrganizations: false
      }
    case 'GET_YOUR_COMPANIES.ERROR':
      return {
        ...state,
        fetchingOrganizations: false,
        error: action.error
      }
    case 'CREATE_ORGANIZATION_SUCCESS':
    {
      const yourOrganizationsCopy = state.yourOrganizations
      const existingIndex = yourOrganizationsCopy.findIndex(x => x.id === action.data.id)
      if (existingIndex >= 0) { yourOrganizationsCopy[existingIndex] = action.data }

      return {
        ...state,
        yourOrganizations: yourOrganizationsCopy
      }
    }
    case 'CREATE_ORGANIZATION_ERROR':
      return {
        ...state,
        error: action.error
      }

    case 'PATCH_ORGANIZATION.REQUEST':
      return {
        ...state,
        patchingOrganization: true
      }

    case 'PATCH_ORGANIZATION.SUCCESS':
    {
      const yourOrganizationsCopy = state.yourOrganizations
      const existingIndex = yourOrganizationsCopy.findIndex(x => x.id === action.data.id)
      if (existingIndex >= 0) { yourOrganizationsCopy[existingIndex] = action.data }

      return {
        ...state,
        yourOrganizations: yourOrganizationsCopy,
        patchingOrganization: false
      }
    }

    case 'PATCH_ORGANIZATION.ERROR':
      return {
        ...state,
        error: action.error,
        patchingOrganization: false
      }

    case 'CHANGE_ORGANIZATION_REQUEST':
      return {
        ...state,
        changingActiveOrganization: true,
        selectedOrganizationId: action.data,
        organizationUsers: []
      }

    case 'CHANGE_ORGANIZATION_SUCCESS':
      return {
        ...state,
        changingActiveOrganization: false
      }

    case 'CHANGE_ORGANIZATION_ERROR':
      return {
        ...state,
        changingActiveOrganization: false,
        error: action.error
      }

    case 'GET_ORGANIZATION_USERS_REQUEST':
      return {
        ...state,
        fetchingOrganizationUsers: true
      }

    case 'GET_ORGANIZATION_USERS_SUCCESS':
      return {
        ...state,
        fetchingOrganizationUsers: false,
        organizationUsers: action.data
      }

    case 'GET_ORGANIZATION_USERS_ERROR':
      return {
        ...state,
        fetchingOrganizationUsers: false,
        error: action.error
      }

    case 'ORGANIZATION_USER_ACCESS_RESPONSE.REQUEST':
      return {
        ...state,
        approvingUsers: [...state.approvingUsers, action.data.id]
      }

    case 'ORGANIZATION_USER_ACCESS_RESPONSE.SUCCESS':
      return {
        ...state,
        organizationUsers: action.data,
        approvingUsers: state.approvingUsers.filter(x => x === action.data.id)
      }

    case 'ORGANIZATION_USER_ACCESS_RESPONSE.ERROR':
      return {
        ...state,
        error: action.error,
        approvingUsers: state.approvingUsers.filter(x => x === action.data.id)
      }

    case 'UPLOAD_ORGLOGO.REQUEST':
    {
      return {
        ...state,
        updatingOrgLogo: true
      }
    }

    case 'UPLOAD_ORGLOGO.SUCCESS':
    {
      const yourOrganizationsCopy = state.yourOrganizations
      const existingIndex = yourOrganizationsCopy.findIndex(x => x.id === action.data.id)
      if (existingIndex >= 0) { yourOrganizationsCopy[existingIndex] = action.data }

      return {
        ...state,
        yourOrganizations: yourOrganizationsCopy,
        updatingOrgLogo: false
      }
    }

    case 'UPLOAD_ORGLOGO.ERROR':
      return {
        ...state,
        error: action.error,
        updatingOrgLogo: false
      }

    case 'ORG_INVITE_USER.REQUEST':
    {
      return {
        ...state,
        invitingUser: true
      }
    }

    case 'ORG_INVITE_USER.SUCCESS':
    {
      const yourOrganizationsCopy = state.yourOrganizations
      const existingIndex = yourOrganizationsCopy.findIndex(x => x.id === action.data.id)
      if (existingIndex >= 0) { yourOrganizationsCopy[existingIndex] = action.data }

      return {
        ...state,
        yourOrganizations: yourOrganizationsCopy,
        invitingUser: false
      }
    }

    case 'ORG_INVITE_USER.ERROR':
      return {
        ...state,
        error: action.error,
        invitingUser: false
      }

    case 'SIGNOUT_SUCCESS':
      return initState

    default:
      return state
  }
}

export default organizationReducer
