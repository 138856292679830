export const HOME = '/'
export const SIGN_IN = '/signin'
export const ACCOUNT = '/account'
export const ADMIN = '/admin'
export const YOUR_FUNDING = '/your-funding'
export const NEW_FUNDING = '/new-funding'
export const FUNDING = '/funding/:id'
export const PROJECTS = '/projects'
export const COMPANY = '/company'
export const RESET_PASSWORD = '/reset-password'
export const PROJECT = '/projects/:id'
export const NEW_PROJECT = '/new-project'
export const EDIT_PROJECT = '/projects/:id/edit'
export const EDIT_FUNDING = '/funding/:id/edit'
export const ABOUT_PAGE = '/about'
export const FUNDING_OVERVIEW = '/funding'
