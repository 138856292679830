import axios from 'axios'
import { API_ROOT, configWithAuth, configWithoutAuth } from '../api'
import { history } from '../history'

export const signIn = (credentials) => {
  return (dispatch) => {
    dispatch({ type: 'LOGIN_REQUEST' })

    axios.post(`${API_ROOT}auth/login`, JSON.stringify(credentials), configWithoutAuth())
      .then((result) => {
        window.localStorage.setItem('authBody', result.data.token)
        axios.get(`${API_ROOT}user`, configWithAuth())
          .then((getUserResult) => {
            dispatch({ type: 'GET_USER_SUCCESS', data: getUserResult.data })
            dispatch({ type: 'LOGIN_SUCCESS', data: result.data })
            dispatch({ type: 'SET_SNACKBAR', data: { variant: 'success', message: 'Velkommen tilbake' } })
            history.push('/')
          })
          .catch((error) => {
            dispatch({ type: 'GET_USER_ERROR', error })
            dispatch({ type: 'SET_SNACKBAR', data: { variant: 'error', message: 'Fikk ikke hentet brukeren din.' } })
            signOut()
          })
      })
      .catch((error) => {
        const response = error.response ? (typeof error.response.data === 'object' && error.response.data ? Object.values(error.response.data)[0][0] : error.response.data) : error
        dispatch({ type: 'LOGIN_ERROR', response })
        dispatch({ type: 'SET_SNACKBAR', data: { variant: 'error', message: response } })
      })
  }
}

export const signOut = () => {
  return (dispatch) => {
    dispatch({ type: 'SIGNOUT_SUCCESS' })
    history.push('/signin')
  }
}

export const resetPassword = (form) => {
  return (dispatch) => {
    dispatch({ type: 'RESET_PASSWORD_REQUEST' })

    axios.post(`${API_ROOT}auth/reset-password`, JSON.stringify(form), configWithAuth())
      .then((result) => {
        dispatch({ type: 'RESET_PASSWORD_SUCCESS', data: result.data })
        dispatch({ type: 'SET_SNACKBAR', data: { variant: 'success', message: 'Nytt passord er satt' } })
      })
      .catch((error) => {
        const response = typeof error.response.data === 'object' && error.response.data !== null ? Object.values(error.response.data)[0][0] : error.response.data
        dispatch({ type: 'RESET_PASSWORD_ERROR', response })
        dispatch({ type: 'SET_SNACKBAR', data: { variant: 'error', message: 'Feil ved setting av nytt passord' } })
      })
  }
}

export const resetPasswordRequestPin = (email) => {
  return (dispatch) => {
    dispatch({ type: 'RESET_PASSWORD_REQUEST_PIN.REQUEST' })

    axios.get(`${API_ROOT}auth/reset-password-request/${email}`, configWithoutAuth())
      .then((result) => {
        dispatch({ type: 'RESET_PASSWORD_REQUEST_PIN.SUCCESS', data: result.data })
        dispatch({ type: 'SET_SNACKBAR', data: { variant: 'success', message: 'Pin er sendt til din epost' } })
      })
      .catch((error) => {
        const response = typeof error.response.data === 'object' && error.response.data !== null ? Object.values(error.response.data)[0][0] : error.response.data
        dispatch({ type: 'RESET_PASSWORD_REQUEST_PIN.ERROR', response })
        dispatch({ type: 'SET_SNACKBAR', data: { variant: 'error', message: 'Feil ved gjenoppretting av passord' } })
      })
  }
}

export const resetPasswordPin = (form) => {
  return (dispatch) => {
    dispatch({ type: 'RESET_PASSWORD_PIN.REQUEST' })

    axios.post(`${API_ROOT}auth/reset-password-pin`, JSON.stringify(form), configWithAuth())
      .then((result) => {
        dispatch({ type: 'RESET_PASSWORD_PIN.SUCCESS', data: result.data })
        history.push(`/signin?email=${form.email}`)
        dispatch({ type: 'SET_SNACKBAR', data: { variant: 'success', message: 'Nytt passord er satt' } })
      })
      .catch((error) => {
        const response = typeof error.response.data === 'object' && error.response.data !== null ? Object.values(error.response.data)[0][0] : error.response.data
        dispatch({ type: 'RESET_PASSWORD_PIN.ERROR', response })
        dispatch({ type: 'SET_SNACKBAR', data: { variant: 'error', message: 'Feil ved setting av nytt passord' } })
      })
  }
}

export const signUpRequestAccess = (credentials, orgnr) => {
  return (dispatch) => {
    dispatch({ type: 'REQUEST_ACCESS_REQUEST' })

    const json = JSON.stringify({
      email: credentials.email,
      password: credentials.password,
      orgnr: orgnr
    })

    axios.post(`${API_ROOT}auth/request-access`, json, configWithoutAuth())
      .then((result) => {
        window.localStorage.setItem('authBody', result.data.token)
        axios.get(`${API_ROOT}user`, configWithAuth())
          .then((getUserResult) => {
            dispatch({ type: 'GET_USER_SUCCESS', data: getUserResult.data })
            dispatch({ type: 'REQUEST_ACCESS_SUCCESS', data: result.data })
            dispatch({ type: 'SET_SNACKBAR', data: { variant: 'success', message: 'Sendt forespørsel om tilgang.' } })
            history.push('/')
          })
          .catch((error) => {
            dispatch({ type: 'GET_USER_ERROR', error })
            dispatch({ type: 'SET_SNACKBAR', data: { variant: 'error', message: 'Fikk ikke hentet brukeren din.' } })
            signOut()
          })
      })
      .catch((error) => {
        const response = error.response ? error.response && typeof (error.response.data) === 'object' ? Object.values(error.response.data)[0][0] : error.response.data : ''
        dispatch({ type: 'REQUEST_ACCESS_ERROR', response })
        dispatch({ type: 'SET_SNACKBAR', data: { variant: 'error', message: response } })
      })
  }
}

export const signUp = (credentials, organization = null) => {
  return (dispatch) => {
    dispatch({ type: 'SIGNUP_REQUEST' })

    const json = JSON.stringify({
      email: credentials.email,
      password: credentials.password,
      newOrganization: organization
        ? {
            orgnr: organization.organisasjonsnummer,
            name: organization.navn,
            orgform: organization.organisasjonsform.kode,
            orgformDescription: organization.organisasjonsform.beskrivelse,
            website: organization.hjemmeside ? organization.hjemmeside : '',
            incorporationDate: organization.stiftelsesdato,
            registerDateUnitRegister: organization.registreringsdatoEnhetsregisteret,
            vatRegistered: organization.registrertIMvaregisteret,
            industryCode: organization.naeringskode1 ? organization.naeringskode1.kode : null,
            industryDescription: organization.naeringskode1 ? organization.naeringskode1.beskrivelse : null,
            numEmployees: organization.antallAnsatte,
            country: organization.forretningsadresse.land,
            countryCode: organization.forretningsadresse.landkode,
            postCode: organization.forretningsadresse.postnummer,
            postLocation: organization.forretningsadresse.poststed,
            postAddress: organization.forretningsadresse.adresse[0],
            municipality: organization.forretningsadresse.kommune,
            municipalityNumber: organization.forretningsadresse.kommunenummer
          }
        : null
    })

    axios.post(`${API_ROOT}auth/register`, json, configWithoutAuth())
      .then((result) => {
        window.localStorage.setItem('authBody', result.data.token)
        axios.get(`${API_ROOT}user`, configWithAuth())
          .then((getUserResult) => {
            dispatch({ type: 'GET_USER_SUCCESS', data: getUserResult.data })
            dispatch({ type: 'SIGNUP_SUCCESS', data: result.data })
            dispatch({ type: 'SET_SNACKBAR', data: { variant: 'success', message: 'Velkommen' } })
            history.push('/')
          })
          .catch((error) => {
            dispatch({ type: 'GET_USER_ERROR', error })
            dispatch({ type: 'SET_SNACKBAR', data: { variant: 'error', message: 'Fikk ikke hentet brukeren din.' } })
            signOut()
          })
      })
      .catch((error) => {
        const response = error.response ? error.response && typeof (error.response.data) === 'object' ? Object.values(error.response.data)[0][0] : error.response.data : ''
        dispatch({ type: 'SIGNUP_ERROR', response })
        dispatch({ type: 'SET_SNACKBAR', data: { variant: 'error', message: response } })
      })
  }
}
