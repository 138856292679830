import React, { useCallback, useEffect, useState } from 'react'

import PasswordChangeForm from '../components/PasswordChange'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { makeStyles } from '@mui/styles'
import jsonpatch from 'fast-json-patch'
import Button from '@mui/material/Button'
import { patchUser, uploadProfilePicture } from '../store/actions/userActions'
import CircularProgress from '@mui/material/CircularProgress'
import CardContent from '@mui/material/CardContent'
import CardHeader from '@mui/material/CardHeader'
import CardActions from '@mui/material/CardActions'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import AppBar from '@mui/material/AppBar'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import { useDropzone } from 'react-dropzone'
import Avatar from '@mui/material/Avatar'
import EditIcon from '@mui/icons-material/Edit'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import useMediaQuery from '@mui/material/useMediaQuery'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import {FormattedMessage} from "react-intl";

const useStyles = makeStyles(theme => ({
  button: {
    height: '50px'
  },
  profilePictureContainer: {
    width: '150px',
    height: '150px',
    borderRadius: '50%'
  },
  iconButton: {
    width: '35px !important',
    height: '35px !important',
    padding: '9px !important',
    backgroundColor: '#FFFFFF !important',
    color: '#333 !important',
    position: 'absolute !important',
    right: '0px !important',
    bottom: '0px !important',
    '&:hover': {
      backgroundColor: '#CCCCCC !important'
    }
  }
}))

const AccountPage = (props) => {
  const { userInfo, patchingUser } = props
  const classes = useStyles()

  const [userCopy, setUserCopy] = useState(null)
  const [siteLanguage, setSiteLanguage] = useState('')
  const [value, setValue] = React.useState(0)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const overridePreferDarkMode = localStorage.getItem('preferDarkMode')
  const defaultPrefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)')
  const preferDarkMode = overridePreferDarkMode != null ? Boolean(overridePreferDarkMode === 'true') : defaultPrefersDarkMode

  const onDrop = useCallback(acceptedFiles => {
    const formData = new FormData()
    formData.append('file', acceptedFiles[0])
    props.uploadProfilePicture(formData)
  }, [])
  const { getRootProps, getInputProps, open } = useDropzone({ onDrop, accept: 'image/png, image/jpeg', noClick: true, noKeyboard: true })

  const handleClick = event => {
    event.stopPropagation()

    setAnchorEl(event.currentTarget)
  }

  const handleClose = (event) => {
    event.stopPropagation()

    setAnchorEl(null)
  }

  useEffect(() => {
    setSiteLanguage(localStorage.getItem('language'))
  }, [])

  useEffect(() => {
    if (userInfo != null) {
      setUserCopy(userInfo)
    }
  }, [userInfo])

  const changeUserInfo = (event) => {
    setUserCopy({
      ...userCopy,
      [event.target.id]: event.target.value
    })
  }

  const removeProfilePictureClicked = (event) => {
    const formData = new FormData()
    props.uploadProfilePicture(formData)
  }

  const handleOpenUploadDialog = (event) => {
    event.stopPropagation()

    open()
    setAnchorEl(null)
  }

  const saveChangesClicked = (event) => {
    const patch = jsonpatch.compare(userInfo, userCopy)
    props.patchUser(patch)
  }

  const changeSiteLanguageChanged = (event) => {
    setSiteLanguage(event.target.value)
    localStorage.setItem('language', event.target.value)
    window.location.reload()
  }

  const togglePreferDarkMode = (event) => {
    event.preventDefault()

    if (preferDarkMode === true) {
      localStorage.setItem('preferDarkMode', 'false')
    } else if (preferDarkMode === false) {
      localStorage.setItem('preferDarkMode', 'true')
    }
    window.location.reload()
  }

  return (
        <div style={{ padding: '20px' }}>
            <Typography variant={'body2'}>
                Her kan du endre informasjon brukeren din
            </Typography>
            <Typography variant={'h4'}>
                Min profil
            </Typography>
            <AppBar position="static" style={{ marginTop: '20px' }}>
                <Tabs
                    value={value}
                    onChange={(event, value) => setValue(value)}
                >
                    <Tab label="Generelt" tabIndex={0} />
                    <Tab label="Innstillinger" tabIndex={1} />
                    <Tab label="Sikkerhet" tabIndex={2} />
                </Tabs>
            </AppBar>
            {
                value === 0 &&
                <div style={{ padding: '20px 0px' }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={10}>
                            <Card>
                                <CardHeader
                                    title={'Profilbilde'}
                                />
                                <CardContent>
                                    <div {...getRootProps()}
                                         className={classes.profilePictureContainer}
                                    >
                                        <input {...getInputProps()} />
                                        {
                                            <div style={{ height: 'inherit', width: 'inherit', position: 'relative' }}>
                                                <Avatar src={userInfo.profilePictureUrl} style={{ height: 'inherit', width: 'inherit' }} />
                                                <IconButton className={classes.iconButton} onClick={handleClick} disabled={patchingUser}>
                                                    <EditIcon style={{ height: 'inherit', width: 'inherit' }} />
                                                </IconButton>
                                                <Menu
                                                    id="simple-menu"
                                                    anchorEl={anchorEl}
                                                    keepMounted
                                                    open={Boolean(anchorEl)}
                                                    onClose={handleClose}
                                                >
                                                    <MenuItem onClick={handleOpenUploadDialog}>Change</MenuItem>
                                                    {
                                                        userInfo.profilePictureUrl && userInfo.profilePictureUrl.length > 0 &&
                                                        <MenuItem onClick={removeProfilePictureClicked}>Remove</MenuItem>
                                                    }
                                                </Menu>
                                            </div>
                                        }
                                    </div>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} sm={10}>
                            <Card>
                                <CardHeader
                                    title={'Brukerinformasjon'}
                                />
                                <CardContent>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <TextField
                                                margin="normal"
                                                variant="outlined"
                                                label="Email"
                                                id="email"
                                                disabled
                                                value={userCopy ? userCopy.email : ''}
                                                fullWidth
                                                onChange={changeUserInfo}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                margin="normal"
                                                variant="outlined"
                                                label="Navn"
                                                id="name"
                                                disabled={!userCopy || patchingUser}
                                                value={userInfo && userCopy ? userCopy.name : ''}
                                                fullWidth
                                                onChange={changeUserInfo}
                                            />
                                        </Grid>
                                    </Grid>
                                </CardContent>
                                <CardActions>
                                    <Button onClick={saveChangesClicked}
                                            variant={'contained'}
                                            className={classes.button}
                                            disabled={JSON.stringify(userInfo) === JSON.stringify(userCopy) || patchingUser}
                                    >
                                        {
                                            patchingUser
                                              ? <CircularProgress size={24} />
                                              : 'Save changes'
                                        }
                                    </Button>
                                </CardActions>
                            </Card>
                        </Grid>
                    </Grid>
                </div>
            }
            {
                value === 1 &&
                <div style={{ padding: '20px 0px' }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={10}>
                            <Card>
                                <CardHeader
                                    title={'Innstillinger'}
                                />
                                <CardContent>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <FormControl fullWidth variant={'outlined'}>
                                                <InputLabel id="siteLanguage">Site language</InputLabel>
                                                <Select
                                                    labelId="siteLanguage"
                                                    id="siteLanguage"
                                                    value={siteLanguage}
                                                    label={'Site language'}
                                                    onChange={changeSiteLanguageChanged}
                                                >
                                                    <MenuItem value={'en'}
                                                              key={'en'}>
                                                        <FormattedMessage
                                                            id="Languages.English"
                                                            defaultMessage='English'
                                                            description='Names of languages'
                                                        />
                                                    </MenuItem>
                                                    <MenuItem value={'nb'}
                                                              key={'nb'}>
                                                        <FormattedMessage
                                                            id="Languages.Norwegian"
                                                            defaultMessage='Norwegian'
                                                            description='Names of languages'
                                                        />
                                                    </MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={preferDarkMode}
                                                        onChange={event => togglePreferDarkMode(event)}
                                                        name="preferDarkMode"
                                                        color="primary"
                                                    />
                                                }
                                                label="Dark Mode"
                                            />
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </div>
            }
            {
                value === 2 &&
                <div style={{ padding: '20px 0px' }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={10}>
                            <Card>
                                <CardHeader
                                    title={'Passord'}
                                />
                                <CardContent>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <PasswordChangeForm/>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </div>
            }
        </div>
  )
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.authError,
    patchingUser: state.user.patchingUser,
    userInfo: state.user.userInfo
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    patchUser: (userPatch) => dispatch(patchUser(userPatch)),
    uploadProfilePicture: (formData) => dispatch(uploadProfilePicture(formData))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AccountPage))
