import React from 'react'

import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import Typography from '@mui/material/Typography'
import {CardActions, CardContent, Stack} from '@mui/material'
import { makeStyles } from '@mui/styles'
import Button from '@mui/material/Button'
import { Link } from 'react-router-dom'
import { financeOptionOrgforms, financeOptionTypes, financeOptionSectors } from '../constants/financeOptionEnums'
import moment from 'moment'
import Avatar from '@mui/material/Avatar'
import {FormattedMessage, useIntl} from 'react-intl'
import { history } from '../store/history'

const useStyles = makeStyles(theme => ({
    root: {
        padding: '20px',
        // height: '300px'
    },
    statTitle: {
        opacity: 0.5,
    },
}))

const FinanceOptionCard = (props) => {
    const { option } = props
    const classes = useStyles()
    const intl = useIntl()

    const handleFundingOptionClicked = (event) => {
        history.push(`funding/${option.id}`)
    }

    const sortedDeadlines = option.deadlines && option.deadlines.sort((a, b) => new Date(a.deadline) - new Date(b.deadline)).filter(x => moment(x.deadline).isAfter(moment()))

    return (
        <Card className={classes.root}>
            <CardContent>
                <Grid container justify={'flex-start'} alignItems={'center'} spacing={2}>
                    <Grid item xs={12}>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <Avatar src={option.organization.logoUrl} style={{marginRight: '10px'}}>
                                {' '}
                            </Avatar>
                            <div>
                                <Typography variant={'h6'} align={'left'} style={{ cursor: 'pointer', fontSize: '1.1em' }}
                                            onClick={handleFundingOptionClicked}>
                                    {option.name}
                                </Typography>
                                <Typography variant={'body2'} align={'left'}>
                                    {option.organization && props.option.organization.name}
                                </Typography>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <Stack direction={'column'} spacing={2}>
                            <Stack direction={'row'} justifyContent={'space-between'}>
                                <div>
                                    <Typography variant={'caption'} align={'left'} className={classes.statTitle} >
                                        <FormattedMessage
                                            id="Funding.Purpose"
                                            defaultMessage='Formål'
                                        />
                                    </Typography>
                                    <Typography variant={'body2'} align={'left'}>
                                        {intl.formatMessage({ id: financeOptionOrgforms.find(x => x.enum === option.orgForm).name })}
                                    </Typography>
                                </div>
                                <div>
                                    <Typography variant={'caption'} align={'left'} className={classes.statTitle} >
                                        <FormattedMessage
                                            id="Funding.Sector"
                                            defaultMessage='Sektor'
                                        />
                                    </Typography>
                                    <Typography variant={'body2'} align={'left'}>
                                        {intl.formatMessage({ id: financeOptionSectors.find(x => x.enum === option.sector).name })}
                                    </Typography>
                                </div>
                                <div>
                                    <Typography variant={'caption'} align={'left'} className={classes.statTitle} >
                                        <FormattedMessage
                                            id="Funding.Type"
                                            defaultMessage='Type'
                                        />
                                    </Typography>
                                    <Typography variant={'body2'} align={'left'}>
                                        {intl.formatMessage({ id: financeOptionTypes.find(x => x.enum === option.type).name })}
                                    </Typography>
                                </div>
                            </Stack>
                            <Stack direction={'row'} justifyContent={'space-between'}>
                                <Typography variant={'body2'} align={'left'}>
                                    {
                                        sortedDeadlines && sortedDeadlines.length > 0
                                            ? moment(sortedDeadlines[0].deadline).format('LL')
                                            : <FormattedMessage
                                                id="Funding.NoNewDeadline"
                                                defaultMessage='Ingen ny søknadsfrist'
                                            />
                                    }
                                </Typography>
                            </Stack>
                        </Stack>
                    </Grid>
                </Grid>
            </CardContent>
            <CardActions>
                <Button variant={'contained'} component={Link} to={`funding/${option.id}`}>
                    <FormattedMessage
                        id="Global.ReadMore"
                        defaultMessage='LES MER'
                    />
                </Button>
            </CardActions>
        </Card>
    )
}

export default FinanceOptionCard
