const initState = {
  yourProjects: [],
  genres: [],
  fetchingGenres: false,
  fetchingProjects: false,
  creatingProject: false,
  patchingProject: false,
  error: ''
}

const projectReducer = (state = initState, action) => {
  switch (action.type) {
    case 'GET_PROJECTS.REQUEST':
      return {
        ...state,
        error: '',
        fetchingProjects: true
      }
    case 'GET_PROJECTS.SUCCESS':
      return {
        ...state,
        error: '',
        yourProjects: action.data,
        fetchingProjects: false
      }
    case 'GET_PROJECTS.ERROR':
      return {
        ...state,
        error: action.error,
        fetchingProjects: false
      }

    case 'CREATE_PROJECT.REQUEST':
    {
      return {
        ...state,
        error: '',
        creatingProject: true
      }
    }
    case 'CREATE_PROJECT.SUCCESS':
    {
      const yourProjectsCopy = state.yourProjects
      yourProjectsCopy.push(action.data)

      return {
        ...state,
        error: '',
        yourProjects: yourProjectsCopy,
        creatingProject: false
      }
    }
    case 'CREATE_PROJECT.ERROR':
      return {
        ...state,
        error: action.error,
        creatingProject: false
      }

    case 'PATCH_PROJECT.REQUEST':
    {
      return {
        ...state,
        error: '',
        patchingProject: true
      }
    }
    case 'PATCH_PROJECT.SUCCESS':
    {
      const yourProjectsCopy = state.yourProjects
      const existingIndex = yourProjectsCopy.findIndex(x => x.id === action.data.id)
      if (existingIndex >= 0) { yourProjectsCopy[existingIndex] = action.data }

      return {
        ...state,
        yourProjects: yourProjectsCopy,
        patchingProject: false
      }
    }
    case 'PATCH_PROJECT.ERROR':
      return {
        ...state,
        error: action.error,
        patchingProject: false
      }

    case 'DELETE_PROJECT.REQUEST':
      return {
        ...state,
        error: '',
        deletingProject: true
      }
    case 'DELETE_PROJECT.SUCCESS':
    {
      const yourProjectsCopy = state.yourProjects
      const existingIndex = yourProjectsCopy.findIndex(x => x.id === action.data)
      if (existingIndex >= 0) { yourProjectsCopy.splice(existingIndex, 1) }

      return {
        ...state,
        yourProjects: yourProjectsCopy,
        deletingProject: false
      }
    }
    case 'DELETE_PROJECT.ERROR':
      return {
        ...state,
        error: action.error,
        deletingProject: false
      }

    case 'UPDATE_PROJECT_COVER.REQUEST':
      return {
        ...state,
        error: '',
        updatingProjectPhoto: true
      }
    case 'UPDATE_PROJECT_COVER.SUCCESS':
    {
      const yourProjectsCopy = state.yourProjects
      const existingIndex = yourProjectsCopy.findIndex(x => x.id === action.data.id)
      if (existingIndex >= 0) { yourProjectsCopy[existingIndex] = action.data }

      return {
        ...state,
        yourProjects: yourProjectsCopy,
        updatingProjectPhoto: false
      }
    }
    case 'UPDATE_PROJECT_COVER.ERROR':
      return {
        ...state,
        error: action.error,
        updatingProjectPhoto: false
      }

    case 'GET_GENRES.REQUEST':
      return {
        ...state,
        fetchingGenres: true
      }

    case 'GET_GENRES.SUCCESS':
      return {
        ...state,
        fetchingGenres: false,
        genres: action.data
      }
    case 'GET_GENRES.ERROR':
      return {
        ...state,
        fetchingGenres: false,
        error: action.error
      }

    case 'CHANGE_ORGANIZATION_REQUEST':
      return state

    case 'SIGNOUT_SUCCESS':
      return initState

    default:
      return state
  }
}

export default projectReducer
