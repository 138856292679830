import React, { useEffect, useState } from 'react'

import Grid from '@mui/material/Grid'
import { makeStyles } from '@mui/styles'
import Typography from '@mui/material/Typography'
import Card from '@mui/material/Card'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import CardHeader from '@mui/material/CardHeader'
import { createProject, getGenres, getYourProjects } from '../store/actions/projectActions'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import Chip from '@mui/material/Chip'
import Autocomplete from '@mui/material/Autocomplete'
import CardContent from '@mui/material/CardContent'
import moment from 'moment'
import DatePicker from '@mui/lab/DatePicker';
import NumberFormat from 'react-number-format'
import pegiMessages, {
  companyRoleEnum,
  esrbRatingEnum,
  gameBusinessModelEnum,
  gamePlayModeEnum, gamePurposeEnum,
  pegiRatingEnum,
  projectStageEnums
} from '../constants/projectEnums'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Checkbox from '@mui/material/Checkbox'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import { useIntl } from 'react-intl'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import {FormattedMessage} from 'react-intl'
import CardActions from '@mui/material/CardActions'
import { withStyles } from '@material-ui/styles'
import StepConnector from '@mui/material/StepConnector'
import MoneyIcon from '@mui/icons-material/AttachMoney'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import FormControlLabel from '@mui/material/FormControlLabel'
import Divider from '@mui/material/Divider'
import CultureTestDialog from '../components/CultureTestDialog'
import CreateIcon from '@mui/icons-material/Create'
import ListIcon from '@mui/icons-material/List'
import EventIcon from '@mui/icons-material/Event'

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
const checkedIcon = <CheckBoxIcon fontSize="small" />

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 17
  },
  active: {
    '& $line': {
      backgroundColor: '#FFF'
    }
  },
  completed: {
    '& $line': {
      backgroundColor: '#FFF'
    }
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: 'rgba(255, 255, 255, 1)',
    borderRadius: 3,
    cursor: 'default'
  }
})(StepConnector)

const useColorlibStepIconStyles = makeStyles(theme => ({
  root: {
    backgroundColor: 'rgba(255, 255, 255, 0.3)',
    zIndex: 1,
    color: '#fff',
    width: 35,
    height: 35,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center'
  },
  active: {
    backgroundColor: '#FFF',
    boxShadow: theme.palette.mode === 'dark' ? '0px 5px 10px #171c2b' : '0px 5px 10px #DFE1E6'
  },
  completed: {
    backgroundColor: '#FFF'
  },
  icon: {
    width: 18,
    height: 18,
    color: '#c72946'
  }
}))

function ColorlibStepIcon (props) {
  const classes = useColorlibStepIconStyles()
  const { active, completed } = props

  const icons = {
    1: <CreateIcon className={classes.icon}/>,
    2: <ListIcon className={classes.icon}/>,
    3: <MoneyIcon className={classes.icon}/>,
    4: <EventIcon className={classes.icon}/>
  }

  return (
        <div
            className={clsx(classes.root, {
              [classes.active]: active,
              [classes.completed]: completed
            })}
        >
            {icons[String(props.icon)]}
        </div>
  )
}

ColorlibStepIcon.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool,
  icon: PropTypes.node
}

const steps = [
  'Generelt',
  'Fakta',
  'Økonomi',
  'Utgivelse'
]

const useStyles = makeStyles(theme => ({
  root: {
    padding: '20px'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
}))

const NewProjectPage = (props) => {
  const { yourProjects, yourOrganizations, userInfo, genres, creatingProject, metadata } = props
  const classes = useStyles()
  const intl = useIntl()
  const currentDate = new Date()

  const [activeCultureTestStep, setActiveCultureTestStep] = React.useState(0)
  const [completedCultureTest, setCompletedCultureTest] = React.useState(false)
  const [cultureTestOpen, setCultureTestOpen] = React.useState(false)
  const [activeStep, setActiveStep] = useState(0)
  const [completedStep, setCompletedStep] = useState(0)
  const [dataCopy, setDataCopy] = useState({
    organizationId: null,
    name: '',
    description: '',
    coverImageUrl: '',
    genre: '',
    releaseDate: '',
    productionBudget: 0,
    marketingBudget: 0,
    stage: 0,
    genres: [],
    pegiRating: 0,
    esrbRating: 0,
    gamePurpose: 0,
    companyRole: 0,
    gamePlayMode: 0,
    gameBusinessModel: 0,
    platforms: []
  })

  useEffect(() => {
    setDataCopy({
      ...dataCopy,
      organizationId: userInfo.currentlyActiveOrganization
    })

    props.getGenres()
    props.getYourProjects(userInfo.currentlyActiveOrganization)
  }, [])

  const createProjectClicked = (event) => {
    props.createProject(dataCopy)
  }

  const handleTextInputChange = (event) => {
    setDataCopy({
      ...dataCopy,
      [event.target.id]: event.target.value
    })
  }

  const handleGenreChange = (event, value) => {
    setDataCopy({
      ...dataCopy,
      genres: value
    })
  }

  const handleDateChange = (date, attribute) => {
    setDataCopy({
      ...dataCopy,
      [attribute]: date
    })
  }

  const changeProjectNumberInfo = (values, attribute) => {
    setDataCopy({
      ...dataCopy,
      [attribute]: values.floatValue
    })
  }

  const handleSelectChange = (event, attribute) => {
    setDataCopy({
      ...dataCopy,
      [attribute]: event.target.value
    })
  }

  const handleCheckboxChange = name => event => {
    setDataCopy({
      ...dataCopy,
      [name]: event.target.checked
    })
  }

  const handlePlatformChange = (event, value) => {
    setDataCopy({
      ...dataCopy,
      platforms: value
    })
  }

  const handleAutoCompleteChange = (event, value, attribute) => {
    setDataCopy({
      ...dataCopy,
      [attribute]: value
    })
  }

  const onFlagValueChanged = (event, attribute, property, enumFlags) => {
    let roles = 0

    attribute.forEach((attr) => {
      roles = roles | enumFlags[attr]
    })

    setDataCopy({
      ...dataCopy,
      [property]: roles
    })
  }

  const handleNext = (event) => {
    if (activeStep === steps.length) { return }

    setActiveStep(activeStep + 1)
    if (activeStep >= completedStep) { setCompletedStep(activeStep) }
  }

  const handleBack = (event) => {
    if (activeStep === 0) { return }

    setActiveStep(activeStep - 1)
  }

  const handleStepChange = (event, step) => {
    if (completedStep < step) { return }

    setActiveStep(step)
  }

  const passNeeded = 3
  let passCount = 0
  if (dataCopy.documentationInNorwegianOrSami) {
    passCount++
  }
  if (dataCopy.relevantForNorwegianAudience) {
    passCount++
  }
  if (dataCopy.mainPlotIsInNorwayOrEUOrFantasy) {
    passCount++
  }
  if (dataCopy.containsNorwegianOriginalScore) {
    passCount++
  }

  const passedCultureTest = passCount >= passNeeded
  const availablePlatforms = metadata.platforms.filter(x => !dataCopy.platforms.find(y => y.id === x.id))

  return (
        <div className={classes.root}>
            <Backdrop className={classes.backdrop} open={creatingProject}>
                <CircularProgress color="inherit"/>
            </Backdrop>
            <CultureTestDialog cultureTestOpen={cultureTestOpen}
                               setCultureTestOpen={setCultureTestOpen}
                               dataCopy={dataCopy}
                               setDataCopy={setDataCopy}
                               setCompletedCultureTest={setCompletedCultureTest}
                               passedCultureTest={passedCultureTest}
            />
          <Grid container spacing={3} justifyContent={'center'}>
            <Grid item xs={12}>
              <Typography variant={'body2'}>
                {'Oppretter et nytt prosjekt'}
              </Typography>
              <Typography variant={'h4'}>
                {dataCopy.name.length > 0 ? dataCopy.name : 'Uten navn'}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Stepper alternativeLabel
                       activeStep={activeStep}
                       connector={<ColorlibConnector/>}
              >
                {steps.map((label, index) => (
                    <Step key={label}
                          onClick={(event) => handleStepChange(event, index)}
                          disabled={index > completedStep}
                          style={{ cursor: 'pointer' }}
                    >
                      <StepLabel StepIconComponent={ColorlibStepIcon}>
                        {label}
                      </StepLabel>
                    </Step>
                ))}
              </Stepper>
            </Grid>
            <Grid item xs={12}>
              {
                activeStep === 0 &&
                <Grid container spacing={2} justifyContent={'center'}>
                  <Grid item xs={12} sm={7}>
                    <Card>
                      <CardHeader
                          title={'Generelt'}
                          subheader={'Spillprosjektet er kun synlig for deg og din organisasjon'}
                      />
                      <CardContent>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <TextField
                                id="name"
                                name="name"
                                autoComplete='off'
                                value={dataCopy.name}
                                onChange={handleTextInputChange}
                                type="text"
                                label={'Har spillet et navn? (Du kan bruke kodenavn)'}
                                fullWidth
                                variant={'filled'}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <FormControl fullWidth variant={'filled'}>
                              <InputLabel id="stage">Hvor langt på vei er spillet?</InputLabel>
                              <Select
                                  id="stage"
                                  label={'Hvor langt på vei er spillet?'}
                                  value={dataCopy.stage}
                                  onChange={(event) => handleSelectChange(event, 'stage')}
                              >
                                {
                                  Object.keys(projectStageEnums).map((projectStageType, index) => (
                                      <MenuItem value={projectStageEnums[projectStageType]}
                                                key={projectStageEnums[projectStageType]}>
                                        {intl.formatMessage({ id: projectStageType })}
                                      </MenuItem>
                                  ))
                                }
                              </Select>
                            </FormControl>
                          </Grid>
                        </Grid>
                      </CardContent>
                      <CardActions style={{ display: 'flex' }}>
                        <Button variant={'contained'}
                                fullWidth
                                disabled={dataCopy.name.length === 0 || dataCopy.stage === 0}
                                onClick={handleNext}
                        >
                          <FormattedMessage
                              id={'Global.NextStep'}
                              defaultMessage={'Neste steg'}
                          />
                        </Button>
                      </CardActions>
                    </Card>
                  </Grid>
                </Grid>
              }
              {
                activeStep === 1 &&
                <Grid container spacing={2} justifyContent={'center'}>
                  <Grid item xs={12} sm={7}>
                    <Card>
                      <CardHeader
                          title={'Fakta'}
                          subheader={'Spillprosjektet er kun synlig for deg og din organisasjon'}
                      />
                      <CardContent>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <Typography variant={'h5'}>
                              Beskrivelse, sjanger og spillmoduser
                            </Typography>
                            <Divider/>
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                                id="description"
                                name="description"
                                value={dataCopy.description}
                                onChange={handleTextInputChange}
                                type="text"
                                label={'Her må du gjerne skrive eller lime inn en beskrivelse'}
                                fullWidth
                                variant={'filled'}
                                multiline
                                rows={5}
                            />
                          </Grid>
                          <Grid item xs={8}>
                            <Autocomplete
                                multiple
                                id="genres"
                                options={genres.sort((a, b) => a.title.localeCompare(b.title))}
                                filterSelectedOptions
                                disableCloseOnSelect
                                onChange={handleGenreChange}
                                getOptionLabel={option => option.title}
                                value={dataCopy.genres}
                                renderTags={(value, getTagProps) =>
                                    value.map((option, index) => (
                                        <Chip key={index} label={option.title} {...getTagProps({ index })} />
                                    ))
                                }
                                style={{ width: '100%' }}
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        variant="filled"
                                        label={'Hvilke sjangre har spillet?'}
                                        placeholder=""
                                        fullWidth
                                    />
                                )}
                            />
                          </Grid>
                          <Grid item xs={12} sm={8}>
                            <Autocomplete
                                multiple
                                id="gamePlayMode"
                                options={Object.keys(gamePlayModeEnum).map((key, index) => key)}
                                filterSelectedOptions
                                disableCloseOnSelect
                                onChange={(event, attributes) => onFlagValueChanged(event, attributes, 'gamePlayMode', gamePlayModeEnum)}
                                getOptionLabel={option => {
                                  return intl.formatMessage({ id: option })
                                }}
                                renderOption={(props, option, { selected }) => (
                                    <li {...props}>
                                      <Checkbox
                                          icon={icon}
                                          checkedIcon={checkedIcon}
                                          style={{ marginRight: 8 }}
                                          checked={selected}
                                      />
                                      {intl.formatMessage({ id: option })}
                                    </li>
                                )}
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        fullWidth
                                        label="Hvilke spillermoduser har spillet?"
                                        variant="filled"
                                    />
                                )}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Typography variant={'h5'}>
                              Kulturtesten
                            </Typography>
                            <Divider/>
                          </Grid>
                          <Grid item xs={12}>
                            {
                              !completedCultureTest &&
                              <Typography variant={'body2'} gutterBottom>
                                {'Du har ikke tatt kulturtesten enda, trykk på knappen for å starte'}
                              </Typography>
                            }
                            {
                              completedCultureTest &&
                              <Typography variant={'body2'} gutterBottom>
                                {passedCultureTest ? 'Du har bestått kulturtesten' : 'Ikke bestått kulturtesten'}
                              </Typography>
                            }
                            <Grid item xs={12}>
                              <Button variant={'contained'}
                                      style={{ margin: '10px auto' }}
                                      onClick={event => {
                                        setActiveCultureTestStep(0)
                                        setCultureTestOpen(true)
                                      }}
                              >
                                Ta kulturtesten
                              </Button>
                            </Grid>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography variant={'h5'}>
                              Rettigheter, formål og rolle
                            </Typography>
                            <Divider/>
                          </Grid>
                          <Grid item xs={12}>
                            <FormControlLabel
                                control={
                                  <Checkbox
                                      checked={dataCopy.rightsToIp ?? false}
                                      onChange={handleCheckboxChange('rightsToIp')}
                                      value={'rightsToIp'}
                                      color="primary"
                                  />
                                }
                                label="Har du rettighetene til spillets IP?"
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <FormControl fullWidth variant={'filled'}>
                              <InputLabel id="gamePurpose">Spillets formål</InputLabel>
                              <Select
                                  label="Spillets formål"
                                  id="gamePurpose"
                                  value={dataCopy.gamePurpose}
                                  onChange={(event) => handleSelectChange(event, 'gamePurpose')}
                              >
                                {
                                  Object.keys(gamePurposeEnum).map((key, index) => (
                                      <MenuItem value={gamePurposeEnum[key]}
                                                key={gamePurposeEnum[key]}>
                                        {intl.formatMessage({ id: key })}
                                      </MenuItem>
                                  ))
                                }
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12}>
                            <Autocomplete
                                multiple
                                id="field1"
                                options={Object.keys(companyRoleEnum).map((key, index) => key)}
                                disableCloseOnSelect
                                onChange={(event, attributes) => onFlagValueChanged(event, attributes, 'companyRole', companyRoleEnum)}
                                getOptionLabel={option => {
                                  return intl.formatMessage({ id: option })
                                }}
                                renderOption={(props, option, { selected }) => (
                                    <li {...props}>
                                      <Checkbox
                                          icon={icon}
                                          checkedIcon={checkedIcon}
                                          style={{ marginRight: 8 }}
                                          checked={selected}
                                      />
                                      {intl.formatMessage({ id: option })}
                                    </li>
                                )}
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        fullWidth
                                        label="Hva er selskapets rolle i prosjektet?"
                                        variant="filled"
                                    />
                                )}
                            />
                          </Grid>
                        </Grid>
                      </CardContent>
                      <CardActions style={{ display: 'flex' }}>
                        <Button variant={'outlined'}
                                fullWidth
                                onClick={handleBack}
                        >
                          <FormattedMessage
                              id={'Global.PreviousStep'}
                              defaultMessage={'Tilbake'}
                          />
                        </Button>
                        <Button variant={'contained'}
                                fullWidth
                                onClick={handleNext}
                        >
                          <FormattedMessage
                              id={'Global.NextStep'}
                              defaultMessage={'Neste steg'}
                          />
                        </Button>
                      </CardActions>
                    </Card>
                  </Grid>
                </Grid>
              }
              {
                activeStep === 2 &&
                <Grid container spacing={2} justifyContent={'center'}>
                  <Grid item xs={12} sm={7}>
                    <Card>
                      <CardHeader
                          title={'Økonomi'}
                          subheader={'Budsjetter og forretningsmodeller.'}
                      />
                      <CardContent>
                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <NumberFormat value={dataCopy.productionBudget}
                                          displayType={'input'}
                                          fullWidth
                                          variant={'filled'}
                                          thousandSeparator={' '}
                                          prefix={'NOK '}
                                          id='productionBudget'
                                          label={'Produksjonsbudsjett'}
                                          onValueChange={(values) => changeProjectNumberInfo(values, 'productionBudget')}
                                          customInput={TextField}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <NumberFormat value={dataCopy.marketingBudget}
                                          displayType={'input'}
                                          thousandSeparator={' '}
                                          fullWidth
                                          variant={'filled'}
                                          prefix={'NOK '}
                                          label={'Markedsføringsbudjett'}
                                          id='marketingBudget'
                                          onValueChange={(values) => changeProjectNumberInfo(values, 'marketingBudget')}
                                          customInput={TextField}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Autocomplete
                                multiple
                                id="businessModel"
                                options={Object.keys(gameBusinessModelEnum).map((key, index) => key)}
                                disableCloseOnSelect
                                onChange={(event, attributes) => onFlagValueChanged(event, attributes, 'businessModel', gameBusinessModelEnum)}
                                getOptionLabel={option => {
                                  return intl.formatMessage({ id: option })
                                }}
                                renderOption={(props, option, { selected }) => (
                                    <li {...props}>
                                      <Checkbox
                                          icon={icon}
                                          checkedIcon={checkedIcon}
                                          style={{ marginRight: 8 }}
                                          checked={selected}
                                      />
                                      {intl.formatMessage({ id: option })}
                                    </li>
                                )}
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        fullWidth
                                        label="Hvilke forretningsmodeller har spillet?"
                                        variant="filled"
                                    />
                                )}
                            />
                          </Grid>
                        </Grid>
                      </CardContent>
                      <CardActions style={{ display: 'flex' }}>
                        <Button variant={'outlined'}
                                fullWidth
                                onClick={handleBack}
                        >
                          <FormattedMessage
                              id={'Global.PreviousStep'}
                              defaultMessage={'Tilbake'}
                          />
                        </Button>
                        <Button variant={'contained'}
                                fullWidth
                                onClick={handleNext}
                        >
                          <FormattedMessage
                              id={'Global.NextStep'}
                              defaultMessage={'Neste steg'}
                          />
                        </Button>
                      </CardActions>
                    </Card>
                  </Grid>
                </Grid>
              }
              {
                activeStep === 3 &&
                <Grid container spacing={2} justifyContent={'center'}>
                  <Grid item xs={12} sm={7}>
                    <Card>
                      <CardHeader
                          title={'Utgivelse'}
                          subheader={'Spillprosjektet er kun synlig for deg og din organisasjon.'}
                      />
                      <CardContent>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <FormControl fullWidth variant={'filled'}>
                              <InputLabel id="pegiRating">Spillets PEGI-merking eller forventede
                                PEGI-merking</InputLabel>
                              <Select
                                  label="Spillets PEGI-merking eller forventede PEGI-merking"
                                  id="pegiRating"
                                  value={dataCopy.pegiRating}
                                  onChange={(event) => handleSelectChange(event, 'pegiRating')}
                              >
                                {
                                  Object.keys(pegiRatingEnum).map((key, index) => (
                                      <MenuItem value={pegiRatingEnum[key]}
                                                key={pegiRatingEnum[key]}>
                                        {intl.formatMessage({ id: key })}
                                      </MenuItem>
                                  ))
                                }
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12}>
                            <DatePicker
                                clearable
                                openTo="year"
                                label={'Har spillet en lanseringsdato?'}
                                views={['year', 'month', 'day']}
                                id="releaseDate"
                                value={dataCopy.releaseDate ? moment(dataCopy.releaseDate) : null}
                                onChange={(date) => handleDateChange(date, 'releaseDate')}
                                renderInput={(params) => <TextField fullWidth variant={"outlined"} {...params} helperText={null} />}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Autocomplete
                                multiple
                                id="platforms"
                                options={availablePlatforms}
                                filterSelectedOptions
                                disableCloseOnSelect
                                onChange={handlePlatformChange}
                                getOptionLabel={option => option.name}
                                value={dataCopy.platforms}
                                style={{ width: '100%' }}
                                renderOption={(props, option, { selected }) => (
                                    <li {...props}>
                                      <Checkbox
                                          icon={icon}
                                          checkedIcon={checkedIcon}
                                          style={{ marginRight: 8 }}
                                          checked={selected}
                                      />
                                      {option.name}
                                    </li>
                                )}
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        variant="filled"
                                        label={'Plattformer'}
                                        placeholder=""
                                        fullWidth
                                    />
                                )}
                                renderTags={(value, getTagProps) =>
                                    value.map((option, index) => (
                                        <Chip key={index} label={option.name} {...getTagProps({ index })} />
                                    ))
                                }
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Autocomplete
                                multiple
                                id="availableInLanguages"
                                options={metadata.languages.map(x => x.name)}
                                filterSelectedOptions
                                disableCloseOnSelect
                                onChange={(event, value) => handleAutoCompleteChange(event, value, 'availableInLanguages')}
                                getOptionLabel={option => option}
                                value={dataCopy.availableInLanguages}
                                renderOption={(props, option, { selected }) => (
                                    <li {...props}>
                                      <Checkbox
                                          icon={icon}
                                          checkedIcon={checkedIcon}
                                          style={{ marginRight: 8 }}
                                          checked={selected}
                                      />
                                      {intl.formatMessage({ id: option })}
                                    </li>
                                )}
                                style={{ width: '100%' }}
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        variant="filled"
                                        label={'Hvilke språk blir spillet tilgjengelig på?'}
                                        fullWidth
                                    />
                                )}
                            />
                          </Grid>
                        </Grid>
                      </CardContent>
                      <CardActions style={{ display: 'flex' }}>
                        <Button variant={'outlined'}
                                fullWidth
                                onClick={handleBack}
                        >
                          <FormattedMessage
                              id={'Global.PreviousStep'}
                              defaultMessage={'Tilbake'}
                          />
                        </Button>
                        <Button variant={'contained'}
                                fullWidth
                                onClick={createProjectClicked}
                        >
                          <FormattedMessage
                              id={'Project.CreateProjectButton'}
                              defaultMessage={'Opprett spillprosjekt'}
                          />
                        </Button>
                      </CardActions>
                    </Card>
                  </Grid>
                </Grid>
              }
            </Grid>
          </Grid>
        </div>
  )
}

const mapStateToProps = (state) => {
  return {
    error: state.project.error,
    yourOrganizations: state.organization.yourOrganizations,
    yourProjects: state.project.yourProjects,
    creatingProject: state.project.creatingProject,
    userInfo: state.user.userInfo,
    genres: state.project.genres,
    metadata: state.metadata.metadata
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getYourProjects: (organizationId) => dispatch(getYourProjects(organizationId)),
    createProject: (newProject) => dispatch(createProject(newProject)),
    getGenres: () => dispatch(getGenres())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(NewProjectPage))
