import React, { useEffect, useState } from 'react'

import Grid from '@mui/material/Grid'
import {ListSubheader, Stack} from '@mui/material'
import { makeStyles } from '@mui/styles'
import Typography from '@mui/material/Typography'
import Card from '@mui/material/Card'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import CardHeader from '@mui/material/CardHeader'
import { connect, shallowEqual, useSelector } from 'react-redux'
import { withRouter } from 'react-router'
import Chip from '@mui/material/Chip'
import Autocomplete from '@mui/material/Autocomplete'
import CardContent from '@mui/material/CardContent'
import moment from 'moment'
import DatePicker from '@mui/lab/DatePicker';
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Checkbox from '@mui/material/Checkbox'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import {FormattedMessage, useIntl} from 'react-intl'
import { uniq } from 'lodash'
import { financeOptionOrgforms, financeOptionSectors, financeOptionTypes } from '../constants/financeOptionEnums'
import FormHelperText from '@mui/material/FormHelperText'
import PersonIcon from '@mui/icons-material/Person'
import EmailIcon from '@mui/icons-material/Email'
import PhoneIcon from '@mui/icons-material/Phone'
import InputAdornment from '@mui/material/InputAdornment'
import LinkIcon from '@mui/icons-material/Link'
import OutlinedInput from '@mui/material/OutlinedInput'
import IconButton from '@mui/material/IconButton'
import DeleteIcon from '@mui/icons-material/Delete'
import {
  createFinanceOptionDeadline,
  deleteFinanceOption,
  deleteFinanceOptionDeadline,
  getSubscriptions,
  getFinanceOptions,
  patchFinanceOption,
  subscriptionChange,
  updateFinanceOptionDeadline,
  deleteResource,
  uploadFinanceOptionGrants, getFinanceOptionGrants
} from '../store/actions/financeOptionActions'
import { getYourOrganizations } from '../store/actions/organizationActions'
import CardActions from '@mui/material/CardActions'
import jsonpatch from 'fast-json-patch'
import AppBar from '@mui/material/AppBar'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import SaveUnsavedChangesPopup from '../components/SaveUnsavedChangesPopup'
import Slider from '@mui/material/Slider'
import {
  companyRoleEnum,
  gameBusinessModelEnum,
  gamePlayModeEnum,
  gamePurposeEnum, pegiRatingEnum,
  projectStageEnums
} from '../constants/projectEnums'
import FormControlLabel from '@mui/material/FormControlLabel'
import NumberFormat from 'react-number-format'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogActions from '@mui/material/DialogActions'
import Dialog from '@mui/material/Dialog'
import { flagToArray } from '../constants/helpers'
import Avatar from '@mui/material/Avatar'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'
import LanguageIcon from '@mui/icons-material/Language'
import ListItemText from '@mui/material/ListItemText'
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction'
import GetAppIcon from '@mui/icons-material/GetApp'
import NewFinanceOptionResourceDialog from '../components/NewFinanceOptionResourceDialog'
import { CSVReader } from 'react-papaparse'
import currency from 'currency.js'
import {DataGrid} from "@mui/x-data-grid";
import GrantsTemplate from "../assets/Grants - Template.xlsx";
import {ReactComponent as ExcelIcon} from "../assets/excel-icon.svg";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
const checkedIcon = <CheckBoxIcon fontSize="small" />

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: '50px',
    padding: '20px'
  },
  sliderContainer: {
    margin: '40px 25px 25px 25px',
  },
  adminBar: {
    position: 'fixed',
    top: '64px',
    height: '50px',
    left: '0px',
    right: '0px',
    background: theme.palette.background.default,
    zIndex: 1300,
    boxShadow: '0px 10px 10px rgba(0, 0, 0, 0.1)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0px 15px 0px 25px',
    [theme.breakpoints.down('xs')]: {
      left: '0px'
    }
  },
  actionButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'space-evenly'
    }
  },
  actionButton: {
    margin: '5px',
    textDecoration: 'none'
  }
}))

const EditFundingPage = (props) => {
  const {
    yourOrganizations, error, fetchingFinanceOptions, creatingFinanceOption, creatingDeadline,
    deletingDeadline, userInfo, financeOptionsSubscriptions, subscriptionChanging, subscriptionFetching, metadata, patchingFinanceOption, platforms, uploadingResource, deletingResource
  } = props
  const classes = useStyles()
  const intl = useIntl()
  const currentDate = new Date()

  const financeOptions = useSelector(state => state.financeOption.financeOptions, shallowEqual)
  const [value, setValue] = useState(0)
  const [deleteOpen, setDeleteOpen] = useState(false)
  const [companyMinMax, setCompanyMinMax] = useState([0, 100])
  const [maxFundingOfTotalProjectBudget, setMaxFundingOfTotalProjectBudget] = useState([0])
  const [deleteDialogueFinanceOptionName, setDeleteDialogueFinanceOptionName] = useState('')
  const [newDeadline, setNewDeadline] = useState(null)
  const [newResourceDialogOpen, setNewResourceDialogOpen] = useState(false)
  const [grantsForUpload, setGrantsForUpload] = useState([])
  const [grantsWithErrors, setGrantsWithErrors] = useState([])
  const [newDeadlineModalOpen, setNewDeadlineModalOpen] = useState(false)
  const [newDeadlineDate, setNewDeadlineDate] = useState(new Date())

  const [dataCopy, setDataCopy] = useState({
    id: '',
    name: '',
    organization: {
      id: -1
    },
    description: '',
    descriptionReadMoreUrl: '',
    deadlines: [],
    type: 0,
    sector: 0,
    orgForm: 0,
    contactName: '',
    website: '',
    counties: [],
    companyAgeMin: 0,
    companyAgeMax: 100,

    projectBudgetMin: 0,
    projectBudgetMax: 50000000,
    maximumFundingOfTotalProjectBudget: 0,

    hasToPassCultureTest: false,
    stage: '0',
    pegiRating: 0,
    esrbRating: 0,
    gamePurpose: 0,
    companyRole: 0,
    gamePlayMode: 0,
    businessModel: 0,
    hasRightsToIp: null,
    availableInLanguages: [],
    platforms: [],
    resources: [],
    contactEmail: ''
  })
  const [dataBackup, setDataBackup] = useState({
    id: '',
    name: '',
    organization: {
      id: -1
    },
    description: '',
    descriptionReadMoreUrl: '',
    deadlines: [],
    type: 0,
    sector: 0,
    orgForm: 0,
    contactName: '',
    website: '',
    counties: [],
    companyAgeMin: 0,
    companyAgeMax: 100,

    projectBudgetMin: 0,
    projectBudgetMax: 50000000,
    maximumFundingOfTotalProjectBudget: 0,

    hasToPassCultureTest: false,
    stage: '0',
    pegiRating: 0,
    esrbRating: 0,
    gamePurpose: 0,
    companyRole: 0,
    gamePlayMode: 0,
    businessModel: 0,
    hasRightsToIp: null,
    availableInLanguages: [],
    platforms: [],
    resources: [],
    contactEmail: ''
  })

  useEffect(() => {
    props.getFinanceOptions()
    props.getFinanceOptionGrants(props.match.params.id)
    setDataCopy({
      ...dataCopy,
      organizationId: userInfo.currentlyActiveOrganization
    })
  }, [])

  useEffect(() => {
    const financeOptionId = props.match.params.id
    if (financeOptionId != null) {
      const currentFinanceOption = financeOptions.find(x => parseInt(x.id) === parseInt(financeOptionId))

      if (currentFinanceOption != null) {
        setDataCopy({ ...dataCopy, ...currentFinanceOption })
        setDataBackup({ ...dataCopy, ...currentFinanceOption })
        setCompanyMinMax([currentFinanceOption.companyAgeMin, currentFinanceOption.companyAgeMax])
        setMaxFundingOfTotalProjectBudget([currentFinanceOption.maximumFundingOfTotalProjectBudget])
      }
    }
  }, [financeOptions, creatingDeadline, deletingDeadline, uploadingResource, deletingResource])

  const saveChangesClicked = (event) => {
    const financeOptionId = props.match.params.id
    if (financeOptionId != null) {
      const original = financeOptions.find(x => parseInt(x.id) === parseInt(financeOptionId))

      if (original != null) {
        const patch = jsonpatch.compare(original, dataCopy)
        props.patchFinanceOption(patch, financeOptionId)

        setDataBackup(dataCopy)
      }
    }
  }

  const handleInputChange = (event) => {
    setDataCopy({
      ...dataCopy,
      [event.target.id]: event.target.value
    })
  }

  const handleAutoCompleteChange = (event, value, attribute) => {
    setDataCopy({
      ...dataCopy,
      [attribute]: value
    })
  }

  const handleDateChange = (date, attribute) => {
    setDataCopy({
      ...dataCopy,
      [attribute]: date
    })
  }

  const changeProjectNumberInfo = (values, attribute) => {
    setDataCopy({
      ...dataCopy,
      [attribute]: values.floatValue
    })
  }

  const handleSelectChange = (event, attribute) => {
    setDataCopy({
      ...dataCopy,
      [attribute]: event.target.value
    })
  }

  const handleRangeSliderChange = (minAttribute, maxAttribute, minValue, maxValue) => {
    setDataCopy({
      ...dataCopy,
      [minAttribute]: minValue,
      [maxAttribute]: maxValue
    })
  }

  const handleChange = (attribute, newValue) => {
    setDataCopy({
      ...dataCopy,
      [attribute]: newValue[0]
    })
  }

  const handlePlatformChange = (event, value) => {
    setDataCopy({
      ...dataCopy,
      platforms: value
    })
  }

  const handleCheckboxChange = name => event => {
    setDataCopy({
      ...dataCopy,
      [name]: event.target.checked
    })
  }

  const deleteFinanceOptionClicked = (event) => {
    const id = props.match.params.id

    if (id) { props.deleteFinanceOption(id) }
  }

  const onFlagValueChanged = (event, attribute, property, enumFlags) => {
    let roles = 0

    attribute.forEach((attr) => {
      roles = roles | enumFlags[attr]
    })

    setDataCopy({
      ...dataCopy,
      [property]: roles
    })
  }

  function deleteResourceClicked (event, resource) {
    const deleteBody = {
      resourceId: resource.id,
      fromProject: true,
      fromOrganization: true
    }

    props.deleteResource(deleteBody)
  }

  const handleOnDrop = (data) => {
    let newGrantsWithErrors = [];
    let newGrantsToUpload = [];

    data.forEach((row, index) => {
      let errors = [];
      const sum = currency(row.data.Sum.replace(/\s/g, ''), { precision: 0, separator: '', decimal: ',', symbol: '' }).format();
      
      if (!row.data.Produksjonsselskap || row.data.Produksjonsselskap.length === 0) {
        errors.push({column: 'Produksjonsselskap', error: "Feil verdi"})
      }
      if (!row.data.Prosjekt || row.data.Prosjekt.length === 0) {
        errors.push({column: 'Prosjekt', error: "Feil verdi"})
      }
      if (!sum || sum <= 0) {
        errors.push({column: 'Sum', error: "Feil verdi"})
      }
      if (!row.data.Dato || row.data.Dato.length === 0 || !new Date(row.data.Dato)) {
        errors.push({column: 'Dato', error: "Feil verdi"})
      }
      if (props.grants.findIndex(x => x.companyName === row.data.Produksjonsselskap &&
          x.projectName === row.data.Prosjekt &&
          moment.utc(x.fundingDateTime).format('DD.MM.YYYY') === moment.utc(row.data.Dato, 'DD.MM.YYYY').format('DD.MM.YYYY') &&
          x.amount === parseInt(sum)) >= 0) {
        errors.push({column: 'all', error: 'Already exists'})
      }
      
      newGrantsWithErrors.push({
        id: index,
        ...row.data,
        errors: errors,
        isSuccess: errors.length === 0,
      });
      
      if (errors.length === 0) {
        newGrantsToUpload.push({
          companyName: row.data.Produksjonsselskap,
          projectName: row.data.Prosjekt,
          fase: row.data.Fase ?? '',
          producer: row.data.Produsent ?? '',
          director: row.data.Regi ?? '',
          writer: row.data.Manus ?? '',
          amount: currency(row.data.Sum.replace(/\s/g, ''), { precision: 0, separator: '', decimal: ',', symbol: '' }).format(),
          fundingDateTime: moment.utc(row.data.Dato, 'DD.MM.YYYY').format(),
          financeOptionId: props.match.params.id
        })
      }
    })

    setGrantsForUpload(newGrantsToUpload)
    setGrantsWithErrors(newGrantsWithErrors)
  }

  const handleOnError = (err, file, inputElem, reason) => {
    console.log(err)
  }

  const handleOnRemoveFile = (data) => {
  }
  
  function newDeadlineClicked (event) {
    setNewDeadlineModalOpen(true);
  }
  
  function addNewDeadlineClicked (event) {
    const deadline = {
      financeOptionId: dataCopy.id,
      deadline: newDeadlineDate
    }
    
    props.createFinanceOptionDeadline(deadline)
  }

  const uploadGrants = (event) => {
    const body = {
      financeOptionId: props.match.params.id,
      grants: grantsForUpload,
    }
    
    props.uploadFinanceOptionGrants(body)
  }

  const gamePlayModes = flagToArray(dataCopy.gamePlayMode, gamePlayModeEnum)
  const gameBusinessModels = flagToArray(dataCopy.businessModel, gameBusinessModelEnum)
  const companyRoles = flagToArray(dataCopy.companyRole, companyRoleEnum)
  const gamePurposes = flagToArray(dataCopy.gamePurpose, gamePurposeEnum)
  const stages = flagToArray(dataCopy.stage, projectStageEnums)
  const pegiRatings = flagToArray(dataCopy.pegiRating, pegiRatingEnum)

  const deadlinesSortedAndFiltered = dataCopy.deadlines
      .sort((a, b) => moment(a.deadline) - moment(b.deadline))

  const changes = jsonpatch.compare(dataCopy, dataBackup)

  const officialResources = dataCopy.resources.filter(x => x.isOfficial)
  const nonOfficialResources = dataCopy.resources.filter(x => !x.isOfficial)

  return (
      <div className={classes.root}>
        <SaveUnsavedChangesPopup show={changes.length > 0} onSaveClicked={() => saveChangesClicked(null)}/>
        <Dialog open={newDeadlineModalOpen} onClose={event => setNewDeadlineModalOpen(false)}>
          <DialogTitle id="form-dialog-title">
            <FormattedMessage
                id={'FinanceOption.NewDeadlineModalTitle'}
                defaultMessage={'Legg til ny søknadsfrist'}
            />
          </DialogTitle>
          <DialogContent>
            <DatePicker
                openTo="year"
                views={['year', 'month', 'day']}
                value={newDeadlineDate}
                onChange={(date) => setNewDeadlineDate(date)}
                renderInput={(params) => <TextField fullWidth variant={"outlined"} {...params} helperText={'Åpen informasjon'} />}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={addNewDeadlineClicked}>
              <FormattedMessage
                  id={'FinanceOption.NewDeadlineModalAddButton'}
                  defaultMessage={'Legg til'}
              />
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={deleteOpen} onClose={(event) => setDeleteOpen(false)} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Slett finansieringskilde</DialogTitle>
          <DialogContent>
            <DialogContentText>
              <FormattedMessage
                  id={'FinanceOption.ReallyDelete'}
                  defaultMessage={'Om du virkelig ønsker å slette finansieringskilden, skriv navnet og trykk "Slett"'}
              />
            </DialogContentText>
            <TextField
                autoFocus
                margin="dense"
                id="projectName"
                label="Finansieringsnavn"
                type="name"
                onChange={(event) => setDeleteDialogueFinanceOptionName(event.target.value)}
                value={deleteDialogueFinanceOptionName}
                fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={(event) => setDeleteOpen(false)} color="primary">
              <FormattedMessage
                  id={'Global.Regret'}
                  defaultMessage={'Angre'}
              />
            </Button>
            <Button
                onClick={deleteFinanceOptionClicked}
                color="primary"
                disabled={deleteDialogueFinanceOptionName.toLowerCase() !== dataCopy.name.toLowerCase()}
            >
              <FormattedMessage
                  id={'Global.Delete'}
                  defaultMessage={'Slett'}
              />
            </Button>
          </DialogActions>
        </Dialog>
        <div className={classes.adminBar}>
          <Typography variant={'body2'} style={{
            background: '#E43030',
            color: '#FFF',
            padding: '5px 10px',
            userSelect: 'none',
            borderRadius: '5px'
          }}>
            <FormattedMessage
                id={'Global.YouAreEditing'}
                defaultMessage={'Gjør endringer'}
            />
          </Typography>
          <Button className={classes.actionButton}
                  variant={'contained'}
                  onClick={(event) => {
                    if (changes.length > 0) {
                      if (window.confirm('Du har ulagrede endringer, er du sikker på at du vil gå tilbake uten å lagre?')) {
                        window.history.back()
                      }
                    } else {
                      window.history.back()
                    }
                  }}>
            <FormattedMessage
                id={'Global.EndEdit'}
                defaultMessage={'Avslutt redigering'}
            />
          </Button>
        </div>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={8}>
            <Typography variant={'h4'}>
              {dataCopy.name}
            </Typography>
          </Grid>
        </Grid>
        <AppBar position="static" style={{ marginTop: '20px' }}>
          <Tabs
              value={value}
              onChange={(event, value) => setValue(value)}
          >
            <Tab
                label={
                  <FormattedMessage
                      id="FinanceOption.Information"
                      defaultMessage={'Informasjon'}
                  />
                }
                tabIndex={0}/>
            <Tab
                label={
                  <FormattedMessage
                      id="FinanceOption.Criteria"
                      defaultMessage={'Kriterier'}
                  />
                }
                tabIndex={1}/>
            <Tab
                label={
                  <FormattedMessage
                      id="FinanceOption.Deadlines"
                      defaultMessage={'Frister'}
                  />
                }
                tabIndex={2}/>
            <Tab
                label={
                  <FormattedMessage
                      id="FinanceOption.ResourcesTab"
                      defaultMessage={'Ressurser'}
                  />
                }
                tabIndex={3}/>
            <Tab
                label={
                  <FormattedMessage
                      id="FinanceOption.Grants"
                      defaultMessage={'Tilskudd'}
                  />
                }
                tabIndex={4}/>
          </Tabs>
        </AppBar>
        {
          value === 0 &&
          <div style={{ padding: '20px 0px' }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={7}>
                <Card>
                  <CardHeader
                      title={'Om finansieringskilde'}
                  />
                  <CardContent>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <TextField
                            id="name"
                            name="name"
                            value={dataCopy.name}
                            onChange={handleInputChange}
                            type="text"
                            label={'Hva heter din finansieringskilde?'}
                            fullWidth
                            variant={'outlined'}
                            helperText="Åpen informasjon"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                            id="description"
                            name="description"
                            value={dataCopy.description}
                            onChange={handleInputChange}
                            type="text"
                            label={'Beskrivelse'}
                            fullWidth
                            rows={4}
                            variant={'outlined'}
                            multiline
                            helperText="Åpen informasjon"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl fullWidth variant="outlined">
                          <InputLabel htmlFor="descriptionReadMoreUrl">Lenke for å lese mer</InputLabel>
                          <OutlinedInput
                              id="descriptionReadMoreUrl"
                              type="text"
                              label={'Lenke for å lese mer'}
                              onChange={handleInputChange}
                              value={dataCopy.descriptionReadMoreUrl}
                              startAdornment={<InputAdornment
                                  position="start"><LinkIcon/></InputAdornment>}
                          />
                          <FormHelperText>
                            Åpen informasjon
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </CardContent>
                  <CardActions>
                    <Button color={'primary'}
                            variant={'outlined'}
                            onClick={(event) => setDeleteOpen(true)}
                    >
                      <FormattedMessage
                          id="Global.DeleteFinancePage"
                          defaultMessage="Slett finansieringskilden"
                          description='Button to delete'
                      />
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
              <Grid item xs={12} sm={5}>
                <Card>
                  <CardHeader
                      title={'Fakta'}
                  />
                  <CardContent>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <FormControl fullWidth variant="outlined">
                          <InputLabel id={'type'}>
                            Type finansiering
                          </InputLabel>
                          <Select
                              labelId="type"
                              id="type"
                              label={'Type finansiering'}
                              value={dataCopy.type}
                              onChange={(event) => handleSelectChange(event, 'type')}
                          >
                            {
                              financeOptionTypes.map((item, index) => (
                                  <MenuItem value={item.enum}
                                            key={item.enum}>
                                    {intl.formatMessage({ id: financeOptionTypes.find(x => x.enum === item.enum).name })}
                                  </MenuItem>
                              ))
                            }
                          </Select>
                          <FormHelperText style={{ marginLeft: '14px' }}>
                            Åpen informasjon
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl fullWidth variant="outlined">
                          <InputLabel id={'sector'}>
                            Sektor for finansieringen
                          </InputLabel>
                          <Select
                              labelId="sector"
                              id="sector"
                              label={'Sektor for finansieringen'}
                              value={dataCopy.sector}
                              onChange={(event) => handleSelectChange(event, 'sector')}
                          >
                            {
                              financeOptionSectors.map((item, index) => (
                                  <MenuItem value={item.enum}
                                            key={item.enum}>
                                    {intl.formatMessage({ id: financeOptionSectors.find(x => x.enum === item.enum).name })}
                                  </MenuItem>
                              ))
                            }
                          </Select>
                          <FormHelperText style={{ marginLeft: '14px' }}>
                            Åpen informasjon
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl fullWidth variant="outlined">
                          <InputLabel id={'orgForm'}>
                            Finansieringen gjelder
                          </InputLabel>
                          <Select
                              labelId="orgForm"
                              label={'Finansieringen gjelder'}
                              id="orgForm"
                              value={dataCopy.orgForm}
                              onChange={(event) => handleSelectChange(event, 'orgForm')}
                          >
                            {
                              financeOptionOrgforms.map((item, index) => (
                                  <MenuItem value={item.enum}
                                            key={item.enum}>
                                    {intl.formatMessage({ id: financeOptionOrgforms.find(x => x.enum === item.enum).name })}
                                  </MenuItem>
                              ))
                            }
                          </Select>
                          <FormHelperText style={{ marginLeft: '14px' }}>
                            Åpen informasjon
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl fullWidth variant="outlined">
                          <InputLabel htmlFor="website">Kontaktperson</InputLabel>
                          <OutlinedInput
                              id="contactName"
                              type="text"
                              label={<FormattedMessage
                                  id="Global.ContactPerson"
                                  defaultMessage='Kontaktperson'
                                  description='Contact person'
                              />}
                              onChange={handleInputChange}
                              value={dataCopy.contactName}
                              startAdornment={<InputAdornment
                                  position="start"><PersonIcon/></InputAdornment>}
                          />
                          <FormHelperText style={{ marginLeft: '14px' }}>
                            Åpen informasjon
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid item xs={6}>
                        <FormControl fullWidth variant="outlined">
                          <InputLabel htmlFor="website">Kontaktepost</InputLabel>
                          <OutlinedInput
                              id="contactEmail"
                              type="email"
                              label={<FormattedMessage
                                  id="Global.ContactEmail"
                                  defaultMessage='Kontaktepost'
                              />}
                              onChange={handleInputChange}
                              value={dataCopy.contactEmail}
                              startAdornment={<InputAdornment
                                  position="start"><EmailIcon/></InputAdornment>}
                          />
                          <FormHelperText style={{ marginLeft: '14px' }}>
                            Åpen informasjon
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid item xs={6}>
                        <FormControl fullWidth variant="outlined">
                          <InputLabel htmlFor="website">Kontakttelefon</InputLabel>
                          <OutlinedInput
                              id="contactPhone"
                              type="tel"
                              label={<FormattedMessage
                                  id="Global.ContactPhone"
                                  defaultMessage='Kontakttelefon'
                              />}
                              onChange={handleInputChange}
                              value={dataCopy.contactPhone}
                              startAdornment={<InputAdornment
                                  position="start"><PhoneIcon/></InputAdornment>}
                          />
                          <FormHelperText style={{ marginLeft: '14px' }}>
                            Åpen informasjon
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl fullWidth variant="outlined">
                          <InputLabel htmlFor="website">Lenke til siden man søker</InputLabel>
                          <OutlinedInput
                              id="website"
                              type="text"
                              label={'Lenke til siden man søker'}
                              onChange={handleInputChange}
                              value={dataCopy.website}
                              startAdornment={<InputAdornment
                                  position="start"><LinkIcon/></InputAdornment>}
                          />
                          <FormHelperText style={{ marginLeft: '14px' }}>
                            Åpen informasjon
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </div>
        }
        {
          value === 1 &&
          <div style={{ padding: '20px 0px' }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Card>
                  <CardHeader
                      title={'Prosjekt'}
                      subheader={'Dette brukes til å filtrere på prosjektet'}
                  />
                  <CardContent>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Autocomplete
                            multiple
                            autoComplete={false}
                            id="gamePurpose"
                            options={Object.keys(gamePurposeEnum).map((key, index) => key)}
                            disableCloseOnSelect
                            value={gamePurposes}
                            onChange={(event, attributes) => onFlagValueChanged(event, attributes, 'gamePurpose', gamePurposeEnum)}
                            getOptionLabel={option => {
                              return intl.formatMessage({ id: option })
                            }}
                            renderOption={(props, option, { selected }) => (
                                <li {...props}>
                                  <Checkbox
                                      icon={icon}
                                      checkedIcon={checkedIcon}
                                      style={{ marginRight: 8 }}
                                      checked={selected}
                                  />
                                  {intl.formatMessage({ id: option })}
                                </li>
                            )}
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    fullWidth
                                    label="Prosjektet må ha en av disse formålene"
                                    variant="outlined"
                                />
                            )}
                            renderTags={(value, getTagProps) =>
                                value.map((option, index) => (
                                    <Chip key={index} label={intl.formatMessage({ id: option })}/>
                                ))
                            }
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Autocomplete
                            multiple
                            autoComplete={false}
                            id="pegiRating"
                            options={Object.keys(pegiRatingEnum).map((key, index) => key)}
                            disableCloseOnSelect
                            value={pegiRatings}
                            onChange={(event, attributes) => onFlagValueChanged(event, attributes, 'pegiRating', pegiRatingEnum)}
                            getOptionLabel={option => {
                              return intl.formatMessage({ id: option })
                            }}
                            renderOption={(props, option, { selected }) => (
                                <li {...props}>
                                  <Checkbox
                                      icon={icon}
                                      checkedIcon={checkedIcon}
                                      style={{ marginRight: 8 }}
                                      checked={selected}
                                  />
                                  {intl.formatMessage({ id: option })}
                                </li>
                            )}
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    fullWidth
                                    label="Aksepterte PEGI-merkinger"
                                    variant="outlined"
                                />
                            )}
                            renderTags={(value, getTagProps) =>
                                value.map((option, index) => (
                                    <Chip key={index} label={intl.formatMessage({ id: option })}/>
                                ))
                            }
                        />
                      </Grid>
                      {/* <Grid item xs={12}> */}
                      {/*    <Autocomplete */}
                      {/*        multiple */}
                      {/*        id="platforms" */}
                      {/*        options={platforms} */}
                      {/*        disableCloseOnSelect */}
                      {/*        onChange={handlePlatformChange} */}
                      {/*        value={dataCopy.platforms} */}
                      {/*        renderOption={(option, {selected}) => ( */}
                      {/*            <React.Fragment> */}
                      {/*                <Checkbox */}
                      {/*                    icon={icon} */}
                      {/*                    checkedIcon={checkedIcon} */}
                      {/*                    style={{marginRight: 8}} */}
                      {/*                    checked={selected} */}
                      {/*                /> */}
                      {/*                {option.name} */}
                      {/*            </React.Fragment> */}
                      {/*        )} */}
                      {/*        renderInput={params => ( */}
                      {/*            <TextField */}
                      {/*                {...params} */}
                      {/*                fullWidth */}
                      {/*                variant="outlined" */}
                      {/*                label={"Spillet må komme ut på en av disse plattformene"} */}
                      {/*            /> */}
                      {/*        )} */}
                      {/*        renderTags={(value, getTagProps) => */}
                      {/*            value.map((option, index) => ( */}
                      {/*                <Chip key={index} label={option.name} /> */}
                      {/*            )) */}
                      {/*        } */}
                      {/*    /> */}
                      {/* </Grid> */}
                      <Grid item xs={12} sm={8}>
                        <Autocomplete
                            multiple
                            id="gamePlayMode"
                            options={Object.keys(gamePlayModeEnum).map((key, index) => key)}
                            disableCloseOnSelect
                            value={gamePlayModes}
                            onChange={(event, attributes) => onFlagValueChanged(event, attributes, 'gamePlayMode', gamePlayModeEnum)}
                            getOptionLabel={option => {
                              return intl.formatMessage({ id: option })
                            }}
                            renderOption={(props, option, { selected }) => (
                                <li {...props}>
                                  <Checkbox
                                      icon={icon}
                                      checkedIcon={checkedIcon}
                                      style={{ marginRight: 8 }}
                                      checked={selected}
                                  />
                                  {intl.formatMessage({ id: option })}
                                </li>
                            )}
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    fullWidth
                                    label="Aksepterte spillermoduser"
                                    variant="outlined"
                                />
                            )}
                            renderTags={(value, getTagProps) =>
                                value.map((option, index) => (
                                    <Chip key={index} label={intl.formatMessage({ id: option })}/>
                                ))
                            }
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormControlLabel
                            control={
                              <Checkbox
                                  checked={dataCopy.hasToPassCultureTest ?? false}
                                  onChange={handleCheckboxChange('hasToPassCultureTest')}
                                  value={'hasToPassCultureTest'}
                                  color="primary"
                              />
                            }
                            label="Må bestå kulturtesten"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormControlLabel
                            control={
                              <Checkbox
                                  checked={dataCopy.hasRightsToIp ?? false}
                                  onChange={handleCheckboxChange('hasRightsToIp')}
                                  value={'hasRightsToIp'}
                                  color="primary"
                              />
                            }
                            label="Selskapet innehar rettighetene til spillets IP"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormControlLabel
                            control={
                              <Checkbox
                                  checked={dataCopy.isNarrative ?? false}
                                  onChange={handleCheckboxChange('isNarrative')}
                                  value={'isNarrative'}
                                  color="primary"
                              />
                            }
                            label="Prosjektet må ha et sentralt narrativ"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Autocomplete
                            multiple
                            id="availableInLanguages"
                            options={metadata.languages.map(x => x.name)}
                            // filterSelectedOptions
                            disableCloseOnSelect
                            onChange={(event, value) => handleAutoCompleteChange(event, value, 'availableInLanguages')}
                            getOptionLabel={option => option}
                            value={dataCopy.availableInLanguages}
                            renderTags={(value, getTagProps) =>
                                value.map((option, index) => (
                                    <Chip
                                        key={index} label={option} {...getTagProps({ index })} />
                                ))
                            }
                            renderOption={(props, option, { selected }) => (
                                <li {...props}>
                                  <Checkbox
                                      icon={icon}
                                      checkedIcon={checkedIcon}
                                      style={{ marginRight: 8 }}
                                      checked={selected}
                                  />
                                  {intl.formatMessage({ id: option })}
                                </li>
                            )}
                            style={{ width: '100%' }}
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    label={'Spillet må være tilgjengelig på disse språkene'}
                                    fullWidth
                                />
                            )}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Autocomplete
                            multiple
                            id="originalMusicComposerNationality"
                            options={metadata.languages.map(x => x.name)}
                            // filterSelectedOptions
                            disableCloseOnSelect
                            onChange={(event, value) => handleAutoCompleteChange(event, value, 'originalMusicComposerNationality')}
                            getOptionLabel={option => option}
                            value={dataCopy.originalMusicComposerNationality}
                            renderTags={(value, getTagProps) =>
                                value.map((option, index) => (
                                    <Chip
                                        key={index} label={option} {...getTagProps({ index })} />
                                ))
                            }
                            renderOption={(props, option, { selected }) => (
                                <li {...props}>
                                  <Checkbox
                                      icon={icon}
                                      checkedIcon={checkedIcon}
                                      style={{ marginRight: 8 }}
                                      checked={selected}
                                  />
                                  {intl.formatMessage({ id: option })}
                                </li>
                            )}
                            style={{ width: '100%' }}
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    label={'Spillets komponist og originalmusikk må ha nasjonalitet fra'}
                                    fullWidth
                                />
                            )}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Autocomplete
                            multiple
                            id="mainProducerCounties"
                            options={uniq(metadata.countiesMap.map(x => x.county))}
                            // filterSelectedOptions
                            disableCloseOnSelect
                            onChange={(event, value) => handleAutoCompleteChange(event, value, 'mainProducerCounties')}
                            getOptionLabel={option => option}
                            value={dataCopy.mainProducerCounties}
                            renderTags={(value, getTagProps) =>
                                value.map((option, index) => (
                                    <Chip
                                        key={index} label={option} {...getTagProps({ index })} />
                                ))
                            }
                            renderOption={(props, option, { selected }) => (
                                <li {...props}>
                                  <Checkbox
                                      icon={icon}
                                      checkedIcon={checkedIcon}
                                      style={{ marginRight: 8 }}
                                      checked={selected}
                                  />
                                  {intl.formatMessage({ id: option })}
                                </li>
                            )}
                            style={{ width: '100%' }}
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    label={'Hovedprodusenten må ha bostedsadresse i en av disse fylkene'}
                                    fullWidth
                                />
                            )}
                        />
                      </Grid>

                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Card>
                  <CardHeader
                      title={'Prosjekt: Økonomi & forretningsmodell'}
                      subheader={'Dette brukes til å filtrere på prosjektet'}
                  />
                  <CardContent>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Autocomplete
                            multiple
                            id="stage"
                            options={Object.keys(projectStageEnums).map((key, index) => key)}
                            disableCloseOnSelect
                            value={stages}
                            onChange={(event, attributes) => onFlagValueChanged(event, attributes, 'stage', projectStageEnums)}
                            getOptionLabel={option => {
                              return intl.formatMessage({ id: option })
                            }}
                            renderOption={(props, option, { selected }) => (
                                <li {...props}>
                                  <Checkbox
                                      icon={icon}
                                      checkedIcon={checkedIcon}
                                      style={{ marginRight: 8 }}
                                      checked={selected}
                                  />
                                  {intl.formatMessage({ id: option })}
                                </li>
                            )}
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    fullWidth
                                    label="Prosjektfaser det kan gis penger til"
                                    variant="outlined"
                                />
                            )}
                            renderTags={(options, getTagProps) => (
                                options.map((option, index) => (
                                    <Chip key={index} label={intl.formatMessage({ id: option })}/>
                                ))
                            )}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Autocomplete
                            multiple
                            id="businessModel"
                            options={Object.keys(gameBusinessModelEnum).map((key, index) => key)}
                            disableCloseOnSelect
                            value={gameBusinessModels}
                            onChange={(event, attributes) => onFlagValueChanged(event, attributes, 'businessModel', gameBusinessModelEnum)}
                            getOptionLabel={option => {
                              return intl.formatMessage({ id: option })
                            }}
                            renderOption={(props, option, { selected }) => (
                                <li {...props}>
                                  <Checkbox
                                      icon={icon}
                                      checkedIcon={checkedIcon}
                                      style={{ marginRight: 8 }}
                                      checked={selected}
                                  />
                                  {intl.formatMessage({ id: option })}
                                </li>
                            )}
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    fullWidth
                                    label="Aksepterte finansieringsmodeller"
                                    variant="outlined"
                                />
                            )}
                            renderTags={(options, getTagProps) => (
                                options.map((option, index) => (
                                    <Chip key={index} label={intl.formatMessage({ id: option })}/>
                                ))
                            )}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <NumberFormat value={dataCopy.projectBudgetMin}
                                      displayType={'input'}
                                      variant={'outlined'}
                                      fullWidth
                                      thousandSeparator={' '}
                                      prefix={'NOK '}
                                      id='projectBudgetMin'
                                      label={'Minimum posjektbudsjett'}
                                      onValueChange={(values) => changeProjectNumberInfo(values, 'projectBudgetMin')}
                                      customInput={TextField}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <NumberFormat value={dataCopy.projectBudgetMax}
                                      displayType={'input'}
                                      variant={'outlined'}
                                      fullWidth
                                      thousandSeparator={' '}
                                      prefix={'NOK '}
                                      id='projectBudgetMax'
                                      label={'Maksimum posjektbudsjett'}
                                      onValueChange={(values) => changeProjectNumberInfo(values, 'projectBudgetMax')}
                                      customInput={TextField}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography gutterBottom>
                          Hvor mye kan dette tilskuddet støtte maksimalt av totalbudsjett?
                        </Typography>
                        <div className={classes.sliderContainer}>
                          <Slider
                              min={0}
                              step={5}
                              marks={[
                                {
                                  value: 0,
                                  label: '0%'
                                },
                                {
                                  value: 25,
                                  label: '25%'
                                },
                                {
                                  value: 50,
                                  label: '50%'
                                },
                                {
                                  value: 75,
                                  label: '75%'
                                },
                                {
                                  value: 100,
                                  label: '100%'
                                }
                              ]}
                              max={100}
                              value={maxFundingOfTotalProjectBudget}
                              onChange={(event, value) => setMaxFundingOfTotalProjectBudget(value)}
                              onChangeCommitted={(event, value) => handleChange('maximumFundingOfTotalProjectBudget', value)}
                              valueLabelDisplay="on"
                              getAriaValueText={(value) => `${value}`}
                          />
                        </div>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Card>
                  <CardHeader
                      title={'Selskap'}
                      subheader={'Dette brukes til å filtrere på selskapsdata'}
                  />
                  <CardContent>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Autocomplete
                            multiple
                            autoComplete={false}
                            id="companyRole"
                            options={Object.keys(companyRoleEnum).map((key, index) => key)}
                            disableCloseOnSelect
                            value={companyRoles}
                            onChange={(event, attributes) => onFlagValueChanged(event, attributes, 'companyRole', companyRoleEnum)}
                            getOptionLabel={option => {
                              return intl.formatMessage({ id: option })
                            }}
                            renderOption={(props, option, { selected }) => (
                                <li {...props}>
                                  <Checkbox
                                      icon={icon}
                                      checkedIcon={checkedIcon}
                                      style={{ marginRight: 8 }}
                                      checked={selected}
                                  />
                                  {intl.formatMessage({ id: option })}
                                </li>
                            )}
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    fullWidth
                                    label="Rolle(r) søkers selskap må inneha i Prosjektet"
                                    variant="outlined"
                                />
                            )}
                            renderTags={(options, getTagProps) => (
                                options.map((option, index) => (
                                    <Chip key={index} label={intl.formatMessage({ id: option })}/>
                                ))
                            )}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Autocomplete
                            multiple
                            id="counties"
                            options={uniq(metadata.countiesMap.map(x => x.county))}
                            // filterSelectedOptions
                            disableCloseOnSelect
                            onChange={(event, value) => handleAutoCompleteChange(event, value, 'counties')}
                            getOptionLabel={option => option}
                            value={dataCopy.counties}
                            renderTags={(value, getTagProps) =>
                                value.map((option, index) => (
                                    <Chip
                                        key={index} label={option} {...getTagProps({ index })} />
                                ))
                            }
                            renderOption={(props, option, { selected }) => (
                                <li {...props}>
                                  <Checkbox
                                      icon={icon}
                                      checkedIcon={checkedIcon}
                                      style={{ marginRight: 8 }}
                                      checked={selected}
                                  />
                                  {intl.formatMessage({ id: option })}
                                </li>
                            )}
                            style={{ width: '100%' }}
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    label={'Selskapet må ha hovedkontor i en av disse fylkene'}
                                    fullWidth
                                    helperText={'Åpen informasjon'}
                                />
                            )}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography id="range-slider" gutterBottom>
                          Optimal selskapsalder
                        </Typography>
                        <div className={classes.sliderContainer}>
                          <Slider
                              min={0}
                              max={100}
                              value={companyMinMax}
                              onChange={(event, values) => setCompanyMinMax(values)}
                              onChangeCommitted={(event, values) => handleRangeSliderChange('companyAgeMin', 'companyAgeMax', Math.min(...values), Math.max(...values))}
                              valueLabelDisplay="on"
                              aria-labelledby="range-slider"
                              getAriaValueText={(value) => `${value}`}
                          />
                        </div>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </div>
        }
        {
          value === 2 &&
          <div style={{ padding: '20px 0px' }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Card>
                  <CardHeader
                      title={'Søknadsfrister'}
                      subheader={'Fyll inn søknadsfrister for tilskuddet her'}
                  />
                  <CardContent>
                    <Grid container spacing={3}>
                      {
                        deadlinesSortedAndFiltered.map((deadline, index) => (
                            <Grid item xs={12} key={deadline.id}>
                              <Grid container alignItems="flex-start">
                                <Grid item xs={10}>
                                  <DatePicker
                                      id="deadline"
                                      openTo="year"
                                      views={['year', 'month', 'day']}
                                      label="Søknadsfrist"
                                      value={deadline.deadline}
                                      onChange={(date) => props.updateFinanceOptionDeadline({
                                        ...deadline,
                                        deadline: date
                                      })}
                                      renderInput={(params) => <TextField fullWidth variant={"outlined"} {...params} helperText={'Åpen informasjon'} />}
                                  />
                                </Grid>
                                <Grid item xs={2}
                                      style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                  <IconButton
                                      onClick={(event) => props.deleteFinanceOptionDeadline(deadline.id)}
                                  >
                                    <DeleteIcon/>
                                  </IconButton>
                                </Grid>
                              </Grid>
                            </Grid>
                        ))
                      }
                    </Grid>
                  </CardContent>
                  <CardActions>
                    <Button onClick={newDeadlineClicked}>
                      <FormattedMessage
                          id={'FinanceOption.AddNewDeadline'}
                          defaultMessage={'Ny søknadsfrist'}
                      />
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            </Grid>
          </div>
        }
        {
          value === 3 &&
          <div style={{ padding: '20px 0px' }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Card>
                  <CardHeader
                      titleTypographyProps={{ variant: 'h6' }}
                      title={<FormattedMessage
                          id="FinanceOption.Resources"
                          defaultMessage={'Ressurser og maler'}
                      />}
                  />
                  <CardContent>
                    <NewFinanceOptionResourceDialog dialogOpen={newResourceDialogOpen}
                                                    financeOption={dataCopy}
                                                    onClose={() => setNewResourceDialogOpen(false)}/>
                    <Button variant={'contained'} onClick={event => setNewResourceDialogOpen(true)}>
                      Add new resource
                    </Button>
                    <List>
                      {
                        officialResources.length > 0 &&
                        <ListSubheader>
                          Offisielle ressurser
                        </ListSubheader>
                      }
                      {
                        officialResources.map((resource, index) => (
                            <ListItem key={index}>
                              <ListItemAvatar>
                                <Avatar>
                                  {resource.resourceType === 0 &&
                                  <PictureAsPdfIcon/>}
                                  {resource.resourceType === 1 && <LanguageIcon/>}
                                </Avatar>
                              </ListItemAvatar>
                              <ListItemText primary={resource.name}
                                            secondary={moment(resource.createdAt).format('LL')}/>
                              <ListItemSecondaryAction>
                                <a href={resource.resourceUrl} target={'_blank'} rel="noreferrer">
                                  <IconButton edge="end" aria-label="download">
                                    {resource.resourceType === 0 &&
                                    <GetAppIcon/>}
                                    {resource.resourceType === 1 && <LinkIcon/>}
                                  </IconButton>
                                </a>
                                <IconButton edge="end" aria-label="download"
                                            onClick={e => deleteResourceClicked(e, resource)}>
                                  <DeleteIcon/>
                                </IconButton>
                              </ListItemSecondaryAction>
                            </ListItem>
                        ))
                      }
                      {
                        nonOfficialResources.length > 0 &&
                        <ListSubheader>
                          Eksempelsøknader
                        </ListSubheader>
                      }
                      {
                        nonOfficialResources.map((resource, index) => (
                            <ListItem key={index}>
                              <ListItemAvatar>
                                <Avatar>
                                  {resource.resourceType === 0 &&
                                  <PictureAsPdfIcon/>}
                                  {resource.resourceType === 1 && <LanguageIcon/>}
                                </Avatar>
                              </ListItemAvatar>
                              <ListItemText primary={resource.name}
                                            secondary={moment(resource.createdAt).format('LL')}/>
                              <ListItemSecondaryAction>
                                <a href={resource.resourceUrl} target={'_blank'} rel="noreferrer">
                                  <IconButton edge="end" aria-label="download">
                                    {resource.resourceType === 0 &&
                                    <GetAppIcon/>}
                                    {resource.resourceType === 1 && <LinkIcon/>}
                                  </IconButton>
                                </a>
                                <IconButton edge="end" aria-label="download"
                                            onClick={e => deleteResourceClicked(e, resource)}>
                                  <DeleteIcon/>
                                </IconButton>
                              </ListItemSecondaryAction>
                            </ListItem>
                        ))
                      }
                    </List>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </div>
        }
        {
          value === 4 &&
          <div style={{ padding: '20px 0px' }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Card>
                  <CardHeader
                      title={
                        <FormattedMessage
                            id="FinanceOption.Grants"
                            defaultMessage={'Tilskudd'}
                        />
                      }
                      subheader={
                        <FormattedMessage
                            id="FinanceOption.EditGrantsSubheader"
                            defaultMessage={'Last opp CSV filer med oversikt med tilskuddshistorikk'}
                        />
                      }
                  />
                  <CardContent>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <Stack direction={'row'} spacing={2} alignItems={'center'} justifyContent={'center'}>
                          <a href={GrantsTemplate} target={'_blank'} rel="noreferrer">
                            <Button variant={"outlined"} startIcon={<ExcelIcon style={{width: '16px', height: '16px'}} />}>
                              <FormattedMessage
                                  id="FinanceOption.GrantsDownloadSpreadsheet"
                                  defaultMessage={'Last ned regneark-mal og fyll ut.'}
                              />
                            </Button>
                          </a>
                          <CSVReader
                              onDrop={handleOnDrop}
                              onError={handleOnError}
                              addRemoveButton
                              onRemoveFile={handleOnRemoveFile}
                              config={{
                                header: true,
                                dynamicTyping: true
                              }}
                          >
                            <span>
                              <FormattedMessage
                                  id="FinanceOption.GrantsUploadCSV"
                                  defaultMessage={'Last opp CSV her'}
                              />
                            </span>
                          </CSVReader>
                        </Stack>
                      </Grid>
                      <Grid item xs={12}>
                        <div style={{ height: 400, width: '100%' }}>
                          <DataGrid
                              rows={grantsWithErrors}
                              columns={[
                                {
                                  field: 'isSuccess',
                                  headerName: '',
                                  width: 25,
                                  filterable: false,
                                  sortable: false,
                                  renderCell: (params) => params.value ? 
                                      <CheckCircleIcon color={"success"} />
                                      :
                                      <CancelIcon color={"error"}  />,
                                },
                                {
                                  field: 'Produksjonsselskap',
                                  headerName: 'Produksjonsselskap',
                                  width: 150,
                                },
                                {
                                  field: 'Prosjekt',
                                  headerName: 'Prosjekt',
                                  width: 150,
                                },
                                {
                                  field: 'Dato',
                                  headerName: 'Dato',
                                  width: 110,
                                },
                                {
                                  field: 'Sum',
                                  headerName: 'Sum',
                                  type: 'number',
                                  width: 110,
                                },
                                {
                                  field: 'Valuta',
                                  headerName: 'Valuta',
                                  width: 110,
                                },
                                {
                                  field: 'Fase',
                                  headerName: 'Fase',
                                  width: 110,
                                },
                                {
                                  field: 'Produsent',
                                  headerName: 'Produsent',
                                  width: 110,
                                },
                                {
                                  field: 'Regi',
                                  headerName: 'Regi',
                                  width: 110,
                                },
                                {
                                  field: 'Manus',
                                  headerName: 'Manus',
                                  width: 110,
                                },
                                {
                                  field: 'errors',
                                  headerName: 'Feil',
                                  sortable: false,
                                  width: 160,
                                  renderCell: (params) => params.value.map((errorEntry, j) => (
                                      <div key={j}>
                                        {errorEntry.error}
                                      </div>
                                  )),
                                },
                              ]}
                              pageSize={50}
                              rowsPerPageOptions={[5]}
                              disableSelectionOnClick
                          />
                        </div>
                      </Grid>
                    </Grid>
                  </CardContent>
                  <CardActions>
                    {
                      grantsForUpload.length > 0 &&
                      <Button onClick={uploadGrants}>
                        Upload
                      </Button>
                    }
                  </CardActions>
                </Card>
              </Grid>
            </Grid>
          </div>
        }
      </div>
  )
}

const mapStateToProps = (state) => {
  return {
    fetchingFinanceOptions: state.financeOption.fetchingFinanceOptions,
    creatingFinanceOption: state.financeOption.creatingFinanceOption,
    creatingDeadline: state.financeOption.creatingDeadline,
    deletingDeadline: state.financeOption.deletingDeadline,
    error: state.financeOption.error,
    financeOptionsSubscriptions: state.financeOption.financeOptionsSubscriptions,
    subscriptionChanging: state.financeOption.subscriptionChanging,
    subscriptionFetching: state.financeOption.subscriptionFetching,
    uploadingResource: state.financeOption.uploadingResource,
    deletingResource: state.financeOption.deletingResource,
    yourOrganizations: state.organization.yourOrganizations,
    userInfo: state.user.userInfo,
    metadata: state.metadata.metadata,
    platforms: state.metadata.metadata.platforms,
    grants: state.financeOption.grants,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getFinanceOptions: () => dispatch(getFinanceOptions()),
    getYourOrganizations: () => dispatch(getYourOrganizations()),
    patchFinanceOption: (patch, id) => dispatch(patchFinanceOption(patch, id)),
    createFinanceOptionDeadline: (newDeadline) => dispatch(createFinanceOptionDeadline(newDeadline)),
    deleteFinanceOptionDeadline: (deadlineId) => dispatch(deleteFinanceOptionDeadline(deadlineId)),
    updateFinanceOptionDeadline: (deadline) => dispatch(updateFinanceOptionDeadline(deadline)),
    subscriptionChange: (change) => dispatch(subscriptionChange(change)),
    getSubscriptions: () => dispatch(getSubscriptions()),
    deleteFinanceOption: (id) => dispatch(deleteFinanceOption(id)),
    deleteResource: (data) => dispatch(deleteResource(data)),
    uploadFinanceOptionGrants: (data) => dispatch(uploadFinanceOptionGrants(data)),
    getFinanceOptionGrants: (id) => dispatch(getFinanceOptionGrants(id))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(EditFundingPage))
