import React, { useCallback, useEffect, useState } from 'react'

import Grid from '@mui/material/Grid'
import { makeStyles } from '@mui/styles'
import Typography from '@mui/material/Typography'
import Card from '@mui/material/Card'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import CardHeader from '@mui/material/CardHeader'
import {
    getYourOrganizations, inviteUser,
    organizationUsersAccessResponse,
    patchOrganization, uploadOrganizationLogo
} from '../store/actions/organizationActions'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import moment from 'moment'
import jsonpatch from 'fast-json-patch'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import Divider from '@mui/material/Divider'
import Avatar from '@mui/material/Avatar'
import CircularProgress from '@mui/material/CircularProgress'
import CardContent from '@mui/material/CardContent'
import CardActions from '@mui/material/CardActions'
import { organizationRoleEnum } from '../constants/organizationEnums'
import { useDropzone } from 'react-dropzone'
import AppBar from '@mui/material/AppBar'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import BusinessIcon from '@mui/icons-material/Business'
import Badge from '@mui/material/Badge'
import IconButton from '@mui/material/IconButton'
import EditIcon from '@mui/icons-material/Edit'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogActions from '@mui/material/DialogActions'
import Dialog from '@mui/material/Dialog'
import { DataGrid } from '@mui/x-data-grid';
import {FormattedMessage} from "react-intl";

const useStyles = makeStyles(theme => ({
    root: {
        padding: '20px'
    },
    companyLogoContainer: {
        width: '50px',
        height: '50px',
        boxSizing: 'content-box'
    },
    iconButton: {
        width: '10px',
        height: '10px',
        padding: '7px',
        backgroundColor: '#FFFFFF',
        color: '#333',
        position: 'absolute',
        right: '0px',
        bottom: '0px',
        boxShadow: theme.palette.mode === 'dark' ? '0px 2px 3px #0F0F0F' : '0px 2px 3px #DFE1E6',
        '&:hover': {
            backgroundColor: '#CCCCCC'
        }
    }
}))

const Company = (props) => {
    const { yourOrganizations, userInfo, metadata, organizationUsers, approvingUsers, fetchingOrganizations, patchingOrganization, invitingUser, updatingOrgLogo } = props
    const classes = useStyles()
    const selectedOrganization = userInfo && yourOrganizations.find(x => x.id === userInfo.currentlyActiveOrganization)

    const [inviteUserOpen, setInviteUserOpen] = React.useState(false)
    const [inviteUserEmail, setInviteUserEmail] = React.useState('')
    const [value, setValue] = React.useState(0)
    const [anchorEl, setAnchorEl] = React.useState(null)

    const onDrop = useCallback(acceptedFiles => {
        const formData = new FormData()
        formData.append('file', acceptedFiles[0])
        props.uploadOrganizationLogo(formData, selectedOrganization.id)
    }, [])
    const { getRootProps, getInputProps, open } = useDropzone({ onDrop, accept: 'image/png, image/jpeg', noClick: true, noKeyboard: true })

    const [localOrgCopy, setLocalOrgCopy] = useState({
        orgnr: '',
        name: '',
        registerDateUnitRegister: '',
        orgform: '',
        orgformDescription: '',
        industryCode: '',
        industryDescription: '',
        postAddress: '',
        postCode: '',
        postLocation: '',
        municipality: '',
        municipalityNumber: -1,
        country: '',
        numEmployees: '',
        website: '',
        vatRegistered: '',
        hasFunding: false,
        hasProjects: false
    })

    useEffect(() => {
        const updatedOrganization = userInfo && yourOrganizations.find(x => x.id === userInfo.currentlyActiveOrganization)

        if (updatedOrganization != null) {
            setLocalOrgCopy(updatedOrganization)
        }
    }, [yourOrganizations, userInfo, patchingOrganization, updatingOrgLogo])

    const handleClick = event => {
        event.stopPropagation()

        setAnchorEl(event.currentTarget)
    }

    const handleClose = (event) => {
        event.stopPropagation()

        setAnchorEl(null)
    }

    const removeProfilePictureClicked = (event) => {
        const formData = new FormData()
        props.uploadOrganizationLogo(formData, selectedOrganization.id)
    }

    const inviteUserClicked = (event) => {
        const form = {
            email: inviteUserEmail
        }
        props.inviteUser(form, selectedOrganization.id)
        setInviteUserOpen(false)
    }

    const handleOpenUploadDialog = (event) => {
        event.stopPropagation()

        open()
        setAnchorEl(null)
    }

    const changeOrganizationInfo = (event) => {
        setLocalOrgCopy({
            ...localOrgCopy,
            [event.target.id]: event.target.value
        })
    }

    const handleUserAccessResponse = (event, userId, approve) => {
        props.organizationUsersAccessResponse(localOrgCopy.id, userId, approve)
    }

    const saveChangesClicked = (event) => {
        const selectedOrganization = yourOrganizations.find(x => x.id === userInfo.currentlyActiveOrganization)

        const patch = jsonpatch.compare(selectedOrganization, localOrgCopy)
        props.patchOrganization(patch, localOrgCopy.id)
    }

    const handleAddFundingModule = (event) => {
        const patchedOrgCopy = {
            ...localOrgCopy,
            hasFunding: true
        }
        setLocalOrgCopy(patchedOrgCopy)

        const patch = jsonpatch.compare(selectedOrganization, patchedOrgCopy)
        props.patchOrganization(patch, localOrgCopy.id)
    }

    const handleAddProjectModule = event => {
        const patchedOrgCopy = {
            ...localOrgCopy,
            hasProjects: true
        }
        setLocalOrgCopy(patchedOrgCopy)

        const patch = jsonpatch.compare(selectedOrganization, patchedOrgCopy)
        props.patchOrganization(patch, localOrgCopy.id)
    }

    const countyMap = metadata.countiesMap.find(x => x.municipality.toLowerCase() === localOrgCopy.municipality.toLowerCase())

    return (
        <div className={classes.root}>
            <Dialog open={inviteUserOpen} onClose={(event) => setInviteUserOpen(false)}
                    aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">
                    <FormattedMessage
                        id="Company.InviteUser"
                        defaultMessage={'Inviter bruker'}
                    />
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <FormattedMessage
                            id="Company.InviteUserDescription"
                            defaultMessage={'Skriv inn e-posten til brukeren du vil invitere'}
                        />
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="inviteUserEmail"
                        label="Brukerens epost"
                        type="email"
                        onChange={(event) => setInviteUserEmail(event.target.value)}
                        value={inviteUserEmail}
                        fullWidth
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={(event) => setInviteUserOpen(false)} color="primary">
                        <FormattedMessage
                            id="Global.Cancel"
                            defaultMessage={'Cancel'}
                        />
                    </Button>
                    <Button
                        onClick={inviteUserClicked}
                        color="primary"
                        disabled={invitingUser || inviteUserEmail.length === 0}
                    >
                        <FormattedMessage
                            id="Company.InviteUser"
                            defaultMessage={'Inviter'}
                        />
                    </Button>
                </DialogActions>
            </Dialog>
            <Typography variant={'body2'}>
                <FormattedMessage
                    id="Company.InfoDescription"
                    defaultMessage={'Her kan du endre informasjon om selskapet ditt'}
                />
            </Typography>
            <Typography variant={'h4'}>
                {localOrgCopy && localOrgCopy.name}
            </Typography>
            <AppBar position="static" style={{ marginTop: '20px' }}>
                <Tabs
                    value={value}
                    onChange={(event, value) => setValue(value)}
                >
                    <Tab label={
                        <FormattedMessage
                            id="Company.TabGeneral"
                            defaultMessage={'Generelt'}
                        />
                    } tabIndex={0}/>
                    <Tab label={
                        <Badge badgeContent={organizationUsers.filter(x => !x.approved).length} color="secondary">
                            <FormattedMessage
                                id="Company.TabUserAccess"
                                defaultMessage={'Brukertilgang'}
                            />
                        </Badge>
                    } tabIndex={1}/>
                    <Tab label={
                        <FormattedMessage
                            id="Company.TabModules"
                            defaultMessage={'Moduler'}
                        />
                    } tabIndex={2}/>
                </Tabs>
            </AppBar>
            {
                value === 0 &&
                <div style={{ padding: '20px 0px' }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={10}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Card>
                                        <CardHeader
                                            title={
                                                <FormattedMessage
                                                    id="Company.InfoSource"
                                                    defaultMessage={'Hentet fra Brønnøysund'}
                                                />
                                            }
                                        />
                                        <CardContent>
                                            <Grid container spacing={2}>
                                                <Grid item>
                                                    <div {...getRootProps()}
                                                         className={classes.companyLogoContainer}
                                                    >
                                                        <input {...getInputProps()} />
                                                        {
                                                            <div style={{
                                                                height: 'inherit',
                                                                width: 'inherit',
                                                                position: 'relative'
                                                            }}>
                                                                <Avatar
                                                                    src={updatingOrgLogo ? null : localOrgCopy.logoUrl}
                                                                    style={{ height: 'inherit', width: 'inherit' }}>
                                                                    {
                                                                        updatingOrgLogo
                                                                            ? <CircularProgress/>
                                                                            : <BusinessIcon/>
                                                                    }
                                                                </Avatar>
                                                                <IconButton className={classes.iconButton}
                                                                            onClick={handleClick}
                                                                            disabled={patchingOrganization}>
                                                                    <EditIcon
                                                                        style={{ height: 'inherit', width: 'inherit' }}/>
                                                                </IconButton>
                                                                <Menu
                                                                    id="simple-menu"
                                                                    anchorEl={anchorEl}
                                                                    keepMounted
                                                                    open={Boolean(anchorEl)}
                                                                    onClose={handleClose}
                                                                >
                                                                    <MenuItem
                                                                        onClick={handleOpenUploadDialog}>Change</MenuItem>
                                                                    {
                                                                        localOrgCopy.logoUrl && localOrgCopy.logoUrl.length > 0 &&
                                                                        <MenuItem onClick={removeProfilePictureClicked}>
                                                                            <FormattedMessage
                                                                                id="Global.Remove"
                                                                                defaultMessage={'Fjern'}
                                                                            />
                                                                        </MenuItem>
                                                                    }
                                                                </Menu>
                                                            </div>
                                                        }
                                                    </div>
                                                </Grid>
                                                <Grid item xs>
                                                    <TextField
                                                        id="name"
                                                        name="name"
                                                        value={localOrgCopy.name}
                                                        onChange={changeOrganizationInfo}
                                                        type="text"
                                                        label={'Firmanavn'}
                                                        fullWidth
                                                        variant={'filled'}
                                                        disabled
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} sm={6} lg={4}>
                                                    <TextField
                                                        id="orgform"
                                                        value={`(${localOrgCopy.orgform}) ${localOrgCopy.orgformDescription}`}
                                                        type="text"
                                                        label={'Organisasjonsform'}
                                                        fullWidth
                                                        variant={'filled'}
                                                        disabled
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={6} lg={4}>
                                                    <TextField
                                                        id="orgnr"
                                                        name="orgnr"
                                                        value={localOrgCopy.orgnr}
                                                        onChange={changeOrganizationInfo}
                                                        type="text"
                                                        label={'Organisasjonsnummer'}
                                                        fullWidth
                                                        variant={'filled'}
                                                        disabled
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={6} lg={4}>
                                                    <TextField
                                                        id="registerDateUnitRegister"
                                                        name="registerDateUnitRegister"
                                                        value={moment(localOrgCopy.registerDateUnitRegister).format('LL')}
                                                        type="text"
                                                        label={'Registreringsdato'}
                                                        fullWidth
                                                        variant={'filled'}
                                                        disabled
                                                    />
                                                </Grid>
                                                <Grid item xs={8}>
                                                    <TextField
                                                        id="industry"
                                                        value={`(${localOrgCopy.industryCode}) ${localOrgCopy.industryDescription}`}
                                                        type="text"
                                                        label={'Industrykode'}
                                                        fullWidth
                                                        variant={'filled'}
                                                        disabled
                                                    />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={localOrgCopy.vatRegistered === true}
                                                                value="vatRegistered"
                                                                color="primary"
                                                                disabled
                                                            />
                                                        }
                                                        label="Momsregistrert"
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        id="postAddress"
                                                        name="postAddress"
                                                        value={localOrgCopy.postAddress}
                                                        onChange={changeOrganizationInfo}
                                                        type="text"
                                                        label={'Postadresse'}
                                                        fullWidth
                                                        variant={'filled'}
                                                        disabled
                                                    />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <TextField
                                                        id="postCode"
                                                        name="postCode"
                                                        value={localOrgCopy.postCode}
                                                        onChange={changeOrganizationInfo}
                                                        type="text"
                                                        label={'Postkode'}
                                                        fullWidth
                                                        variant={'filled'}
                                                        disabled
                                                    />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <TextField
                                                        id="postLocation"
                                                        name="postLocation"
                                                        value={localOrgCopy.postLocation}
                                                        onChange={changeOrganizationInfo}
                                                        type="text"
                                                        label={'Poststed'}
                                                        fullWidth
                                                        variant={'filled'}
                                                        disabled
                                                    />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <TextField
                                                        id="county"
                                                        name="county"
                                                        value={countyMap != null ? countyMap.county : ''}
                                                        type="text"
                                                        label={'Fylke'}
                                                        fullWidth
                                                        variant={'filled'}
                                                        disabled
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        id="country"
                                                        name="country"
                                                        value={localOrgCopy.country}
                                                        onChange={changeOrganizationInfo}
                                                        type="text"
                                                        label={'Land'}
                                                        fullWidth
                                                        variant={'filled'}
                                                        disabled
                                                    />
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <Divider/>
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <TextField
                                                        id="numEmployees"
                                                        name="numEmployees"
                                                        value={localOrgCopy.numEmployees}
                                                        onChange={changeOrganizationInfo}
                                                        type="number"
                                                        label={'Number of employees'}
                                                        fullWidth
                                                        variant={'filled'}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        id="website"
                                                        name="website"
                                                        value={localOrgCopy.website}
                                                        onChange={changeOrganizationInfo}
                                                        type="text"
                                                        label={'Nettside'}
                                                        fullWidth
                                                        variant={'filled'}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                        <CardActions>
                                            <Button
                                                variant={'contained'}
                                                onClick={saveChangesClicked}
                                                disabled={JSON.stringify(localOrgCopy) === JSON.stringify(selectedOrganization) || patchingOrganization}
                                            >
                                                {
                                                    patchingOrganization
                                                        ? <CircularProgress size={24}/>
                                                        : <FormattedMessage
                                                            id="Global.Save"
                                                            defaultMessage={'Lagre'}
                                                        />
                                                }
                                            </Button>
                                        </CardActions>
                                    </Card>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            }
            {
                value === 1 &&
                <div style={{ padding: '20px 0px' }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={10}>
                            <Card>
                                <CardHeader
                                    title={
                                        <FormattedMessage
                                            id="Company.UserAccessHeader"
                                            defaultMessage={'Brukeroversikt'}
                                        />
                                    }
                                />
                                <CardContent>
                                    {/* { */}
                                    {/*    !user.approved && */}
                                    {/*    <Button */}
                                    {/*        onClick={(event) => handleUserAccessResponse(event, user.id, true)} */}
                                    {/*        disabled={approvingUsers && approvingUsers.includes(user.id)} */}
                                    {/*    > */}
                                    {/*        Accept */}
                                    {/*    </Button> */}
                                    {/* } */}
                                    {/* { */}
                                    {/*    !user.approved && */}
                                    {/*    <Button */}
                                    {/*        onClick={(event) => handleUserAccessResponse(event, user.id, false)} */}
                                    {/*        disabled={approvingUsers && approvingUsers.includes(user.id)} */}
                                    {/*    > */}
                                    {/*        Reject */}
                                    {/*    </Button> */}
                                    {/* } */}
                                    <div style={{ height: 400, width: '100%' }}>
                                        <DataGrid
                                            rows={organizationUsers || []}
                                            columns={[
                                                {
                                                    headerName: '',
                                                    field: 'profilePictureUrl',
                                                    width: 50,
                                                    filterable: false,
                                                    sortable: false,
                                                    renderCell: (params) =>
                                                        <Avatar src={params.value}/>,
                                                },
                                                {
                                                    headerName: 'Email',
                                                    field: 'email',
                                                    flex: 1,
                                                    minWidth: 150,
                                                },
                                                {
                                                    headerName: 'Role',
                                                    field: 'role',
                                                    valueGetter: (params) =>
                                                        `${Object.keys(organizationRoleEnum)[params.row.role]}`,
                                                },
                                                {
                                                    headerName: 'Created',
                                                    field: 'createdAt',
                                                    valueGetter: (params) =>
                                                        `${moment(params.row.createdAt).fromNow("LL")}`,
                                                },
                                                {
                                                    headerName: 'Last Active',
                                                    field: 'lastActiveAt',
                                                    valueGetter: (params) =>
                                                        `${moment(params.row.lastActiveAt).fromNow()}`,
                                                }
                                            ]}
                                            pageSize={5}
                                            rowsPerPageOptions={[5]}
                                            checkboxSelection
                                            disableSelectionOnClick
                                        />
                                    </div>
                                </CardContent>
                                <CardActions>
                                    <Button variant={'contained'} disabled={invitingUser}
                                            onClick={(event) => setInviteUserOpen(true)}>
                                        <FormattedMessage
                                            id="Company.InviteNewUser"
                                            defaultMessage={'Inviter ny organisasjonsbruker'}
                                        />
                                    </Button>
                                </CardActions>
                            </Card>
                        </Grid>
                    </Grid>
                </div>
            }
            {
                value === 2 &&
                <div style={{ padding: '20px 0px' }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={10}>
                            <Card>
                                <CardHeader
                                    title={
                                        <FormattedMessage
                                            id="Company.ModulesHeader"
                                            defaultMessage={'Moduler'}
                                        />
                                    }
                                />
                                <CardContent>
                                    {
                                        !localOrgCopy.hasFunding &&
                                        <div>
                                            <Typography variant={'subtitle1'} gutterBottom>
                                                <FormattedMessage
                                                    id="Company.ModuleFundingHeader"
                                                    defaultMessage={'Har du finansiering du tilbyr til spillutviklere?'}
                                                />
                                            </Typography>
                                            <Button variant={'contained'} fullWidth onClick={handleAddFundingModule}>
                                                <FormattedMessage
                                                    id="Company.ModuleFundingButton"
                                                    defaultMessage={'Legg til finansieringsmodul'}
                                                />
                                            </Button>
                                        </div>
                                    }
                                    {
                                        !localOrgCopy.hasProjects &&
                                        <div>
                                            <Typography variant={'subtitle1'} gutterBottom>
                                                <FormattedMessage
                                                    id="Company.ModuleProjectsHeader"
                                                    defaultMessage={'Har du spillprosjekter som ser etter finansiering?'}
                                                />
                                            </Typography>
                                            <Button variant={'contained'} fullWidth onClick={handleAddProjectModule}>
                                                <FormattedMessage
                                                    id="Company.ModuleProjectsButton"
                                                    defaultMessage={'Legg til prosjektmodul'}
                                                />
                                            </Button>
                                        </div>
                                    }
                                    {
                                        localOrgCopy.hasFunding && localOrgCopy.hasProjects &&
                                        <div>
                                            <Typography variant={'subtitle1'} gutterBottom>
                                                <FormattedMessage
                                                    id="Company.ModulesNoAvailable"
                                                    defaultMessage={'Du har allerede alle modulene som er tilgjengelig.'}
                                                />
                                            </Typography>
                                        </div>
                                    }
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </div>
            }
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        authError: state.auth.authError,
        yourOrganizations: state.organization.yourOrganizations,
        organizationUsers: state.organization.organizationUsers,
        approvingUsers: state.organization.approvingUsers,
        patchingOrganization: state.organization.patchingOrganization,
        updatingOrgLogo: state.organization.updatingOrgLogo,
        fetchingOrganizations: state.organization.fetchingOrganizations,
        userInfo: state.user.userInfo,
        metadata: state.metadata.metadata,
        invitingUser: state.organization.invitingUser
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getYourOrganizations: () => dispatch(getYourOrganizations()),
        patchOrganization: (organization, id) => dispatch(patchOrganization(organization, id)),
        organizationUsersAccessResponse: (organization, id, approve) => dispatch(organizationUsersAccessResponse(organization, id, approve)),
        uploadOrganizationLogo: (formData, organizationId) => dispatch(uploadOrganizationLogo(formData, organizationId)),
        inviteUser: (form, organizationId) => dispatch(inviteUser(form, organizationId))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Company))
