import axios from 'axios'
import { API_ROOT, configWithAuth } from '../api'
import { history } from '../history'
import {getUser} from "./userActions";

export const getFinanceOptions = () => {
  return (dispatch) => {
    dispatch({ type: 'GET_FINANCEOPTIONS_REQUEST' })

    axios.get(`${API_ROOT}financeOption`, configWithAuth())
      .then((result) => {
        dispatch({ type: 'GET_FINANCEOPTIONS_SUCCESS', data: result.data })
      })
      .catch((error) => {
        dispatch({ type: 'GET_FINANCEOPTIONS_ERROR', error })
        dispatch({ type: 'SET_SNACKBAR', data: { variant: 'error', message: 'Feil ved henting av finansiering' } })
      })
  }
}

export const createFinanceOption = (newFinanceOption, organizationId) => {
  return (dispatch) => {
    dispatch({ type: 'CREATE_FINANCEOPTION_REQUEST' })

    axios.post(`${API_ROOT}financeOption/${organizationId}`, JSON.stringify(newFinanceOption), configWithAuth())
      .then((result) => {
        dispatch({ type: 'CREATE_FINANCEOPTION_SUCCESS', data: result.data })
        dispatch({ type: 'SET_SNACKBAR', data: { variant: 'success', message: 'Finansiering laget' } })
        history.push('/funding/' + result.data.id)
      })
      .catch((error) => {
        dispatch({ type: 'CREATE_FINANCEOPTION_ERROR', error })
        dispatch({ type: 'SET_SNACKBAR', data: { variant: 'error', message: 'Feil ved oppretting av finansiering' } })
      })
  }
}

export const createFinanceOptionDeadline = (newDeadline) => {
  return (dispatch) => {
    dispatch({ type: 'CREATE_FINANCEOPTION_DEADLINE_REQUEST' })

    axios.post(`${API_ROOT}financeOption/new-deadline`, JSON.stringify(newDeadline), configWithAuth())
      .then((result) => {
        dispatch({ type: 'CREATE_FINANCEOPTION_DEADLINE_SUCCESS', data: result.data })
        dispatch({ type: 'SET_SNACKBAR', data: { variant: 'success', message: 'Søknadsfrist laget' } })
      })
      .catch((error) => {
        dispatch({ type: 'CREATE_FINANCEOPTION_DEADLINE_ERROR', error })
        dispatch({ type: 'SET_SNACKBAR', data: { variant: 'error', message: 'Feil ved oppretting av søknadsfrist' } })
      })
  }
}

export const updateFinanceOptionDeadline = (deadline) => {
  return (dispatch) => {
    dispatch({ type: 'UPDATE_FINANCEOPTION_DEADLINE_REQUEST' })

    axios.post(`${API_ROOT}financeOption/update-deadline`, JSON.stringify(deadline), configWithAuth())
      .then((result) => {
        dispatch({ type: 'UPDATE_FINANCEOPTION_DEADLINE_SUCCESS', data: result.data })
        dispatch({ type: 'SET_SNACKBAR', data: { variant: 'success', message: 'Søknadsfrist oppdatert' } })
      })
      .catch((error) => {
        dispatch({ type: 'UPDATE_FINANCEOPTION_DEADLINE_ERROR', error })
        dispatch({ type: 'SET_SNACKBAR', data: { variant: 'error', message: 'Feil ved endring av søknadsfrist' } })
      })
  }
}

export const deleteFinanceOptionDeadline = (deadlineId) => {
  return (dispatch) => {
    dispatch({ type: 'DELETE_FINANCEOPTION_DEADLINE_REQUEST' })

    axios.delete(`${API_ROOT}financeOption/deadline/${deadlineId}`, configWithAuth())
      .then((result) => {
        dispatch({ type: 'DELETE_FINANCEOPTION_DEADLINE_SUCCESS', data: result.data })
        dispatch({ type: 'SET_SNACKBAR', data: { variant: 'success', message: 'Søknadsfrist slettet' } })
      })
      .catch((error) => {
        dispatch({ type: 'DELETE_FINANCEOPTION_DEADLINE_ERROR', error })
        dispatch({ type: 'SET_SNACKBAR', data: { variant: 'error', message: 'Feil ved sletting av søknadsfrist' } })
      })
  }
}

export const deleteFinanceOption = (id) => {
  return (dispatch) => {
    dispatch({ type: 'DELETE_FINANCEOPTION_REQUEST' })

    axios.delete(`${API_ROOT}financeOption/${id}`, configWithAuth())
      .then((result) => {
        dispatch({ type: 'DELETE_FINANCEOPTION_SUCCESS', data: result.data })
        dispatch({ type: 'SET_SNACKBAR', data: { variant: 'success', message: 'Finansiering slettet' } })
        history.push('/funding')
      })
      .catch((error) => {
        dispatch({ type: 'DELETE_FINANCEOPTION_ERROR', error })
        dispatch({ type: 'SET_SNACKBAR', data: { variant: 'error', message: 'Feil ved sletting av finansiering' } })
      })
  }
}

export const patchFinanceOption = (patch, id) => {
  return (dispatch) => {
    dispatch({ type: 'PATCH_FINANCEOPTION_REQUEST' })

    axios.patch(`${API_ROOT}financeOption/${id}`, JSON.stringify(patch), configWithAuth('application/json-patch+json'))
      .then((result) => {
        dispatch({ type: 'PATCH_FINANCEOPTION_SUCCESS', data: result.data })
        dispatch({ type: 'SET_SNACKBAR', data: { variant: 'success', message: 'Finansiering oppdatert' } })
      })
      .catch((error) => {
        dispatch({ type: 'PATCH_FINANCEOPTION_ERROR', error })
        dispatch({ type: 'SET_SNACKBAR', data: { variant: 'error', message: 'Feil ved oppdatering av finansiering' } })
      })
  }
}

export const subscriptionChange = (subscriptionChange) => {
  return (dispatch) => {
    dispatch({ type: 'SUBSCRIPTION_CHANGE_REQUEST' })

    axios.post(`${API_ROOT}financeOption/subscription`, JSON.stringify(subscriptionChange), configWithAuth())
      .then((result) => {
        dispatch({ type: 'SUBSCRIPTION_CHANGE_SUCCESS', data: result.data })
        if (subscriptionChange.subscribe) {
          dispatch({ type: 'SET_SNACKBAR', data: { variant: 'info', message: 'Nytt abonnement registrert' } })
        } else {
          dispatch({ type: 'SET_SNACKBAR', data: { variant: 'info', message: 'Abonnement avregistrert' } })
        }
      })
      .catch((error) => {
        dispatch({ type: 'SUBSCRIPTION_CHANGE_ERROR', error })
      })
  }
}

export const getSubscriptions = () => {
  return (dispatch) => {
    dispatch({ type: 'SUBSCRIPTION_GET_REQUEST' })

    axios.get(`${API_ROOT}financeOption/subscription`, configWithAuth())
      .then((result) => {
        dispatch({ type: 'SUBSCRIPTION_GET_SUCCESS', data: result.data })
      })
      .catch((error) => {
        dispatch({ type: 'SUBSCRIPTION_GET_ERROR', error })
        dispatch({ type: 'SET_SNACKBAR', data: { variant: 'error', message: 'Feil ved henting av abonnement' } })
      })
  }
}

export const uploadFinanceOptionResourceFile = (formData) => {
  return (dispatch) => {
    dispatch({ type: 'UPLOAD_FINANCEOPTIONRESOURCE.REQUEST' })

    axios.post(`${API_ROOT}financeoption/upload-resource-file`, formData, configWithAuth('multipart/form-data'))
      .then((result) => {
        dispatch({ type: 'UPLOAD_FINANCEOPTIONRESOURCE.SUCCESS', data: result.data })
        dispatch({ type: 'SET_SNACKBAR', data: { variant: 'success', message: 'Lenken er lastet opp.' } })
      })
      .catch((error) => {
        dispatch({ type: 'UPLOAD_FINANCEOPTIONRESOURCE.ERROR', error })
        dispatch({ type: 'SET_SNACKBAR', data: { variant: 'error', message: 'Feil ved oppdatering av lenke.' } })
      })
  }
}

export const uploadFinanceOptionResourceUrl = (formData) => {
  return (dispatch) => {
    dispatch({ type: 'UPLOAD_FINANCEOPTIONRESOURCE.REQUEST' })

    axios.post(`${API_ROOT}financeoption/upload-resource-url`, formData, configWithAuth('multipart/form-data'))
      .then((result) => {
        dispatch({ type: 'UPLOAD_FINANCEOPTIONRESOURCE.SUCCESS', data: result.data })
        dispatch({ type: 'SET_SNACKBAR', data: { variant: 'success', message: 'Filen er lastet opp.' } })
      })
      .catch((error) => {
        dispatch({ type: 'UPLOAD_FINANCEOPTIONRESOURCE.ERROR', error })
        dispatch({ type: 'SET_SNACKBAR', data: { variant: 'error', message: 'Feil ved oppdatering av fil.' } })
      })
  }
}

export const deleteResource = (data) => {
  return (dispatch) => {
    dispatch({ type: 'DELETE_FINANCEOPTIONRESOURCE.REQUEST' })

    axios.post(`${API_ROOT}financeoption/delete-resource`, JSON.stringify(data), configWithAuth())
      .then((result) => {
        dispatch({ type: 'DELETE_FINANCEOPTIONRESOURCE.SUCCESS', data: result.data })
        dispatch({ type: 'SET_SNACKBAR', data: { variant: 'success', message: 'Filen er fjernet.' } })
      })
      .catch((error) => {
        dispatch({ type: 'DELETE_FINANCEOPTIONRESOURCE.ERROR', error })
        dispatch({ type: 'SET_SNACKBAR', data: { variant: 'error', message: 'Feil ved fjerning av fil.' } })
      })
  }
}

export const getFinanceOptionGrants = (id) => {
  return (dispatch) => {
    dispatch({ type: 'GET_FINANCEOPTIONGRANTS.REQUEST' })

    axios.get(`${API_ROOT}financeoption/${id}/grants`, configWithAuth())
      .then((result) => {
        dispatch({ type: 'GET_FINANCEOPTIONGRANTS.SUCCESS', data: result.data })
      })
      .catch((error) => {
        dispatch({ type: 'GET_FINANCEOPTIONGRANTS.ERROR', error })
        dispatch({ type: 'SET_SNACKBAR', data: { variant: 'error', message: 'Feil ved henting av tilskudd.' } })
      })
  }
}

export const uploadFinanceOptionGrants = (data) => {
  return (dispatch) => {
    dispatch({ type: 'UPLOAD_FINANCEOPTIONGRANTS.REQUEST' })

    axios.post(`${API_ROOT}financeoption/grants`, JSON.stringify(data), configWithAuth())
      .then((result) => {
        dispatch({ type: 'UPLOAD_FINANCEOPTIONGRANTS.SUCCESS', data: result.data })
        dispatch({ type: 'SET_SNACKBAR', data: { variant: 'success', message: 'Tilskudd er lastet opp.' } })
      })
      .catch((error) => {
        dispatch({ type: 'UPLOAD_FINANCEOPTIONGRANTS.ERROR', error })
        dispatch({ type: 'SET_SNACKBAR', data: { variant: 'error', message: 'Feil ved opplasting av tilskudd.' } })
      })
  }
}

export const addFinanceOptionFavourite = (fundingOptionId) => {
    return (dispatch) => {
        dispatch({ type: 'ADD_FINANCEOPTIONFAVOURITE.REQUEST' })

        axios.post(`${API_ROOT}financeoption/favourites/${fundingOptionId}`, null, configWithAuth())
            .then((result) => {
                dispatch({ type: 'ADD_FINANCEOPTIONFAVOURITE.SUCCESS', data: result.data })
                dispatch({ type: 'SET_SNACKBAR', data: { variant: 'success', message: ' Ny favoritt lagt til.' } })
            })
            .then(() => {
                dispatch(getUser());
            })
            .catch((error) => {
                dispatch({ type: 'ADD_FINANCEOPTIONFAVOURITE.ERROR', error })
                dispatch({ type: 'SET_SNACKBAR', data: { variant: 'error', message: 'Feil ved oppretting av favoritt.' } })
            })
    }
}

export const removeFinanceOptionFavourite = (fundingOptionId) => {
    return (dispatch) => {
        dispatch({ type: 'REMOVE_FINANCEOPTIONFAVOURITE.REQUEST' })

        axios.delete(`${API_ROOT}financeoption/favourites/${fundingOptionId}`, configWithAuth())
            .then((result) => {
                dispatch({ type: 'REMOVE_FINANCEOPTIONFAVOURITE.SUCCESS', data: result.data })
                dispatch({ type: 'SET_SNACKBAR', data: { variant: 'success', message: 'Favoritt fjernet.' } })
            })
            .then(() => {
                dispatch(getUser());
            })
            .catch((error) => {
                dispatch({ type: 'REMOVE_FINANCEOPTIONFAVOURITE.ERROR', error })
                dispatch({ type: 'SET_SNACKBAR', data: { variant: 'error', message: 'Feil ved fjerning av favoritt.' } })
            })
    }
}
