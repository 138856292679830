import React, { useEffect } from 'react'

import Grid from '@mui/material/Grid'
import { makeStyles } from '@mui/styles'
import Typography from '@mui/material/Typography'
import AddIcon from '@mui/icons-material/AddCircle'
import ProjectCard from '../components/ProjectCard'

import { Link, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { getYourProjects } from '../store/actions/projectActions'
import Fade from '@mui/material/Fade'
import { projectStageEnums } from '../constants/projectEnums'

const useStyles = makeStyles(theme => ({
  root: {
    padding: '20px'
  },
  newProject: {
    padding: '20px',
    height: '300px',
    overflow: 'hidden',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '20px',
    display: 'flex',
    background: 'none'
  },
  addIcon: {
    height: '50px',
    width: '50px',
    margin: '10px auto'
  },
  createProjectButton: {
    cursor: 'pointer',
    display: 'grid',
    userSelect: 'none',
    transition: 'all 150ms ease-out',
    color: '#a3454b',
    '&:hover': {
      color: '#FF666E'
    }
  }
}))

const Projects = (props) => {
  const { yourProjects, userInfo } = props
  const classes = useStyles()

  useEffect(() => {
    props.getYourProjects(userInfo.currentlyActiveOrganization)
  }, [])

  return (
        <div className={classes.root}>
            <Grid container spacing={5}>
                <Grid item xs={12}>
                    <Typography variant={'body2'} fullWidth>
                        Her kan du administrere prosjektene dine
                    </Typography>
                    <Typography variant={'h4'}>
                        Mine Prosjekter
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant={'h6'}>
                        Pågående prosjekter
                    </Typography>
                </Grid>
                {
                    yourProjects && yourProjects.filter(x => x.stage !== projectStageEnums.Released).map((project, index) => (
                        <Fade key={index} in timeout={500} style={{ transitionDelay: index * 25 }}>
                            <Grid item xs={12} sm={6} lg={3}>
                                <ProjectCard key={project.id} project={project} {...props} />
                            </Grid>
                        </Fade>
                    ))
                }
                <Grid item xs={12} sm={6} lg={3}>
                    <div className={classes.newProject} >
                        <Link to={'new-project'}
                             className={classes.createProjectButton}
                        >
                            <AddIcon className={classes.addIcon} />
                            <Typography variant={'subtitle2'}>
                                Opprett spillprosjekt
                            </Typography>
                        </Link>

                    </div>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant={'h6'}>
                        Ferdigstilte prosjekter
                    </Typography>
                </Grid>
                {
                    yourProjects && yourProjects.filter(x => x.stage === projectStageEnums.Released).map((project, index) => (
                        <Fade key={index} in timeout={500} style={{ transitionDelay: index * 25 }}>
                            <Grid item xs={12} sm={6} lg={3}>
                                <ProjectCard key={project.id} project={project} {...props} />
                            </Grid>
                        </Fade>
                    ))
                }
            </Grid>
        </div>
  )
}

const mapStateToProps = (state) => {
  return {
    error: state.project.error,
    yourOrganizations: state.organization.yourOrganizations,
    yourProjects: state.project.yourProjects,
    userInfo: state.user.userInfo
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getYourProjects: (organizationId) => dispatch(getYourProjects(organizationId))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Projects))
