import React, { useEffect, useState } from 'react'

import Grid from '@mui/material/Grid'
import { makeStyles } from '@mui/styles'
import Typography from '@mui/material/Typography'
import Card from '@mui/material/Card'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import CardHeader from '@mui/material/CardHeader'
import {
  deleteProject,
  getGenres,
  getYourProjects,
  patchProject,
  uploadCoverImage
} from '../store/actions/projectActions'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import Chip from '@mui/material/Chip'
import Autocomplete from '@mui/material/Autocomplete'
import CardContent from '@mui/material/CardContent'
import moment from 'moment'
import DatePicker from '@mui/lab/DatePicker';
import NumberFormat from 'react-number-format'
import {
  companyRoleEnum,
  gameBusinessModelEnum,
  gamePlayModeEnum, gamePurposeEnum,
  pegiRatingEnum,
  projectStageEnums
} from '../constants/projectEnums'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Checkbox from '@mui/material/Checkbox'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import {FormattedMessage, useIntl} from 'react-intl'
import jsonpatch from 'fast-json-patch'
import FormControlLabel from '@mui/material/FormControlLabel'
import SaveUnsavedChangesPopup from '../components/SaveUnsavedChangesPopup'
import AppBar from '@mui/material/AppBar'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import { flagToArray } from '../constants/helpers'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogActions from '@mui/material/DialogActions'
import CultureTestDialog from '../components/CultureTestDialog'
import Divider from '@mui/material/Divider'
import CardActions from '@mui/material/CardActions'

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
const checkedIcon = <CheckBoxIcon fontSize="small" />

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: '50px',
    padding: '20px'
  },
  adminBar: {
    position: 'fixed',
    top: '64px',
    height: '50px',
    left: '0px',
    right: '0px',
    background: theme.palette.background.default,
    zIndex: 1300,
    boxShadow: '0px 10px 10px rgba(0, 0, 0, 0.1)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0px 15px 0px 25px',
    [theme.breakpoints.down('xs')]: {
      left: '0px'
    }
  },
}))

const EditProjectPage = (props) => {
  const { yourProjects, yourOrganizations, userInfo, genres, metadata, platforms } = props
  const classes = useStyles()
  const intl = useIntl()
  const currentDate = new Date()

  const [deleteOpen, setDeleteOpen] = useState(false)
  const [activeCultureTestStep, setActiveCultureTestStep] = React.useState(0)
  const [completedCultureTest, setCompletedCultureTest] = React.useState(false)
  const [cultureTestOpen, setCultureTestOpen] = React.useState(false)
  const [deleteDialogueProjectName, setDeleteDialogueProjectName] = useState('')
  const [value, setValue] = useState(0)
  const [dataCopy, setDataCopy] = useState({
    organizationId: null,
    name: '',
    description: '',
    coverImageUrl: '',
    genre: '',
    releaseDate: '',
    productionBudget: 0,
    marketingBudget: 0,
    stage: '0',
    genres: [],
    pegiRating: 0,
    esrbRating: 0,
    gamePurpose: 0,
    companyRole: 0,
    gamePlayMode: 0,
    businessModel: 0,
    rightsToIp: null,
    documentationInNorwegianOrSami: null,
    relevantForNorwegianAudience: null,
    mainPlotIsInNorwayOrEUOrFantasy: null,
    containsCentralNarrativeThroughEntireGame: null,
    containsNorwegianOriginalScore: null,
    availableInLanguages: [],
    platforms: []
  })
  const [dataBackup, setDataBackup] = useState({
    organizationId: null,
    name: '',
    description: '',
    coverImageUrl: '',
    genre: '',
    releaseDate: '',
    productionBudget: 0,
    marketingBudget: 0,
    stage: '0',
    genres: [],
    pegiRating: 0,
    esrbRating: 0,
    gamePurpose: 0,
    companyRole: 0,
    gamePlayMode: 0,
    businessModel: 0,
    rightsToIp: null,
    documentationInNorwegianOrSami: null,
    relevantForNorwegianAudience: null,
    mainPlotIsInNorwayOrEUOrFantasy: null,
    containsCentralNarrativeThroughEntireGame: null,
    containsNorwegianOriginalScore: null,
    availableInLanguages: [],
    platforms: []
  })

  useEffect(() => {
    setDataCopy({
      ...dataCopy,
      organizationId: userInfo.currentlyActiveOrganization
    })
    
    props.getGenres()
    props.getYourProjects(userInfo.currentlyActiveOrganization)
  }, [])

  useEffect(() => {
    const projectQueryId = props.match.params.id
    if (projectQueryId != null) {
      const currentProject = yourProjects.find(x => x.id.toString() === projectQueryId)

      if (currentProject != null) {
        setDataCopy(currentProject)
        setDataBackup(currentProject)
      }
    }
  }, [yourProjects])

  const deleteProjectClicked = (event) => {
    const projectId = props.match.params.id

    if (projectId) { props.deleteProject(projectId) }
  }

  const saveChangesClicked = (event) => {
    const projectQueryId = props.match.params.id
    if (projectQueryId != null) {
      const currentProject = yourProjects.find(x => x.id.toString() === projectQueryId)

      if (currentProject != null) {
        const patch = jsonpatch.compare(currentProject, dataCopy)
        props.patchProject(patch, projectQueryId)

        setDataBackup(dataCopy)
      }
    }
  }

  const changeNewProject = (event) => {
    setDataCopy({
      ...dataCopy,
      [event.target.id]: event.target.value
    })
  }

  const handleGenreChange = (event, value) => {
    setDataCopy({
      ...dataCopy,
      genres: value
    })
  }

  const handlePlatformChange = (event, value) => {
    setDataCopy({
      ...dataCopy,
      platforms: value
    })
  }

  const handleDateChange = (date, attribute) => {
    setDataCopy({
      ...dataCopy,
      [attribute]: date
    })
  }

  const changeProjectNumberInfo = (values, attribute) => {
    setDataCopy({
      ...dataCopy,
      [attribute]: values.floatValue
    })
  }

  const handleSelectChange = (event, attribute) => {
    setDataCopy({
      ...dataCopy,
      [attribute]: event.target.value
    })
  }

  const handleCheckboxChange = name => event => {
    setDataCopy({
      ...dataCopy,
      [name]: event.target.checked
    })
  }

  const handleAutoCompleteChange = (event, value, attribute) => {
    setDataCopy({
      ...dataCopy,
      [attribute]: value
    })
  }

  const onFlagValueChanged = (event, attribute, property, enumFlags) => {
    let roles = 0

    attribute.forEach((attr) => {
      roles = roles | enumFlags[attr]
    })

    setDataCopy({
      ...dataCopy,
      [property]: roles
    })
  }

  const gamePlayModes = flagToArray(dataCopy.gamePlayMode, gamePlayModeEnum)
  const gameBusinessModels = flagToArray(dataCopy.businessModel, gameBusinessModelEnum)
  const companyRoles = flagToArray(dataCopy.companyRole, companyRoleEnum)

  const changes = jsonpatch.compare(dataCopy, dataBackup)

  const passNeeded = 3
  let passCount = 0
  if (dataCopy.documentationInNorwegianOrSami) {
    passCount += 1
  }
  if (dataCopy.relevantForNorwegianAudience) {
    passCount += 1
  }
  if (dataCopy.mainPlotIsInNorwayOrEUOrFantasy) {
    passCount += 1
  }
  if (dataCopy.containsCentralNarrativeThroughEntireGame) {
    passCount += 1
  }

  const passedCultureTest = passCount >= passNeeded

  const availablePlatforms = metadata.platforms.filter(x => !dataCopy.platforms.find(y => y.id === x.id))

  return (
      <div className={classes.root}>
        <CultureTestDialog cultureTestOpen={cultureTestOpen}
                           setCultureTestOpen={setCultureTestOpen}
                           dataCopy={dataCopy}
                           setDataCopy={setDataCopy}
                           setCompletedCultureTest={setCompletedCultureTest}
                           passedCultureTest={passedCultureTest}
        />
        <SaveUnsavedChangesPopup show={!cultureTestOpen && changes.length > 0} onSaveClicked={() => saveChangesClicked(null)}/>
        <Dialog open={deleteOpen} onClose={(event) => setDeleteOpen(false)} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Slett prosjekt</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {'Om du virkelig ønsker å slette prosjektet, skriv prosjektnavn og trykk "Slett"'}
            </DialogContentText>
            <TextField
                autoFocus
                margin="dense"
                id="projectName"
                label="Prosjektnavn"
                type="name"
                onChange={(event) => setDeleteDialogueProjectName(event.target.value)}
                value={deleteDialogueProjectName}
                fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={(event) => setDeleteOpen(false)} color="primary">
              Angre
            </Button>
            <Button
                onClick={deleteProjectClicked}
                color="primary"
                disabled={deleteDialogueProjectName.toLowerCase() !== dataCopy.name.toLowerCase()}
            >
              Slett
            </Button>
          </DialogActions>
        </Dialog>
        <div className={classes.adminBar}>
          <Typography variant={'body2'} style={{ background: '#E43030', color: '#FFF', padding: '5px 10px', userSelect: 'none', borderRadius: '5px' }}>
            <FormattedMessage
                id={'Global.YouAreEditing'}
                defaultMessage={'Gjør endringer'}
            />
          </Typography>
          <Button variant={'contained'}
                  onClick={(event) => {
                    if (changes.length > 0) {
                      if (window.confirm('Du har ulagrede endringer, er du sikker på at du vil gå tilbake uten å lagre?')) {
                        window.history.back()
                      }
                    } else {
                      window.history.back()
                    }
                  }}>
            <FormattedMessage
                id={'Global.EndEdit'}
                defaultMessage={'Avslutt redigering'}
            />
          </Button>
        </div>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant={'h4'}>
              {dataCopy.name}
            </Typography>
          </Grid>
        </Grid>
        <AppBar position="static" style={{ marginTop: '20px' }}>
          <Tabs
              value={value}
              onChange={(event, value) => setValue(value)}
          >
            <Tab label="Generelt" tabIndex={0}/>
            <Tab label="Fakta" tabIndex={1}/>
            <Tab label="Økonomi" tabIndex={2}/>
            <Tab label="Utgivelse" tabIndex={3}/>
          </Tabs>
        </AppBar>
        {
          value === 0 &&
          <div style={{ padding: '20px 0px' }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={8}>
                <Card>
                  <CardHeader
                      title={'Generelt'}
                      subheader={'Spillprosjektet er kun synlig for deg og din organisasjon.'}
                  />
                  <CardContent>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <TextField
                            id="name"
                            name="name"
                            autoComplete={'off'}
                            value={dataCopy.name}
                            onChange={changeNewProject}
                            type="text"
                            label={'Har spillet et navn? (Du kan bruke kodenavn)'}
                            fullWidth
                            variant={'filled'}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                            id="description"
                            name="description"
                            value={dataCopy.description}
                            onChange={changeNewProject}
                            type="text"
                            label={'Her må du gjerne skrive eller lime inn en beskrivelse'}
                            fullWidth
                            variant={'filled'}
                            multiline
                            rows={5}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl variant={'filled'} fullWidth>
                          <InputLabel id="stage">Hvor langt på vei er spillet?</InputLabel>
                          <Select
                              labelId="stage"
                              id="stage"
                              label={'Hvor langt på vei er spillet?'}
                              value={dataCopy.stage}
                              onChange={(event) => handleSelectChange(event, 'stage')}
                          >
                            {
                              Object.keys(projectStageEnums).map((projectStageType, index) => (
                                  <MenuItem value={projectStageEnums[projectStageType]}
                                            key={projectStageEnums[projectStageType]}>
                                    {intl.formatMessage({ id: projectStageType })}
                                  </MenuItem>
                              ))
                            }
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </CardContent>
                  <CardActions>
                    <Button color={'primary'}
                            variant={'filled'}
                            onClick={(event) => setDeleteOpen(true)}
                    >
                      <FormattedMessage
                          id="Global.DeleteProjecttPage"
                          defaultMessage="Slett prosjektet"
                      />
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            </Grid>
          </div>
        }
        {
          value === 1 &&
          <div style={{ padding: '20px 0px' }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={8}>
                <Card>
                  <CardHeader
                      title={'Fakta'}
                      subheader={'Spillprosjektet er kun synlig for deg og din organisasjon.'}
                  />
                  <CardContent>
                    <Grid container spacing={2}>
                      <Grid item xs={8}>
                        <Autocomplete
                            multiple
                            id="genres"
                            options={genres.sort((a, b) => a.title.localeCompare(b.title))}
                            filterSelectedOptions
                            disableCloseOnSelect
                            onChange={handleGenreChange}
                            getOptionLabel={option => option.title}
                            value={dataCopy.genres.sort((a, b) => a.title - b.title)}
                            renderTags={(value, getTagProps) =>
                                value.map((option, index) => (
                                    <Chip key={index} label={option.title} {...getTagProps({ index })} />
                                ))
                            }
                            style={{ width: '100%' }}
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    variant="filled"
                                    label={'Hvilke sjangre har spillet?'}
                                    placeholder=""
                                    fullWidth
                                />
                            )}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant={'h5'}>
                          Kulturtesten
                        </Typography>
                        <Divider/>
                      </Grid>
                      <Grid item xs={12}>
                        {
                          !cultureTestOpen &&
                          <Typography variant={'body2'} gutterBottom>
                            {passedCultureTest ? 'Du har bestått kulturtesten' : 'Ikke bestått kulturtesten'}
                          </Typography>
                        }
                        <Grid item xs={12}>
                          <Button variant={'contained'}
                                  style={{ margin: '10px auto' }}
                                  onClick={event => {
                                    setActiveCultureTestStep(0)
                                    setCultureTestOpen(true)
                                  }}
                          >
                            Ta kulturtesten
                          </Button>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} sm={8}>
                        <Autocomplete
                            multiple
                            id="gamePlayMode"
                            options={Object.keys(gamePlayModeEnum).map((key, index) => key)}
                            disableCloseOnSelect
                            value={gamePlayModes}
                            onChange={(event, attributes) => onFlagValueChanged(event, attributes, 'gamePlayMode', gamePlayModeEnum)}
                            getOptionLabel={option => {
                              return intl.formatMessage({ id: option })
                            }}
                            renderOption={(props, option, { selected }) => (
                                <li {...props}>
                                  <Checkbox
                                      icon={icon}
                                      checkedIcon={checkedIcon}
                                      style={{ marginRight: 8 }}
                                      checked={selected}
                                  />
                                  {intl.formatMessage({ id: option })}
                                </li>
                            )}
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    fullWidth
                                    label="Hvilke spillermoduser har spillet?"
                                    variant="filled"
                                />
                            )}
                        />
                      </Grid>
                      <Grid item xs={12} sm={8}>
                        <FormControl fullWidth variant={'filled'}>
                          <InputLabel id="gamePurpose">Spillets formål</InputLabel>
                          <Select
                              labelId="gamePurpose"
                              id="gamePurpose"
                              label={'Spillets formål'}
                              value={dataCopy.gamePurpose}
                              onChange={(event) => handleSelectChange(event, 'gamePurpose')}
                          >
                            {
                              Object.keys(gamePurposeEnum).map((key, index) => (
                                  <MenuItem value={gamePurposeEnum[key]}
                                            key={gamePurposeEnum[key]}>
                                    {intl.formatMessage({ id: key })}
                                  </MenuItem>
                              ))
                            }
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={8}>
                        <Autocomplete
                            multiple
                            autoComplete={false}
                            id="companyRole"
                            options={Object.keys(companyRoleEnum).map((key, index) => key)}
                            disableCloseOnSelect
                            value={companyRoles}
                            onChange={(event, attributes) => onFlagValueChanged(event, attributes, 'companyRole', companyRoleEnum)}
                            getOptionLabel={option => {
                              return intl.formatMessage({ id: option })
                            }}
                            renderOption={(props, option, { selected }) => (
                                <li {...props}>
                                  <Checkbox
                                      icon={icon}
                                      checkedIcon={checkedIcon}
                                      style={{ marginRight: 8 }}
                                      checked={selected}
                                  />
                                  {intl.formatMessage({ id: option })}
                                </li>
                            )}
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    fullWidth
                                    label="Hva er selskapets rolle i prosjektet?"
                                    variant="filled"
                                />
                            )}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormControlLabel
                            control={
                              <Checkbox
                                  checked={dataCopy.rightsToIp ?? false}
                                  onChange={handleCheckboxChange('rightsToIp')}
                                  value={'rightsToIp'}
                                  color="primary"
                              />
                            }
                            label="Har du rettighetene til spillets IP?"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormControlLabel
                            control={
                              <Checkbox
                                  checked={dataCopy.containsCentralNarrativeThroughEntireGame ?? false}
                                  onChange={handleCheckboxChange('containsCentralNarrativeThroughEntireGame')}
                                  value="containsCentralNarrativeThroughEntireGame"
                                  color="primary"
                              />
                            }
                            label="Inneholder et sentralt narrativ gjennom hele spillet"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormControlLabel
                            control={
                              <Checkbox
                                  checked={dataCopy.containsNorwegianOriginalScore ?? false}
                                  onChange={handleCheckboxChange('containsNorwegianOriginalScore')}
                                  value="containsNorwegianOriginalScore"
                                  color="primary"
                              />
                            }
                            label="Inneholder ny originalmusikk fra norsk komponist"
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </div>
        }
        {
          value === 2 &&
          <div style={{ padding: '20px 0px' }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={8}>
                <Card>
                  <CardHeader
                      title={'Økonomi'}
                      subheader={'Spillprosjektet er kun synlig for deg og din organisasjon.'}
                  />
                  <CardContent>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <NumberFormat value={dataCopy.productionBudget}
                                      displayType={'input'}
                                      fullWidth
                                      variant={'filled'}
                                      thousandSeparator={' '}
                                      prefix={'NOK '}
                                      id='productionBudget'
                                      label={'Produksjonsbudsjett'}
                                      onValueChange={(values) => changeProjectNumberInfo(values, 'productionBudget')}
                                      customInput={TextField}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <NumberFormat value={dataCopy.marketingBudget}
                                      displayType={'input'}
                                      thousandSeparator={' '}
                                      fullWidth
                                      variant={'filled'}
                                      prefix={'NOK '}
                                      label={'Markedsføringsbudjett'}
                                      id='marketingBudget'
                                      onValueChange={(values) => changeProjectNumberInfo(values, 'marketingBudget')}
                                      customInput={TextField}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Autocomplete
                            multiple
                            id="businessModel"
                            options={Object.keys(gameBusinessModelEnum).map((key, index) => key)}
                            disableCloseOnSelect
                            value={gameBusinessModels}
                            onChange={(event, attributes) => onFlagValueChanged(event, attributes, 'businessModel', gameBusinessModelEnum)}
                            getOptionLabel={option => {
                              return intl.formatMessage({ id: option })
                            }}
                            renderOption={(props, option, { selected }) => (
                                <li {...props}>
                                  <Checkbox
                                      icon={icon}
                                      checkedIcon={checkedIcon}
                                      style={{ marginRight: 8 }}
                                      checked={selected}
                                  />
                                  {intl.formatMessage({ id: option })}
                                </li>
                            )}
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    fullWidth
                                    label="Hvilke forretningsmodeller har spillet?"
                                    variant="filled"
                                />
                            )}
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </div>
        }
        {
          value === 3 &&
          <div style={{ padding: '20px 0px' }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={8}>
                <Card>
                  <CardHeader
                      title={'Utgivelse'}
                      subheader={'Spillprosjektet er kun synlig for deg og din organisasjon.'}
                  />
                  <CardContent>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <FormControl fullWidth variant={'filled'}>
                          <InputLabel id="pegiRating">PEGI Rating</InputLabel>
                          <Select
                              labelId="pegiRating"
                              id="pegiRating"
                              label={'PEGI Rating'}
                              value={dataCopy.pegiRating}
                              onChange={(event) => handleSelectChange(event, 'pegiRating')}
                          >
                            {
                              Object.keys(pegiRatingEnum).map((key, index) => (
                                  <MenuItem value={pegiRatingEnum[key]}
                                            key={pegiRatingEnum[key]}>
                                    {intl.formatMessage({ id: key })}
                                  </MenuItem>
                              ))
                            }
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <DatePicker
                            clearable
                            openTo="year"
                            format="DD/MM/YYYY"
                            label={'Har spillet en lanseringsdato?'}
                            views={['year', 'month', 'day']}
                            margin="normal"
                            id="releaseDate"
                            value={dataCopy.releaseDate ? moment(dataCopy.releaseDate) : null}
                            onChange={(date) => handleDateChange(date, 'releaseDate')}
                            renderInput={(params) => <TextField fullWidth variant={"outlined"} {...params} helperText={null} />}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Autocomplete
                            multiple
                            id="platforms"
                            options={availablePlatforms}
                            filterSelectedOptions
                            disableCloseOnSelect
                            onChange={handlePlatformChange}
                            getOptionLabel={option => option.name}
                            value={dataCopy.platforms}
                            renderOption={(props, option, { selected }) => (
                                <li {...props}>
                                  <Checkbox
                                      icon={icon}
                                      checkedIcon={checkedIcon}
                                      style={{ marginRight: 8 }}
                                      checked={selected}
                                  />
                                  {option.name}
                                </li>
                            )}
                            style={{ width: '100%' }}
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    variant="filled"
                                    label={'Plattformer'}
                                    placeholder=""
                                    fullWidth
                                />
                            )}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Autocomplete
                            multiple
                            id="availableInLanguages"
                            options={metadata.languages.map(x => x.name)}
                            // filterSelectedOptions
                            disableCloseOnSelect
                            onChange={(event, value) => handleAutoCompleteChange(event, value, 'availableInLanguages')}
                            getOptionLabel={option => option}
                            value={dataCopy.availableInLanguages}
                            renderOption={(props, option, { selected }) => (
                                <li {...props}>
                                  <Checkbox
                                      icon={icon}
                                      checkedIcon={checkedIcon}
                                      style={{ marginRight: 8 }}
                                      checked={selected}
                                  />
                                  {intl.formatMessage({ id: option })}
                                </li>
                            )}
                            style={{ width: '100%' }}
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    variant="filled"
                                    label={'Hvilke språk blir spillet tilgjengelig på?'}
                                    fullWidth
                                />
                            )}
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </div>
        }
      </div>
  )
}

const mapStateToProps = (state) => {
  return {
    error: state.project.error,
    yourOrganizations: state.organization.yourOrganizations,
    yourProjects: state.project.yourProjects,
    userInfo: state.user.userInfo,
    genres: state.project.genres,
    platforms: state.metadata.metadata.platforms,
    fetchingGenres: state.project.fetchingGenres,
    metadata: state.metadata.metadata
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getYourProjects: (organizationId) => dispatch(getYourProjects(organizationId)),
    updateProjectCover: (formData) => dispatch(uploadCoverImage(formData)),
    deleteProject: (projectId) => dispatch(deleteProject(projectId)),
    patchProject: (patch, projectId) => dispatch(patchProject(patch, projectId)),
    getGenres: () => dispatch(getGenres())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(EditProjectPage))
