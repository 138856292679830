import React, { useCallback, useEffect, useState } from 'react'

import Grid from '@mui/material/Grid'
import { makeStyles, useScrollTrigger } from '@mui/styles'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import Table from '@mui/material/Table'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableBody from '@mui/material/TableBody'
import LinearProgress from '@mui/material/LinearProgress'
import CardHeader from '@mui/material/CardHeader'
import { deleteProject, getYourProjects, patchProject, uploadCoverImage, getGenres } from '../store/actions/projectActions'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { useDropzone } from 'react-dropzone'
import ImageIcon from '@mui/icons-material/Image'
import Chip from '@mui/material/Chip'
import {
    companyRoleEnum,
    gameBusinessModelEnum,
    gamePlayModeEnum, gamePurposeEnum, pegiRatingEnum,
    projectStageEnums,
    projectStrengths
} from '../constants/projectEnums'
import NumberFormat from 'react-number-format'
import moment from 'moment'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import CardContent from '@mui/material/CardContent'
import { history } from '../store/history'
import {FormattedMessage} from 'react-intl'
import { useIntl } from 'react-intl'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
    checkBool,
    checkCompanyAge,
    checkCultureTest,
    checkFlag,
    checkOrganizationLocation,
    filterBasedOnCultureTest,
    filterBasedOnOrgLocation,
    filterOnBool,
    filterOnCompanyAge,
    filterOnFlag
} from '../constants/filteringLogic'
import CheckIcon from '@mui/icons-material/Check'
import ClearIcon from '@mui/icons-material/Clear'
import AccordionDetails from '@mui/material/AccordionDetails'
import { flagToArray } from '../constants/helpers'
import CriteriaStatus from '../components/CriteriaStatus'
import { Link } from 'react-router-dom'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
const checkedIcon = <CheckBoxIcon fontSize="small" />

const useStyles = makeStyles(theme => ({
    root: {
        marginTop: '50px',
        padding: '20px'
    },
    adminBar: {
        position: 'fixed',
        top: '64px',
        height: '50px',
        left: '0px',
        right: '0px',
        background: theme.palette.background.default,
        zIndex: 1300,
        boxShadow: '0px 10px 10px rgba(0, 0, 0, 0.1)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '0px 15px 0px 25px',
        [theme.breakpoints.down('xs')]: {
            left: '0px'
        }
    },
    topButton: {
        margin: '10px',
        float: 'right'
    },
    tableCard: {
        overflowY: 'auto'
    },
    table: {
        width: '100%'
    },
    tableCell: {
        borderBottom: 'solid #FFF 1px',
        paddingLeft: '5px',
        paddingRight: '5px !important'
    },
    progress: {
        position: 'relative',
        height: '30px',
        width: '100%',
        marginBottom: '10px'
    },
    barBackground: {
        height: '100%',
        width: '100%',
        borderRadius: '10px'
        // background: 'rgba(83, 38, 117, 0.5)',
    },
    barProgress: {
        // background: 'rgba(0, 0, 0, 0.3)',
        borderTopRightRadius: '10px',
        borderBottomRightRadius: '10px'
    },
    progressText: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        fontWeight: 'bold',
        fontSize: '10px',
        alignItems: 'center',
        display: 'flex',
        userSelect: 'none'
    },
    editIcon: {
        cursor: 'pointer',
        height: '18px',
        width: '18px',
        marginLeft: '10px',
        color: '#FFFFFFAA',
        transition: 'all 150ms ease-out',
        '&:hover': {
            color: '#FFFFFFFF'
        }
    },
    headerContainer: {
        height: '100px',
        display: 'flex'
    },
    projectImageContainer: {
        position: 'relative',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        background: '#00000033',
        borderRadius: '10px',
        width: '60px',
        minWidth: '60px',
        height: '60px',
        minHeight: '60px',
        margin: 'auto 15px auto 0px',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        color: '#FFFFFF00',
        transition: 'all 150ms ease-in-out',
        outline: 'none',
        '&:hover': {
            color: '#FFFFFFFF',
            cursor: 'pointer'
        }
    },
    projectNameAndDescription: {
        margin: 'auto 0px'
    },
    factInput: {
        border: '0px',
        width: '100%',
        color: theme.palette.secondary.contrastText,
        paddingTop: '0px',
        fontSize: '0.875rem !important',
        height: 'auto',
        padding: '0px !important',
        background: 'rgba(0, 0, 0, 0.2)',
        textAlign: 'right'
    },
    formControl: {
        width: '100%'
    },
    actionButtons: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        [theme.breakpoints.down('xs')]: {
            justifyContent: 'space-evenly'
        }
    },
    actionButton: {
        margin: '5px',
        textDecoration: 'none'
    }
}))

const ProjectPage = (props) => {
    const { yourProjects, yourOrganizations, userInfo, genres, fetchingGenres, financeOptions, metadata } = props
    const classes = useStyles()

    const onDrop = useCallback(acceptedFiles => {
        const formData = new FormData()
        formData.append('projectId', props.match.params.id)
        formData.append('file', acceptedFiles[0])
        props.updateProjectCover(formData)
    }, [])

    const intl = useIntl()
    const [scroll, setScroll] = React.useState('paper')

    const [viewAsMember, setViewAsMember] = useState(false)
    const [showNonMatching, setShowNonMatching] = useState(false)

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, accept: 'image/png, image/jpeg' })
    const [anchorSettingsEl, setAnchorSettingsEl] = React.useState(null)
    const [localProjectCopy, setLocalProjectCopy] = useState({
        id: '',
        name: '',
        description: '',
        coverImageUrl: '',
        genre: '',
        releaseDate: '',
        productionBudget: 0,
        marketingBudget: 0,
        stage: '1',
        genres: [],
        organization: {
            id: -1
        },
        rightsToIp: false
    })
    const [localProjectBackup, setLocalProjectBackup] = useState({
        id: '',
        name: '',
        description: '',
        coverImageUrl: '',
        genre: '',
        releaseDate: '',
        productionBudget: 0,
        marketingBudget: 0,
        stage: '1',
        genres: [],
        organization: {
            id: -1
        }
    })

    useEffect(() => {
        const projectQueryId = props.match.params.id
        if (projectQueryId != null) {
            const currentProject = yourProjects.find(x => x.id.toString() === projectQueryId)

            if (currentProject != null) {
                setLocalProjectCopy(currentProject)
                setLocalProjectBackup(currentProject)
            }
        }
    }, [yourProjects])

    const handleEditProjectClick = (event, focus) => {
        event.stopPropagation()
        event.preventDefault()

        let extraFocus = ''
        if (focus) { extraFocus += `#${focus}` }

        history.push(`/projects/${localProjectCopy.id}/edit${extraFocus}`)
    }

    useEffect(() => {
        props.getYourProjects(userInfo.currentlyActiveOrganization)
        props.getGenres()
    }, [])

    const selectedOrganization = yourOrganizations.length > 0 && yourOrganizations.find(x => x.id === userInfo.currentlyActiveOrganization)
    const canEdit = localProjectCopy.organization && localProjectCopy.organization.id === userInfo.currentlyActiveOrganization

    const currentDate = new Date()
    const projectCriterias = []
    projectCriterias.push(localProjectCopy.name.length > 0 ? 1.0 : 0.0)
    projectCriterias.push(localProjectCopy.description.length > 0 ? 1.0 : 0.0)
    projectCriterias.push(localProjectCopy.coverImageUrl ? 1.0 : 0.0)
    projectCriterias.push(localProjectCopy.genre && localProjectCopy.genre.length > 0 ? 1.0 : 0.0)
    projectCriterias.push(localProjectCopy.releaseDate ? 1.0 : 0.0)
    projectCriterias.push(localProjectCopy.productionBudget > 0 ? 1.0 : 0.0)
    projectCriterias.push(localProjectCopy.marketingBudget > 0 ? 1.0 : 0.0)

    const projectStrength = projectCriterias.reduce((a, b) => a + b, 0) / projectCriterias.length
    let projectStrengthName = ''
    projectStrengths.forEach(strength => {
        if (projectStrength >= strength.minValue) { projectStrengthName = strength.name }
    })

    let orderedOptions = financeOptions

    orderedOptions = orderedOptions.filter(x => x.orgForm !== 1)
    orderedOptions = filterBasedOnOrgLocation(metadata.countiesMap, orderedOptions, selectedOrganization)
    orderedOptions = filterOnCompanyAge(orderedOptions, selectedOrganization)
    orderedOptions = filterBasedOnCultureTest(orderedOptions, yourProjects)
    orderedOptions = filterOnFlag(orderedOptions, yourProjects, 'stage', 'stage')
    orderedOptions = filterOnFlag(orderedOptions, yourProjects, 'gamePlayMode', 'gamePlayMode')
    orderedOptions = filterOnFlag(orderedOptions, yourProjects, 'gamePurpose', 'gamePurpose')
    orderedOptions = filterOnFlag(orderedOptions, yourProjects, 'businessModel', 'businessModel')
    orderedOptions = filterOnFlag(orderedOptions, yourProjects, 'companyRole', 'companyRole')
    orderedOptions = filterOnFlag(orderedOptions, yourProjects, 'pegiRating', 'pegiRating')
    orderedOptions = filterOnBool(orderedOptions, yourProjects, 'hasRightsToIp', 'rightsToIp')
    orderedOptions = filterOnBool(orderedOptions, yourProjects, 'isNarrative', 'containsCentralNarrativeThroughEntireGame')

    let orderedNonMatchingOptions = financeOptions.filter(financeOption => financeOption.orgForm !== 1 && !orderedOptions.find(matchingOption => matchingOption.id === financeOption.id))
    orderedOptions = orderedOptions.sort((a, b) => (a.name > b.name ? 1 : -1))
    orderedNonMatchingOptions = orderedNonMatchingOptions.sort((a, b) => (a.name > b.name ? 1 : -1))

    return (
        <div className={classes.root}>
            {
                canEdit &&
                <div className={classes.adminBar}>
                    <Typography variant={'body2'} 
                                style={{ 
                                    background: '#E43030', 
                                    color: '#FFF', 
                                    padding: '5px 10px', 
                                    userSelect: 'none', 
                                    borderRadius: '5px' 
                                }}>
                        <FormattedMessage
                            id={'Global.YouAreAdmin'}
                            defaultMessage={'Du kan redigere denne siden'}
                        />
                    </Typography>
                    {
                        canEdit &&
                        <Button className={classes.actionButton}
                                onClick={(event) => handleEditProjectClick(event, null)}
                                variant={'contained'}
                        >
                            <FormattedMessage
                                id={'Global.MakeEdits'}
                                defaultMessage={'Rediger siden'}
                            />
                        </Button>
                    }
                </div>
            }
            <Grid container spacing={2} alignItems={'center'}>
                <Grid item xs={12} sm={8} className={classes.headerContainer}>
                    <div {...getRootProps()}
                         className={classes.projectImageContainer}
                    >
                        <div style={{
                            backgroundImage: `url(${localProjectCopy.coverImageUrl})`,
                            backgroundSize: 'cover',
                            backgroundPosition: '50% 50%',
                            backgroundRepeat: 'no-repeat',
                            height: '100%',
                            width: '100%',
                            borderRadius: '10px',
                            position: 'absolute',
                            // opacity: '0.3',
                            zIndex: -1
                            // filter: 'blur(1px) saturate(0%)',
                        }}
                        />
                        <input {...getInputProps()} />
                        {
                            isDragActive
                                ? <ImageIcon/>
                                : <ImageIcon/>
                        }
                    </div>
                    <div className={classes.projectNameAndDescription}>
                        <Typography variant={'h4'}>
                            {localProjectCopy.name}
                        </Typography>
                        <Typography variant={'overline'}>
                            {selectedOrganization && selectedOrganization.name}
                        </Typography>
                    </div>
                </Grid>
                {
                    !viewAsMember && canEdit &&
                    <Grid item xs={12} sm={12} lg={7}>
                        <div className={classes.progress}>
                            <LinearProgress value={projectStrength * 100}
                                            variant={'determinate'}
                                            classes={{
                                                root: classes.barBackground,
                                                bar1Determinate: classes.barProgress
                                            }}
                            />
                            <Typography variant={'overline'} className={classes.progressText}>
                                <FormattedMessage
                                    id="Project.Strength"
                                    defaultMessage="Prosjektstyrke: {strength}"
                                    values={{
                                        strength: projectStrengthName
                                    }}
                                />
                            </Typography>
                        </div>
                    </Grid>
                }
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={7}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Card style={{ minHeight: '300px' }}>
                                <CardHeader
                                    title={'Om prosjektet'}
                                />
                                <CardContent>
                                    <Typography variant={'body2'}
                                                style={{ whiteSpace: 'pre-line' }}>
                                        {localProjectCopy.description}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12}>
                            <Card>
                                <CardHeader titleTypographyProps={{ variant: 'h6' }}
                                            title={<FormattedMessage
                                                id="Project.FundingOptionsHeader"
                                                defaultMessage={'Finansiering til ditt prosjekt'}
                                            />}
                                            subheader={<FormattedMessage
                                                id="Project.FundingOptionsSubHeader"
                                                defaultMessage={'Her kan du se finansiering som passer til ditt prosjekt, samt grunner til at noen finansieringskilder ikke passer.'}
                                            />}
                                />
                                <CardContent>
                                    {
                                        orderedOptions && orderedOptions.map((financeOption, index) => (
                                            <Accordion key={index}>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon/>}
                                                    aria-controls={localProjectCopy.id}
                                                    id={localProjectCopy.id}
                                                >
                                                    <Typography variant={'body1'}
                                                                style={{ display: 'flex', alignItems: 'center' }}
                                                    >
                                                        <CheckIcon style={{ marginRight: '10px' }}/>
                                                        {financeOption.name}
                                                    </Typography>
                                                </AccordionSummary>
                                                <AccordionDetails style={{ padding: '20px' }}>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                            <Typography variant={'h5'}>
                                                                {`${financeOption.organization.name}`}
                                                            </Typography>
                                                            <Button
                                                                variant={'contained'}
                                                                component={Link}
                                                                to={`/funding/${financeOption.id}`}
                                                            >
                                                                Sjekk finansieringsside
                                                            </Button>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Typography variant={'h6'} style={{
                                                                display: 'flex',
                                                                alignItems: 'center'
                                                            }}>
                                                                Kriterier for selskaper
                                                            </Typography>
                                                            <CriteriaStatus name={'Geografi'}
                                                                            didPass={checkOrganizationLocation(metadata.countiesMap, financeOption, selectedOrganization)}
                                                                            messageIfPass={'Selskap med din hovedadresse kan søke'}
                                                                            messageIfFail={`Kun selskap fra ${financeOption.counties.join(', ')} kan søke`}
                                                            />
                                                            {
                                                                financeOption.companyRole > 0 &&
                                                                <CriteriaStatus name={'Rolle'}
                                                                                didPass={checkFlag(financeOption, localProjectCopy, 'companyRole', 'companyRole')}
                                                                                messageIfPass={'Selskapet ditt har en rolle man kan søke for'}
                                                                                messageIfFail={`Kun selskaper med rolle ${flagToArray(financeOption.companyRole, companyRoleEnum).map(x => intl.formatMessage({ id: x })).join(', ')} kan søke`}
                                                                />
                                                            }
                                                            {
                                                                financeOption.companyAgeMin > 0 && financeOption.companyAgeMax < 999 &&
                                                                <CriteriaStatus name={'Selskapetsalder'}
                                                                                didPass={checkCompanyAge(financeOption, selectedOrganization)}
                                                                                messageIfPass={'Selskapet ditt har riktig alder for å søke'}
                                                                                messageIfFail={`Kun selskaper eldre enn ${financeOption.companyAgeMax} år og yngre enn ${financeOption.companyAgeMax} år kan søke`}
                                                                />
                                                            }
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Typography variant={'h6'} style={{
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                margin: '10px 0px'
                                                            }}>
                                                                Kriterier for prosjekter
                                                            </Typography>
                                                            <CriteriaStatus name={'Stadie'}
                                                                            didPass={checkFlag(financeOption, localProjectCopy, 'stage', 'stage')}
                                                                            messageIfPass={'Prosjektet ditt er i riktig stadiet for å søke'}
                                                                            messageIfFail={`Kun prosjekter i et av følgende stadier kan søke: ${flagToArray(financeOption.stage, projectStageEnums).map(x => intl.formatMessage({ id: x })).join(', ')}`}
                                                            />
                                                            {
                                                                financeOption.hasToPassCultureTest &&
                                                                <CriteriaStatus name={'Kulturtesten'}
                                                                                didPass={checkCultureTest(financeOption, localProjectCopy)}
                                                                                messageIfPass={'Prosjektet ditt har bestått kulturtesten'}
                                                                                messageIfFail={`Prosjektet ditt består ikke kulturtesten, som ${financeOption.name} krever`}
                                                                />
                                                            }
                                                            {
                                                                financeOption.isNarrative &&
                                                                <CriteriaStatus name={'Narrativt fokus'}
                                                                                didPass={checkBool(financeOption, localProjectCopy, 'isNarrative', 'containsCentralNarrativeThroughEntireGame')}
                                                                                messageIfPass={'Prosjektet ditt har et narrativt fokus'}
                                                                                messageIfFail={`Kun prosjekter med narrativt fokus kan søke på ${financeOption.name}`}
                                                                />
                                                            }
                                                            {
                                                                financeOption.hasRightsToIp &&
                                                                <CriteriaStatus name={'Rettigheter til IP'}
                                                                                didPass={checkBool(financeOption, localProjectCopy, 'hasRightsToIp', 'rightsToIp')}
                                                                                messageIfPass={'Prosjektet ditt har rettighetene til denne IP\'en'}
                                                                                messageIfFail={`Kun prosjekter med rettigheter til prosjektets IP kan søke på ${financeOption.name}`}
                                                                />
                                                            }
                                                            {
                                                                financeOption.gamePlayMode > 0 &&
                                                                <CriteriaStatus name={'Spillermodus'}
                                                                                didPass={checkFlag(financeOption, localProjectCopy, 'gamePlayMode', 'gamePlayMode')}
                                                                                messageIfPass={'Prosjektet ditt har en spillermodus man kan søke for'}
                                                                                messageIfFail={`Kun prosjekter med spillermodus ${flagToArray(financeOption.gamePlayMode, gamePlayModeEnum).map(x => intl.formatMessage({ id: x })).join(', ')} kan søke`}
                                                                />
                                                            }
                                                            {
                                                                financeOption.gamePurpose > 0 &&
                                                                <CriteriaStatus name={'Formål'}
                                                                                didPass={checkFlag(financeOption, localProjectCopy, 'gamePurpose', 'gamePurpose')}
                                                                                messageIfPass={'Prosjektet ditt har et formål man kan søke for'}
                                                                                messageIfFail={`Kun prosjekter med formål ${flagToArray(financeOption.gamePurpose, gamePurposeEnum).map(x => intl.formatMessage({ id: x })).join(', ')} kan søke`}
                                                                />
                                                            }
                                                            {
                                                                financeOption.businessModel > 0 &&
                                                                <CriteriaStatus name={'Forretningsmodell'}
                                                                                didPass={checkFlag(financeOption, localProjectCopy, 'businessModel', 'businessModel')}
                                                                                messageIfPass={'Prosjektet ditt har en forretningsmodell man kan søke for'}
                                                                                messageIfFail={`Kun prosjekter med forretningsmodell ${flagToArray(financeOption.businessModel, gameBusinessModelEnum).map(x => intl.formatMessage({ id: x })).join(', ')} kan søke`}
                                                                />
                                                            }
                                                            {
                                                                financeOption.pegiRating > 0 &&
                                                                <CriteriaStatus name={'PEGI'}
                                                                                didPass={checkFlag(financeOption, localProjectCopy, 'pegiRating', 'pegiRating')}
                                                                                messageIfPass={'Prosjektet ditt har en PEGI Rating man kan søke for'}
                                                                                messageIfFail={`Kun prosjekter med rating ${flagToArray(financeOption.pegiRating, pegiRatingEnum).map(x => intl.formatMessage({ id: x })).join(', ')} kan søke`}
                                                                />
                                                            }
                                                        </Grid>
                                                    </Grid>
                                                </AccordionDetails>
                                            </Accordion>
                                        ))
                                    }
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={showNonMatching}
                                                onChange={(event) => setShowNonMatching(event.target.checked)}
                                                value={'showNotMatching'}
                                                color="primary"
                                            />
                                        }
                                        label="Vis finansieringskilder prosjektet ikke kvalifiserer til"
                                    />
                                    {
                                        showNonMatching &&
                                        <div>
                                            {
                                                orderedNonMatchingOptions && orderedNonMatchingOptions.map((financeOption, index) => (
                                                    <Accordion key={index}>
                                                        <AccordionSummary
                                                            expandIcon={<ExpandMoreIcon/>}
                                                            aria-controls={localProjectCopy.id}
                                                            id={localProjectCopy.id}
                                                        >
                                                            <Typography variant={'body1'}
                                                                        style={{ display: 'flex', alignItems: 'center' }}
                                                            >
                                                                <ClearIcon style={{ marginRight: '10px' }}/>
                                                                {financeOption.name}
                                                            </Typography>
                                                        </AccordionSummary>
                                                        <AccordionDetails style={{ padding: '20px' }}>
                                                            <Grid container spacing={2}>
                                                                <Grid item xs={12} style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                    <Typography variant={'h5'}>
                                                                        {`${financeOption.organization.name}`}
                                                                    </Typography>
                                                                    <Button
                                                                        variant={'contained'}
                                                                        component={Link}
                                                                        to={`/funding/${financeOption.id}`}
                                                                    >
                                                                        Sjekk finansieringsside
                                                                    </Button>
                                                                </Grid>
                                                                <Grid item xs={6}>
                                                                    <Typography variant={'h6'} style={{
                                                                        display: 'flex',
                                                                        alignItems: 'center'
                                                                    }}>
                                                                        Kriterier for selskaper
                                                                    </Typography>
                                                                    <CriteriaStatus name={'Geografi'}
                                                                                    didPass={checkOrganizationLocation(metadata.countiesMap, financeOption, selectedOrganization)}
                                                                                    messageIfPass={'Selskap med din hovedadresse kan søke'}
                                                                                    messageIfFail={`Kun selskap fra ${financeOption.counties.join(', ')} kan søke`}
                                                                    />
                                                                    {
                                                                        financeOption.companyRole > 0 &&
                                                                        <CriteriaStatus name={'Rolle'}
                                                                                        didPass={checkFlag(financeOption, localProjectCopy, 'companyRole', 'companyRole')}
                                                                                        messageIfPass={'Selskapet ditt har en rolle man kan søke for'}
                                                                                        messageIfFail={`Kun selskaper med rolle ${flagToArray(financeOption.companyRole, companyRoleEnum).map(x => intl.formatMessage({ id: x })).join(', ')} kan søke`}
                                                                        />
                                                                    }
                                                                    {
                                                                        financeOption.companyAgeMin > 0 && financeOption.companyAgeMax < 999 &&
                                                                        <CriteriaStatus name={'Selskapetsalder'}
                                                                                        didPass={checkCompanyAge(financeOption, selectedOrganization)}
                                                                                        messageIfPass={'Selskapet ditt har riktig alder for å søke'}
                                                                                        messageIfFail={`Kun selskaper eldre enn ${financeOption.companyAgeMax} år og yngre enn ${financeOption.companyAgeMax} år kan søke`}
                                                                        />
                                                                    }
                                                                </Grid>
                                                                <Grid item xs={6}>
                                                                    <Typography variant={'h6'} style={{
                                                                        display: 'flex',
                                                                        alignItems: 'center',
                                                                        margin: '10px 0px'
                                                                    }}>
                                                                        Kriterier for prosjekter
                                                                    </Typography>
                                                                    <CriteriaStatus name={'Stadie'}
                                                                                    didPass={checkFlag(financeOption, localProjectCopy, 'stage', 'stage')}
                                                                                    messageIfPass={'Prosjektet ditt er i riktig stadiet for å søke'}
                                                                                    messageIfFail={`Kun prosjekter i et av følgende stadier kan søke: ${flagToArray(financeOption.stage, projectStageEnums).map(x => intl.formatMessage({ id: x })).join(', ')}`}
                                                                    />
                                                                    {
                                                                        financeOption.hasToPassCultureTest &&
                                                                        <CriteriaStatus name={'Kulturtesten'}
                                                                                        didPass={checkCultureTest(financeOption, localProjectCopy)}
                                                                                        messageIfPass={'Prosjektet ditt har bestått kulturtesten'}
                                                                                        messageIfFail={`Prosjektet ditt består ikke kulturtesten, som ${financeOption.name} krever`}
                                                                        />
                                                                    }
                                                                    {
                                                                        financeOption.isNarrative &&
                                                                        <CriteriaStatus name={'Narrativt fokus'}
                                                                                        didPass={checkBool(financeOption, localProjectCopy, 'isNarrative', 'containsCentralNarrativeThroughEntireGame')}
                                                                                        messageIfPass={'Prosjektet ditt har et narrativt fokus'}
                                                                                        messageIfFail={`Kun prosjekter med narrativt fokus kan søke på ${financeOption.name}`}
                                                                        />
                                                                    }
                                                                    {
                                                                        financeOption.hasRightsToIp &&
                                                                        <CriteriaStatus name={'Rettigheter til IP'}
                                                                                        didPass={checkBool(financeOption, localProjectCopy, 'hasRightsToIp', 'rightsToIp')}
                                                                                        messageIfPass={'Prosjektet ditt har rettighetene til denne IP\'en'}
                                                                                        messageIfFail={`Kun prosjekter med rettigheter til prosjektets IP kan søke på ${financeOption.name}`}
                                                                        />
                                                                    }
                                                                    {
                                                                        financeOption.gamePlayMode > 0 &&
                                                                        <CriteriaStatus name={'Spillermodus'}
                                                                                        didPass={checkFlag(financeOption, localProjectCopy, 'gamePlayMode', 'gamePlayMode')}
                                                                                        messageIfPass={'Prosjektet ditt har en spillermodus man kan søke for'}
                                                                                        messageIfFail={`Kun prosjekter med spillermodus ${flagToArray(financeOption.gamePlayMode, gamePlayModeEnum).map(x => intl.formatMessage({ id: x })).join(', ')} kan søke`}
                                                                        />
                                                                    }
                                                                    {
                                                                        financeOption.gamePurpose > 0 &&
                                                                        <CriteriaStatus name={'Formål'}
                                                                                        didPass={checkFlag(financeOption, localProjectCopy, 'gamePurpose', 'gamePurpose')}
                                                                                        messageIfPass={'Prosjektet ditt har et formål man kan søke for'}
                                                                                        messageIfFail={`Kun prosjekter med formål ${flagToArray(financeOption.gamePurpose, gamePurposeEnum).map(x => intl.formatMessage({ id: x })).join(', ')} kan søke`}
                                                                        />
                                                                    }
                                                                    {
                                                                        financeOption.businessModel > 0 &&
                                                                        <CriteriaStatus name={'Forretningsmodell'}
                                                                                        didPass={checkFlag(financeOption, localProjectCopy, 'businessModel', 'businessModel')}
                                                                                        messageIfPass={'Prosjektet ditt har en forretningsmodell man kan søke for'}
                                                                                        messageIfFail={`Kun prosjekter med forretningsmodell ${flagToArray(financeOption.businessModel, gameBusinessModelEnum).map(x => intl.formatMessage({ id: x })).join(', ')} kan søke`}
                                                                        />
                                                                    }
                                                                    {
                                                                        financeOption.pegiRating > 0 &&
                                                                        <CriteriaStatus name={'PEGI'}
                                                                                        didPass={checkFlag(financeOption, localProjectCopy, 'pegiRating', 'pegiRating')}
                                                                                        messageIfPass={'Prosjektet ditt har en PEGI Rating man kan søke for'}
                                                                                        messageIfFail={`Kun prosjekter med rating ${flagToArray(financeOption.pegiRating, pegiRatingEnum).map(x => intl.formatMessage({ id: x })).join(', ')} kan søke`}
                                                                        />
                                                                    }
                                                                </Grid>
                                                            </Grid>
                                                        </AccordionDetails>
                                                    </Accordion>
                                                ))
                                            }
                                        </div>
                                    }
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={5}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Card className={classes.tableCard}>
                                <CardHeader
                                    title={'Faktaboks'}
                                />
                                <CardContent>
                                    <Table className={classes.table} size="small">
                                        <TableBody>
                                            <TableRow>
                                                <TableCell component="th" scope="row" className={classes.tableCell}>
                                                    Sjangre/Tags
                                                </TableCell>
                                                <TableCell align="right" className={classes.tableCell}>
                                                    {
                                                        localProjectCopy.genres && localProjectCopy.genres.length > 0
                                                            ? localProjectCopy.genres.map((genre, index) => (
                                                                <Chip key={genre.id} size="small"
                                                                      label={genre.title}/>
                                                            ))
                                                            : 'Ikke satt'
                                                    }
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell component="th" scope="row" className={classes.tableCell}>
                                                    Utgivelsesdato
                                                </TableCell>
                                                <TableCell align="right" className={classes.tableCell}>
                                                    {localProjectCopy.releaseDate ? moment(localProjectCopy.releaseDate).format('L') : 'Not set'}
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell component="th" scope="row" className={classes.tableCell}>
                                                    Budsjett - Produksjon
                                                </TableCell>
                                                <TableCell align="right" className={classes.tableCell}>
                                                    <NumberFormat value={localProjectCopy.productionBudget}
                                                                  displayType={'text'}
                                                                  thousandSeparator={' '} prefix={'NOK '}/>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell component="th" scope="row" className={classes.tableCell}>
                                                    Budsjett - Markedsføring
                                                </TableCell>
                                                <TableCell align="right" className={classes.tableCell}>
                                                    <NumberFormat value={localProjectCopy.marketingBudget}
                                                                  displayType={'text'}
                                                                  thousandSeparator={' '} prefix={'NOK '}/>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell component="th" scope="row" className={classes.tableCell}>
                                                    Fase
                                                </TableCell>
                                                <TableCell align="right" className={classes.tableCell}>
                                                    {intl.formatMessage({ id: Object.keys(projectStageEnums).find(x => parseInt(projectStageEnums[x]) === parseInt(localProjectCopy.stage)) })}
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell component="th" scope="row" className={classes.tableCell}>
                                                    Forretningsmodell
                                                </TableCell>
                                                <TableCell align="right" className={classes.tableCell}>
                                                    {
                                                        localProjectCopy.businessModel
                                                            ? Object.keys(gameBusinessModelEnum).map((key, index) => {
                                                                if (localProjectCopy.businessModel & gameBusinessModelEnum[key]) {
                                                                    return <Chip
                                                                        key={gameBusinessModelEnum[key]}
                                                                        size="small" label={key}/>
                                                                } else { return null }
                                                            })
                                                            : 'Ikke satt'
                                                    }
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        error: state.project.error,
        yourOrganizations: state.organization.yourOrganizations,
        yourProjects: state.project.yourProjects,
        financeOptions: state.financeOption.financeOptions,
        userInfo: state.user.userInfo,
        genres: state.project.genres,
        metadata: state.metadata.metadata,
        fetchingGenres: state.project.fetchingGenres
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getYourProjects: (organizationId) => dispatch(getYourProjects(organizationId)),
        updateProjectCover: (formData) => dispatch(uploadCoverImage(formData)),
        deleteProject: (projectId) => dispatch(deleteProject(projectId)),
        patchProject: (patch, projectId) => dispatch(patchProject(patch, projectId)),
        getGenres: () => dispatch(getGenres())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ProjectPage))
