const initState = {
  fetchUserInfoError: '',
  userInfo: null,
  patchingUser: false,

  // Admin
  adminGettingUsers: false,
  adminAllUsers: []
}

const userReducer = (state = initState, action) => {
  switch (action.type) {
    case 'GET_USER_SUCCESS':
      return {
        ...state,
        fetchUserInfoError: '',
        userInfo: action.data
      }
    case 'GET_USER_ERROR':
      return {
        ...state,
        fetchUserInfoError: 'Failed to fetch user info'
      }

    case 'CHANGE_ORGANIZATION_SUCCESS':
      return {
        ...state,
        userInfo: action.data
      }

    case 'PATCH_USER.REQUEST':
    {
      return {
        ...state,
        patchingUser: true
      }
    }

    case 'PATCH_USER.SUCCESS':
    {
      return {
        ...state,
        userInfo: action.data,
        patchingUser: false
      }
    }

    case 'PATCH_USER.ERROR':
      return {
        ...state,
        error: action.error,
        patchingUser: false
      }

    case 'UPLOAD_PROFILEPICTURE.REQUEST':
    {
      return {
        ...state,
        patchingUser: true
      }
    }

    case 'UPLOAD_PROFILEPICTURE.SUCCESS':
    {
      return {
        ...state,
        userInfo: action.data,
        patchingUser: false
      }
    }

    case 'UPLOAD_PROFILEPICTURE.ERROR':
      return {
        ...state,
        error: action.error,
        patchingUser: false
      }

      // Admin
    case 'USER.ADMIN_GET_USERS.REQUEST':
    {
      return {
        ...state,
        adminGettingUsers: true
      }
    }

    case 'USER.ADMIN_GET_USERS.SUCCESS':
    {
      return {
        ...state,
        adminAllUsers: action.data,
        adminGettingUsers: false
      }
    }

    case 'USER.ADMIN_GET_USERS.ERROR':
      return {
        ...state,
        error: action.error,
        adminGettingUsers: false
      }

    case 'SIGNOUT_SUCCESS':
      return initState

    default:
      return state
  }
}

export default userReducer
