import React from 'react'
import Typography from '@mui/material/Typography'
import { connect } from 'react-redux'
import TermsOfServicePdf from '../assets/Terms of Service.pdf'
import PrivacyPdf from '../assets/Privacy Policy.pdf'

const TermsOfService = (props) => {
  return (
        <div>
            <Typography>
                Ved å registrere deg så godtar du sidens <a href={TermsOfServicePdf} target={'_blank'} rel="noreferrer">Vilkår</a> og <a href={PrivacyPdf} target={'_blank'} rel="noreferrer">Personvernregler</a>
            </Typography>
        </div>
  )
}

const mapStateToProps = (state) => {
  return {
  }
}

const mapDispatchToProps = (dispatch) => {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(TermsOfService)
