import {defineMessages} from '@formatjs/intl';

export const projectStageEnums = {
  Concept: 1,
  PreProduction: 2,
  Production: 4,
  Polish: 8,
  Released: 16,
  Versioning: 32
}

export const projectStageMessages = defineMessages({
  None: {
    id: 'None',
    defaultMessage: 'Ikke satt'
  },
  Concept: {
    id: 'Concept',
    defaultMessage: 'Konsept'
  },
  PreProduction: {
    id: 'PreProduction',
    defaultMessage: 'Pre-produksjon'
  },
  Production: {
    id: 'Production',
    defaultMessage: 'Produksjon'
  },
  Polish: {
    id: 'Polish',
    defaultMessage: 'Polering'
  },
  Released: {
    id: 'Released',
    defaultMessage: 'Utgitt'
  },
  Versioning: {
    id: 'Versioning',
    defaultMessage: 'Versjonering'
  }
})

export const projectStrengths = [
  {
    minValue: 0,
    name: 'Svak'
  },
  {
    minValue: 0.33,
    name: 'Middels'
  },
  {
    minValue: 0.66,
    name: 'Bra'
  },
  {
    minValue: 0.9,
    name: 'Utmerket'
  }
]

export const gameBusinessModelEnum = {
  Premium: 1,
  Subscription: 2,
  DLC: 4,
  Microtransaction: 8,
  Ads: 16,
  Free: 32
}

export const gameBusinessModelMessages = defineMessages({
  None: {
    id: 'None',
    defaultMessage: 'Ikke satt'
  },
  Premium: {
    id: 'Premium',
    defaultMessage: 'Premium'
  },
  Subscription: {
    id: 'Subscription',
    defaultMessage: 'Subscription'
  },
  DLC: {
    id: 'DLC',
    defaultMessage: 'DLC'
  },
  Microtransaction: {
    id: 'Microtransaction',
    defaultMessage: 'Microtransaction'
  },
  Ads: {
    id: 'Ads',
    defaultMessage: 'Ads'
  },
  Free: {
    id: 'Free',
    defaultMessage: 'Gratis'
  }
})

export const gamePlayModeEnum = {
  SinglePlayer: 1,
  LocalMultiplayer: 2,
  OnlineMultiplayer: 4
}

export const gamePlayModeMessages = defineMessages({
  None: {
    id: 'None',
    defaultMessage: 'Ikke satt'
  },
  SinglePlayer: {
    id: 'SinglePlayer',
    defaultMessage: 'SinglePlayer'
  },
  LocalMultiplayer: {
    id: 'LocalMultiplayer',
    defaultMessage: 'Local Multiplayer'
  },
  OnlineMultiplayer: {
    id: 'OnlineMultiplayer',
    defaultMessage: 'Online Multiplayer'
  }
})

export const pegiRatingEnum = {
  Pegi3: 1,
  Pegi7: 2,
  Pegi12: 4,
  Pegi16: 8,
  Pegi18: 16
}

export const pegiMessages = defineMessages({
  None: {
    id: 'None',
    defaultMessage: 'Ikke satt'
  },
  Pegi3: {
    id: 'Pegi3',
    defaultMessage: 'Pegi 3'
  },
  Pegi7: {
    id: 'Pegi7',
    defaultMessage: 'Pegi 7'
  },
  Pegi12: {
    id: 'Pegi12',
    defaultMessage: 'Pegi 12'
  },
  Pegi16: {
    id: 'Pegi16',
    defaultMessage: 'Pegi 16'
  },
  Pegi18: {
    id: 'Pegi18',
    defaultMessage: 'Pegi 18'
  }
})

export const esrbRatingEnum = {
  EsrbEveryone: 1,
  EsrbEveryone10: 2,
  EsrbTeen: 4,
  EsrbMature: 8,
  EsrbAdults: 16
}

export const esrbMessages = defineMessages({
  None: {
    id: 'None',
    defaultMessage: 'Ikke satt'
  },
  EsrbEveryone: {
    id: 'EsrbEveryone',
    defaultMessage: 'Esrb Everyone'
  },
  EsrbEveryone10: {
    id: 'EsrbEveryone10',
    defaultMessage: 'Esrb Everyone 10'
  },

  EsrbTeen: {
    id: 'EsrbTeen',
    defaultMessage: 'Esrb Teen'
  },
  EsrbMature: {
    id: 'EsrbMature',
    defaultMessage: 'Esrb Mature'
  },
  EsrbAdults: {
    id: 'EsrbAdults',
    defaultMessage: 'Esrb Adults'
  }
})

export const gamePurposeEnum = {
  Entertainment: 1,
  Education: 2,
  Promotion: 4
}

export const gamePurposeMessages = defineMessages({
  None: {
    id: 'None',
    defaultMessage: 'Ikke satt'
  },
  Entertainment: {
    id: 'Entertainment',
    defaultMessage: 'Entertainment'
  },
  Education: {
    id: 'Education',
    defaultMessage: 'Education'
  },
  Promotion: {
    id: 'Promotion',
    defaultMessage: 'Promotion'
  }
})

export const companyRoleEnum = {
  Developer: 1,
  Publisher: 2,
  Marketeer: 4
}

export const companyRoleMessages = defineMessages({
  None: {
    id: 'None',
    defaultMessage: 'Ikke satt'
  },
  Developer: {
    id: 'Developer',
    defaultMessage: 'Developer'
  },
  Publisher: {
    id: 'Publisher',
    defaultMessage: 'Publisher'
  },
  Marketeer: {
    id: 'Marketeer',
    defaultMessage: 'Marketeer'
  }
})
