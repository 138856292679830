import React, { useEffect, useState } from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import { makeStyles } from '@mui/styles'
import Dialog from '@mui/material/Dialog'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'
import { motion } from 'framer-motion'
import MobileStepper from '@mui/material/MobileStepper'
import { KeyboardArrowLeft } from '@material-ui/icons'

const useStyles = makeStyles(theme => ({
  yesButton: {
    height: '200px',
    borderRadius: '0px',
    // backgroundColor: '#337CA0',
    fontSize: '4em',
    transition: 'all 200ms ease-in-out',
    '&:hover': {
      // backgroundColor: '#2b6785',
    }
  },
  noButton: {
    height: '200px',
    borderRadius: '0px',
    // backgroundColor: '#FF3333',
    fontSize: '4em',
    transition: 'all 200ms ease-in-out',
    '&:hover': {
      // backgroundColor: '#dc2e2e',
    }
  }
}))

const CultureTestDialog = ({ cultureTestOpen, setCultureTestOpen, dataCopy, setDataCopy, setCompletedCultureTest, passedCultureTest }) => {
  const classes = useStyles()

  const [activeCultureTestStep, setActiveCultureTestStep] = useState(0)

  useEffect(() => {
    if (!cultureTestOpen) { setActiveCultureTestStep(0) }
  }, [cultureTestOpen])

  const handleCultureTestNext = (event, answer) => {
    switch (activeCultureTestStep) {
      case 1:
        setDataCopy({
          ...dataCopy,
          documentationInNorwegianOrSami: answer
        })
        break
      case 2:
        setDataCopy({
          ...dataCopy,
          relevantForNorwegianAudience: answer
        })
        break
      case 3:
        setDataCopy({
          ...dataCopy,
          mainPlotIsInNorwayOrEUOrFantasy: answer
        })
        break
      case 4:
        setDataCopy({
          ...dataCopy,
          containsCentralNarrativeThroughEntireGame: answer
        })

        setCompletedCultureTest(true)
        break
    }

    setActiveCultureTestStep((prevActiveStep) => prevActiveStep + 1)
  }

  const handleCultureTestBack = () => {
    setActiveCultureTestStep((prevActiveStep) => prevActiveStep - 1)
  }

  const handleCultureTestSetStep = (event) => {
    setActiveCultureTestStep(0)
  }

  return (
        <Dialog onClose={(event) => setCultureTestOpen(false)}
                aria-labelledby="culturetest"
                fullWidth
                maxWidth={'sm'}
                open={cultureTestOpen}>
            <div style={{ margin: '25px' }}>
                <Grid container>
                    <Grid item xs={12}
                          style={{ alignItems: 'center', justifyContent: 'center', marginBottom: '15px' }}>
                        {
                            activeCultureTestStep === 0 &&
                            <Typography variant={'h4'} align={'center'}>
                                Kulturtesten
                            </Typography>
                        }
                        {
                            activeCultureTestStep === 1 &&
                            <div>
                                <Typography variant={'h6'} align={'center'}>
                                    Manuskript eller litterært forelegg er originalskrevet på norsk eller samisk
                                </Typography>
                                <Typography variant={'body1'} align={'center'}>
                                    Er spillets dokumentasjon skrevet på norsk eller samisk? For spill vil det være
                                    en
                                    beskrivelse av spillet, innhold, eksempler på spilldesign og gameplay.
                                </Typography>
                            </div>
                        }
                        {
                            activeCultureTestStep === 2 &&
                            <div>
                                <Typography variant={'h6'} align={'center'}>
                                    Hovedtemaet er knyttet til norsk historie, kultur eller samfunnsforhold
                                </Typography>
                                <Typography variant={'body1'} align={'center'}>
                                    Er spillet relevant for et norsk publikum?
                                </Typography>
                            </div>
                        }
                        {
                            activeCultureTestStep === 3 &&
                            <div>
                                <Typography variant={'h6'} align={'center'}>
                                    Handlingen utspiller seg i Norge eller et annet EØS-land
                                </Typography>
                                <Typography variant={'body1'} align={'center'}>
                                    Foregår spillets handling i Norge, EU/EØS eller et Fantasiunivers?
                                </Typography>
                            </div>
                        }
                        {
                            activeCultureTestStep === 4 &&
                            <div>
                                <Typography variant={'h6'} align={'center'}>
                                    Verket har et vesentlig bidrag fra opphavsmenn eller utøvende kunstnere bosatt i
                                    Norge, et annet
                                    EØS-land
                                </Typography>
                                <Typography variant={'body1'} align={'center'}>
                                    For spill vil det være roller som kunstnerisk leder, producer, game director og
                                    designer, hvor det som er avgjørende at man må være registrert i Folkeregisteret
                                    eller tilsvarende som fast bosatt på en adresse i det aktuelle landet.
                                </Typography>
                            </div>
                        }
                        {
                            activeCultureTestStep === 5 &&
                            <Typography variant={'h6'} align={'center'}>
                                {passedCultureTest ? 'Du besto kulturtesten!' : 'Beklager, du besto ikke kulturtesten.'}
                            </Typography>
                        }
                    </Grid>
                    {
                        activeCultureTestStep === 0 &&
                        <Grid item xs={12}>
                            <div>
                                <Typography variant={'body1'} align={'center'}>
                                    Noen offentlige finansieringskilder som NFI krever at du består noe som heter
                                    kulturtesten
                                    for at de skal kunne støtte prosjektet ditt. Denne testen består av 4 krav, hvor
                                    du
                                    må
                                    bestå minimum 3 for å kvalifisere.
                                    Hovedutfordringen er hvordan du tolker disse, derfor har NFI lagt ut et eget
                                    dokument
                                    for tolkning av kulturtesten. Vi har lagt den ved under her, samt hentet utsnitt
                                    relevant for spill på hvert spørsmål i denne guiden.
                                </Typography>
                                <a href={'https://shybonsai.fra1.digitaloceanspaces.com/financeportal/Tolkning-av-kulturtest%20(2).pdf'}
                                   style={{ display: 'flex', justifyContent: 'center', textDecoration: 'none' }}
                                   target={'_blank'} rel="noreferrer">
                                    <Button style={{ margin: '25px' }}>
                                        <PictureAsPdfIcon/> NFI: Tolkning av Kulturtesten
                                    </Button>
                                </a>
                                <Button variant={'contained'}
                                        fullWidth
                                        onClick={event => handleCultureTestNext(event, true)}>
                                    Ta testen
                                </Button>
                            </div>
                        </Grid>
                    }
                    {
                        activeCultureTestStep !== 0 && activeCultureTestStep !== 5 &&
                        <Grid item xs={6}>
                            <Button fullWidth
                                    className={classes.noButton}
                                    onClick={event => handleCultureTestNext(event, false)}>
                                <motion.div
                                    initial={{ scale: 1 }}
                                    whileHover={{ scale: 1.3 }}
                                >
                                    👎
                                </motion.div>
                            </Button>
                        </Grid>
                    }
                    {
                        activeCultureTestStep !== 0 && activeCultureTestStep !== 5 &&
                        <Grid item xs={6}>
                            <Button fullWidth
                                    className={classes.yesButton}
                                    onClick={event => handleCultureTestNext(event, true)}>
                                <motion.div
                                    initial={{ scale: 1 }}
                                    whileHover={{ scale: 1.3 }}
                                >
                                    👍
                                </motion.div>
                            </Button>
                        </Grid>
                    }
                    {
                        activeCultureTestStep === 5 &&
                        <Grid item xs={12} style={{ display: 'flex' }}>
                            <Typography variant={'body1'}>
                                Da har vi lagret svarene dine og disse bidrar i forslagene vi gir deg senere. Ønsker
                                du
                                å ta testen på nytt eller er du ferdig?
                            </Typography>
                            <Button variant={'outlined'}
                                    style={{ width: '50%', margin: '10px' }}
                                    onClick={event => handleCultureTestSetStep(event, true)}>
                                Ta testen på nytt
                            </Button>
                            <Button variant={'contained'}
                                    style={{ width: '50%', margin: '10px' }}
                                    onClick={event => setCultureTestOpen(false)}>
                                Ferdig
                            </Button>
                        </Grid>
                    }
                </Grid>
            </div>
            {
                activeCultureTestStep !== 0 && activeCultureTestStep !== 5 &&
                <MobileStepper
                    variant="dots"
                    steps={5}
                    position="static"
                    activeStep={activeCultureTestStep}
                    // nextButton={
                    //     <Button size="small" onClick={handleCultureTestNext} disabled={activeCultureTestStep === 5}>
                    //         Next
                    //         <KeyboardArrowRight/>
                    //     </Button>
                    // }
                    backButton={
                        <Button size="small" onClick={handleCultureTestBack} disabled={activeCultureTestStep === 0}>
                            <KeyboardArrowLeft/>
                            Back
                        </Button>
                    }
                />
            }
        </Dialog>
  )
}

export default CultureTestDialog
