import React, { useEffect, useState } from 'react'

import FinanceOptionCard from '../components/FinanceOptionCard'
import Grid from '@mui/material/Grid'
import { makeStyles } from '@mui/styles'
import Typography from '@mui/material/Typography'
import { connect } from 'react-redux'
import { getFinanceOptions } from '../store/actions/financeOptionActions'
import { motion } from 'framer-motion'
import {
    filterBasedOnCultureTest,
    filterBasedOnOrgLocation, filterOnBool, filterOnCompanyAge,
    filterOnFlag
} from '../constants/filteringLogic'
import { patchOrganization } from '../store/actions/organizationActions'
import { useIntl } from 'react-intl'
import {
    Checkbox,
    CircularProgress,
    FormControlLabel,
    FormGroup,
    FormLabel,
    InputLabel, Stack, TextField,
    useMediaQuery
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import { groupBy} from 'lodash';
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {financeOptionOrgforms, financeOptionSectors, financeOptionTypes} from "../constants/financeOptionEnums";
import Button from "@mui/material/Button";
import {BottomSheet} from "react-spring-bottom-sheet";
import 'react-spring-bottom-sheet/dist/style.css';
import Paper from "@mui/material/Paper";
import {useTheme} from "@mui/material/styles";
import {Filter} from "@mui/icons-material";
import moment from "moment";

const useStyles = makeStyles(theme => ({
    root: {
        padding: '20px'
    },
    filterHeader: {
        display: 'flex'
    },
    columnHeader: {
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        borderRadius: '25px',
        transition: 'all 150ms ease-in-out',
        height: '40px',
        userSelect: 'none',
        '&:hover': {
            background: 'rgba(0, 0, 0, 0.2)'
        }
    },
    columnHeaderOrderIcon: {
        display: 'flex'
    },
    list: {
        padding: '0px',
        margin: '0px'
    },
    listItem: {
        listStyle: 'none',
        padding: '0px',
        margin: '10px auto'
    },
    missingOppositeContent: {
        '&:before': {
            content: 'none'
        }
    },
    bottomSheet: {
        '--rsbs-max-w': '640px',
        '--rsbs-ml': 'auto',
        '--rsbs-mr': 'auto',
        zIndex: 100000,
        '& [data-rsbs-overlay]': {
            backgroundColor: `var(--rsbs-bg, ${theme.palette.background.default})`,
        }
    },
}))

const spring = {
    type: 'spring',
    damping: 50,
    stiffness: 300
}

const listVariant = {
    initial: { scale: 1, opacity: 1 },
    enter: {
        scale: 1,
        opacity: 1,
        transition: {
            when: 'beforeChildren',
            staggerChildren: 0.3
        }
    }
}

const listItemVariant = {
    initial: { scale: 1, opacity: 0 },
    enter: { scale: 1, opacity: 1, transition: { duration: 0.5 } }
}

const FundingOverviewFilter = (props) => {
    const { counties, sectors, orgForms, fundingTypes, search,
        handleFilterRegionChecked, handleFilterSectorChecked, 
        handleFilterOrgFormChecked, handleFilterFundingTypeChecked,
        handleSearchChanged } = props;

    return (
        <Grid container>
            <Grid item xs={12}>
                <FormControl sx={{ m: 2 }} component="fieldset" variant="standard">
                    <FormLabel component="legend">Søk i beskrivelse</FormLabel>
                    <FormGroup>
                        <TextField
                            value={search}
                            onChange={handleSearchChanged}
                            placeholder="Søk..."
                        />
                    </FormGroup>
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <FormControl sx={{ m: 2 }} component="fieldset" variant="standard">
                    <FormLabel component="legend">Område</FormLabel>
                    <FormGroup>
                        {
                            Object.keys(counties).map((key, index) => {
                                return (
                                    <FormControlLabel
                                        key={index}
                                        control={
                                            <Checkbox checked={counties[key].selected} onChange={e => handleFilterRegionChecked(e, key)} name={key} />
                                        }
                                        label={key}
                                    />
                                )
                            })
                        }
                    </FormGroup> 
                </FormControl>
            </Grid>
            
            <Grid item xs={12}>
                <FormControl sx={{ m: 2 }} component="fieldset" variant="standard">
                    <FormLabel component="legend">Sektor</FormLabel>
                    <FormGroup>
                        {
                            sectors.map((sector, index) => {
                                return (
                                    <FormControlLabel
                                        key={index}
                                        control={
                                            <Checkbox checked={sectors.find(x => x.name === sector.name).selected} onChange={e => handleFilterSectorChecked(e, sector.name)} name={sector.name} />
                                        }
                                        label={sector.name}
                                    />
                                )
                            })
                        }
                    </FormGroup>
                </FormControl>
            </Grid>
            
            <Grid item xs={12}>
                <FormControl sx={{ m: 2 }} component="fieldset" variant="standard">
                    <FormLabel component="legend">Prosjekt eller organisasjon</FormLabel>
                    <FormGroup>
                        {
                            orgForms.map((orgForm, index) => {
                                return (
                                    <FormControlLabel
                                        key={index}
                                        control={
                                            <Checkbox checked={orgForms.find(x => x.name === orgForm.name).selected} onChange={e => handleFilterOrgFormChecked(e, orgForm.name)} name={orgForm.name} />
                                        }
                                        label={orgForm.name}
                                    />
                                )
                            })
                        }
                    </FormGroup>
                </FormControl>
            </Grid>
            
            <Grid item xs={12}>
                <FormControl sx={{ m: 2 }} component="fieldset" variant="standard">
                    <FormLabel component="legend">Type</FormLabel>
                    <FormGroup>
                        {
                            fundingTypes.map((type, index) => {
                                return (
                                    <FormControlLabel
                                        key={index}
                                        control={
                                            <Checkbox checked={fundingTypes.find(x => x.name === type.name).selected} onChange={e => handleFilterFundingTypeChecked(e, type.name)} name={type.name} />
                                        }
                                        label={type.name}
                                    />
                                )
                            })
                        }
                    </FormGroup>
                </FormControl>
            </Grid>
        </Grid>
    )
}

const FundingOverviewPage = (props) => {
    const { financeOptions, fetchingFinanceOptions, yourProjects, yourOrganizations, userInfo, metadata, fetchingProjects } = props
    const classes = useStyles()
    const intl = useIntl()

    const theme = useTheme();
    const smOrUp = useMediaQuery(theme.breakpoints.up('sm'));
    
    const [counties, setCounties] = useState([])
    const [sectors, setSectors] = useState([])
    const [orgForms, setOrgForms] = useState([])
    const [fundingTypes, setFundingTypes] = useState([])
    const [ordering, setOrdering] = useState("name-asc")
    const [date, changeDate] = useState(new Date())
    const [open, setOpen] = useState(true)
    const [search, setSearch] = useState('')

    const selectedOrganization = userInfo && yourOrganizations.find(x => x.id === userInfo.currentlyActiveOrganization)

    useEffect(() => {
        props.getFinanceOptions()
        
        setSectors(financeOptionSectors.map(x => {
            return {...x, selected: false}
        }))
        setOrgForms(financeOptionOrgforms.map(x => {
            return {...x, selected: false}
        }))
        setFundingTypes(financeOptionTypes.map(x => {
            return {...x, selected: false}
        }))
    }, [])

    useEffect(() => {
        const grouped = groupBy(props.metadata.countiesMap, x => x.county)
        Object.keys(grouped).forEach(key => grouped[key].selected = false)
        setCounties(grouped)
    }, [props.metadata.countiesMap])

    // Ensure it animates in when loaded
    useEffect(() => {
        setOpen(true)
    }, [])

    function onDismiss() {
        setOpen(false)
    }

    function handleFilterRegionChecked (event, key) {
        let countiesCopy = counties;

        countiesCopy[key].selected = event.target.checked;
        setCounties({...countiesCopy});
    }

    function handleFilterSectorChecked (event, name) {
        let sectorsCopy = sectors;

        sectorsCopy.find(x => x.name === name).selected = event.target.checked;
        setSectors([...sectorsCopy]);
    }

    function handleFilterOrgFormChecked (event, name) {
        let orgFormsCopy = orgForms;

        orgFormsCopy.find(x => x.name === name).selected = event.target.checked;
        setOrgForms([...orgFormsCopy]);
    }

    function handleFilterFundingTypeChecked (event, name) {
        let fundingTypesCopy = fundingTypes;

        fundingTypesCopy.find(x => x.name === name).selected = event.target.checked;
        setFundingTypes([...fundingTypesCopy]);
    }
    
    function handleOrderingChange (event) {
        setOrdering(event.target.value)
    }

    // let orderedOptions = financeOptions
    // const hasProjects = yourProjects.length > 0

    // if (!hasProjects) {
    //     orderedOptions = orderedOptions.filter(x => x.orgForm !== 0)
    // }
    // if (!selectedOrganization) {
    //     orderedOptions = orderedOptions.filter(x => x.orgForm !== 1)
    // }

    // orderedOptions = filterBasedOnOrgLocation(metadata.countiesMap, orderedOptions, selectedOrganization)
    // orderedOptions = filterOnCompanyAge(orderedOptions, selectedOrganization)
    // orderedOptions = filterBasedOnCultureTest(orderedOptions, yourProjects)
    // orderedOptions = filterOnFlag(orderedOptions, yourProjects, 'stage', 'stage')
    // orderedOptions = filterOnFlag(orderedOptions, yourProjects, 'gamePlayMode', 'gamePlayMode')
    // orderedOptions = filterOnFlag(orderedOptions, yourProjects, 'gamePurpose', 'gamePurpose')
    // orderedOptions = filterOnFlag(orderedOptions, yourProjects, 'businessModel', 'businessModel')
    // orderedOptions = filterOnFlag(orderedOptions, yourProjects, 'companyRole', 'companyRole')
    // orderedOptions = filterOnFlag(orderedOptions, yourProjects, 'pegiRating', 'pegiRating')
    // orderedOptions = filterOnBool(orderedOptions, yourProjects, 'hasRightsToIp', 'rightsToIp')
    // orderedOptions = filterOnBool(orderedOptions, yourProjects, 'isNarrative', 'containsCentralNarrativeThroughEntireGame')

    let filteredFundingOptions = financeOptions
    if (search.length > 0) {
        filteredFundingOptions = filteredFundingOptions.filter(x => x.name.includes(search) || x.description.includes(search))
    }
    const selectedCounties = Object.keys(counties).filter(key => counties[key].selected)
    const selectedSectors = sectors.filter(x => x.selected)
    const selectedOrgForms = orgForms.filter(x => x.selected)
    const selectedFundingTypes = fundingTypes.filter(x => x.selected)
    
    if(selectedCounties.length > 0) {
        filteredFundingOptions = filteredFundingOptions.filter(financeOption => financeOption.counties.some(county => selectedCounties.includes(county)))
    }
    if(selectedSectors.length > 0) {
        filteredFundingOptions = filteredFundingOptions.filter(fundingOption => selectedSectors.some(x => x.enum === fundingOption.sector))
    }
    if(selectedOrgForms.length > 0) {
        filteredFundingOptions = filteredFundingOptions.filter(fundingOption => selectedOrgForms.some(x => x.enum === fundingOption.orgForm))
    }
    if(selectedFundingTypes.length > 0) {
        filteredFundingOptions = filteredFundingOptions.filter(fundingOption => selectedFundingTypes.some(x => x.enum === fundingOption.type))
    }
    
    if (ordering === 'name-asc') {
        filteredFundingOptions = filteredFundingOptions.sort((a, b) => (a.name > b.name ? 1 : -1))
    } else if (ordering === 'name-desc') {
        filteredFundingOptions = filteredFundingOptions.sort((a, b) => (a.name > b.name ? -1 : 1))
    }  else if (ordering === 'orgForm') {
        filteredFundingOptions = filteredFundingOptions.sort((a, b) => ordering.orderAsc ? a.orgForm - b.orgForm : b.orgForm - a.orgForm)
    } else if (ordering === 'sector') {
        filteredFundingOptions = filteredFundingOptions.sort((a, b) => ordering.orderAsc ? a.sector - b.sector : b.sector - a.sector)
    } else if (ordering === 'type') {
        filteredFundingOptions = filteredFundingOptions.sort((a, b) => ordering.orderAsc ? a.type - b.type : b.type - a.type)
    } else if (ordering === 'deadline-asc') {
        filteredFundingOptions = filteredFundingOptions.sort((optionA, optionB) => {
            const optionADeadlines = optionA.deadlines.filter(x => moment(x).isAfter(moment())).sort((a, b) => new Date(a.deadline) - new Date(b.deadline))
            const optionBDeadlines = optionB.deadlines.filter(x => moment(x).isAfter(moment())).sort((a, b) => new Date(a.deadline) - new Date(b.deadline))

            if (optionADeadlines.length === 0 && optionBDeadlines.length > 0) { 
                return -1 
            } else if (optionBDeadlines.length === 0 && optionADeadlines.length > 0) { 
                return 1 
            } else if (optionADeadlines.length === 0 && optionBDeadlines.length === 0) { 
                return 0 
            }

            return new Date(optionBDeadlines[0].deadline) - new Date(optionADeadlines[0].deadline)
        })
    } else if (ordering === 'deadline-desc') {
        filteredFundingOptions = filteredFundingOptions.sort((optionA, optionB) => {
            const optionADeadlines = optionA.deadlines.filter(x => moment(x.deadline).isAfter(moment())).sort((a, b) => new Date(a.deadline) - new Date(b.deadline))
            const optionBDeadlines = optionB.deadlines.filter(x => moment(x.deadline).isAfter(moment())).sort((a, b) => new Date(a.deadline) - new Date(b.deadline))
            
            if (optionADeadlines.length === 0 && optionBDeadlines.length > 0) {
                return 1
            } else if (optionBDeadlines.length === 0 && optionADeadlines.length > 0) {
                return -1
            } else if (optionADeadlines.length === 0 && optionBDeadlines.length === 0) {
                return 0 
            }

            return new Date(optionADeadlines[0].deadline) - new Date(optionBDeadlines[0].deadline)
        })
    }

    return (
        <div>
            {
                !smOrUp &&
                <BottomSheet
                    open={open}
                    onDismiss={onDismiss}
                    defaultSnap={({ maxHeight }) => maxHeight / 2}
                    snapPoints={({ maxHeight }) => [
                        maxHeight - maxHeight / 10,
                        maxHeight / 4,
                        maxHeight * 0.6,
                    ]}
                    className={classes.bottomSheet}
                    backgroundComponent={null}
                >
                    <FundingOverviewFilter
                        counties={counties}
                        orgForms={orgForms}
                        sectors={sectors}
                        fundingTypes={fundingTypes}
                        search={search}
                        handleFilterRegionChecked={handleFilterRegionChecked}
                        handleFilterSectorChecked={handleFilterSectorChecked}
                        handleFilterOrgFormChecked={handleFilterOrgFormChecked}
                        handleFilterFundingTypeChecked={handleFilterFundingTypeChecked}
                        handleSearchChanged={event => setSearch(event.target.value)}
                    />
                </BottomSheet>
            }
            <div className={classes.root}>
            {
                selectedOrganization &&
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant={'h4'}>
                            Hei, Ole Andreas
                        </Typography>
                        <Typography variant={'body2'}>
                            Her finner du finansieringskilder som passer din bedrift og dine prosjekter
                        </Typography>
                    </Grid>
                    {
                        smOrUp &&
                        <Grid item xs={3}>
                            <FundingOverviewFilter
                                counties={counties}
                                orgForms={orgForms}
                                sectors={sectors}
                                fundingTypes={fundingTypes}
                                search={search}
                                handleFilterRegionChecked={handleFilterRegionChecked}
                                handleFilterSectorChecked={handleFilterSectorChecked}
                                handleFilterOrgFormChecked={handleFilterOrgFormChecked}
                                handleFilterFundingTypeChecked={handleFilterFundingTypeChecked}
                                handleSearchChanged={event => setSearch(event.target.value)}
                            />
                        </Grid>
                    }
                    <Grid item xs={12} sm={6}>
                        <Grid container spacing={2} alignItems={'center'}>
                            <Grid item xs={12}>
                                {
                                    !fetchingFinanceOptions &&
                                    <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                                        <Typography variant={'body1'}>
                                            {`${filteredFundingOptions.length} finansieringsordninger funnet.`}
                                        </Typography>
                                        <FormControl>
                                            <InputLabel id="order-by">Sortér på</InputLabel>
                                            <Select
                                                labelId="order-by"
                                                id="orderBy"
                                                value={ordering}
                                                label="Sortér på"
                                                onChange={handleOrderingChange}
                                            >
                                                <MenuItem value={"name-asc"}>Navn - Stigende</MenuItem>
                                                <MenuItem value={"name-desc"}>Navn - Synkende</MenuItem>
                                                <MenuItem value={"deadline-desc"}>Etter søknadsfrist</MenuItem>
                                            </Select>
                                        </FormControl>
                                        {
                                            !smOrUp &&
                                            <Button color={"primary"} variant={"contained"}
                                                    onClick={e => setOpen(true)}>
                                                Filter
                                            </Button>
                                        }
                                    </Stack>
                                }
                            </Grid>
                            {
                                fetchingFinanceOptions && 
                                    <Grid item xs={12}>
                                        <CircularProgress />
                                    </Grid>
                            }
                            {
                                !fetchingFinanceOptions && filteredFundingOptions.length > 0 && filteredFundingOptions.map((option, index) => (
                                    <Grid item xs={12} key={index}>
                                        <FinanceOptionCard option={option}/>
                                    </Grid>
                                ))
                            }
                        </Grid>
                    </Grid>
                </Grid>
            }
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        userInfo: state.user.userInfo,
        yourOrganizations: state.organization.yourOrganizations,
        yourProjects: state.project.yourProjects,
        fetchingProjects: state.project.fetchingProjects,
        metadata: state.metadata.metadata,
        financeOptions: state.financeOption.financeOptions,
        fetchingFinanceOptions: state.financeOption.fetchingFinanceOptions
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getFinanceOptions: () => dispatch(getFinanceOptions()),
        patchOrganization: (organization, id) => dispatch(patchOrganization(organization, id))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FundingOverviewPage)
