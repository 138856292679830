import React, { useEffect } from 'react'

import { makeStyles } from '@mui/styles'
import Typography from '@mui/material/Typography'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import Grid from '@mui/material/Grid'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import Divider from '@mui/material/Divider'

const useStyles = makeStyles(theme => ({
  root: {
    padding: '20px'
  }
}))

const WhatsNew = (props) => {
  const classes = useStyles()

  useEffect(() => {
  }, [])

  return (
        <div>
            <Grid container spacing={2} style={{maxHeight: '600px', overflowY: 'auto'}}>
                <Grid item xs={12}>
                    <Typography variant={'h6'}>
                        Versjon 2.0
                    </Typography>
                    <List>
                        <ListItem>
                            <ListItemText primary={'Helt ny drakt'} secondary={'Spillfinansiering.no har blitt redesignet for å tilby deg bedre og mer informasjon, samt støtter nå lys og mørk modus. Modus endrer du inne på din bruker, som du nå finner i menyen øverst til høyre.'} />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary={'Resursser'} secondary={'Vi støtter nå at det lastes opp ressurser for finansieringskilder, som kan merkes som offisielle eller uoffesielle. Disse kan lastes ned når du trenger litt ekstra hjelp til søknaden.'} />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary={'Tilskuddshistorikk'} secondary={'Vi støtter nå tilskuddshistorikk og du vil finne det på finansieringskilder hvor informasjonen har blitt lastes opp.'} />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary={'Favoritter'} secondary={'Du kan nå trykke på stjernen inne på en tilskuddsordning for å legge til som favoritt. Da kan du lettere finne den igjen på forsiden under favoritter.'} />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary={'Ny oversiktsside'} secondary={'Du kan nå filtrere og søke på en ny oversiktsside for alle finansieringskilder.'} />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary={'Nyheter'} secondary={'Vi viser nå en nyhetsliste på forsiden for å lettere se hva som har skjedd av endringer på tilskuddsordninger, samt andre hendelser som er valgt å dele.'} />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary={'Lokalisering'} secondary={'Mer tekst har blitt lokalisert. Vi har ikke 100% dekning enda, men jobber dit for norsk og engelsk.'} />
                        </ListItem>
                    </List>
                </Grid>
                <Divider />
                <Grid item xs={12}>
                    <Typography variant={'h6'}>
                        Versjon 1.3
                    </Typography>
                    <List>
                        <ListItem>
                            <ListItemText primary={'Oppgraderte varslinger'} secondary={'Nå kan du spesifisere om du ønsker varslinger på kun kommende frister eller innholdsoppdateringer på hver spesifikke finansieringskilde.'} />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary={'Mer informasjon om finansieringskilden'} secondary={'Mer informasjon om finasieringskilder har blitt gjort tilgjengelig på sidene til de enkelte finansieringskildene.'} />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary={'Feilrettinger'} secondary={'Fikset feil i tabellen som kobler kommune med fylke, som gjorde at fylker som har navn på både samisk og bokmål ikke ble koblet riktig.'} />
                        </ListItem>
                    </List>
                </Grid>
                <Divider />
                <Grid item xs={12}>
                    <Typography variant={'h6'}>
                        Versjon 1.2
                    </Typography>
                    <List>
                        <ListItem>
                            <ListItemText primary={'Om siden'} secondary={'Ny side som beskriver siden, hvem som er ansvarlig for den og hvem som har finansiert siden.'} />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary={'Sist oppdatert'} secondary={'Finansieringskilder har nå informasjon om når og hvem de ble sist oppdatert av.'} />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary={'Feilrettinger'} secondary={'Fikset feil på filtreringsfunksjonalitet som gjorde at feil antall finansieringskilder dukket opp på forsiden.'} />
                        </ListItem>
                    </List>
                </Grid>
                <Divider />
                <Grid item xs={12}>
                    <Typography variant={'h6'}>
                        Versjon 1.1
                    </Typography>
                    <List>
                        <ListItem>
                            <ListItemText primary={'Søkefunksjon'} secondary={'Lagt til søkefunksjon på siden.'} />
                        </ListItem>
                    </List>
                </Grid>
                <Divider />
                <Grid item xs={12}>
                    <Typography variant={'h6'}>
                        Versjon 1.0
                    </Typography>
                    <List>
                        <ListItem dense>
                            <ListItemText primary={'Lansering av portalen og tilgjengelig for alle.'} secondary={'Denne versjonen er å regne som en beta og noen feil og/eller mangler må påregnes. Vi ønsker så mange tilbakemeldinger som mulig på hva som burde fokuseres på i fremtidige versjoner.'} />
                        </ListItem>
                    </List>
                </Grid>
            </Grid>
        </div>
  )
}

const mapStateToProps = (state) => {
  return {
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(WhatsNew))
