import React, { useEffect, useState } from 'react'
import {Link as RouterLink, Link} from 'react-router-dom'

import * as ROUTES from '../constants/routes'

import PropTypes from 'prop-types'
import { makeStyles } from '@mui/styles'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import { useScrollTrigger } from '@mui/material'
import LanguageIcon from '@mui/icons-material/Language'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import { withRouter } from 'react-router'
import MenuItem from '@mui/material/MenuItem'
import {ReactComponent as NonedaIcon} from "../assets/Noneda_Icon.svg";
import {FormattedMessage} from "react-intl"
import Brightness4Icon from '@mui/icons-material/Brightness4';
import useMediaQuery from "@mui/material/useMediaQuery";

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1
    },
    appBar: {
        // background: 'linear-gradient(135deg, rgba(94,58,197,1) 0%, rgba(255,51,51,1) 100%) fixed',
    },
    toolBar: {
    },
    grow: {
        flexGrow: 1,
        textDecoration: 'none'
    },
    logoContainer: {
        marginRight: '5px !important',
    },
    logoText: {
        color: theme.palette.mode === 'dark' ? '#FFF' : '#253054',
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        }
    },
    logoIcon: {
        height: '30px',
        width: '30px',
        marginRight: '5px'
    },
}))

function ElevationScroll (props) {
    const { children, window } = props
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0,
        target: window ? window() : undefined
    })

    return React.cloneElement(children, {
        elevation: trigger ? 4 : 0
    })
}

ElevationScroll.propTypes = {
    children: PropTypes.element.isRequired,
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window: PropTypes.func
}

const LoggedOutNav = (props) => {
    const { content } = props
    const classes = useStyles()
    const [anchorEl, setAnchorEl] = React.useState(null)
    const [siteLanguage, setSiteLanguage] = useState('')
    const open = Boolean(anchorEl)

    const overridePreferDarkMode = localStorage.getItem('preferDarkMode')
    const defaultPrefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)')
    const preferDarkMode = overridePreferDarkMode != null ? Boolean(overridePreferDarkMode === 'true') : defaultPrefersDarkMode

    useEffect(() => {
        setSiteLanguage(localStorage.getItem('language'))
    }, [])

    const handleClose = () => {
        setAnchorEl(null)
    }

    const handleClick = event => {
        setAnchorEl(event.currentTarget)
    }

    const changeSiteLanguageChanged = (value) => {
        setSiteLanguage(value)
        localStorage.setItem('language', value)
        window.location.reload()

        handleClose()
    }

    const handleDarkModeClick = (event) => {
        event.preventDefault()

        if (preferDarkMode === true) {
            localStorage.setItem('preferDarkMode', 'false')
        } else if (preferDarkMode === false) {
            localStorage.setItem('preferDarkMode', 'true')
        }
        window.location.reload()
    }

    return (
        <div className={classes.root}>
            <ElevationScroll {...props}>
                <AppBar position="sticky" className={classes.appBar}>
                    <Toolbar className={classes.toolbar}>
                        <Button className={classes.logoContainer} component={RouterLink} to={'/'} >
                            <NonedaIcon className={classes.logoIcon} />
                            <Typography style={{ textDecoration: 'none' }} className={classes.logoText}>
                                <FormattedMessage
                                    id="Global.LogoText"
                                    defaultMessage='Funding Portal'
                                    description='The logo text used on most pages'
                                />
                            </Typography>
                        </Button>

                        <div className={classes.grow} />

                        <Button component={Link} to={ROUTES.SIGN_IN}>
                            <FormattedMessage
                                id="SignIn.Button"
                                defaultMessage='Sign In'
                                description='Sign In Button'
                            />
                        </Button>
                        <IconButton onClick={handleDarkModeClick}>
                            <Brightness4Icon />
                        </IconButton>
                        <IconButton onClick={handleClick}>
                            <LanguageIcon />
                        </IconButton>
                        <Menu
                            id="language-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={open}
                            onClose={handleClose}
                            PaperProps={{
                                style: {
                                    maxHeight: 200,
                                    width: 200
                                }
                            }}
                        >
                            <MenuItem value={'en'}
                                      selected={siteLanguage === 'en'}
                                      onClick={(event) => changeSiteLanguageChanged('en')}
                                      key={'en'}>
                                <FormattedMessage
                                    id="Languages.English"
                                    defaultMessage='English'
                                    description='Names of languages'
                                />
                            </MenuItem>
                            <MenuItem value={'nb'}
                                      selected={siteLanguage === 'nb'}
                                      onClick={(event) => changeSiteLanguageChanged('nb')}
                                      key={'nb'}>
                                <FormattedMessage
                                    id="Languages.Norwegian"
                                    defaultMessage='Norwegian'
                                    description='Names of languages'
                                />
                            </MenuItem>
                        </Menu>
                    </Toolbar>
                </AppBar>
            </ElevationScroll>
            <main className={classes.content}>
                {content}
            </main>
        </div>
    )
}

export default withRouter(LoggedOutNav)
