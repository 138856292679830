import React, { useState, useEffect } from 'react'
import clsx from 'clsx'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import Typography from '@mui/material/Typography'
import { makeStyles, useTheme } from '@mui/styles'
import TextField from '@mui/material/TextField'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import InputAdornment from '@mui/material/InputAdornment'
import axios from 'axios'
import NumberFormat from 'react-number-format'
import Button from '@mui/material/Button'
import Fade from '@mui/material/Fade'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import Stepper from '@mui/material/Stepper'
import StepLabel from '@mui/material/StepLabel'
import Step from '@mui/material/Step'
import { withStyles } from '@material-ui/styles'
import StepConnector from '@mui/material/StepConnector'
import PropTypes from 'prop-types'
import SearchIcon from '@mui/icons-material/Search'
import BusinessIcon from '@mui/icons-material/Business'
import MoneyIcon from '@mui/icons-material/AttachMoney'
import SignUpForm from '../components/SignUpForm'
import { signUp, signUpRequestAccess } from '../store/actions/authActions'
import { connect } from 'react-redux'
import { API_ROOT, configWithoutAuth } from '../store/api'
import CardContent from '@mui/material/CardContent'
import {FormattedMessage} from 'react-intl'
import Divider from '@mui/material/Divider'
import { ReactComponent as NonedaLogoWhite } from '../assets/Noneda_Logo_White.svg';
import { ReactComponent as NonedaLogoBlack } from '../assets/Noneda_Logo_Black.svg';

const useStyles = makeStyles(theme => ({
  root: {
    padding: '20px',
    marginBottom: '100px',
    maxWidth: '800px',
    display: 'flex',
    margin: 'auto'
  },
  logo: {
    height: '30px',
    margin: '5px auto',
    display: 'flex',
    justifyContent: 'center'
  },
  title: {
    fontSize: '3em'
  },
  helperText: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex'
  },
  button: {
    margin: '10px auto'
  },
  valueProp: {
    whiteSpace: 'pre-line'
  },
  searchList: {
    overflowY: 'auto',
    maxHeight: '200px',
    transition: '150ms all linear'
  },
  listItem: {
    '&:hover': {
      background: 'rbga(0, 0, 0, 0.2)'
    }
  },
  clickable: {
    cursor: 'pointer'
  }
}))

const steps = [
  'Søk',
  'Validér og fyll ut',
  'kom i gang!'
]

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 17
  },
  active: {
    '& $line': {
      backgroundColor: '#FFF'
    }
  },
  completed: {
    '& $line': {
      backgroundColor: '#FFF'
    }
  },
  line: {
    height: 2,
    border: 0,
    backgroundColor: 'rgba(255, 255, 255, 0.3)',
    borderRadius: 3,
    cursor: 'default'
  }
})(StepConnector)

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: 'rgba(255, 255, 255, 0.3)',
    zIndex: 1,
    color: '#fff',
    width: 35,
    height: 35,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center'
  },
  active: {
    backgroundColor: '#FFF',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)'
  },
  completed: {
    backgroundColor: '#FFF'
  },
  icon: {
    width: 18,
    height: 18,
    color: '#E43030'
  }
})

function ColorlibStepIcon (props) {
  const classes = useColorlibStepIconStyles()
  const { active, completed } = props

  const icons = {
    1: <SearchIcon className={classes.icon}/>,
    2: <BusinessIcon className={classes.icon}/>,
    3: <MoneyIcon className={classes.icon}/>
  }

  return (
      <div
          className={clsx(classes.root, {
            [classes.active]: active,
            [classes.completed]: completed
          })}
      >
        {icons[String(props.icon)]}
      </div>
  )
}

ColorlibStepIcon.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool,
  icon: PropTypes.node
}

const LandingPage = (props) => {
  const classes = useStyles()
  const theme = useTheme()

  const [activeStep, setActiveStep] = useState(0)
  const [searchResults, setSearchResults] = useState([])
  const [searchInput, setSearchInput] = useState('')
  const [selectedSearchResult, setSelectedSearchResult] = useState(null)
  const [completedStep, setCompletedStep] = useState(0)
  const [companyExists, setCompanyExists] = useState(false)

  useEffect(() => {
    const timer = setTimeout(() => {
      axios.get(`https://data.brreg.no/enhetsregisteret/api/enheter?navn=${searchInput}&konkurs=false`)
          .then(response => response.data)
          .then((data) => {
            setSearchResults(data._embedded && data._embedded.enheter ? data._embedded.enheter : [])
          })
    }, 300)
    return () => clearTimeout(timer)
  }, [searchInput])

  const handleSearchChange = event => {
    setSearchInput(event.target.value)
  }

  const handleSelectSearchResult = (event, result) => {
    setSelectedSearchResult(result)
    handleNext(result)
  }

  const handleNext = (result) => {
    if (activeStep === 0) {
      axios.get(`${API_ROOT}Organization/${result.organisasjonsnummer}/available`, configWithoutAuth())
          .then((result) => {
            if (result.data.toLowerCase() === 'available') { setCompanyExists(false) } else { setCompanyExists(true) }

            setActiveStep(prevActiveStep => prevActiveStep + 1)
            if (activeStep >= completedStep) { setCompletedStep(activeStep) }
          })
          .catch((error) => {
            console.log(error)
          })
    } else {
      setActiveStep(prevActiveStep => prevActiveStep + 1)
      if (activeStep >= completedStep) { setCompletedStep(activeStep) }
    }
  }

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1)
  }

  const handleStepChange = (event, step) => {
    if (completedStep < step) { return }

    setActiveStep(step)
  }

  const onSignUpCallback = (username, email, password) => {
    const newUser = {
      email: email,
      password: password
    }

    props.signUp(newUser, selectedSearchResult)
  }

  const onRequestAccessCallback = (username, email, password) => {
    const newUser = {
      email: email,
      password: password
    }

    props.signUpRequestAccess(newUser, selectedSearchResult.organisasjonsnummer)
  }

  return (
      <div className={classes.root}>
        <Grid container spacing={3} justifyContent={'center'} alignItems={'center'}>
          <Grid item xs={12} sm={8}>
            {
              theme.palette.mode === 'dark' ?
                  <NonedaLogoWhite className={classes.logo} />
                  :
                  <NonedaLogoBlack className={classes.logo} />
            }
            <Typography variant={'h3'} align={'center'} className={classes.title}>
              <FormattedMessage
                  id="Global.GameFunding"
                  defaultMessage='Spillfinansiering'
              />
            </Typography>
          </Grid>

          <Grid item xs={12} sm={8}>
            <div className={classes.helperText}>
              {
                  activeStep === 0 &&
                  <Fade in={activeStep === 0} exit={activeStep !== 0} duration={'500ms'}>
                    <Typography variant={'body1'} align={'center'} className={classes.valueProp}>
                      <FormattedMessage
                          id="LandingPage.Step1Description"
                          defaultMessage={'Denne portalen gir deg oversikt og tilpasset søk over finansieringsmuligheter for ditt spillselskap.\n\nSøk opp firmaet ditt under og kom i gang!'}
                          description='Step to explain value prop and ask to search'
                      />
                    </Typography>
                  </Fade>
              }
              {
                  activeStep === 1 && companyExists &&
                  <Fade in={activeStep === 1} exit={activeStep !== 1} duration={'500ms'}>
                    <Typography variant={'body1'} align={'center'} className={classes.valueProp}>
                      <FormattedMessage
                          id="LandingPage.StepAlreadyRegistered"
                          defaultMessage={'Bedriften din er allerede registrert. Ønsker du å be bedriftens administrator om tilgang?'}
                          description='Message when company is already registered'
                      />
                    </Typography>
                  </Fade>
              }
              {
                  activeStep === 1 && !companyExists &&
                  <Fade in={activeStep === 1} exit={activeStep !== 1} duration={'500ms'}>
                    <Typography variant={'body1'} align={'center'} className={classes.valueProp}>
                      <FormattedMessage
                          id="LandingPage.Step2Description"
                          defaultMessage={'Vennligst sjekk at informasjonen om selskapet ditt under stemmer, og fyll gjerne inn resterende felter.'}
                          description='Step to check if company information is correct'
                      />
                    </Typography>
                  </Fade>
              }
              {
                  activeStep === 2 &&
                  <Fade in={activeStep === 2} exit={activeStep !== 2} duration={'500ms'}>
                    <Typography variant={'body1'} align={'center'} className={classes.valueProp}>
                      <FormattedMessage
                          id="LandingPage.Step3Description"
                          defaultMessage={'For å se hvilke finansieringsmuligheter du har må må du lage en bruker. Du vil også få muligheten til å abonnere på oppdateringer ved endringer og kommende søknadsfrister.'}
                          description='Step to get started'
                      />
                    </Typography>
                  </Fade>
              }
            </div>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Stepper alternativeLabel
                     activeStep={activeStep}
                     connector={<ColorlibConnector/>}
            >
              {steps.map((label, index) => (
                  <Step key={label}
                        onClick={(event) => handleStepChange(event, index)}
                        disabled={index > completedStep}
                        className={classes.clickable}
                  >
                    <StepLabel StepIconComponent={ColorlibStepIcon}>
                      {label}
                    </StepLabel>
                  </Step>
              ))}
            </Stepper>
          </Grid>
          <Grid item xs={12} sm={8}>
            {
                activeStep === 0 &&
                <Fade in={activeStep === 0} exit={activeStep !== 0} duration={'500ms'}>
                  <Card>
                    <CardContent>
                      <TextField
                          margin="normal"
                          variant="outlined"
                          label="Selskapsnavn"
                          value={searchInput}
                          fullWidth
                          onChange={handleSearchChange}
                          color={'primary'}
                          helperText="F.eks. Krillbite Studio AS (Hentet fra Brønnøysund)"
                          InputProps={{
                            endAdornment: <InputAdornment position="end"><SearchIcon/></InputAdornment>
                          }}
                      />
                      <List dense className={classes.searchList}>
                        {
                            searchResults && searchResults.map((result, index) => (
                                <ListItem button
                                          key={index}
                                          color={'primary'}
                                          onClick={(event) => handleSelectSearchResult(event, result)}
                                >
                                  <ListItemText
                                      color={'primary'}
                                      primary={result.navn}
                                      secondary={<NumberFormat displayType={'text'}
                                                               value={result.organisasjonsnummer}
                                                               format="### ### ###"/>}
                                  />
                                </ListItem>
                            ))
                        }
                      </List>
                    </CardContent>
                  </Card>
                </Fade>
            }
            {
                activeStep === 1 && companyExists &&
                <Fade in={activeStep === 1} exit={activeStep !== 1} duration={'500ms'}>
                  <Card>
                    <CardContent>
                      <SignUpForm onSubmitCallback={onRequestAccessCallback} buttonMessage={'Be om tilgang'}/>
                    </CardContent>
                  </Card>
                </Fade>
            }
            {
                activeStep === 1 && !companyExists &&
                <Fade in={activeStep === 1} exit={activeStep !== 1} duration={'500ms'}>
                  <Card>
                    <CardContent>
                      <form>
                        <TextField
                            margin="normal"
                            variant="outlined"
                            label="Ditt firmanavn"
                            value={selectedSearchResult.navn}
                            fullWidth
                            disabled
                        />
                        <TextField
                            margin="normal"
                            variant="outlined"
                            label="Organisasjonsnummer"
                            value={selectedSearchResult.organisasjonsnummer}
                            fullWidth
                            disabled
                        />
                        <TextField
                            margin="normal"
                            variant="outlined"
                            label="Organisasjonsform"
                            value={selectedSearchResult.organisasjonsform.beskrivelse}
                            fullWidth
                            disabled
                        />
                        {
                            selectedSearchResult.naeringskode1 &&
                            <TextField
                                margin="normal"
                                variant="outlined"
                                label="Næringskode"
                                value={selectedSearchResult.naeringskode1.kode + ': ' + selectedSearchResult.naeringskode1.beskrivelse}
                                fullWidth
                                disabled
                            />
                        }
                        {
                            selectedSearchResult.stiftelsesdato &&
                            <TextField
                                margin="normal"
                                variant="outlined"
                                label="Stiftelsesdato"
                                value={selectedSearchResult.stiftelsesdato}
                                fullWidth
                                disabled
                            />
                        }
                        <TextField
                            margin="normal"
                            variant="outlined"
                            label="Registreringsdato"
                            value={selectedSearchResult.registreringsdatoEnhetsregisteret}
                            fullWidth
                            disabled
                        />
                        <FormControlLabel
                            control={
                              <Checkbox checked={selectedSearchResult.registrertIMvaregisteret}
                                        color={'primary'}
                                        value="MVA-registrert"
                              />
                            }
                            label="MVA-registrert"
                            disabled
                        />
                        <TextField
                            margin="normal"
                            variant="outlined"
                            label="Forretningsadresse"
                            value={`${selectedSearchResult.forretningsadresse.adresse[0]}, ${selectedSearchResult.forretningsadresse.postnummer} ${selectedSearchResult.forretningsadresse.poststed}, ${selectedSearchResult.forretningsadresse.land}`}
                            disabled
                            fullWidth
                        />
                        <Divider />
                        <TextField
                            margin="normal"
                            variant="outlined"
                            label="Hjemmeside"
                            value={selectedSearchResult.hjemmeside}
                            onChange={event => {
                              setSelectedSearchResult({
                                ...selectedSearchResult,
                                hjemmeside: event.target.value
                              })
                            }}
                            fullWidth
                        />
                        <TextField
                            margin="normal"
                            variant="outlined"
                            label="Antall ansatte"
                            type={'number'}
                            value={selectedSearchResult.antallAnsatte}
                            onChange={event => {
                              setSelectedSearchResult({
                                ...selectedSearchResult,
                                antallAnsatte: event.target.value
                              })
                            }}
                            fullWidth
                        />
                        <Button variant={'contained'}
                                className={classes.button}
                                onClick={handleNext}
                                fullWidth
                        >
                          Kom i gang
                        </Button>
                      </form>
                    </CardContent>
                  </Card>
                </Fade>
            }
            {
                activeStep === 2 &&
                <Fade in={activeStep === 2} exit={activeStep !== 2} duration={'500ms'}>
                  <Card>
                    <CardContent>
                      <SignUpForm onSubmitCallback={onSignUpCallback} buttonMessage={'Kom i gang'}/>
                    </CardContent>
                  </Card>
                </Fade>
            }
          </Grid>
        </Grid>
      </div>
  )
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.authError
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    signUp: (creds, organization) => dispatch(signUp(creds, organization)),
    signUpRequestAccess: (creds, orgnr) => dispatch(signUpRequestAccess(creds, orgnr))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LandingPage)
