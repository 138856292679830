import React, { useEffect } from 'react'

import { DataGrid } from '@mui/x-data-grid';
import { makeStyles } from '@mui/styles'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { adminGetAllUsers } from '../store/actions/userActions'
import AppBar from '@mui/material/AppBar'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'
import moment from 'moment'
import Avatar from '@mui/material/Avatar'
import {
    LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend
} from 'recharts'

const useStyles = makeStyles(theme => ({
    root: {
        padding: '20px'
    }
}))

const AdminPage = (props) => {
    const { adminAllUsers, adminGettingUsers } = props
    const classes = useStyles()

    const [value, setValue] = React.useState(0)

    useEffect(() => {
        props.adminGetAllUsers()
    }, [])

    const data = [
        {
            name: 'Page A', uv: 4000, pv: 2400, amt: 2400
        },
        {
            name: 'Page B', uv: 3000, pv: 1398, amt: 2210
        },
        {
            name: 'Page C', uv: 2000, pv: 9800, amt: 2290
        },
        {
            name: 'Page D', uv: 2780, pv: 3908, amt: 2000
        },
        {
            name: 'Page E', uv: 1890, pv: 4800, amt: 2181
        },
        {
            name: 'Page F', uv: 2390, pv: 3800, amt: 2500
        },
        {
            name: 'Page G', uv: 3490, pv: 4300, amt: 2100
        }
    ]

    return (
        <div className={classes.root}>
            <Typography variant={'overline'}>
                Gjør endringer og se statistikk på hvordan brukere bruker portalen
            </Typography>
            <Typography variant={'h4'}>
                Administratorpanel
            </Typography>
            <AppBar position="static" style={{ marginTop: '20px' }}>
                <Tabs
                    value={value}
                    onChange={(event, value) => setValue(value)}
                >
                    <Tab label="Brukere" tabIndex={0}/>
                    {/*<Tab label="Organisasjoner" tabIndex={1}/>*/}
                </Tabs>
            </AppBar>
            {
                value === 0 &&
                <div style={{ padding: '20px 0px' }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <Card>
                                <CardHeader
                                    title={'Brukere'}
                                    subheader={'Her kan du se oversikt over brukere i portalen'}
                                />
                                <CardContent>
                                    <div style={{ height: 600, width: '100%' }}>
                                        <div style={{ display: 'flex', height: '100%' }}>
                                            <div style={{ flexGrow: 1 }}>
                                                <DataGrid
                                                    columns={[
                                                        {
                                                            headerName: '',
                                                            field: 'profilePictureUrl',
                                                            width: 60,
                                                            filterable: false,
                                                            sortable: false,
                                                            renderCell: (params) =>
                                                                <Avatar src={params.value}/>,
                                                        },
                                                        {
                                                            headerName: 'Bruker',
                                                            field: 'email',
                                                            flex: 1,
                                                            valueGetter: params => params.row.name ? params.row.name : params.row.email,
                                                        },
                                                        {
                                                            headerName: 'Created',
                                                            field: 'createdAt',
                                                            width: 150,
                                                            renderCell: (params) =>
                                                                moment(params.value).format('LL'),
                                                        },
                                                        {
                                                            headerName: 'Last Active',
                                                            field: 'lastActiveAt',
                                                            width: 150,
                                                            renderCell: (params) =>
                                                                moment(params.value).format('LL'),
                                                        }
                                                    ]}
                                                    rows={adminAllUsers || []}
                                                    pageSize={50}
                                                    rowsPerPageOptions={[10]}
                                                    disableSelectionOnClick
                                                    initialState={{
                                                        sorting: {
                                                            sortModel: [
                                                                {
                                                                    field: 'lastActiveAt',
                                                                    sort: 'desc',
                                                                },
                                                            ],
                                                        },
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </CardContent>
                            </Card>
                        </Grid>
                        {/*<Grid item xs={12} sm={6}>*/}
                        {/*    <Card>*/}
                        {/*        <CardHeader*/}
                        {/*            title={'Brukere'}*/}
                        {/*            subheader={'Her kan du se oversikt over brukere i portalen'}*/}
                        {/*        />*/}
                        {/*        <CardContent>*/}
                        {/*            <LineChart*/}
                        {/*                width={500}*/}
                        {/*                height={300}*/}
                        {/*                data={data}*/}
                        {/*                margin={{*/}
                        {/*                    top: 5, right: 30, left: 20, bottom: 5*/}
                        {/*                }}*/}
                        {/*            >*/}
                        {/*                <CartesianGrid strokeDasharray="3 3" />*/}
                        {/*                <XAxis dataKey="name" />*/}
                        {/*                <YAxis />*/}
                        {/*                <Tooltip />*/}
                        {/*                <Legend />*/}
                        {/*                <Line type="monotone" dataKey="pv" stroke="#8884d8" activeDot={{ r: 8 }} />*/}
                        {/*                <Line type="monotone" dataKey="uv" stroke="#82ca9d" />*/}
                        {/*            </LineChart>*/}
                        {/*        </CardContent>*/}
                        {/*    </Card>*/}
                        {/*</Grid>*/}
                    </Grid>
                </div>
            }
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        adminAllUsers: state.user.adminAllUsers,
        adminGettingUsers: state.auth.adminGettingUsers
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        adminGetAllUsers: () => dispatch(adminGetAllUsers())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AdminPage))
