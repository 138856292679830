import React from 'react'
import Typography from '@mui/material/Typography'
import { makeStyles } from '@mui/styles'
import { Parallax } from 'react-parallax'

const useStyles = makeStyles(theme => ({
  root: {
    height: '300px',
    overflow: 'hidden',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '10px',
    userSelect: 'none',
    transition: 'all 150ms ease-out',
    color: '#FFFFFF66',
    cursor: 'pointer',
    position: 'relative',
    background: 'rgba(0, 0, 0, 0.12)',
    boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.1)',
    '&:hover': {
      color: '#FFFFFFFF',
      boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.3)'
    }
  },
  title: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    height: 'min-content',
    margin: 'auto',
    padding: '20px',
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex'
  },
  backgroundImageOverlay: {
    // filter: 'blur(1px) saturate(50%)',
  }
}))

const ProjectCard = (props) => {
  const classes = useStyles()

  const handleCardClicked = (event) => {
    event.stopPropagation()
    event.preventDefault()

    props.history.push(`projects/${props.project.id}`)
  }

  return (
        <div className={classes.root}
             onClick={handleCardClicked}
        >
            {
                props.project.coverImageUrl &&
                <Parallax
                    className={classes.backgroundImageOverlay}
                    bgImage={props.project.coverImageUrl}
                    strength={200}
                >
                    <div style={{ height: '300px' }}/>
                </Parallax>
            }
            <Typography variant={'h6'} className={classes.title}>
                {props.project.name}
            </Typography>
        </div>
  )
}

export default ProjectCard
