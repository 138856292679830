import React, { Component, useState } from 'react'

import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import withStyles from '@mui/material/styles/withStyles'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { makeStyles } from '@mui/styles'
import { resetPassword } from '../store/actions/authActions'
import Grid from '@mui/material/Grid'

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  button: {
    margin: '10px auto',
    height: '50px'
  }
}))

const PasswordChangeForm = (props) => {
  const classes = useStyles()
  const [form, setForm] = useState({
    currentPassword: '',
    newPassword: ''
  })

  const isValid = form.currentPassword !== form.newPassword && form.currentPassword.length > 7 && form.newPassword.length > 7

  const onSubmit = (event) => {
    event.preventDefault()
    event.stopPropagation()

    props.resetPassword(form)
  }

  const onChange = event => {
    setForm({
      ...form,
      [event.target.name]: event.target.value
    })
  }

  return (
        <form onSubmit={onSubmit} className={classes.container}>
            <Grid container spacing={2} justifyContent={'center'}>
                <Grid item xs={12}>
                    <TextField
                        name="currentPassword"
                        value={form.currentPassword}
                        onChange={onChange}
                        type="password"
                        placeholder="Current password"
                        fullWidth
                        variant={'outlined'}
                        className={classes.textField}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        name="newPassword"
                        value={form.newPassword}
                        onChange={onChange}
                        type="password"
                        placeholder="New Password"
                        fullWidth
                        variant={'outlined'}
                        className={classes.textField}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Button disabled={!isValid}
                            variant={'contained'}
                            type="submit"
                            fullWidth
                            className={classes.button}
                    >
                        Set new password
                    </Button>
                </Grid>
            </Grid>
        </form>
  )
}

const mapStateToProps = (state) => {
  return {
    resettingPassword: state.auth.resettingPassword
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    resetPassword: (form) => dispatch(resetPassword(form))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PasswordChangeForm))
