const initState = {
  authError: null,
  isAuthenticated: false,
  resettingPassword: false,
  isAuthenticating: false
}

const authReducer = (state = initState, action) => {
  switch (action.type) {
    case 'LOGIN_REQUEST':
      return {
        ...state,
        isAuthenticating: true,
        authError: null,
        isAuthenticated: false
      }

    case 'LOGIN_ERROR':
      return {
        ...state,
        isAuthenticating: false,
        authError: action.error,
        isAuthenticated: false
      }

    case 'LOGIN_SUCCESS':
      return {
        ...state,
        isAuthenticating: false,
        authError: null,
        isAuthenticated: true
      }

    case 'SIGNUP_REQUEST':
      return {
        ...state,
        isAuthenticating: true,
        authError: null,
        isAuthenticated: false
      }

    case 'SIGNUP_SUCCESS':
      window.localStorage.setItem('authBody', action.data.token)
      return {
        ...state,
        isAuthenticating: false,
        authError: null,
        isAuthenticated: true
      }

    case 'SIGNUP_ERROR':
      return {
        ...state,
        isAuthenticating: false,
        authError: action.error,
        isAuthenticated: false
      }

    case 'REQUEST_ACCESS_REQUEST':
      return {
        ...state,
        isAuthenticating: true,
        authError: null,
        isAuthenticated: false
      }

    case 'REQUEST_ACCESS_SUCCESS':
      window.localStorage.setItem('authBody', action.data.token)
      return {
        ...state,
        isAuthenticating: false,
        authError: null,
        isAuthenticated: true
      }

    case 'REQUEST_ACCESS_ERROR':
      return {
        ...state,
        isAuthenticating: false,
        authError: action.error,
        isAuthenticated: false
      }

    case 'RESET_PASSWORD_REQUEST':
      return {
        ...state,
        authError: '',
        resettingPassword: true
      }

    case 'RESET_PASSWORD_SUCCESS':
      return {
        ...state,
        authError: '',
        resettingPassword: false
      }

    case 'RESET_PASSWORD_ERROR':
      return {
        ...state,
        authError: action.error,
        resettingPassword: false
      }

    case 'RESET_PASSWORD_REQUEST_PIN.REQUEST':
      return {
        ...state,
        authError: '',
        resettingPassword: true
      }

    case 'RESET_PASSWORD_REQUEST_PIN.SUCCESS':
      return {
        ...state,
        authError: '',
        resettingPassword: false
      }

    case 'RESET_PASSWORD_REQUEST_PIN.ERROR':
      return {
        ...state,
        authError: action.error,
        resettingPassword: false
      }

    case 'RESET_PASSWORD_PIN.REQUEST':
      return {
        ...state,
        authError: '',
        resettingPassword: true
      }

    case 'RESET_PASSWORD_PIN.SUCCESS':
      return {
        ...state,
        authError: '',
        resettingPassword: false
      }

    case 'RESET_PASSWORD_PIN.ERROR':
      return {
        ...state,
        authError: action.error,
        resettingPassword: false
      }

    case 'SIGNOUT_SUCCESS':
      window.localStorage.removeItem('authBody')
      return initState

    default:
      return state
  }
}

export default authReducer
