const initState = {
    news: [],
    fetchingNews: false,
    posingNews: false,
    error: ''
}

let newsCopy

const newsReducer = (state = initState, action) => {
    switch (action.type) {
        case 'GET_NEWS_REQUEST':
            return {
                ...state,
                error: '',
                fetchingNews: true
            }

        case 'GET_NEWS_SUCCESS':
            return {
                ...state,
                error: '',
                fetchingNews: false,
                news: action.data
            }
        case 'GET_NEWS_ERROR':
            return {
                ...state,
                fetchingNews: false,
                error: 'Failed to fetch news'
            }

        case 'POST_NEWS_REQUEST':
            return {
                ...state,
                error: '',
                postingNews: true
            }

        case 'POST_NEWS_SUCCESS':
            newsCopy = state.news
            newsCopy.push(action.data)

            return {
                ...state,
                error: '',
                postingNews: false,
                news: newsCopy
            }
        case 'POST_NEWS_ERROR':
            return {
                ...state,
                postingNews: false,
                error: 'Failed to fetch news'
            }

        default:
            return state
    }
}

export default newsReducer
