import React, { useEffect, useState } from 'react'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import { signIn, signOut } from '../store/actions/authActions'
import { connect } from 'react-redux'
import { makeStyles } from '@mui/styles'
import Fade from '@mui/material/Fade'
import CircularProgress from '@mui/material/CircularProgress'
import CardContent from '@mui/material/CardContent'
import CardActions from '@mui/material/CardActions'
import {FormattedMessage} from 'react-intl'
import { Link } from 'react-router-dom'
import * as queryString from 'query-string'
import {useTheme} from "@mui/material/styles";

const useStyles = makeStyles(theme => ({
  root: {
    padding: '20px'
  },
}))

const SignInPage = (props) => {
  const { authError, isAuthenticating } = props
  const classes = useStyles()
  const theme = useTheme()

  const [signInForm, setSignInForm] = useState({
    email: '',
    password: ''
  })

  useEffect(() => {
    props.signOut()
    const params = queryString.parse(props.location.search)
    if (params.email != null) {
      setSignInForm({
        ...signInForm,
        email: params.email
      })
    }
  }, [])

  const onSubmit = event => {
    event.preventDefault()
    props.signIn(signInForm)
  }

  const onChange = event => {
    setSignInForm({
      ...signInForm,
      [event.target.name]: event.target.value
    })
  }

  const isInvalid = signInForm.password === '' || signInForm.email === ''

  return (
        <div className={classes.root}>
            <Grid container spacing={5} justifyContent={'center'} alignContent={'center'}>
                <Grid item xs={12} sm={5}>
                    <Fade in timeout={500}>
                        <Card>
                            <form onSubmit={onSubmit} style={{ padding: 20 }}>
                                <CardContent>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <Typography variant={'h6'} align={'center'} marginBottom>
                                                <FormattedMessage
                                                    id="SignIn.Greeting"
                                                    defaultMessage='Welcome back, just sign in below to get started'
                                                    description='Greeting to users who are logging in'
                                                />
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                name="email"
                                                value={signInForm.email}
                                                onChange={onChange}
                                                type="text"
                                                label={
                                                    <FormattedMessage
                                                        id="Global.EmailTextField"
                                                        defaultMessage='Email address'
                                                        description='Label for the email address text field'
                                                    />
                                                }
                                                fullWidth
                                                variant={'outlined'}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                name="password"
                                                value={signInForm.password}
                                                onChange={onChange}
                                                type="password"
                                                label={
                                                    <FormattedMessage
                                                        id="Global.PasswordTextField"
                                                        defaultMessage='Password'
                                                        description='Label for the password text field'
                                                    />
                                                }
                                                fullWidth
                                                variant={'outlined'}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            {authError && <p>{authError}</p>}
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Button disabled={isInvalid || isAuthenticating}
                                                    variant={'contained'}
                                                    fullWidth
                                                    type="submit">
                                                {
                                                    isAuthenticating
                                                      ? <CircularProgress size={24}/>
                                                      : <FormattedMessage
                                                            id="SignIn.Button"
                                                            defaultMessage='Sign In'
                                                            description='Sign In Button'
                                                        />
                                                }
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                                <CardActions>
                                    <Typography variant={'caption'} component={Link} to={`reset-password?email=${signInForm.email}`} style={{ margin: 'auto auto' }}>
                                        Jeg har glemt passordet mitt
                                    </Typography>
                                </CardActions>
                            </form>
                        </Card>
                    </Fade>
                </Grid>
            </Grid>
        </div>
  )
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.authError,
    isAuthenticating: state.auth.isAuthenticating
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    signIn: (creds) => dispatch(signIn(creds)),
    signOut: () => dispatch(signOut())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SignInPage)
