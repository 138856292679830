import {defineMessages} from '@formatjs/intl';

export const financeOptionTypes = [
  {
    enum: 0,
    name: 'Grant'
  },
  {
    enum: 1,
    name: 'Loan'
  },
  {
    enum: 2,
    name: 'Investment'
  }
]

export const financeOptionTypeMessages = defineMessages({
  Grant: {
    id: 'Grant',
    defaultMessage: 'Grant'
  },
  Loan: {
    id: 'Loan',
    defaultMessage: 'Loan'
  },
  Investment: {
    id: 'Investment',
    defaultMessage: 'Investment'
  }
})

export const subscriptionTypes = [
  {
    enum: 0,
    name: 'None'
  },
  {
    enum: 1,
    name: 'Deadlines'
  },
  {
    enum: 2,
    name: 'Content'
  }
]

export const financeOptionSectors = [
  {
    enum: 0,
    name: 'Public'
  },
  {
    enum: 1,
    name: 'Private'
  }
]

export const financeOptionSectorMessages = defineMessages({
  Grant: {
    id: 'Public',
    defaultMessage: 'Public'
  },
  Loan: {
    id: 'Private',
    defaultMessage: 'Private'
  }
})

export const financeOptionOrgforms = [
  {
    enum: 0,
    name: 'Project'
  },
  {
    enum: 1,
    name: 'Company'
  }
]

export const financeOptionOrgformMessages = defineMessages({
  Grant: {
    id: 'Project',
    defaultMessage: 'Project'
  },
  Loan: {
    id: 'Company',
    defaultMessage: 'Company'
  }
})
