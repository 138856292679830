import React, { useState } from 'react'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import { connect } from 'react-redux'
import CircularProgress from '@mui/material/CircularProgress'
import TermsOfService from './TermsOfService'

const SignUpForm = (props) => {
  const { buttonMessage, isAuthenticating } = props
  const [signUpForm, setSignUpForm] = useState({
    username: '',
    email: '',
    passwordOne: '',
    passwordTwo: ''
  })

  const onSubmit = event => {
    event.preventDefault()
    const { onSubmitCallback } = props

    if (onSubmitCallback) { onSubmitCallback(signUpForm.username, signUpForm.email, signUpForm.passwordOne) }
  }

  const onChange = event => {
    setSignUpForm({
      ...signUpForm,
      [event.target.name]: event.target.value
    })
  }

  const isInvalid =
        signUpForm.passwordOne !== signUpForm.passwordTwo ||
        signUpForm.passwordOne === '' ||
        signUpForm.email === ''

  return (
        <div>
            <form onSubmit={onSubmit}>
                <Grid container spacing={2} justifyContent={'center'} alignContent={'center'}>
                    <Grid item xs={12}>
                        <TextField
                            autoFocus
                            name="email"
                            value={signUpForm.email}
                            onChange={onChange}
                            type="text"
                            label={'Email'}
                            fullWidth
                            variant={'filled'}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            name="passwordOne"
                            value={signUpForm.passwordOne}
                            onChange={onChange}
                            type="password"
                            label={'Password'}
                            fullWidth
                            variant={'filled'}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            name="passwordTwo"
                            value={signUpForm.passwordTwo}
                            onChange={onChange}
                            type="password"
                            fullWidth
                            label={'Repeat password'}
                            variant={'filled'}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button disabled={isInvalid}
                                variant={'contained'}
                                fullWidth
                                type="submit">
                            {
                                isAuthenticating
                                  ? <CircularProgress size={24} />
                                  : props.buttonMessage
                            }
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        <TermsOfService />
                        {/* {error && <p>{error.message}</p>} */}
                    </Grid>
                </Grid>
            </form>
        </div>
  )
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.authError,
    isAuthenticating: state.auth.isAuthenticating
  }
}

const mapDispatchToProps = (dispatch) => {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(SignUpForm)
